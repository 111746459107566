import { useSelector } from 'react-redux';
import { selectPermissionsState } from '@/store/slices/AuthSlice';
import Code401 from '../Errors/Code401';

// This component is meant to be used everywhere a restriction based on user permission is needed
function Restricted({
  to,
  toComponent,
  children,
  isPage,
  subList = 'user_perms',
}) {
  const permissions = useSelector(selectPermissionsState);
  const isAllowedTo = (permission, permissionComponent, store) => {
    if (store !== undefined) {
      if (permissionComponent === undefined) {
        if (subList !== 'user_perms' || Array.isArray(store)) {
          return store.includes(permission);
        }
        return store[permission];
      }
      if (store[permission] !== undefined) {
        return store[permission].includes(permissionComponent);
      }
    }
    return false;
  };

  // If the user has that permission, render the children
  if (
    to === undefined ||
    (to !== undefined && to.length === 2
      ? isAllowedTo(to[0], to[1], permissions[subList])
      : isAllowedTo(to, toComponent, permissions[subList]))
  ) {
    return [children];
  }

  // If is a page and the first element is the option of subList
  if (
    isPage &&
    to !== undefined &&
    permissions[to[0]] !== undefined &&
    isAllowedTo(to[1], to[2], permissions[to[0]])
  ) {
    return [children];
  }

  // If we try yo enter to a page, without permission, then render error
  if (isPage) {
    return <Code401 />;
  }
  // Otherwise, do not render anything
  return null;
}

export default Restricted;
