function DisplayName({ channel, displayTitle }) {
  const channelTypes = {
    WhatsAppGuest: {
      name: channel.data?.channel_header?.phone_number,
    },
    WhatsAppContact: {
      name: channel.data?.channel_header?.description,
    },
    Colleague: {
      name: displayTitle,
    },
    team: {
      name: displayTitle,
    },
  };
  const getName = () => channelTypes[channel.data.type]?.name || '';

  return <h4>{getName()}</h4>;
}

export default DisplayName;
