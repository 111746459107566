import {
  Box,
  Datepicker,
  Dropdown,
  Icon,
  Input,
  Label,
  Text,
  Textarea,
} from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import { es as locale } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { addMinutes, differenceInMinutes, format, set } from 'date-fns';
import InputsHours from './InputsHours/InputsHours';
import ModalRepeatEvent from './ModalRepeatEvent/ModalRepeatEvent';
import style from './DetailsContent.module.scss';
import {
  changerValue,
  changeEvent,
  toggleDescription,
  changeTypeSelected,
  changeDragTime,
  setScroll,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import DropDownRepeatEvent from './DropDownRepeatEvent/DropDownRepeatEvent';
import AgentsData from './AgentsData/AgentsData';
import PropertiesData from './PropertiesData/PropertiesData';
import ContactsData from './ContactsData/ContactsData';
import {
  getAgents,
  getContacts,
  getProperties,
  getTypes,
} from '@/store/slices/components/calendarEvents/services';
import translations from './DetailsContent.trans.json';
import Trans from '@/components/shared/Trans';

export default function DetailsContent() {
  const [showRepeatModal, setShowReapeatModal] = useState(false);
  const { event, description, types, errors } = useSelector(
    (state) => state.CalendarEvents
  );
  const dispatch = useDispatch();
  const { start, end, title } = event[0];
  const inpiutTitleRef = useRef();

  const toggleRepeatModal = () => setShowReapeatModal(!showRepeatModal);

  const handlePickerChange = (date) => {
    if (start) {
      const diff = differenceInMinutes(end, start);
      const [d, m, y] = format(date, 'dd/MM/yyyy').split('/');
      const setTime = set(start, {
        year: Number(y),
        month: Number(m) - 1,
        date: Number(d),
        seconds: 0,
      });

      const obj = {
        start: setTime,
        end: addMinutes(setTime, diff),
      };

      dispatch(changeEvent(obj));
      dispatch(changeDragTime(obj));
      dispatch(setScroll(obj.start));
    }
  };

  let debounceTimer;
  const handleAutoComplete = (query, endPoint) => {
    const clearQuery = query.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(async () => {
        const EP = {
          agents: () => dispatch(getAgents(clearQuery)),
          properties: () => dispatch(getProperties(clearQuery)),
          contacts: () => dispatch(getContacts(clearQuery)),
        };

        EP[endPoint]();
      }, 1500);
    }
  };

  useEffect(() => {
    if (!title) {
      inpiutTitleRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!types.typesData.length) {
      dispatch(getTypes());
    }
  }, []);

  const handleChangeTitle = (e) => {
    const inputValue = e.target.value;
    dispatch(
      changeEvent({
        title: inputValue.startsWith(' ') ? inputValue.trim() : inputValue,
      })
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === ' ' && title.endsWith(' ')) {
      e.preventDefault();
    }
  };

  return (
    <Box __css={{ marginTop: '30px' }}>
      {showRepeatModal && <ModalRepeatEvent closeModal={toggleRepeatModal} />}
      <Box className={style.form}>
        <Box className={style.row}>
          <Box className={style.column}>
            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'EventTitle')}
                </Text>
              </Label>
              <Input
                ref={inpiutTitleRef}
                infoAlert={errors?.title && ''}
                maxLength="60"
                type="text"
                autoComplete="off"
                placeholder={Trans(translations, 'EventTitle')}
                value={title}
                onChange={handleChangeTitle}
                onKeyPress={handleKeyPress}
              />
            </Box>

            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'EventType')}
                </Text>
              </Label>
              <Dropdown height="32px" width="257px">
                <Dropdown.Button
                  className={style.ddButton}
                  type="button"
                  variant="dropdownDefault"
                  text={
                    types.typeSelected?.name || Trans(translations, 'Select')
                  }
                />
                <Dropdown.Items width="257px" clickClose>
                  <Dropdown.Default
                    onClick={() => dispatch(changeTypeSelected(false))}
                  >
                    {Trans(translations, 'None')}
                  </Dropdown.Default>
                  {types.typesData.map((type) => (
                    <Dropdown.Default
                      key={type.id}
                      ellipsis
                      onClick={() => dispatch(changeTypeSelected(type))}
                    >
                      {type.name}
                    </Dropdown.Default>
                  ))}
                </Dropdown.Items>
              </Dropdown>
            </Box>

            {/* Toggler description */}
            <Box
              as="span"
              className={style.addDescription}
              onClick={() => dispatch(toggleDescription())}
            >
              {description.active ? (
                <Icon icon="icon-mostrarmenos" fontSize="14px" />
              ) : (
                <Icon icon="icon-agregar" fontSize="14px" />
              )}
              {description.active ? (
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'RemoveDescription')}
                </Text>
              ) : (
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'AddDescription')}
                </Text>
              )}
            </Box>
          </Box>

          <Box className={style.column}>
            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'Date')}
                </Text>
              </Label>
              <Datepicker
                showPopperArrow={false}
                selected={start || new Date()}
                onChange={(date) => handlePickerChange(date)}
                dateFormat="dd/MM/yyyy"
                locale={locale}
                placeholderText="día, mes y año"
              />
            </Box>

            <InputsHours />

            <DropDownRepeatEvent toggleRepeatModal={toggleRepeatModal} />
          </Box>
        </Box>

        <Textarea
          className={`${description.active ? style.fadeIn : style.fadeOut} ${
            style.txtarea
          }`}
          style={{ transition: 'all 0.3s linear', overflow: 'auto' }}
          value={description.text}
          onChange={(e) =>
            dispatch(
              changerValue({
                block: 'description',
                key: 'text',
                value: e.target.value,
              })
            )
          }
          placeholder={Trans(translations, 'AddDescription')}
          marginTop="10px"
          marginBottom="12px"
          width="538px"
        />

        <Box className={style.column}>
          {/* Search contacts */}
          <ContactsData handleAutoComplete={handleAutoComplete} />

          {/* Search properties */}
          <PropertiesData handleAutoComplete={handleAutoComplete} />

          {/* Search agents */}
          <AgentsData handleAutoComplete={handleAutoComplete} />
        </Box>
      </Box>
    </Box>
  );
}
