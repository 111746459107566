import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import Checkbox from '@/components/checkbox/Checkbox';
import { setSelectedProperties } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function FilesDropdown({ property }) {
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.SenderSlice);
  const allFiles = [...property.files, ...property.developmentFiles];
  const allChecked = allFiles.every((file) => file.active);
  const allUnchecked = allFiles.every((file) => !file.active);
  const areMixedFiles =
    property.files.length > 0 && property.developmentFiles.length > 0;

  const handleCheckboxChangeDevelopment = (selectedFile) => {
    const updatedDevelopmentFiles = property.developmentFiles.map(
      (developmentFile) =>
        developmentFile.id === selectedFile.id
          ? { ...developmentFile, active: !selectedFile.active }
          : developmentFile
    );

    const updatedProperties = properties.selected.map((selectedProperty) =>
      selectedProperty.id === property.id
        ? { ...selectedProperty, developmentFiles: updatedDevelopmentFiles }
        : selectedProperty
    );

    dispatch(setSelectedProperties(updatedProperties));
  };

  const handleCheckboxChangeProperty = (selectedFile) => {
    const updatedFiles = property.files.map((file) =>
      file.id === selectedFile.id ? { ...file, active: !file.active } : file
    );

    const updatedProperties = properties.selected.map((selectedProperty) =>
      selectedProperty.id === property.id
        ? { ...selectedProperty, files: updatedFiles }
        : selectedProperty
    );

    dispatch(setSelectedProperties(updatedProperties));
  };

  const handleSelectAllChange = () => {
    const allCheckboxNewState = !allChecked;
    const updatedFiles = property.files.map((file) => ({
      ...file,
      active: allCheckboxNewState,
    }));

    const updatedDevelopmentFiles = property.developmentFiles.map((file) => ({
      ...file,
      active: allCheckboxNewState,
    }));

    const updatedProperties = properties.selected.map((selectedProperty) =>
      selectedProperty.id === property.id
        ? {
            ...selectedProperty,
            files: updatedFiles,
            developmentFiles: updatedDevelopmentFiles,
          }
        : selectedProperty
    );

    dispatch(setSelectedProperties(updatedProperties));
  };

  return (
    <Box
      className={`${styles.container} ${
        areMixedFiles ? styles['container--mixed-files'] : ''
      }`}
    >
      <Box className={styles['scroll-wrapper']}>
        <Box
          as="label"
          onClick={handleSelectAllChange}
          className={styles['checkbox-row']}
        >
          <Checkbox
            checked={!allUnchecked}
            iconType={
              !allChecked && !allUnchecked
                ? 'icon-mostrarmenos'
                : 'icon-aceptar'
            }
          />
          <Text className={styles['checkbox-text']}>
            {Trans(translations, 'SelectAll')}
          </Text>
        </Box>
        {areMixedFiles && (
          <Text className={styles['files-title']}>
            {Trans(translations, 'FromTheUnit')}
          </Text>
        )}
        {property.developmentFiles.map((file) => (
          <Box
            key={file.id}
            as="label"
            onClick={() => handleCheckboxChangeDevelopment(file)}
            className={`${styles['checkbox-row']} ${
              areMixedFiles ? styles['checkbox-row--extra-padding'] : ''
            }`}
          >
            <Checkbox checked={file.active} />
            <Text className={styles['checkbox-text']}> {file.name} </Text>
          </Box>
        ))}
        {areMixedFiles && (
          <Text className={styles['files-title']}>
            {Trans(translations, 'FromTheDevelopment')}
          </Text>
        )}
        {property.files.map((file) => (
          <Box
            key={file.id}
            as="label"
            onClick={() => handleCheckboxChangeProperty(file)}
            className={`${styles['checkbox-row']} ${
              areMixedFiles ? styles['checkbox-row--extra-padding'] : ''
            }`}
          >
            <Checkbox checked={file.active} />
            <Text className={styles['checkbox-text']}> {file.name} </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default FilesDropdown;
