import ApiService from './ApiService';

class ContactsService {
  static async getContact(id) {
    const composedURL = `/api3/contact/?id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getContacts(q) {
    const composedURL = `/api3/contact/?q=${q}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getRelatedContacts(id) {
    const composedURL = `/api3/contact/${id}/relateds`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDuplicated(data) {
    const composedURL = `/api3/contact/${data.id}/duplicate_contacts_by_contact_id/?page=${data.page}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async createContact(data) {
    const composedURL = '/api3/contacts';
    const response = await ApiService.post(composedURL, data);
    return response;
  }

  static async getLeadsStatus() {
    const composedURL = '/api3/contact/leads_status';
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getStarred(id) {
    const composedURL = `/api3/contact/${id}/starreds_for`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async update(data) {
    const composedURL = `/api3/contacts/${data.id}`;
    const response = await ApiService.patch(composedURL, data.body);
    return response;
  }

  static async checkIfExistsByEmail(email) {
    const composedURL = `/api3/contact/check_if_exists?email=${email}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async checkIfExistsByCellphone(cellphone) {
    const composedURL = `/api3/contact/check_if_exists?cellphone=${cellphone}`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default ContactsService;
