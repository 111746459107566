import React from 'react';
import { Box, Image, Text } from '@oneloop/jopijs';
import styles from './SearchItemDevelopment.module.scss';
import translations from './SearchItemDevelopment.trans.json';
import Trans from '@/components/shared/Trans';

function SearchItemDevelopment({ development }) {
  return (
    <Box className={styles.item}>
      <Image variant="rows" src={development.image} />
      <Box className={`${styles.column} ${styles.columnDescription}`}>
        <Box className={styles.description}>
          <Text
            variant="bodyBold.fontSize14"
            className={`${styles.colorNeutralGray1} ${styles.textTwoMaxLines}`}
          >
            {development.name}
          </Text>
        </Box>
        <Text variant="body.fontSize11" className={styles.colorNeutralGray3}>
          {development.description}
        </Text>
      </Box>
      <Box className={styles.containerEndRow}>
        <Text
          variant="bodyBold.fontSize12"
          className={`${styles.textState} ${
            development.devel_status === 2
              ? styles.colorSuccessAvailable
              : styles.colorRedDarkNegative
          }`}
        >
          {development.devel_status === 1 &&
            Trans(translations, 'HeaderSearchItemDevelopment.Dataloading')}
          {development.devel_status === 2 &&
            Trans(
              translations,
              'HeaderSearchItemDevelopment.Commercialization'
            )}
          {development.devel_status === 3 &&
            Trans(translations, 'HeaderSearchItemDevelopment.Archived')}
        </Text>
      </Box>
    </Box>
  );
}

export default SearchItemDevelopment;
