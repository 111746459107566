import { Box, Text } from '@oneloop/jopijs';
import styles from './LeadsStatusDropDown.module.scss';

export default function LeadsStatusDropDown({ list, itemClick, ...props }) {
  return (
    <Box as="ul" className={styles.container} {...props}>
      {list.map((item) => (
        <Box key={item.id} as="li" onClick={() => itemClick(item)}>
          <Box as="span" style={{ background: `#${item.color}` }} />
          <Text variant={['body.fontSize14', 'ellipsis']}>{item.name}</Text>
        </Box>
      ))}
    </Box>
  );
}
