import React from 'react';
import { Box, Image, Text, Heading, ButtonIcon } from '@oneloop/jopijs';
import styles from './CustomSharePropertyPreview.module.scss';
import translations from '../SharePropertiesModal.trans.json';
import Trans from '@/components/shared/Trans';

function CustomSharePropertiesPreview({ property, onClose }) {
  const statusTranslations = {
    Available: {
      className: styles.Available,
      translation: Trans(translations, 'Available'),
    },
    Reserved: {
      className: styles.Reserved,
      translation: Trans(translations, 'Reserved'),
    },
    Appraising: {
      className: styles.Appraising,
      translation: Trans(translations, 'Appraising'),
    },
    'Not Available': {
      className: styles.NotAvailable,
      translation: Trans(translations, 'Not Available'),
    },
  };
  const getClassNameForStatus = (status) =>
    statusTranslations[status.split('').join('')]?.className || '';

  const getTranslationForStatus = (status) =>
    statusTranslations[status.split('').join('')]?.translation || status;
  return (
    <Box className={styles.customPreviewSharePropertyBox}>
      <Box className={styles.customPreviewSharePropertyCard}>
        <ButtonIcon
          variant={['transparentIcon', 'iconExtraSmall22px']}
          icon="icon-cerrar"
          onClick={onClose}
        />
        <Box __css={{ display: 'flex', gap: '8px' }}>
          <Image src={property.picture_url} variant="rows" />
          <Box>
            <Text
              className={getClassNameForStatus(property.status)}
              variant="bodyBold.fontSize10"
            >
              {getTranslationForStatus(property.status)}
            </Text>
            <Box __css={{ display: 'flex', flexDirection: 'column' }}>
              <Text variant="subtitle.1">{property.address}</Text>
              <Heading as="h5" variant="heading.4">
                {property.type}
              </Heading>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomSharePropertiesPreview;
