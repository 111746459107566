/* eslint-disable react/destructuring-assignment */
/* eslint-disable @next/next/no-img-element */
import { Box, Text } from '@oneloop/jopijs';
import { Attachment, useChannelStateContext } from 'stream-chat-react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CustomAttachemnts.module.scss';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import translations from './CustomAttachemnts.trans.json';
import Trans from '@/components/shared/Trans';

function PropCard({ property_id, title, thumb_url, title_link, type }) {
  const dispatch = useDispatch();
  const { show: showQD } = useSelector((state) => state.QuickDisplaySlice);

  const openQuickDisplay = () => {
    dispatch(
      showQuickDisplay({
        id: Number(property_id),
        buttonChatDisabled: true,
        development: type === 'development',
      })
    );
  };

  return (
    <Box __css={{ padding: '11px' }}>
      <Box __css={{ maxWidth: '250px' }}>
        <Box className={styles.cardAttachmentImg}>
          <img src={thumb_url} alt="Img" />
        </Box>
        <Box className={styles.cardAttachmentDesc}>
          <Text
            className={styles.cardAttachmentTitle}
            variant="bodyBold.fontSize14"
          >
            {title}
          </Text>

          <a
            href={title_link}
            target="_blank"
            className={styles.cardAttachmentLink}
            rel="noreferrer"
          >
            Ficha info
          </a>
          <Box
            className={`${styles.showPropBtn} ${
              showQD ? styles.disableShowProp : ''
            }`}
            onClick={openQuickDisplay}
          >
            <Text variant="bodyBold.fontSize14">
              {Trans(translations, 'ShowProperty')}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function CustomAttachemnts({ message }) {
  const { channel } = useChannelStateContext();

  if (!message.attachments[0]) {
    return null;
  }

  const { type } = message.attachments[0];

  if (
    (type === 'property' || type === 'development') &&
    (!channel.data.type.startsWith('WhatsApp') || Boolean(message?.is_fake_msg))
  ) {
    const { property_id, title, thumb_url, title_link } =
      message.attachments[0];

    return (
      <PropCard
        property_id={property_id}
        title={title}
        thumb_url={thumb_url}
        title_link={title_link}
        type={type}
      />
    );
  }

  return <Attachment attachments={message.attachments} />;
}
