import { Box, Text } from '@oneloop/jopijs';
import store from '@/store/store';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

const tourContainerStyles = {
  backgroundColor: '#143742',
  borderRadius: 12,
  padding: 0,
};

export const createTourSteps = (tourType, textIsGotIt) => {
  const { addressees } = store.getState().SenderSlice;
  const isReportTourAvailable =
    addressees.selected.length === 1 &&
    addressees.selected[0]?.isValid &&
    addressees.selected[0].reports?.length > 0;

  let steps = [];

  if (tourType === 'initial') {
    const isAddMoreVisible = document.querySelector('#add-more-properties');
    const isPropertiesSearchVisible = document.querySelector(
      '#properties-search-container'
    );

    const possibleSteps = [
      {
        id: 0,
        selector: '#addressees-tooltip-container',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: isAddMoreVisible || isPropertiesSearchVisible ? '1' : '2',
        position: 'bottom',
        title: Trans(translations, 'TourTitleAddressees'),
        description: Trans(translations, 'TourBodyAddressees'),
        shouldFinishTour: true,
      },
      {
        id: 1,
        selector: isAddMoreVisible
          ? '#add-more-properties'
          : '#properties-search-container',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: '2',
        position: isAddMoreVisible ? 'top' : 'bottom',
        title: Trans(translations, 'TourTitleProperties'),
        description: Trans(translations, 'TourBodyProperties'),
        shouldFinishTour: true,
      },
      {
        id: 2,
        selector: '#quick-responses',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: '3',
        position: 'top',
        title: Trans(translations, 'TourTitleQuickResponses'),
        description: Trans(translations, 'TourBodyQuickResponses'),
        shouldFinishTour: true,
      },
      {
        id: 3,
        selector: '#dynamic-tags',
        position: 'bottom',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: -1,
        title: Trans(translations, 'TourTitleDynamicTags'),
        description: Trans(translations, 'TourBodyDynamicTags'),
        shouldFinishTour: true,
      },
    ];

    const shouldRemoveStepOne = !isAddMoreVisible && !isPropertiesSearchVisible;
    const filteredSteps = possibleSteps.filter(
      (_, index) => index !== 1 || (index === 1 && !shouldRemoveStepOne)
    );
    steps = filteredSteps.map((step, index) => ({
      ...step,
      id: index,
      nextStep: index === filteredSteps.length - 1 ? -1 : index + 1,
    }));
  } else if (tourType === 'cc') {
    steps = [
      {
        id: 0,
        selector: '#button-cc',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: isReportTourAvailable ? '1' : -1,
        position: 'bottom',
        title: Trans(translations, 'TourTitleCC'),
        description: Trans(translations, 'TourBodyCC'),
        shouldFinishTour: true,
      },
    ];

    if (isReportTourAvailable) {
      steps.push({
        id: 1,
        selector: '#owner-reports',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: -1,
        position: 'left',
        title: Trans(translations, 'TourTitleReports'),
        description: Trans(translations, 'TourBodyReports'),
        shouldFinishTour: true,
      });
    }
  } else if (tourType === 'files') {
    steps = [
      {
        id: 0,
        selector: '.card-selected-with-files',
        leftButtonText: Trans(translations, 'SkipTour'),
        rightButtonText: Trans(translations, 'Next'),
        nextStep: -1,
        position: 'bottom',
        title: Trans(translations, 'TourTitleFiles'),
        description: Trans(translations, 'TourBodyFiles'),
        shouldFinishTour: true,
      },
    ];
  }

  const lastStep = steps[steps.length - 1];
  if (textIsGotIt) {
    lastStep.leftButtonText = Trans(translations, 'GotIt');
    lastStep.rightButtonText = null;
    lastStep.textIsGotIt = true;
  }

  return steps;
};

export const generateTourSteps = (stepsOptions, handleFinishStep, onSkipTour) =>
  stepsOptions.map((stepOption, stepIndex) => ({
    selector: stepOption.selector,
    style: tourContainerStyles,
    position: stepOption.position,
    content: ({ goTo }) => (
      <Box className={`${styles.wrapper} ${stepOption.classes ?? ''}`}>
        <Text className={styles.title}>{stepOption.title}</Text>
        <Text className={styles.body}>{stepOption.description}</Text>
        <Box className={styles['buttons-group']}>
          {stepOption.leftButtonText && (
            <Box
              as="button"
              className={styles.button}
              onClick={() => {
                goTo(0);
                if (stepOption.textIsGotIt) {
                  handleFinishStep();
                } else {
                  onSkipTour();
                }
              }}
            >
              {stepOption.leftButtonText}
            </Box>
          )}

          {stepOption.rightButtonText && (
            <Box
              as="button"
              className={styles.button}
              onClick={() => {
                if (stepIndex === stepsOptions.length - 1) {
                  goTo(0);
                  handleFinishStep();
                } else {
                  goTo(stepOption.nextStep);
                }
              }}
            >
              {stepOption.rightButtonText}
            </Box>
          )}
        </Box>
      </Box>
    ),
  }));
