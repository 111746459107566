import { Box, Loader } from '@oneloop/jopijs';
import styles from './styles.module.scss';

function LoadingResults() {
  return (
    <Box className={styles.container}>
      <Loader
        variant="spinner"
        style={{
          width: '36px',
          height: '36px',
          backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
        }}
      />
    </Box>
  );
}

export default LoadingResults;
