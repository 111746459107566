import { useState, useRef } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ToolbarButton from '../ToolbarButton';
import { LineHeightIcon } from '../icons';
import { LineHeightPicker } from '../pickers';

export default function LineHeightButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  const handleChangeLineHeight = (value) => {
    editor.chain().focus().setLineHeight(value).run();
    setIsOpen(false);
  };

  return (
    <Box ref={ref} __css={{ minWidth: 'unset', position: 'relative' }}>
      <ToolbarButton
        onClick={() => setIsOpen((prevState) => !prevState)}
        aria-label="editor-line-height"
      >
        <LineHeightIcon style={{ transform: 'scale(1.25)' }} />
        <Icon icon="icon-dropdown" fontSize="12px" />
      </ToolbarButton>
      {isOpen && <LineHeightPicker onClick={handleChangeLineHeight} />}
    </Box>
  );
}
