import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box, Icon, Modal } from '@oneloop/jopijs';
import {
  handleSender,
  resetSlice,
  setAfterSending,
  setMessage,
  setSubject,
  setTour,
  setWhatsappApp,
} from '@/store/slices/components/sender/SenderSlice';
import WysiwygEditor from '../WysiwygEditor';
import Addressees from './Addressees';
import CC from './Addressees/CC';
import AfterSending from './Addressees/AfterSending';
import Subject from './Addressees/Subject';
import SenderControlls from './Controlls';
import tokkoModalActions from '../TokkoModalActions/TokkoModalActions';
import WhatsappTextArea from './Addressees/WhatsappTextArea';
import WhatsappSendBy from './Addressees/WhatsappSendBy';
import WhatsappTo from './Addressees/WhatsappTo';
import {
  getAfterSending,
  getPreSelectedContacts,
  getPreSelectedDevelopments,
  getPreSelectedPropertiesByHash,
  getPreSelectedPropertiesById,
  getPropertiesFiles,
  getPropertyAndDevelopmentFiles,
} from '@/store/slices/components/sender/services';
import { initialState as senderInitialState } from '@/store/slices/components/sender/initialState';
import PropertiesContent from './Properties/PropertiesContent';
import FooterWarning from './FooterWarning';
import SenderTour from './SenderTour';
import Trans from '../shared/Trans';
import translations from './Sender.trans.json';
import styles from './styles.module.scss';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import isSenderWithChanges from './utils/isSenderWithChanges';

function Sender({ ...props }) {
  const dispatch = useDispatch();
  const senderActualState = useSelector((state) => state.SenderSlice);
  const {
    show,
    fromOuterNet,
    preSelectedContacts,
    preSelectedProperties,
    preSelectedDevelopments,
    warning,
    afterSending,
  } = senderActualState;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const isWarningWysiwyg = warning && warning.fields.includes('message');

  useEffect(() => {
    dispatch(getAfterSending());
  }, []);

  useEffect(() => {
    const fetchContextData = async () => {
      try {
        if (preSelectedContacts.length > 0) {
          dispatch(getPreSelectedContacts(preSelectedContacts));
        }

        if (preSelectedProperties.length > 0) {
          const getProperties = fromOuterNet
            ? getPreSelectedPropertiesByHash
            : getPreSelectedPropertiesById;

          const response = await dispatch(getProperties(preSelectedProperties));
          if (show === 'email' && !fromOuterNet) {
            const { payload } = response;
            const onlyPropsIds = [];
            const propsAndDevesIds = [];

            for (let i = 0; i < payload.length; i++) {
              const property = payload[i];
              if (property.development) {
                propsAndDevesIds.push({
                  propertyId: property.id,
                  developmentId: property.development.id,
                });
              } else {
                onlyPropsIds.push(property.id);
              }
            }

            dispatch(getPropertiesFiles(onlyPropsIds));

            propsAndDevesIds.forEach((pairOfIds) =>
              dispatch(getPropertyAndDevelopmentFiles(pairOfIds))
            );
          }
        }

        if (preSelectedDevelopments.length > 0) {
          dispatch(getPreSelectedDevelopments(preSelectedDevelopments));
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setWhatsappPreference = () => {
      if (show === 'email') return;
      const options = ['web', 'desktop'];

      const preference = localStorage.getItem('sender-whatsapp-preference');
      if (options.includes(preference)) {
        dispatch(setWhatsappApp(preference));
      }
    };

    setWhatsappPreference();
    fetchContextData();

    return () => {
      dispatch(resetSlice());
    };
  }, [show]);

  const debouncedHandleOnChange = useCallback(
    debounce((text, html) => {
      dispatch(setMessage({ text, html }));
    }, 300),
    [dispatch]
  );

  const handleSelectQuickResponse = ({ subject: paramSubject, html }) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const text = doc.body.textContent || html;

    dispatch(setSubject(paramSubject));
    dispatch(setMessage({ text, html }));
  };

  const handleResetTour = () => {
    dispatch(
      setTour({
        presentation: true,
        initial: false,
        cc: false,
        files: false,
      })
    );
  };

  const handleSelectAfterSending = (item) => {
    dispatch(setAfterSending(item));
  };

  const closeSenderModal = () => {
    if (window.location.href.includes('sender')) {
      tokkoModalActions({ msg: 'closeSender' }, tokkoBrokerHost);
    }

    dispatch(handleSender({ show: false }));
  };

  const attemptToCloseModal = () => {
    if (isSenderWithChanges(senderInitialState, senderActualState)) {
      setShowConfirmModal(true);
    } else {
      closeSenderModal();
    }
  };

  if (!show) return null;

  return (
    <>
      <Modal
        className={styles.modal}
        modalBG="transparent"
        blockScroll
        id="sender"
        closeModal={attemptToCloseModal}
        maxWidth="unset!important"
        minWidth="782px!important"
        {...props}
      >
        <Modal.Header
          className={styles['sender-header']}
          closeIcon={attemptToCloseModal}
          variant={['start', 'l']}
          text={
            <Box
              as="span"
              className={styles['header-wrapper']}
              aria-label="sender-header"
            >
              <Box as="span" className={styles['header-wrapper__text']}>
                {show === 'email'
                  ? Trans(translations, 'SendEmail')
                  : Trans(translations, 'SendWhatsapp')}
              </Box>
              {show === 'email' && (
                <Icon
                  icon="icon-dudas"
                  className={styles['header-wrapper__icon']}
                  fontSize="16px"
                  color="#25697E"
                  onClick={handleResetTour}
                />
              )}
            </Box>
          }
        />

        <Modal.Body className={styles['sender-modal-body']} id="sender-body">
          <Box className={styles['main-content']}>
            <Box className={styles['left-side']}>
              {show === 'email' && (
                <>
                  <Addressees />
                  <CC />
                  <Subject />
                  <WysiwygEditor
                    onChange={debouncedHandleOnChange}
                    highlight={isWarningWysiwyg}
                  >
                    <WysiwygEditor.Toolbar />
                    <WysiwygEditor.Editor />
                    <WysiwygEditor.QuickResponses
                      onSelect={handleSelectQuickResponse}
                    />
                  </WysiwygEditor>
                </>
              )}

              {show === 'whatsapp' && (
                <>
                  <WhatsappTo />
                  <WhatsappTextArea />
                  <WhatsappSendBy />
                </>
              )}

              {!warning && (
                <AfterSending
                  label={Trans(translations, 'AfterSending')}
                  handleSelect={handleSelectAfterSending}
                  isLoading={afterSending.isLoading}
                  selectedLeadStatus={afterSending.selected}
                  listOfLeadStatus={afterSending.data}
                />
              )}
            </Box>
            <Box id="sender-right-side" className={styles['right-side']}>
              <PropertiesContent />
              <SenderControlls handleBack={attemptToCloseModal} />
            </Box>
          </Box>

          {warning && <FooterWarning />}
        </Modal.Body>
      </Modal>

      {show === 'email' && <SenderTour />}
      {showConfirmModal && (
        <ConfirmModal
          title={Trans(translations, 'YouHaveUnsavedChanges')}
          btnTextPri={Trans(translations, 'ExitWithoutSending')}
          btnTextSecond={Trans(translations, 'KeepEditing')}
          clickPrimary={closeSenderModal}
          clickSecondary={() => setShowConfirmModal(false)}
          icon={
            <Icon
              icon="icon-editar"
              fontSize="32px"
              color="#2F373D"
              alignSelf="center"
              marginBottom="-16px"
            />
          }
        />
      )}
    </>
  );
}

export default Sender;
