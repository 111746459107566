import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Loader } from '@oneloop/jopijs';
import { getRelated } from '@/store/slices/components/sender/services';
import {
  setCCData,
  setSelectedCC,
  setRelatedData,
} from '@/store/slices/components/sender/SenderSlice';
import ImageNext from '@/components/shared/ImageNext';
import ChevronIcon from '@/public/images/chevron.svg';
import DropdownCC from './DropdownCC';
import useOnClickOutside from '@/components/shared/Hooks';
import styles from './styles.module.scss';

function ButtonCC() {
  const dispatch = useDispatch();
  const { addressees, cc, related } = useSelector((state) => state.SenderSlice);
  const chevronRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useOnClickOutside(chevronRef, isDropdownOpen, setIsDropdownOpen);

  const onlyOneAddresseeSelected = addressees.selected.length === 1;
  const thereIsRelated = related.data.length > 0;

  useEffect(() => {
    const selectedAddressee = addressees.selected[0];

    if (!onlyOneAddresseeSelected) {
      dispatch(setCCData({ key: 'show', value: false }));
      dispatch(setRelatedData({ key: 'data', value: [] }));
      dispatch(setSelectedCC([]));
    }

    if (onlyOneAddresseeSelected && !selectedAddressee.isRaw) {
      dispatch(getRelated(selectedAddressee.id));
    } else {
      dispatch(setRelatedData({ key: 'data', value: [] }));
    }

    return () => {
      dispatch(setCCData({ key: 'show', value: false }));
      dispatch(setRelatedData({ key: 'data', value: [] }));
      dispatch(setSelectedCC([]));
    };
  }, [addressees.selected.length]);

  const toggleShowCC = () => {
    dispatch(setCCData({ key: 'show', value: !cc.show }));

    if (cc.show) {
      dispatch(setCCData({ key: 'isCollapsed', value: false }));
    }
  };

  const preventCollapse = (event) => {
    event.stopPropagation();
  };

  if (!onlyOneAddresseeSelected) return null;

  if (related.isLoading) {
    return (
      <Box className={styles.loading}>
        <Loader
          variant="spinner"
          style={{
            width: '20px',
            height: '20px',
            backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      className={styles['absolute-container']}
      onClick={preventCollapse}
      id="button-cc"
      data-testid="button-cc"
    >
      <Box className={styles['relative-wrapper']}>
        <Box
          as="button"
          onClick={toggleShowCC}
          className={`${styles.cc} ${
            cc.selected.length > 0 ? styles['cc--on'] : styles['cc--off']
          } ${
            thereIsRelated
              ? styles['cc--with-chevron']
              : styles['cc--without-chevron']
          }`}
        >
          CC
        </Box>

        {thereIsRelated && (
          <Box ref={chevronRef} className={styles['chevron-wrapper']}>
            <Box
              as="button"
              className={styles['chevron-button']}
              onClick={() => setIsDropdownOpen((prevState) => !prevState)}
            >
              <ImageNext alt="dropdown" src={ChevronIcon} />
            </Box>

            {isDropdownOpen && <DropdownCC />}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ButtonCC;
