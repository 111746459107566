import ToolbarButton from '../ToolbarButton';
import { UnderlineIcon } from '../icons';

export default function UnderlineButton({ editor }) {
  return (
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      disabled={!editor.can().chain().focus().toggleUnderline().run()}
      data-active={editor.isActive('underline')}
      aria-label="editor-underline"
    >
      <UnderlineIcon />
    </ToolbarButton>
  );
}
