import { Box, Heading, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from './NotificationsEdit.module.scss';
import translations from './NotificationsEdit.trans.json';
import Trans from '@/components/shared/Trans';

export default function NotificationsEdit() {
  const { notifications } = useSelector((state) => state.CalendarEvents);
  const {
    contactEmailBefore,
    agentNotioficationBefore,
    agentEmailBefore,
    agentNotificationAfter,
    agentEmailAfter,
  } = notifications;

  return (
    <Box __css={{ marginTop: '30px' }} className={style.container}>
      <Box className={style.notifBefore}>
        <Heading as="h4" variant="heading.3">
          {Trans(translations, 'NotificationsBeforeEvents')}
        </Heading>
        <Box className={style.row}>
          <Text className={style.text} variant="bodyBold.fontSize14">
            {Trans(translations, 'ContactsByEmail')}
          </Text>
          {contactEmailBefore ? (
            <Text className={style.text} variant="body.fontSize14">
              {`${contactEmailBefore} ${Trans(translations, 'MinutesBefore')}`}
            </Text>
          ) : (
            <Text className={style.withOutText} variant="body.fontSize14">
              {Trans(translations, 'WithoutNotifications')}
            </Text>
          )}
        </Box>

        <Box className={style.row}>
          <Text className={style.text} variant="bodyBold.fontSize14">
            {Trans(translations, 'AgentByNotifications')}
          </Text>
          {agentNotioficationBefore ? (
            <Text className={style.text} variant="body.fontSize14">
              {`${agentNotioficationBefore} ${Trans(
                translations,
                'MinutesBefore'
              )}`}
            </Text>
          ) : (
            <Text className={style.withOutText} variant="body.fontSize14">
              {Trans(translations, 'WithoutNotifications')}
            </Text>
          )}
          {agentEmailBefore && (
            <Text className={style.text} variant="bodyBold.fontSize14">
              {Trans(translations, 'ByEmailAlso')}
            </Text>
          )}
        </Box>
      </Box>

      <Box className={style.notiAfter}>
        <Box className={style.tileNotiAfter}>
          <Heading as="h4" variant="heading.3">
            {Trans(translations, 'NotificationsAfterEvents')}
          </Heading>
          <Text variant="body.fontSize14" className={style.afterText}>
            {Trans(translations, 'SendNotificationsReportNotComplete')}
          </Text>
        </Box>
        <Box className={style.contAfterRow}>
          <Box className={style.row}>
            <Text className={style.text} variant="bodyBold.fontSize14">
              {Trans(translations, 'AgentByNotifications')}
            </Text>
            {!agentNotificationAfter && (
              <Text className={style.withOutText} variant="body.fontSize14">
                {Trans(translations, 'WithoutNotifications')}
              </Text>
            )}
          </Box>
          <Box className={style.row}>
            {agentEmailAfter && (
              <Text className={style.text} variant="bodyBold.fontSize14">
                {Trans(translations, 'ByEmailAlso')}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
