import { Box } from '@oneloop/jopijs';
import styles from './styles.module.scss';

export default function ToolbarButton(props) {
  const { className, children, tabIndex = '-1', ...rest } = props;

  return (
    <Box
      as="button"
      className={`${styles.button} ${className ?? ''}`}
      tabIndex={tabIndex}
      {...rest}
    >
      {children}
    </Box>
  );
}
