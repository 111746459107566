import { useSelector } from 'react-redux';
import { Box, Icon, Label, Text } from '@oneloop/jopijs';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import style from './DetailEventEdit.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';
import ItemsComp from './ItemsComp';
import translations from './DetailEventEdit.trans.json';
import Trans from '@/components/shared/Trans';

export default function DetailEventEdit() {
  const {
    edit,
    event,
    repeat,
    types,
    contacts,
    properties,
    agents,
    description,
  } = useSelector((state) => state.CalendarEvents);
  const [cAgents, setCAgents] = useState([]);
  const [cProperties, setCProperties] = useState([]);
  const [cContacts, setCContacts] = useState([]);

  const { title, start } = event[0];
  const { editLoader } = edit;
  const { itemsDropDownRepeatEvent, dropDownRepeatEventIndex } = repeat;
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  useEffect(() => {
    // add agents clickfn
    const nuevosAgentes = agents.agentsSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/user/${item.id}/details`),
    }));

    setCAgents(nuevosAgentes);

    // add properties clickfn
    const nuevasProperties = properties.propertiesSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/property/${item.id}`),
    }));

    setCProperties(nuevasProperties);

    // add contacts clickfn
    const nuevosContactos = contacts.contactsSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/contact/${item.id}`),
    }));

    setCContacts(nuevosContactos);
  }, [
    agents.agentsSelected,
    properties.propertiesSelected,
    contacts.contactsSelected,
  ]);

  return (
    <Box __css={{ marginTop: '30px' }}>
      <Box className={style.formEdit}>
        <Box className={style.row}>
          <Box className={style.column}>
            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'EventTitle')}
                </Text>
              </Label>
              {title ? (
                <Text
                  variant="body.fontSize14"
                  className={`${style.textColor} ${style.ellipsis}`}
                >
                  {title}
                </Text>
              ) : (
                <Skeletons />
              )}
            </Box>

            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12" className={style.textColor}>
                  {Trans(translations, 'EventType')}
                </Text>
              </Label>
              {editLoader ? (
                <Skeletons />
              ) : (
                <Text variant="body.fontSize14" className={style.textColor}>
                  {types.typeSelected?.name || '---'}
                </Text>
              )}
            </Box>
          </Box>

          <Box className={style.column}>
            <Box className={style.label}>
              <Label className={style.labelColor}>
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'Date')}
                </Text>
              </Label>
              {start ? (
                <Text variant="body.fontSize14" className={style.textColor}>
                  {format(start, "eee, dd 'de' MMM / HH:mm", { locale: es })}
                </Text>
              ) : (
                <Skeletons />
              )}
            </Box>

            {editLoader ? (
              <Skeletons />
            ) : (
              <Box className={style.itemPropCenter}>
                <Icon icon="icon-appexchange" fontSize="14px" />
                <Text
                  variant="bodyBold.fontSize12"
                  className={style.repeatText}
                  style={{ width: '-webkit-fill-available' }}
                >
                  {itemsDropDownRepeatEvent[dropDownRepeatEventIndex]}
                </Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <Label className={style.labelColor}>
            <Text variant="bodyBold.fontSize12">
              {Trans(translations, 'Description')}
            </Text>
          </Label>
          {editLoader ? (
            <Skeletons />
          ) : (
            <Text
              variant="body.fontSize14"
              className={`${style.textColor} ${style.description}`}
            >
              {description.text || '---'}
            </Text>
          )}
        </Box>

        <Box className={style.column}>
          <ItemsComp
            text={Trans(translations, 'Contacts')}
            items={cContacts}
            icon={<Icon icon="icon-contactos" fontSize="18px" />}
            printKey="name"
            clickFn="clickFn"
          />

          <ItemsComp
            text={Trans(translations, 'Properties')}
            items={cProperties}
            icon={<Icon icon="icon-propiedades" fontSize="18px" />}
            printKey="address"
            clickFn="clickFn"
          />

          <ItemsComp
            text={Trans(translations, 'Agents')}
            items={cAgents}
            icon={<Icon icon="icon-agente" fontSize="18px" />}
            printKey="full_name"
            clickFn="clickFn"
          />
        </Box>
      </Box>
    </Box>
  );
}
