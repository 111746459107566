import { Box, Icon, Text } from '@oneloop/jopijs';
import { useEffect, useRef } from 'react';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import useOnClickOutside from '@/components/shared/Hooks';

export default function NoWebTooltip({
  arrowPosition,
  state,
  seter,
  title,
  ...props
}) {
  const ref = useRef(null);
  useOnClickOutside(ref, state, seter);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const handleScroll = () => {
    seter(false);
  };

  useEffect(() => {
    const modal = document.querySelector('#QuickDisplayBody');
    if (process.env.NODE_ENV !== 'test') {
      modal.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (process.env.NODE_ENV !== 'test') {
        modal.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleRedirect = () => {
    window.open(`${tokkoBrokerHost}/webmanager`, '_blank');
  };

  if (!state) return null;

  return (
    <Box {...props} ref={ref} className={style.noWebTipContainer}>
      <Box
        __css={{ justifyContent: 'space-between' }}
        className={style.iconText}
      >
        <Text className={style.gray1} variant="bodyBold.fontSize16">
          {title}
        </Text>
        <Icon icon="icon-cerrar" fontSize="16px" onClick={() => seter(false)} />
      </Box>

      <Text className={style.gray1} variant="body.fontSize14">
        {Trans(translations, 'UploadTheLinkToTheDevelopmentOr')}{' '}
        <Box as="span" className={style.underline} onClick={handleRedirect}>
          {Trans(translations, 'CreateWebsiteForYourCompany')}
        </Box>
      </Text>

      <Text
        style={{ marginTop: '5px', marginLeft: 'auto', cursor: 'pointer' }}
        className={style.gray1}
        variant="bodyBold.fontSize12"
        onClick={() => seter(false)}
      >
        {Trans(translations, 'Ok')}
      </Text>

      <Box style={{ left: arrowPosition }} className={style.arrowTip} />
    </Box>
  );
}
