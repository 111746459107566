import React, { useRef } from 'react';
import { Box, Button, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import useOnClickOutside from '@/components/shared/Hooks';
import NotificationItem from './NotificationItem/NotificationItem';
import styles from './Notification.module.scss';
import featured from '../../../../public/images/featured_1.svg';
import ImageNext from '@/components/shared/ImageNext';
import translations from './FeaturedNotification.trans.json';
import Trans from '@/components/shared/Trans';

function FeaturedNotification({
  listNotifications,
  openFeaturedNotification,
  setOpenFeaturedNotification,
  updateNotifications,
}) {
  const ref = useRef();
  useOnClickOutside(ref, openFeaturedNotification, setOpenFeaturedNotification);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  return (
    <div className={styles.containerAll} ref={ref}>
      <Box className={styles.containerNotification}>
        <Text variant="bodyBold.fontSize12" className={styles.title}>
          {Trans(translations, 'Featurednotifications')}
        </Text>
        {listNotifications?.length > 0 ? (
          <Box>
            <Box
              className={
                listNotifications?.length > 4
                  ? styles.containerItemsScroll
                  : styles.containerItems
              }
              marginRight="7px"
            >
              {listNotifications.map((notificationItem) => (
                <NotificationItem
                  notificationItem={notificationItem}
                  key={notificationItem.id}
                  updateNotifications={updateNotifications}
                />
              ))}
            </Box>
            <Box className={styles.containerButtonsFeatured}>
              <Link href={`${tokkoBrokerHost}/user/notifications/starred`}>
                <Button variant={['primary', 'extraSmall']}>
                  {Trans(translations, 'Seeallnotifications')}
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box className={styles.containerItems}>
            <Box className={styles.line} />
            <Box className={styles.containerEmpty}>
              <ImageNext alt="featured1" src={featured} unoptimized />
              <Text variant="bodyBold.fontSize12" className={styles.textEmpty}>
                {Trans(translations, 'EmptyFeaturedNotifications')}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default FeaturedNotification;
