/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import theme from '@oneloop/theme';
import ReactGA from 'react-ga';
import { AppWrapper } from '../context/AppContext';
import store from '@/store/store';
import SettingsReactLoader from '@/components/SettingsReactLoader/SettingsReactLoader';
import Restricted from '@/components/Restricted/Restricted';
import DictionaryPermissions from '@/components/Restricted/DictionaryPermissions';
import DictionaryNamePage from '@/components/shared/DictionaryNamePage';
import translations from '@/translations/_app.trans.json';
import Trans from '@/components/shared/Trans';
import ErrorBoundary from '@/components/ErrorBudanry';
import '../styles/globals.scss';
import CustomModalsWrapped from '@/components/CustomModalsWrapped/CustomModalsWrapped';
import Layout from '@/components/Layout/Layout';

function AppHead({ pathname }) {
  return (
    <Head>
      <title>
        {DictionaryNamePage[pathname] &&
          Trans(translations, DictionaryNamePage[pathname])}
        {' | Tokko Broker'}
      </title>
      <meta name="description" content="Tokko Broker" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    </Head>
  );
}

function PublicRoute({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>
  );
}

function ProtectedRoute({
  Component,
  pageProps,
  router,
  isEnabledToken,
  setEnabledToken,
}) {
  const isLayoutRemoved = Component.layoutRemoved;

  return isEnabledToken ? (
    <ErrorBoundary>
      <Restricted to={DictionaryPermissions[router.pathname]} isPage>
        <CustomModalsWrapped>
          {isLayoutRemoved ? (
            <Component {...pageProps} />
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
        </CustomModalsWrapped>
      </Restricted>
    </ErrorBoundary>
  ) : (
    <SettingsReactLoader setEnabledToken={setEnabledToken} />
  );
}

function MyApp({ Component, pageProps }) {
  const [isEnabledToken, setEnabledToken] = useState(false);
  const router = useRouter();
  const isPublicRoute = Component.public;

  useEffect(() => {
    ReactGA.initialize('GTM-5WVHQNX');
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.userguiding.com/media/user-guiding-J2D85763GKSID-embedded.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      (function (g, u, i, d, e, s) {
        g[e] = g[e] || [];
        const f = u.getElementsByTagName(i)[0];
        const k = u.createElement(i);
        k.async = true;
        k.src = `https://static.userguiding.com/media/user-guiding-${s}-embedded.js`;
        f.parentNode.insertBefore(k, f);
        if (g[d]) return;
        const ug = (g[d] = { q: [] });
        ug.c = function (n) {
          return function () {
            ug.q.push([n, arguments]);
          };
        };
        const m = [
          'previewGuide',
          'finishPreview',
          'track',
          'identify',
          'hideChecklist',
          'launchChecklist',
        ];
        for (let j = 0; j < m.length; j += 1) {
          ug[m[j]] = ug.c(m[j]);
        }
      })(
        window,
        document,
        'script',
        'userGuiding',
        'userGuidingLayer',
        'J2D85763GKSID'
      );
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Provider store={store}>
          <AppHead pathname={router.pathname} />
          {isPublicRoute ? (
            <PublicRoute Component={Component} pageProps={pageProps} />
          ) : (
            <ProtectedRoute
              Component={Component}
              pageProps={pageProps}
              router={router}
              isEnabledToken={isEnabledToken}
              setEnabledToken={setEnabledToken}
            />
          )}
        </Provider>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default MyApp;
