import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTour } from '@/store/slices/components/sender/SenderSlice';
import SenderTourComponent from './SenderTourComponent';
import Presentation from './Presentation';

const possibleTours = ['initial', 'cc', 'files'];

function SenderTour() {
  const dispatch = useDispatch();
  const { addressees, properties, tour } = useSelector(
    (state) => state.SenderSlice
  );
  const [isError, setIsError] = useState(false);

  const canShow = useMemo(
    () => ({
      initial: !tour.initial,
      cc: addressees.selected.length === 1 && !tour.cc,
      files:
        properties.selected.some(
          (property) =>
            property.files?.length > 0 || property.developmentFiles?.length > 0
        ) && !tour.files,
    }),
    [tour, addressees.selected, properties.selected]
  );

  const nextToShow = possibleTours.find((next) => !tour[next] && canShow[next]);

  const availableTours = possibleTours.filter((candidate) => !tour[candidate]);
  const queuedTours = availableTours.filter((candidate) => canShow[candidate]);

  useEffect(() => {
    try {
      const parsedTourFromStorage = JSON.parse(
        localStorage.getItem('sender-tour')
      );
      if (parsedTourFromStorage) {
        dispatch(setTour(parsedTourFromStorage));
      } else {
        dispatch(
          setTour({
            presentation: false,
            initial: false,
            cc: false,
            files: false,
          })
        );
      }
    } catch (error) {
      console.error('Error parsing tour from storage:', error);
      setIsError(true);
    }
  }, [dispatch]);

  useEffect(() => {
    const el = document.querySelector('#sender-right-side-content');
    if (!el) return;

    if (nextToShow === 'files') {
      el.style.overflow = 'hidden';
    } else {
      el.style.overflow = null;
    }
  }, [nextToShow]);

  useEffect(() => {
    if (nextToShow !== 'initial') return;
    if (!canShow.initial) return;

    const addMoreElement = document.querySelector('#add-more-properties');
    const propertiesSearchElement = document.querySelector(
      '#properties-search-container'
    );

    if (propertiesSearchElement) {
      propertiesSearchElement.scrollIntoView({
        block: 'center',
      });
      return;
    }

    if (addMoreElement) {
      addMoreElement.scrollIntoView({ block: 'center' });
    }
  }, [tour, nextToShow, canShow.initial]);

  useEffect(() => {
    if (nextToShow !== 'cc') return;
    if (!canShow.cc) return;

    const contact = addressees.selected[0];
    if (contact?.reports?.length > 0) {
      const rightSideContainer = document.querySelector(
        '#sender-right-side-content'
      );
      if (rightSideContainer) {
        rightSideContainer.scrollTop = rightSideContainer.scrollHeight;
      }
    }
  }, [nextToShow, canShow.cc, addressees.selected]);

  useEffect(() => {
    if (nextToShow !== 'files') return;
    if (!canShow.files) return;

    const property = properties.selected.find((prop) => prop.files?.length > 0);
    if (property) {
      const rightSideContainer = document.querySelector(
        '#sender-right-side-content'
      );
      const propertyElement = rightSideContainer?.querySelector(
        '.property-files-button'
      );
      propertyElement?.scrollIntoView({ block: 'center' });
    }
  }, [nextToShow, canShow.files, properties.selected]);

  const updateTourStatus = useCallback(
    (newStatus) => {
      const updatedStatus = { ...tour, ...newStatus };
      localStorage.setItem('sender-tour', JSON.stringify(updatedStatus));
      dispatch(setTour(updatedStatus));
    },
    [dispatch, tour]
  );

  const handleSkipTour = useCallback(() => {
    updateTourStatus({
      presentation: true,
      initial: true,
      cc: true,
      files: true,
    });
  }, []);

  const startPresentation = useCallback(() => {
    updateTourStatus({
      presentation: true,
      initial: false,
      cc: false,
      files: false,
    });
  }, []);

  if (isError) return null;

  if (tour && !tour.presentation) {
    return (
      <Presentation
        handleSkipTour={handleSkipTour}
        handleContinue={startPresentation}
      />
    );
  }

  return (
    <>
      {possibleTours.map((type) => (
        <SenderTourComponent
          key={type}
          isOpen={nextToShow === type}
          type={type}
          updateTourStatus={updateTourStatus}
          handleSkipTour={handleSkipTour}
          textIsGotIt={queuedTours[queuedTours.length - 1] === type}
        />
      ))}
    </>
  );
}

export default SenderTour;
