import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonIcon,
  ContainerMenu,
  Icon,
  Image,
  Modal,
  Radio,
  Submenu,
  Tabs,
  Text,
} from '@oneloop/jopijs';
import { useToggle } from '@oneloop/hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUserPreferencesState,
  setFavouriteSelectedTabState,
} from '@/store/slices/UserPreferencesSlice';
import { handleSender } from '@/store/slices/components/sender/SenderSlice';
import FavoritesService from '@/services/FavoritesService';
import useOnClickOutside from '@/components/shared/Hooks';
import styles from './Favorites.module.scss';
import Searchs from './Searchs/Searchs';
import Properties from './Properties/Properties';
import Contacts from './Contacts/Contacts';
import Developments from './Developments/Developments';
import UserService from '@/services/UserService';
import translations from './Favorites.trans.json';
import Trans from '@/components/shared/Trans';
import PropertiesService from '@/services/PropertiesService';
import { setNotificationText } from '@/store/slices/NotificationSlice';

function Favorites() {
  const [activeBtnConfig, setActiveBtnConfig] = useToggle(false);
  const [dataSearchs, setDataSearchs] = useState([]);
  const [dataProperties, setDataProperties] = useState([]);
  const [dataContacts, setDataContacts] = useState([]);
  const [dataDevelopments, setDataDevelopments] = useState([]);
  const [listSearchs, setListSearchs] = useState([]);
  const [listProperties, setListProperties] = useState([]);
  const [listContacts, setListContacts] = useState([]);
  const [listDevelopments, setListDevelopments] = useState([]);
  const [listPagesPdf, setListPagesPdf] = useState([]);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [openModalExportPdf, setOpenModalExportPdf] = useState(false);
  const [openModalAreYouSure, setOpenModalAreYouSure] = useState(false);
  const [openModalUnityContacts, setOpenModalUnityContacts] = useState(false);
  const [conflictsUnifyContacts, setConflictsUnifyContacts] = useState([]);
  const [idsRadioConflictContact, setIdsRadioConflictContact] = useState([]);
  const [descriptionWarning, setDescriptionWarning] = useState('');
  const [pdfType, setPdfType] = useState(1);
  const [pdfTypeHover, setPdfTypeHover] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();
  const refModal = useRef();
  const refModalWarning = useRef();
  const refModalAreYouSure = useRef();
  const refModalUnifyContacts = useRef();
  const dispatch = useDispatch();
  const { favouriteSelectedTab } = useSelector(selectUserPreferencesState);
  const { permissions } = useSelector((state) => state.AuthSlice);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const updateList = (list, setList, value) => {
    if (!list.includes(value)) {
      setList([...list, value]);
    } else {
      setList(() => list.filter((id) => id !== value));
    }
  };

  const selectAll = (data, list, setList) => {
    if (list.length === data.length) {
      setList([]);
    } else {
      setList([...data.map((value) => value.id)]);
    }
  };

  function getItemSelected() {
    if (favouriteSelectedTab === 'searches') {
      return '0';
    }
    if (favouriteSelectedTab === 'properties') {
      return '1';
    }
    if (favouriteSelectedTab === 'contacts') {
      return '2';
    }
    return '3';
  }

  const selectFavorite = (selectedTab) => {
    const requestData = {
      favorite_tab: selectedTab,
    };
    UserService.postCurrentFavoriteTab(requestData);
    dispatch(setFavouriteSelectedTabState(selectedTab));
  };

  useOnClickOutside(ref, activeBtnConfig, setActiveBtnConfig);
  useOnClickOutside(refModal, openModalExportPdf, setOpenModalExportPdf);
  useOnClickOutside(refModalWarning, openModalWarning, setOpenModalWarning);
  useOnClickOutside(
    refModalAreYouSure,
    openModalAreYouSure,
    setOpenModalAreYouSure
  );
  useOnClickOutside(
    refModalUnifyContacts,
    openModalUnityContacts,
    setOpenModalUnityContacts
  );

  async function getFavoritesSearchs() {
    setIsLoading(true);
    FavoritesService.getFavSearchs()
      .then((result) => {
        setDataSearchs(result.searches);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getFavoritesProperties() {
    setIsLoading(true);
    FavoritesService.getFavProperties()
      .then((result) => {
        setDataProperties(result.properties);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getFavoritesContacts() {
    setIsLoading(true);
    FavoritesService.getFavContacts()
      .then((result) => {
        setDataContacts(result.contacts);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getFavoritesDevelopments() {
    setIsLoading(true);
    FavoritesService.getFavDevelopments()
      .then((result) => {
        setDataDevelopments(result.developments);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function exportExcel() {
    PropertiesService.exportToXls(listProperties).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const date = new Date();
      const dateTransform = date.toJSON().split('T')[0];
      link.setAttribute(
        'download',
        `${Trans(
          translations,
          'favoritesComponent.NameExcelFile',
          dateTransform
        )}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  function openNewTab() {
    const urlPdf = `${tokkoBrokerHost}/properties/pdf_list_details/?ids=${listProperties}&val=${pdfType}&include_agent=true`;
    window.open(urlPdf, '_blank', 'noopener,noreferrer');
  }

  function checkRemoveFromFavorites() {
    if (
      (favouriteSelectedTab === 'contacts' && listContacts.length !== 0) ||
      (favouriteSelectedTab === 'developments' &&
        listDevelopments.length !== 0) ||
      (favouriteSelectedTab === 'properties' && listProperties.length !== 0) ||
      (favouriteSelectedTab === 'searches' && listSearchs.length !== 0)
    ) {
      setOpenModalAreYouSure(true);
    } else {
      let textRemoveFromFavoritesEmpty = '';
      if (favouriteSelectedTab === 'contacts')
        textRemoveFromFavoritesEmpty = Trans(
          translations,
          'favoritesComponent.removeFromFavoritesEmptyContacts'
        );
      else if (favouriteSelectedTab === 'developments')
        textRemoveFromFavoritesEmpty = Trans(
          translations,
          'favoritesComponent.removeFromFavoritesEmptyDevelopments'
        );
      else if (favouriteSelectedTab === 'properties')
        textRemoveFromFavoritesEmpty = Trans(
          translations,
          'favoritesComponent.removeFromFavoritesEmptyProperties'
        );
      else if (favouriteSelectedTab === 'searches')
        textRemoveFromFavoritesEmpty = Trans(
          translations,
          'favoritesComponent.removeFromFavoritesEmptySearches'
        );
      setOpenModalWarning(true);
      setDescriptionWarning(textRemoveFromFavoritesEmpty);
    }
  }

  function removeFromFavorites() {
    if (favouriteSelectedTab === 'contacts') {
      listContacts.map((id) =>
        FavoritesService.deleteFavContacts(id).then(() => {
          if (listContacts.at(listContacts.length - 1) === id)
            setOpenModalAreYouSure(false);
        })
      );
    } else if (favouriteSelectedTab === 'developments') {
      listDevelopments.map((id) =>
        FavoritesService.deleteFavDevelopments(id).then(() => {
          if (listDevelopments.at(listDevelopments.length - 1) === id)
            setOpenModalAreYouSure(false);
        })
      );
    } else if (favouriteSelectedTab === 'properties') {
      listProperties.map((id) =>
        FavoritesService.deleteFavProperties(id).then(() => {
          if (listProperties.at(listProperties.length - 1) === id)
            setOpenModalAreYouSure(false);
        })
      );
    } else if (favouriteSelectedTab === 'searches') {
      listSearchs.map((id) =>
        FavoritesService.deleteFavSearch(id).then(() => {
          if (listSearchs.at(listSearchs.length - 1) === id)
            setOpenModalAreYouSure(false);
        })
      );
    }
  }

  async function confirmUnifyContacts() {
    FavoritesService.updateUnifyContacts(listContacts).then(
      getFavoritesContacts()
    );
  }

  // eslint-disable-next-line no-unused-vars
  async function unifyContacts() {
    if (listContacts.length !== 2) {
      setOpenModalWarning(true);
      setDescriptionWarning(
        Trans(translations, 'favoritesComponent.UnifyContactsWarning')
      );
    } else {
      FavoritesService.getDataForUnifyContacts().then((result) => {
        const { status, conflicts } = result.unify_form_data;
        setIdsRadioConflictContact(
          Array.from({ length: conflicts.length }, () => 0)
        );
        if (status === 'ok') {
          confirmUnifyContacts();
        } else if (status === 'verify') {
          setConflictsUnifyContacts(conflicts);
          setOpenModalUnityContacts(true);
        } else if (status === 'contact_deleted') {
          setOpenModalWarning(true);
          setDescriptionWarning(
            Trans(
              translations,
              'favoritesComponent.UnifyContactsWarningContactDeleted'
            )
          );
        } else if (status === 'unauthorized') {
          setOpenModalWarning(true);
          setDescriptionWarning(
            Trans(
              translations,
              'favoritesComponent.UnifyContactsWarningUnauthorized'
            )
          );
        }
      });
    }
  }

  function handleClickExportPDF() {
    if (listProperties.length === 0) {
      setOpenModalWarning(true);
      setDescriptionWarning(
        Trans(translations, 'favoritesComponent.WarningEmptyListProperties')
      );
    } else {
      setOpenModalExportPdf(true);
    }
  }

  function handleClickExportExcel() {
    if (listProperties.length === 0) {
      setOpenModalWarning(true);
      setDescriptionWarning(
        Trans(translations, 'favoritesComponent.WarningEmptyListProperties')
      );
    } else {
      exportExcel();
    }
  }

  const handleRadioButton = (id, index) => {
    setIdsRadioConflictContact((prevIds) => {
      const newIds = [...prevIds];
      newIds[index] = id;
      return newIds;
    });
  };

  useEffect(() => {
    getFavoritesSearchs();
    getFavoritesProperties();
    getFavoritesContacts();
    getFavoritesDevelopments();
    PropertiesService.getPagesForExportPdf().then((result) => {
      setListPagesPdf(result.pdf_types);
    });
  }, [openModalAreYouSure]);

  const handleOpenSender = (show) => {
    if (
      listProperties.length > 10 &&
      (!permissions.user_perms.properties ||
        !permissions.user_perms.properties.includes('can_export_more_than_10'))
    ) {
      dispatch(
        setNotificationText(
          Trans(translations, 'NoPermissionMoreThan10Properties')
        )
      );
      return;
    }

    const preSelectedProperties =
      favouriteSelectedTab === 'properties' ? listProperties : [];

    dispatch(handleSender({ show, preSelectedProperties }));
  };

  return (
    <>
      <ContainerMenu
        width="252px"
        height="325px"
        prefixIcon="icon-favoritos"
        titleText={Trans(translations, 'Favorites')}
        suffix={
          <ButtonIcon
            icon="icon-configuracion"
            variant={['subtleIcon', 'iconSmallSize18px']}
            active={activeBtnConfig}
            onClick={() => setActiveBtnConfig()}
            id="parentFav0"
            aria-describedby="tooltipFav0"
          />
        }
      >
        {typeof window === 'object' && (
          <div
            id="tooltipFav0"
            hidden={!activeBtnConfig}
            className={styles.tooltip}
            ref={ref}
          >
            <Submenu
              parentId="parentFav0"
              childrenId="tooltipFav0"
              offset={8}
              placement="bottom"
              style={{ paddingTop: '8px' }}
            >
              {favouriteSelectedTab === 'properties' && (
                <>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'ExportPDF')}
                    maxWidth
                    onClick={() => handleClickExportPDF()}
                  />
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'ExportExcel')}
                    maxWidth
                    onClick={() => handleClickExportExcel()}
                  />
                  {/**
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'SendbyWhatsapp')}
                    maxWidth
                  />
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'SendbyEmail')}
                    maxWidth
                  />
                   */}
                  <div className={styles.line} />
                </>
              )}
              {/* favouriteSelectedTab === 'contacts' && (
                <>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'Unifycontacts')}
                    maxWidth
                    onClick={() => unifyContacts()}
                  />
                  <div className={styles.line} />
                </>
              ) */}
              <ButtonIcon
                variant="submenu"
                text={Trans(translations, 'Removefromfavorites')}
                maxWidth
                onClick={() => checkRemoveFromFavorites()}
              />
              {favouriteSelectedTab !== 'developments' && (
                <>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'SendbyWhatsapp')}
                    maxWidth
                    onClick={() => handleOpenSender('whatsapp')}
                  />
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'SendbyEmail')}
                    maxWidth
                    onClick={() => handleOpenSender('email')}
                  />
                </>
              )}
            </Submenu>
          </div>
        )}
        <Tabs itemTabSelected={getItemSelected()}>
          <Tabs.Tab
            variant="default"
            id="searchs"
            key="Tab0"
            onClick={() => selectFavorite('searches')}
            data-testid="searchs"
            className={
              favouriteSelectedTab !== 'searches' ? styles.iconNotSelected : ''
            }
          >
            <Icon icon="icon-buscar" fontSize="12px" />
            <Text variant="body600.fontSize12">{dataSearchs.length}</Text>
          </Tabs.Tab>
          <Tabs.Tab
            variant="default"
            id="properties"
            key="Tab1"
            onClick={() => selectFavorite('properties')}
            data-testid="properties"
            className={
              favouriteSelectedTab !== 'properties'
                ? styles.iconNotSelected
                : ''
            }
          >
            <Icon icon="icon-propiedades" fontSize="12px" />
            <Text variant="body600.fontSize12">{dataProperties.length}</Text>
          </Tabs.Tab>
          <Tabs.Tab
            variant="default"
            id="contacts"
            key="Tab2"
            onClick={() => selectFavorite('contacts')}
            data-testid="contacts"
            className={
              favouriteSelectedTab !== 'contacts' ? styles.iconNotSelected : ''
            }
          >
            <Icon icon="icon-contactos" fontSize="12px" />
            <Text variant="body600.fontSize12">{dataContacts.length}</Text>
          </Tabs.Tab>
          <Tabs.Tab
            variant="default"
            id="developments"
            key="Tab3"
            onClick={() => selectFavorite('developments')}
            data-testid="developments"
            className={
              favouriteSelectedTab !== 'developments'
                ? styles.iconNotSelected
                : ''
            }
          >
            <Icon icon="icon-emprendimientos" fontSize="12px" />
            <Text variant="body600.fontSize12">{dataDevelopments.length}</Text>
          </Tabs.Tab>
          <Tabs.Content id="searchs" key="Content0">
            <Searchs
              dataSearchs={dataSearchs}
              listSearchs={listSearchs}
              setListSearchs={setListSearchs}
              updateList={updateList}
              selectAll={selectAll}
              isLoading={isLoading}
            />
          </Tabs.Content>
          <Tabs.Content id="properties" key="Content1">
            <Properties
              dataProperties={dataProperties}
              listProperties={listProperties}
              setListProperties={setListProperties}
              updateList={updateList}
              selectAll={selectAll}
              isLoading={isLoading}
            />
          </Tabs.Content>
          <Tabs.Content id="contacts" key="Content2">
            <Contacts
              dataContacts={dataContacts}
              listContacts={listContacts}
              setListContacts={setListContacts}
              updateList={updateList}
              selectAll={selectAll}
              isLoading={isLoading}
            />
          </Tabs.Content>
          <Tabs.Content id="developments" key="Content3">
            <Developments
              dataDevelopments={dataDevelopments}
              listDevelopments={listDevelopments}
              setListDevelopments={setListDevelopments}
              updateList={updateList}
              selectAll={selectAll}
              isLoading={isLoading}
            />
          </Tabs.Content>
        </Tabs>
      </ContainerMenu>
      {openModalExportPdf && (
        <Modal modalBG blockScroll>
          <Box ref={refModal}>
            <Modal.Header
              variant={['center', 'l']}
              text={Trans(translations, 'ExportToPDF')}
            />
            <Modal.Body>
              <Box
                __css={{
                  height: '140px',
                  marginTop: '20px',
                  marginBottom: '14px',
                }}
                className={styles.containerItemsPdf}
              >
                {listPagesPdf.map((page) => (
                  <Box
                    __css={{
                      padding: '8px 10px',
                      height: '130px',
                      width: '92px',
                      margin: '0 2px',
                    }}
                    className={
                      pdfType === page.type || pdfTypeHover === page.type
                        ? styles.itemPagePdfSelected
                        : styles.itemPagePdf
                    }
                    onClick={() => setPdfType(page.type)}
                    onMouseEnter={() => setPdfTypeHover(page.type)}
                    onMouseLeave={() => setPdfTypeHover(0)}
                    key={page.name}
                  >
                    <Image
                      src={
                        page.icon ||
                        'https://static.tokkobroker.com/static/img/pdf_templates/export-pdf-custom.svg'
                      }
                      height="60px"
                    />
                    <Text
                      variant={
                        pdfType === page.type || pdfTypeHover === page.type
                          ? 'bodyBold.fontSize13'
                          : 'body.fontSize13'
                      }
                      className={styles.textPdfItem}
                    >
                      {page.name}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Modal.Body>
            <Modal.Footer variant="end">
              <Button
                variant="subtleText"
                onClick={() => setOpenModalExportPdf(false)}
              >
                {Trans(translations, 'Cancel')}
              </Button>
              <Button
                variant={['primary', 'medium']}
                onClick={() => openNewTab()}
              >
                {Trans(translations, 'Ok')}
              </Button>
            </Modal.Footer>
          </Box>
        </Modal>
      )}
      {openModalAreYouSure && (
        <Modal modalBG blockScroll>
          <Box ref={refModalAreYouSure}>
            <Modal.Header
              variant={['left', 'sm']}
              text={Trans(translations, 'Areyousure')}
            />
            <Modal.Body className={styles.bodyModalWarning}>
              <Text variant="body.fontSize13">
                {Trans(
                  translations,
                  'favoritesComponent.MessageConfirmRemoveFromFavorites'
                )}
              </Text>
            </Modal.Body>
            <Modal.Footer variant="end">
              <Button
                variant="subtleText"
                onClick={() => setOpenModalAreYouSure(false)}
              >
                {Trans(translations, 'Cancel')}
              </Button>
              <Button
                variant={['primary', 'medium']}
                onClick={() => removeFromFavorites()}
              >
                {Trans(translations, 'Ok')}
              </Button>
            </Modal.Footer>
          </Box>
        </Modal>
      )}
      {openModalUnityContacts && (
        <Modal modalBG blockScroll>
          <Box ref={refModalUnifyContacts}>
            <Modal.Header
              variant={['center', 'l']}
              text={Trans(translations, 'Unifycontacts')}
            />
            <Modal.Body className={styles.paddingTop}>
              <Text variant="body.fontSize13">
                {Trans(
                  translations,
                  'favoritesComponent.UnifyContactsDescription'
                )}
              </Text>
              {conflictsUnifyContacts.map((conflict, index) => (
                <Box key={conflict.text} className={styles.containerConflict}>
                  <Text variant="bodyBold.fontSize13">{conflict.text}</Text>
                  {conflict.values.map((value) => (
                    <Box key={value.id} className={styles.radioItem}>
                      <Radio
                        checked={idsRadioConflictContact[index] === value.id}
                        onChange={() => handleRadioButton(value.id, index)}
                      />
                      <Text variant="body.fontSize13">{value.value}</Text>
                    </Box>
                  ))}
                </Box>
              ))}
            </Modal.Body>
            <Modal.Footer variant="end" className={styles.paddingTop}>
              <Button
                variant="subtleText"
                onClick={() => setOpenModalUnityContacts(false)}
              >
                {Trans(translations, 'Cancel')}
              </Button>
              <Button
                variant={['primary', 'medium']}
                onClick={() => confirmUnifyContacts()}
              >
                {Trans(translations, 'Ok')}
              </Button>
            </Modal.Footer>
          </Box>
        </Modal>
      )}
      {openModalWarning && (
        <Modal modalBG blockScroll>
          <Box ref={refModalWarning}>
            <Modal.Header
              variant={['left', 'sm']}
              text={Trans(translations, 'Warning')}
            />
            <Modal.Body className={styles.bodyModalWarning}>
              <Text variant="body.fontSize13">{descriptionWarning}</Text>
            </Modal.Body>
            <Modal.Footer variant="center">
              <Button
                variant={['primary', 'medium']}
                onClick={() => setOpenModalWarning(false)}
              >
                {Trans(translations, 'Ok')}
              </Button>
            </Modal.Footer>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default Favorites;
