import { Box, Text } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import styles from './styles.module.scss';

function DynamicTagsPicker({ handleSelect, handleClose }) {
  const TAGS = [
    { id: 1, value: `${Trans(translations, '[[contact_name]]')}` },
    {
      id: 2,
      value: `${Trans(translations, '[[contact_first_name]]')}`,
    },
    {
      id: 3,
      value: `${Trans(translations, '[[starred_properties]]')}`,
    },
    {
      id: 4,
      value: `${Trans(translations, '[[starred_developments]]')}`,
    },
    {
      id: 5,
      value: `${Trans(translations, '[[active_searches]]')}`,
    },
    { id: 6, value: `${Trans(translations, '[[agent_name]]')}` },
    {
      id: 7,
      value: `${Trans(translations, '[[agent_first_name]]')}`,
    },
  ];

  const handleClick = (tag) => {
    handleSelect(tag);
    handleClose();
  };

  return (
    <Box className={styles.container} data-testid="dynamic-tags-picker">
      <Text className={styles.title}>{Trans(translations, 'DynamicTags')}</Text>
      <Text className={styles.subtitle}>{Trans(translations, 'ToUseIt')}</Text>
      <Box className={styles['tags-wrapper']}>
        {TAGS.map((tag) => (
          <Box
            key={tag.id}
            as="button"
            className={styles.tag}
            onClick={() => handleClick(tag)}
          >
            {tag.value}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default DynamicTagsPicker;
