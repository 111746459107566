import { Box, Carousel } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';
import UseResizeWindows from '@/components/shared/Hooks/ResizeWindows';

export default function Gallery() {
  const { quickData, active } = useSelector((state) => state.QuickDisplaySlice);
  const { data } = quickData;
  const winWidth = UseResizeWindows();

  if (!data) {
    return (
      <Box __css={{ display: 'flex', gap: '16px' }}>
        <Skeletons width={555} borderRadius={12} height={307} />
        {winWidth >= 1280 && (
          <Box
            __css={{ display: 'flex', gap: '16px', flexDirection: 'column' }}
          >
            <Skeletons width={233} borderRadius={12} height={144} />
            <Skeletons width={233} borderRadius={12} height={144} />
          </Box>
        )}
      </Box>
    );
  }

  const handleCoverImg = (type) => {
    if (!data.pictures.front_cover_image) {
      return false;
    }

    if (type === 'img') {
      return (
        !data.pictures.front_cover_image.is_blueprint &&
        data.pictures.front_cover_image.url
      );
    }

    return (
      data.pictures.front_cover_image.is_blueprint &&
      data.pictures.front_cover_image.url
    );
  };

  return (
    <Box
      className={`${style.propertyDescriptionContainer} ${
        !active ? style.propertyDeleted : ''
      }`}
    >
      <Carousel
        frontCoverImg={handleCoverImg('img')}
        frontCoverBlueprints={handleCoverImg('blueprint')}
        images={data.pictures.images}
        planos={data.pictures.blueprints}
        video={data.videos.normal}
        video360={data.videos['360']}
      />
    </Box>
  );
}
