import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import Radio from '@/components/radio/Radio';
import { setWhatsappApp } from '@/store/slices/components/sender/SenderSlice';
import TooltipMessage from '../../TooltipMessage';
import useOnClickOutside from '@/components/shared/Hooks';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function WhatsappSendBy() {
  const dispatch = useDispatch();
  const { whatsapp } = useSelector((state) => state.SenderSlice);
  const tooltipRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  useOnClickOutside(tooltipRef, isTooltipOpen, setIsTooltipOpen);

  const handleChange = (value) => {
    dispatch(setWhatsappApp(value));
  };

  return (
    <Box className={styles.container}>
      <Text className={styles.label}> {Trans(translations, 'SendBy')} </Text>

      <Box className={styles['select-app-wrapper']}>
        <Box as="label" className={styles['radio-row']}>
          <Radio
            checked={whatsapp.app === 'desktop'}
            onChange={() => handleChange('desktop')}
          />
          <Text className={styles['radio-text']}>
            {Trans(translations, 'WhatsappDesktop')}
          </Text>
        </Box>

        <Box as="label" className={styles['radio-row']}>
          <Radio
            checked={whatsapp.app === 'web'}
            onChange={() => handleChange('web')}
          />
          <Text className={styles['radio-text']}>WhatsApp web</Text>
        </Box>
      </Box>

      <Box ref={tooltipRef} __css={{ position: 'relative' }}>
        <Icon
          icon="icon-info"
          className={styles['whatsapp-tooltip-button']}
          onClick={() => setIsTooltipOpen((prevState) => !prevState)}
          fontSize="16px"
          color="#566A75"
        />
        {isTooltipOpen && (
          <TooltipMessage __css={{ right: -16, top: -14, width: 314 }}>
            <Box className={styles['tooltip-header']}>
              <Text
                className={`${styles['tooltip-text']} ${styles['tooltip-text--highlighted']}`}
              >
                {Trans(translations, 'SendingOptions')}
              </Text>
              <Icon
                icon="icon-cerrar"
                fontSize="12px"
                color="#FFFFFF"
                onClick={() => setIsTooltipOpen(false)}
                className={styles['tooltip-close-icon']}
              />
            </Box>

            <Text className={styles['tooltip-text']}>
              {Trans(translations, 'YouCanUseWhatsAppFromDesktop')}
            </Text>

            <Box
              as="a"
              className={styles['tooltip-link']}
              href="https://dudas.tokkobroker.com/"
              target="_blank"
            >
              <Text
                className={`${styles['tooltip-text']} ${styles['tooltip-text--highlighted']}`}
              >
                {Trans(translations, 'HelpPage')}
              </Text>
              <Icon icon="icon-ir" fontSize="12px" color="#FFFFFF" />
            </Box>
          </TooltipMessage>
        )}
      </Box>
    </Box>
  );
}

export default WhatsappSendBy;
