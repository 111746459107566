import { forwardRef } from 'react';
import { Box } from '@oneloop/jopijs';
import styles from './styles.module.scss';

const TooltipMessage = forwardRef(
  ({ children, className, centered, ...props }, ref) => (
    <Box
      ref={ref}
      className={`${styles['tooltip-message']} ${
        centered ? styles.centered : styles.default
      } ${className ?? ''}`}
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      {children}
    </Box>
  )
);

export default TooltipMessage;
