import SelectedPropertyCard from './variants/SelectedPropertyCard';
import SuggestionPropertyCard from './variants/SuggestionPropertyCard';

const COMPONENT_VARIANTS = {
  selected: SelectedPropertyCard,
  suggestion: SuggestionPropertyCard,
};

const DEFAULT_VARIANT = SelectedPropertyCard;

function PropertyCard({ property, variant }) {
  const TargetCard = COMPONENT_VARIANTS[variant] ?? DEFAULT_VARIANT;

  return <TargetCard property={property} />;
}

export default PropertyCard;
