function getInitials(nameToSplit) {
  // Dividir el string en palabras utilizando expresiones regulares para separar por espacios
  const words = nameToSplit.split(/\s+/);

  // Mapear cada palabra a su primera letra si es alfabética y convertirla a mayúsculas
  const initials = words
    .map((word) => {
      const match = word[0]?.match(/[A-Za-z]/);
      return match ? match[0].toUpperCase() : '';
    })
    .filter(Boolean) // Filtrar elementos no alfabéticos o vacíos
    .slice(0, 2) // Limitar a las dos primeras iniciales
    .join(''); // Unir las iniciales en un string

  return initials;
}

export default getInitials;
