import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import SearchResults from './SearchResults';
import { getContactsAddressees } from '@/store/slices/components/sender/services';
import {
  setAddresseesData,
  setSelectedAddressees,
} from '@/store/slices/components/sender/SenderSlice';
import createContact from '../utils/createContact';
import getHowManyCollapsedToShow from '../utils/getHowManyCollapsedToShow';
import SelectedItems from './SelectedItems';
import InputForContact from './InputForContact';
import ButtonCC from './ButtonCC';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';
import EmailForContact from './SearchResults/NewContact/EmailForContact';

const MAX_SELECTED_ADDRESSEES = 20;
let debounceTimer;
let searchingSignal;

function Addressees() {
  const dispatch = useDispatch();
  const { addressees, cc, preSelectedContacts, warning } = useSelector(
    (state) => state.SenderSlice
  );
  const selectedWrapperRef = useRef(null);
  const [oneAddresseeError, setOneAddresseeError] = useState(false);
  const addresseesTooltipContainerId = 'addressees-tooltip-container';
  const addresseesItemsContainerId = 'addressees-items-container';
  const maxSelectedReached =
    addressees.selected.length === MAX_SELECTED_ADDRESSEES;
  const allSelected = [...addressees.selected, ...cc.selected];
  const emptyEmailContact = addressees.selected.find(
    (addressee) => addressee.emails.length === 0 && !addressee.isValid
  );

  const isWarning = warning && warning.fields.includes('contact');
  const allSelectedAreValid = allSelected.every((element) => element.isValid);
  const inputAlreadyExists = allSelected.find(
    (el) => el.isRaw && el.id === addressees.input
  );

  const cancelPendentOperations = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      dispatch(setAddresseesData({ key: 'fetchedSuggestions', value: null }));
    }

    if (searchingSignal) {
      searchingSignal.abort();
    }
  };

  const addRawContact = () => {
    if (inputAlreadyExists) return;

    cancelPendentOperations();
    const rawContact = createContact.raw.email(addressees.input);
    const newValue = [...addressees.selected, rawContact];
    dispatch(setSelectedAddressees(newValue));
  };

  useEffect(() => {
    const collapseAddressees = (event) => {
      if (event.target.classList.contains('.sender-send-button')) {
        cancelPendentOperations();
        return;
      }

      if (
        selectedWrapperRef.current &&
        !selectedWrapperRef.current.contains(event.target)
      ) {
        if (addressees.input) {
          addRawContact();
          return;
        }

        const value = getHowManyCollapsedToShow(
          `#${addresseesItemsContainerId}`
        );

        dispatch(setAddresseesData({ key: 'isCollapsed', value: true }));
        dispatch(setAddresseesData({ key: 'howManyShowCollapsed', value }));
      }
    };

    document.addEventListener('click', collapseAddressees);

    return () => {
      document.removeEventListener('click', collapseAddressees);
    };
  }, [addressees.input]);

  useEffect(() => {
    if (cc.selected.length === 0 || addressees.selected.length !== 1) {
      setOneAddresseeError(false);
    }
  }, [addressees.selected.length, cc.selected.length]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    dispatch(setAddresseesData({ key: 'input', value }));
    const clearQuery = value.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        searchingSignal = dispatch(getContactsAddressees(clearQuery));
      }, 1000);
    } else {
      dispatch(setAddresseesData({ key: 'fetchedSuggestions', value: null }));
    }
  }, []);

  const handleSelect = (suggestion) => {
    const newSelected = createContact.suggestion.email(suggestion);
    const newValue = [...addressees.selected, newSelected];
    dispatch(setSelectedAddressees(newValue));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addRawContact();
  };

  const handleClickItemsContainer = () => {
    dispatch(setAddresseesData({ key: 'isCollapsed', value: false }));
  };

  const setSelected = (newValue) => {
    dispatch(setSelectedAddressees(newValue));
  };

  const handleAddNewContact = (newContactCreated) => {
    const parsedContact = createContact.new(newContactCreated);
    const updatedSelected = [...addressees.selected, parsedContact];
    dispatch(setSelectedAddressees(updatedSelected));
  };

  const handleAddEmail = (contactModified) => {
    const parsedContact = createContact.new(contactModified);
    const updatedSelected = addressees.selected.map((addressee) =>
      String(addressee.id) === String(contactModified.id)
        ? parsedContact
        : addressee
    );
    dispatch(setSelectedAddressees(updatedSelected));
  };

  return (
    <Box as="section" className={styles.container}>
      <Box
        className={`${styles.row} ${
          isWarning ? styles['row--warning-highlight'] : ''
        }`}
        id={addresseesTooltipContainerId}
        data-testid="sender-addressees"
      >
        <Text className={styles['row-label']}>{Trans(translations, 'To')}</Text>

        <Box
          ref={selectedWrapperRef}
          id={addresseesItemsContainerId}
          className={styles['content-wrapper']}
          onClick={handleClickItemsContainer}
          __css={{ maxHeight: 150 }}
        >
          <SelectedItems
            selected={addressees.selected}
            setSelected={setSelected}
            isCollapsed={addressees.isCollapsed}
            howManyShowCollapsed={addressees.howManyShowCollapsed}
            tooltipContainerId={addresseesTooltipContainerId}
          />

          {preSelectedContacts.length === 0 &&
            allSelectedAreValid &&
            !maxSelectedReached && (
              <>
                <Box as="form" className={styles.form} onSubmit={handleSubmit}>
                  {cc.selected.length > 0 ? (
                    <Box
                      className={styles['empty-space-instead-input']}
                      onClick={() => setOneAddresseeError(true)}
                    />
                  ) : (
                    <InputForContact
                      value={addressees.input}
                      onChange={handleChange}
                      inputAlreadyExists={inputAlreadyExists}
                      isCollapsed={addressees.isCollapsed}
                      testId="addressees-input"
                    />
                  )}
                </Box>

                <SearchResults
                  row="addressees"
                  isLoading={addressees.isLoading}
                  results={addressees.fetchedSuggestions}
                  onSelect={handleSelect}
                  input={addressees.input}
                  handleAddNewContact={handleAddNewContact}
                />
              </>
            )}

          {emptyEmailContact && (
            <Box className={styles['add-email-wrapper']}>
              <EmailForContact
                handleAddEmail={handleAddEmail}
                id={emptyEmailContact.id}
              />
            </Box>
          )}

          {preSelectedContacts.length > 0 && (
            <>
              <Box
                data-testid="sender-contact-skeleton"
                className={styles.skeleton}
              />
              <Box
                data-testid="sender-contact-skeleton"
                className={styles.skeleton}
              />
            </>
          )}

          {addressees.selected.length === 1 &&
            addressees.selected[0]?.isValid && <ButtonCC />}
        </Box>
      </Box>

      {maxSelectedReached && (
        <Text
          className={styles['warning-message']}
          data-testid="addressees-error-text"
        >
          {`${Trans(
            translations,
            'YouHaveReached'
          )} ${MAX_SELECTED_ADDRESSEES} ${Trans(
            translations,
            'ContactsLimit'
          )}`}
        </Text>
      )}

      {oneAddresseeError && (
        <Text className={styles['warning-message']}>
          {Trans(translations, 'LimitContactsByCC')}
        </Text>
      )}
    </Box>
  );
}

export default Addressees;
