import { createSlice } from '@reduxjs/toolkit';
import createContact from '@/components/Sender/utils/createContact';
import { initialState } from './initialState';
import {
  getContactsCC,
  getAfterSending,
  getContactsAddressees,
  getProperties,
  getRelated,
  getPropertyFiles,
  getContactReports,
  getContactsWhatsapp,
  getDevelopmentFiles,
  getPropertyAndDevelopmentFiles,
  getPreSelectedContacts,
  getPreSelectedDevelopments,
  sendEmail,
  validateWhatsapp,
  getStarredForEmail,
  getStarredForWhatsapp,
  getPreSelectedPropertiesById,
  getPreSelectedPropertiesByHash,
  getPropertiesFiles,
} from './services';

export const SenderSlice = createSlice({
  name: 'Sender',
  initialState,
  reducers: {
    handleSender(state, action) {
      const {
        show,
        preSelectedContacts,
        preSelectedProperties,
        preSelectedDevelopments,
        openedInContactFile,
        fromOuterNet,
        isDefaultEdited,
      } = action.payload;

      state.show = show;
      state.openedInContactFile =
        openedInContactFile ?? initialState.openedInContactFile;
      state.fromOuterNet = fromOuterNet ?? initialState.fromOuterNet;
      state.isDefaultEdited = isDefaultEdited ?? initialState.isDefaultEdited;
      state.preSelectedContacts =
        preSelectedContacts ?? initialState.preSelectedContacts;
      state.preSelectedProperties =
        preSelectedProperties ?? initialState.preSelectedProperties;
      state.preSelectedDevelopments =
        preSelectedDevelopments ?? initialState.preSelectedDevelopments;
    },
    resetSlice(state) {
      Object.assign(state, initialState);
    },
    setAfterSending(state, action) {
      state.afterSending.selected = action.payload;
    },
    setSubject(state, action) {
      state.subject = action.payload;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setMessage(state, action) {
      const { text, html } = action.payload;
      state.message = { text, html };
    },
    setSelectedAddressees(state, action) {
      state.addressees.selected = action.payload;
      state.addressees.fetchedSuggestions = null;
      state.addressees.input = '';

      if (action.payload.length === 0) {
        state.cc.selected = [];
      }
    },
    setSelectedCC(state, action) {
      state.cc.selected = action.payload;
      state.cc.fetchedSuggestions = null;
      state.cc.input = '';
    },
    setSelectedProperties(state, action) {
      state.properties.selected = action.payload;
      state.properties.fetchedSuggestions = null;
      state.properties.input = '';
      state.properties.showStarred = false;
    },
    setSelectedWhatsApp(state, action) {
      state.whatsapp.selected = action.payload;
      state.whatsapp.fetchedSuggestions = null;
      state.whatsapp.input = '';
    },
    setCCData(state, action) {
      const { key, value } = action.payload;
      state.cc[key] = value;
    },
    setAddresseesData(state, action) {
      const { key, value } = action.payload;
      state.addressees[key] = value;
    },
    setWhatsappData(state, action) {
      const { key, value } = action.payload;
      state.whatsapp[key] = value;
    },
    setPropertiesData(state, action) {
      const { key, value } = action.payload;
      state.properties[key] = value;
    },
    setRelatedData(state, action) {
      const { key, value } = action.payload;
      state.related[key] = value;
    },
    setPdfOptionData(state, action) {
      const { key, value } = action.payload;
      state.pdfOption[key] = value;
    },
    handleWarning(state, action) {
      state.warning = action.payload;
    },
    resetWarning(state) {
      state.warning = initialState.warning;
    },
    setShowStarred(state, action) {
      state.properties.showStarred = action.payload;
      state.properties.fetchedSuggestions = null;
    },
    setWhatsappApp(state, action) {
      localStorage.setItem('sender-whatsapp-preference', action.payload);
      state.whatsapp.app = action.payload;
    },
    setSendCompanyData(state, action) {
      state.sendCompanyData = action.payload;
    },
    setIsSendingEmail(state, action) {
      state.isSendingEmail = action.payload;
    },
    setIsPreparingWhatsapp(state, action) {
      state.isPreparingWhatsapp = action.payload;
    },
    setTour(state, action) {
      localStorage.setItem('sender-tour', JSON.stringify(action.payload));
      state.tour = action.payload;
    },
    setFromOuterNet(state, action) {
      state.fromOuterNet = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getAfterSending
    builder
      .addCase(getAfterSending.pending, (state) => {
        state.afterSending.isLoading = true;
      })
      .addCase(getAfterSending.fulfilled, (state, action) => {
        const defaultValue = {
          id: -1,
          color: 'FFFFFF',
          default_status_when_sending_email: false,
          name: 'No cambiar estado',
        };
        const payloadWithDefaultValue = [defaultValue, ...action.payload];

        const transformedPayload = payloadWithDefaultValue.map(
          (leadStatus) => ({
            id: leadStatus.id,
            color: leadStatus.color,
            text: leadStatus.name,
            defaultChecked: leadStatus.default_status_when_sending_email,
          })
        );

        const defaultSelected =
          transformedPayload.find((el) => el.defaultChecked) ||
          transformedPayload[0];

        state.afterSending.isLoading = false;
        state.afterSending.data = transformedPayload;
        state.afterSending.selected = defaultSelected;
      })
      .addCase(getAfterSending.rejected, (state) => {
        state.afterSending.isError = true;
      });

    // getProperties
    builder
      .addCase(getProperties.pending, (state) => {
        state.properties.isError = false;
        state.properties.isLoading = true;
        state.properties.fetchedSuggestions = null;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.properties.showStarred = false;
        state.properties.isLoading = false;
        state.properties.fetchedSuggestions = action.payload.map((property) => {
          const parsedProperty = {
            ...property,
            isLoadingFiles: false,
            isEditedActive: property.is_edited_data,
          };

          return parsedProperty;
        });
      })
      .addCase(getProperties.rejected, (state) => {
        state.properties.showStarred = false;
        state.properties.isError = true;
        state.properties.isLoading = false;
      });

    // getRelated
    builder
      .addCase(getRelated.pending, (state) => {
        state.related.isError = false;
        state.related.isLoading = true;
        state.related.data = [];

        // reset CC
        state.cc.selected = [];
      })
      .addCase(getRelated.fulfilled, (state, action) => {
        state.related.isLoading = false;

        const transformedRelated = action.payload.map((related) =>
          createContact.related(related)
        );
        state.related.data = transformedRelated;
      })
      .addCase(getRelated.rejected, (state) => {
        state.related.isError = true;
        state.related.isLoading = false;
      });

    // getContactsAddressees
    builder
      .addCase(getContactsAddressees.pending, (state) => {
        state.addressees.isError = false;
        state.addressees.isLoading = true;
        state.addressees.fetchedSuggestions = null;
      })
      .addCase(getContactsAddressees.fulfilled, (state, action) => {
        state.addressees.isLoading = false;
        const { addressees, cc } = state;
        const { contacts } = action.payload;

        const foundRawSelected = [...addressees.selected, ...cc.selected]
          .filter((selected) => selected.isRaw)
          .find((selected) =>
            selected.emails.find((email) => email.value === addressees.input)
          );

        const rawSelectedAsArray = foundRawSelected ? [foundRawSelected] : [];

        const targetContacts =
          contacts.length > 0 ? contacts : rawSelectedAsArray;

        state.addressees.fetchedSuggestions = targetContacts;
      })
      .addCase(getContactsAddressees.rejected, (state) => {
        state.addressees.isError = true;
        state.addressees.isLoading = false;
      });

    // getContactsCC
    builder
      .addCase(getContactsCC.pending, (state) => {
        state.cc.isError = false;
        state.cc.isLoading = true;
        state.cc.fetchedSuggestions = null;
      })
      .addCase(getContactsCC.fulfilled, (state, action) => {
        state.cc.isLoading = false;
        const { addressees, cc } = state;
        const { contacts } = action.payload;

        const foundRawSelected = [...addressees.selected, ...cc.selected]
          .filter((selected) => selected.isRaw)
          .find((selected) =>
            selected.emails.find((email) => email.value === cc.input)
          );

        const rawSelectedAsArray = foundRawSelected ? [foundRawSelected] : [];

        const targetContacts =
          contacts.length > 0 ? contacts : rawSelectedAsArray;

        state.cc.fetchedSuggestions = targetContacts;
      })
      .addCase(getContactsCC.rejected, (state) => {
        state.cc.isError = true;
        state.cc.isLoading = false;
      });

    // getPropertyFiles
    builder.addCase(getPropertyFiles.pending, (state, action) => {
      const id = action.meta.arg;

      const updatedSelected = state.properties.selected.map((property) =>
        property.id === id ? { ...property, isLoadingFiles: true } : property
      );

      state.properties.selected = updatedSelected;
    });

    builder.addCase(getPropertyFiles.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const files = action.payload.files.map((file) => ({
        ...file,
        active: false,
      }));

      const updatedSelected = state.properties.selected.map((property) =>
        property.id === id
          ? { ...property, files, developmentFiles: [], isLoadingFiles: false }
          : property
      );

      state.properties.selected = updatedSelected;
    });

    // getPropertiesFiles
    builder.addCase(getPropertiesFiles.fulfilled, (state, action) => {
      const updatedSelected = state.properties.selected.map((property) => {
        const foundProperty = action.payload.find(
          (response) => response.property_id === property.id
        );

        return {
          ...property,
          isLoadingFiles: false,
          files: foundProperty ? foundProperty.files : property.files,
          fromOuterNet: false,
        };
      });

      state.properties.selected = updatedSelected;
    });

    // getDevelopmentFiles
    builder.addCase(getDevelopmentFiles.pending, (state, action) => {
      const id = action.meta.arg;

      const updatedSelected = state.properties.selected.map((property) =>
        property.id === id ? { ...property, isLoadingFiles: true } : property
      );

      state.properties.selected = updatedSelected;
    });

    builder.addCase(getDevelopmentFiles.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const files = action.payload.files.map((file) => ({
        ...file,
        active: false,
      }));

      const updatedSelected = state.properties.selected.map((property) =>
        property.id === id
          ? { ...property, files, developmentFiles: [], isLoadingFiles: false }
          : property
      );

      state.properties.selected = updatedSelected;
    });

    // getPropertyAndDevelopmentFiles
    builder.addCase(getPropertyAndDevelopmentFiles.pending, (state, action) => {
      const { propertyId } = action.meta.arg;

      const updatedSelected = state.properties.selected.map((property) =>
        property.id === propertyId
          ? { ...property, isLoadingFiles: true }
          : property
      );

      state.properties.selected = updatedSelected;
    });

    builder.addCase(
      getPropertyAndDevelopmentFiles.fulfilled,
      (state, action) => {
        const { propertyId } = action.meta.arg;

        const files = action.payload.property.files.map((file) => ({
          ...file,
          active: false,
        }));

        const developmentFiles = action.payload.development.files.map(
          (file) => ({
            ...file,
            active: false,
          })
        );

        const updatedSelected = state.properties.selected.map((property) =>
          property.id === propertyId
            ? { ...property, files, developmentFiles, isLoadingFiles: false }
            : property
        );

        state.properties.selected = updatedSelected;
      }
    );

    // getContactReports
    builder.addCase(getContactReports.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const reports = action.payload.properties.map((report) => ({
        ...report,
        active: false,
      }));

      const updatedSelected = state.addressees.selected.map((addressee) =>
        addressee.id === id ? { ...addressee, reports } : addressee
      );

      state.addressees.selected = updatedSelected;
    });

    // getWhatsapp
    builder
      .addCase(getContactsWhatsapp.pending, (state) => {
        state.whatsapp.isError = false;
        state.whatsapp.isLoading = true;
        state.whatsapp.fetchedSuggestions = null;
      })
      .addCase(getContactsWhatsapp.fulfilled, (state, action) => {
        state.whatsapp.isLoading = false;
        state.whatsapp.fetchedSuggestions = action.payload.contacts;
      })
      .addCase(getContactsWhatsapp.rejected, (state) => {
        state.whatsapp.isError = true;
        state.whatsapp.isLoading = false;
      });

    // getPreSelectedContact
    builder.addCase(getPreSelectedContacts.fulfilled, (state, action) => {
      const { contacts } = action.payload;
      const parseContact =
        state.show === 'email'
          ? createContact.suggestion.email
          : createContact.suggestion.whatsapp;

      const parsedContacts = contacts.map((contact) => {
        const parsedContact = { ...parseContact(contact) };
        return parsedContact;
      });

      const field = state.show === 'email' ? 'addressees' : 'whatsapp';

      state[field].selected = [...state[field].selected, ...parsedContacts];
      state.preSelectedContacts = [];
    });

    // getPreSelectedPropertiesById
    builder.addCase(getPreSelectedPropertiesById.fulfilled, (state, action) => {
      const parsedProperties = action.payload.map((property) => ({
        ...property,
        isEditedActive: property.is_edited_data && state.isDefaultEdited,
        isLoadingFiles: true,
        files: [],
        developmentFiles: [],
        fromOuterNet: false,
      }));

      state.properties.selected = parsedProperties;
      state.preSelectedProperties = [];
      state.isDefaultEdited = true;
    });

    // getPreSelectedPropertiesByHash
    builder.addCase(
      getPreSelectedPropertiesByHash.fulfilled,
      (state, action) => {
        const parsedProperties = action.payload.map((property) => ({
          ...property,
          isEditedActive: property.is_edited_data,
          isLoadingFiles: false,
          files: property.files ?? [],
          developmentFiles: property.developmentFiles ?? [],
          fromOuterNet: true,
        }));

        state.properties.selected = parsedProperties;
        state.preSelectedProperties = [];
      }
    );

    // getPreSelectedDevelopments
    builder.addCase(getPreSelectedDevelopments.fulfilled, (state, action) => {
      const parsedDevelopments = action.payload.map((development) => ({
        ...development,
        fromOuterNet: false,
      }));

      state.properties.selected = [
        ...state.properties.selected,
        ...parsedDevelopments,
      ];
      state.preSelectedDevelopments = [];
    });

    // getStarredForEmail
    builder.addCase(getStarredForEmail.pending, (state, action) => {
      const id = action.meta.arg;
      const newSelected = state.addressees.selected.map((addressee) =>
        String(addressee.id) === String(id)
          ? { ...addressee, starred: [] }
          : addressee
      );

      state.addressees.selected = newSelected;
    });

    builder.addCase(getStarredForEmail.fulfilled, (state, action) => {
      const id = action.meta.arg;

      const parsedStarredProperties = action.payload.map((property) => {
        const parsedProperty = { ...property };
        if (!property.is_development) {
          parsedProperty.isEditedActive = property.is_edited_data;
        }

        return parsedProperty;
      });

      const updatedSelected = state.addressees.selected.map((addressee) =>
        String(addressee.id) === String(id)
          ? { ...addressee, starred: parsedStarredProperties }
          : addressee
      );

      state.addressees.selected = updatedSelected;
    });

    // sendEmail
    builder.addCase(sendEmail.pending, (state) => {
      state.isSendingEmail = true;
    });

    builder.addCase(sendEmail.fulfilled, (state) => {
      state.isSendingEmail = false;
    });

    builder.addCase(sendEmail.rejected, (state) => {
      state.isSendingEmail = false;
    });

    // validateWhatsapp
    builder.addCase(validateWhatsapp.pending, (state, action) => {
      const phone = action.meta.arg;

      const updatedSelected = state.whatsapp.selected.map((selected) =>
        selected.cellphone === phone
          ? { ...selected, isValid: false, isValidated: false }
          : selected
      );

      state.whatsapp.selected = updatedSelected;
    });

    builder.addCase(validateWhatsapp.fulfilled, (state, action) => {
      const phone = action.meta.arg;

      const updatedSelected = state.whatsapp.selected.map((selected) =>
        selected.cellphone === phone
          ? { ...selected, isValid: true, isValidated: true }
          : selected
      );

      state.whatsapp.selected = updatedSelected;
    });

    builder.addCase(validateWhatsapp.rejected, (state, action) => {
      const phone = action.meta.arg;

      const updatedSelected = state.whatsapp.selected.map((selected) =>
        selected.cellphone === phone
          ? { ...selected, isValid: false, isValidated: true }
          : selected
      );

      state.whatsapp.selected = updatedSelected;
    });

    // getStarredForWhatsapp
    builder.addCase(getStarredForWhatsapp.pending, (state, action) => {
      const id = action.meta.arg;
      const newSelected = state.whatsapp.selected.map((addressee) =>
        String(addressee.id) === String(id)
          ? { ...addressee, starred: [] }
          : addressee
      );

      state.whatsapp.selected = newSelected;
    });

    builder.addCase(getStarredForWhatsapp.fulfilled, (state, action) => {
      const id = action.meta.arg;

      const updatedSelected = state.whatsapp.selected.map((addressee) =>
        String(addressee.id) === String(id)
          ? { ...addressee, starred: action.payload }
          : addressee
      );

      state.whatsapp.selected = updatedSelected;
    });
  },
});

export const {
  handleSender,
  resetSlice,
  setImagePickerIsOpen,
  setLinkPickerIsOpen,
  setDynamicTagsPickerIsOpen,
  setAfterSending,
  setSubject,
  setLanguage,
  setMessage,
  setSelectedAddressees,
  setSelectedCC,
  setSelectedProperties,
  setSelectedWhatsApp,
  setAddresseesData,
  setWhatsappData,
  setCCData,
  setPropertiesData,
  setRelatedData,
  setPdfOptionData,
  setTour,
  setFromOuterNet,
  handleWarning,
  resetWarning,
  setShowStarred,
  setWhatsappApp,
  setSendCompanyData,
  setIsSendingEmail,
  setIsPreparingWhatsapp,
} = SenderSlice.actions;

export default SenderSlice.reducer;
