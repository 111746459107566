import { Box, Button, Loader } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import translations from './CalendarEvents.trans.json';
import Trans from '@/components/shared/Trans';
import {
  changerValue,
  resetErrors,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import styles from './calendarEvents.module.scss';

export default function BtnsGroup({
  exitModal,
  handleCreateEvent,
  handleDeleteEvent,
  handleDoneEvent,
}) {
  const { loading, edit, agents } = useSelector(
    (state) => state.CalendarEvents
  );
  const perms = useSelector(
    (state) => state.AuthSlice.permissions.user_perms?.management
  );

  const isAdmin = useSelector(
    (state) => state.AuthSlice.permissions.is_company_admin
  );
  const user = useSelector((state) => state.UserSlice.data);
  const dispatch = useDispatch();
  const [texts, setTexts] = useState({
    primary: Trans(translations, 'CreateEvent'),
    secondary: Trans(translations, 'Cancel'),
    primaryClick: () => handleCreateEvent(),
    secondaryClick: () => exitModal(),
  });

  const togglePanel = (btn) => {
    dispatch(
      changerValue({
        block: 'edit',
        key: 'editPanel',
        value: btn === 'cancel',
      })
    );
    dispatch(resetErrors());
  };

  const secondaryPerms = () =>
    !edit.done &&
    (perms?.includes('can_view_other_agents_events') ||
      agents.agentsSelected.some((a) => a.id === user.id));

  useEffect(() => {
    if (edit.editPanel) {
      if (edit.done) {
        setTexts({
          primary: Trans(translations, 'DeleteEvent'),
          primaryClick: () => handleDeleteEvent(),
        });
      } else {
        setTexts({
          primary: Trans(translations, 'EndEvent'),
          secondary: Trans(translations, 'DeleteEvent'),
          secondaryClick: () => handleDeleteEvent(),
          primaryClick: () => handleDoneEvent(),
        });
      }
    } else if (!edit.editPanel && edit.editItem) {
      setTexts({
        primary: Trans(translations, 'SaveChanges'),
        secondary: Trans(translations, 'Cancel'),
        secondaryClick: () => togglePanel('cancel'),
        primaryClick: () => handleCreateEvent(),
      });
    }
  }, [edit]);

  if (!edit.active) {
    return null;
  }

  return (
    <>
      {secondaryPerms() && (
        <Button
          className={`calendarSecondaryBtn ${styles.noPrint}`}
          variant={`${
            loading || edit.editLoader ? 'textDisabled' : 'subtleText'
          }`}
          onClick={texts.secondaryClick}
        >
          {texts.secondary}
        </Button>
      )}
      {(isAdmin || !edit.done) && (
        <Box
          className={`calendarPrimaryBtn ${styles.buttonMedium} ${
            styles.noPrint
          } ${(loading || edit.editLoader) && styles.disabled}`}
          onClick={texts.primaryClick}
        >
          {texts.primary}
          {(loading || edit.editLoader) && (
            <Box className={styles.spinnerCont}>
              <Loader
                variant="spinner"
                style={{
                  backgroundImage: 'conic-gradient(#878C9B ,#FFFFFF)',
                  margin: '0px',
                  width: '20px',
                  height: '20px',
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
