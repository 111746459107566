import { useMemo } from 'react';
import { Box } from '@oneloop/jopijs';
import styles from '../styles.module.scss';

const fontFamilies = [
  'Arial',
  'Arial Black',
  'Comic Sans MS',
  'Courier New',
  'Helvetica Neue',
  'Helvetica',
  'Impact',
  'Lucida Grande',
  'Nunito Sans',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];

const isAValidFontFamily = (fontFamily) =>
  Boolean(fontFamily) && typeof fontFamily === 'string';

export default function FontFamilyPicker({ onClick }) {
  const handleOnClick = (event) => {
    if (!onClick) return;

    const fontFamily = event.target.dataset.value;
    if (isAValidFontFamily(fontFamily)) {
      onClick(fontFamily);
    }
  };

  const renderFontFamilyButton = (fontFamily, onClickHandler) => (
    <Box
      key={fontFamily}
      __css={{ fontFamily, textAlign: 'left' }}
      as="button"
      className={styles['dropdown-item']}
      onClick={onClickHandler}
      data-event="backFontFamily"
      data-value={fontFamily}
    >
      {fontFamily}
    </Box>
  );

  const renderDropdownItems = useMemo(() => {
    const dropdown = [];
    for (let i = 0; i < fontFamilies.length; i++) {
      const fontFamily = fontFamilies[i];
      dropdown.push(renderFontFamilyButton(fontFamily, handleOnClick));
    }

    return dropdown;
  }, [fontFamilies]);

  return (
    <Box
      className={styles['dropdown-wrapper']}
      data-testid="font-family-picker"
    >
      <Box className={styles.dropdown}>{renderDropdownItems}</Box>
    </Box>
  );
}
