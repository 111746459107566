import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import Checkbox from '@/components/checkbox/Checkbox';
import { setSendCompanyData } from '@/store/slices/components/sender/SenderSlice';
import PdfOptions from '../PdfOptions';
import LanguageOptions from '../LanguageOptions';
import useOnClickOutside from '@/components/shared/Hooks';
import TooltipMessage from '../../TooltipMessage';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function SendingOptions() {
  const dispatch = useDispatch();
  const { companyConfig } = useSelector((state) => state.AuthSlice);
  const { show, properties, sendCompanyData } = useSelector(
    (state) => state.SenderSlice
  );
  const [sendPdf, setSendPdf] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef(null);
  useOnClickOutside(tooltipRef, isTooltipOpen, setIsTooltipOpen);
  const atLeastOneNonDevelopment = properties.selected.some(
    (property) => !property.is_development
  );
  const atLeastOneDevelopment = properties.selected.some(
    (property) => property.is_development
  );

  useEffect(() => {
    if (companyConfig.pdf_always_ask_agent_info) {
      dispatch(setSendCompanyData(true));
    }
  }, [companyConfig]);

  const handleToggleSendCompanyData = () => {
    dispatch(setSendCompanyData(!sendCompanyData));
  };

  const handleToggleSendPdf = () => {
    setSendPdf((prevState) => !prevState);
  };

  const handleOpenTooltip = (event) => {
    event.stopPropagation();
    setIsTooltipOpen((prevState) => !prevState);
  };

  if (!atLeastOneNonDevelopment) return null;

  return (
    <Box className={styles.container}>
      <Text className={styles['highlighted-text']}>
        {atLeastOneDevelopment
          ? Trans(translations, 'SendingPropertiesOptions')
          : Trans(translations, 'SendingOptions')}
      </Text>

      {show === 'email' && (
        <>
          <Box className={styles['options-list']}>
            {companyConfig.pdf_always_ask_agent_info && (
              <Box
                as="label"
                data-testid="send-company-data-row"
                onClick={handleToggleSendCompanyData}
                className={`${styles['option-text-and-checkbox']} ${
                  sendCompanyData
                    ? styles['option-text-and-checkbox--checked']
                    : ''
                }`}
                __css={{ position: 'relative' }}
              >
                <Checkbox checked={sendCompanyData} />
                <Box className={styles['send-company-data-wrapper']}>
                  <Text className={styles['option-text']}>
                    {Trans(translations, 'SendCompanyData')}
                  </Text>
                  <Box ref={tooltipRef}>
                    <Icon
                      icon="icon-info"
                      fontSize="16px"
                      color="#94A2AB"
                      onClick={handleOpenTooltip}
                    />
                    {isTooltipOpen && (
                      <TooltipMessage __css={{ top: '-10px', right: '-30px' }}>
                        <Text className={styles['tooltip-text']}>
                          {Trans(translations, 'SendCompanyDataTooltip')}
                        </Text>
                      </TooltipMessage>
                    )}
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              as="label"
              data-testid="sender-pdf-option-row"
              onClick={handleToggleSendPdf}
              className={`${styles['option-text-and-checkbox']} ${
                sendPdf ? styles['option-text-and-checkbox--checked'] : ''
              }`}
            >
              <Checkbox checked={sendPdf} />
              <Text className={styles['option-text']}>
                {Trans(translations, 'SendPDF')}
              </Text>
            </Box>
          </Box>

          {sendPdf && <PdfOptions />}
        </>
      )}

      {companyConfig.export_pdf_in_english && <LanguageOptions />}
    </Box>
  );
}

export default SendingOptions;
