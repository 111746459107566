import React, { useState, useEffect } from 'react';
import { Box } from '@oneloop/jopijs';
import SearchBar from './SearchBar';
import SearchResultsList from './SearchResultsList';
import styles from './CustomSearch.module.scss';
import MessengerService from '@/services/MessengerService';

function CustomSearch() {
  const [results, setResults] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  function addNewResults(newResults) {
    const combinedResults = [...newResults.teammates, ...newResults.colleagues];
    setResults((prevResults) => [...prevResults, ...combinedResults]);
  }

  useEffect(() => {
    if (isLoading && hasMore) {
      MessengerService.getUserContacts(inputText, page, true).then(
        (newResults) => {
          if (
            newResults.teammates.length === 0 &&
            newResults.colleagues.length === 0
          ) {
            setHasMore(false);
          } else {
            addNewResults(newResults);
          }
          setIsLoading(false);
        }
      );
    }
  }, [isLoading]);

  function handleScroll(event) {
    const container = event.target;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1);
        setIsLoading(true);
      }
    }
  }

  useEffect(() => {
    if (inputText.length >= 3) {
      setResults([]); // Limpiar resultados al inicio de una nueva búsqueda
      setPage(0); // Reiniciar la página al inicio de una nueva búsqueda
      setHasMore(true); // Reiniciar el indicador de más resultados
      setIsLoading(true); // Iniciar la carga de resultados
    }
  }, [inputText]);

  return (
    <Box>
      <SearchBar inputText={inputText} setInputText={setInputText} />
      {inputText.length >= 3 && (
        <div
          className={styles.searchResulContainer}
          onScroll={(e) => handleScroll(e)}
        >
          <SearchResultsList
            className={styles.scrollingSearch}
            results={results}
            inputText={inputText}
            setInputText={setInputText}
            isLoading={isLoading}
            keyExtractor={(result) => result.id}
          />
        </div>
      )}
    </Box>
  );
}

export default CustomSearch;
