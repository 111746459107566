import React, { useState, useEffect, useRef } from 'react';
import {
  useChannelStateContext,
  useChatContext,
  AttachmentPreviewList as DefaultAttachmentPreviewList,
  useComponentContext,
  useMessageInputContext,
} from 'stream-chat-react';
import Picker from '@emoji-mart/react';
import { Box, Icon, Text } from '@oneloop/jopijs';
import data from '@emoji-mart/data';
import styles from './CustomInput.module.scss';
import translations from './CustomInput.trans.json';
import Trans from '@/components/shared/Trans';
import dataES from './translatEmojiPicker/data-es.json';
import AttachmentUploadButton from './AttachmentUpload.js';
import CustomSharePropertiesPreview from '../ShareProperties/CustomSharePropertyPreview/CustomSharePropertyPreview.js';

function CustomInput({ setMessageReply, messageReply }) {
  const { channel } = useChannelStateContext();
  const { AttachmentPreviewList = DefaultAttachmentPreviewList } =
    useComponentContext();
  const { handleSubmit, text, setText } = useMessageInputContext();
  const { client } = useChatContext();
  const inputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeout = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  useEffect(() => {
    if (messageReply) {
      inputRef?.current?.focus();
    }
  }, [messageReply]);

  const handleReplyClick = async () => {
    const trimmedText = text.trim();
    if (trimmedText.length === 0 || isSending) return;
    setIsSending(true);
    try {
      await channel.sendMessage({
        text: trimmedText,
        quoted_message_id: messageReply.id,
      });
      setMessageReply(undefined);
      setText('');
      setShouldFocus(true);
    } catch (error) {
      console.error('Error al responder al mensaje:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setIsEmojiPickerOpen(false);
    }
  };

  const initAttachmentMessage = async () => {
    const trimmedText = text.trim();
    await channel.sendMessage({
      text: trimmedText,
      attachments: [
        {
          type: 'property',
          thumb_url: selectedProperty.picture_url,
          title: selectedProperty.address,
          title_link: selectedProperty.link,
          property_id: selectedProperty.id,
        },
      ],
    });
    setSelectedProperty(null);
    setText('');
  };
  const initAttachmentMessageExternal = async () => {
    const concatProp = `${text} ${selectedProperty?.link || ''}`;
    await channel.sendMessage({
      text: concatProp,
    });
    setSelectedProperty(null);
    setText('');
  };

  useEffect(() => {
    if (isEmojiPickerOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isEmojiPickerOpen]);

  const handleEmojiPickerClick = (event) => {
    event.stopPropagation();
    setIsEmojiPickerOpen((prev) => !prev);
  };

  const handleEmojiSelect = (emoji) => {
    const newInputValue = text + emoji.native;
    setText(newInputValue);
    setIsEmojiPickerOpen(false);
    inputRef.current.focus();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (messageReply) {
      handleReplyClick();
    } else if (selectedProperty) {
      if (channel?.data?.type.startsWith('WhatsApp')) {
        initAttachmentMessageExternal();
      } else {
        initAttachmentMessage();
      }
    } else {
      handleSubmit();
    }
  };

  const handleChange = async (event) => {
    const newText = event.target.value.replace(/^\s+/, '');
    setText(newText);

    await channel.keystroke();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFormSubmit(e);
    }
  };

  const handleBlur = async () => {
    if (isTyping) {
      setIsTyping(false);
      await channel.stopTyping();
    }
  };

  useEffect(() => () => clearTimeout(typingTimeout.current), []);

  const { type } = channel;
  const { member_count } = channel.data;

  if (type === 'team' && member_count === 1) {
    return (
      <Box className={styles.oneMemberNotif}>
        <Text variant="body.fontSize14">
          {Trans(translations, 'YouWillAbleChatWhenMoreMembers')}
        </Text>
      </Box>
    );
  }

  const isWhatsAppUnlinked = () =>
    channel?.data?.type.startsWith('WhatsApp') &&
    client.user.whatsapp.synchronized === false &&
    !channel?.data?.frozen;

  if (isWhatsAppUnlinked()) {
    return (
      <Box className={styles.oneMemberNotif}>
        <Text variant="body.fontSize14">
          {Trans(translations, 'YouWillAbleChatWhenLinkWhatsApp')}
        </Text>
      </Box>
    );
  }

  return (
    <Box className={styles.inputResponseContainer}>
      {selectedProperty && (
        <CustomSharePropertiesPreview
          property={selectedProperty}
          onClose={() => setSelectedProperty(null)}
        />
      )}
      <AttachmentPreviewList />
      {channel?.data?.frozen ? (
        <Box className={styles.containerFrozen}>
          <span>
            {channel?.data?.web_contact?.generated_by !== client?.userID &&
              Trans(translations, 'ThisContactHasBeenAssignedToAnotherAgent')}
          </span>
        </Box>
      ) : (
        <Box className={styles.container}>
          <Box className={styles.inputContainer}>
            <span
              className={styles.emojiPickerIcon}
              onClick={handleEmojiPickerClick}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleEmojiPickerClick(event);
                }
              }}
              role="button"
              tabIndex={0}
              aria-label="emojiPickerIcon"
            >
              <Icon color="#566A75" icon="icon-positivo" fontSize="24px" />
            </span>
            <Box className={styles.emojiPickerBox} ref={emojiPickerRef}>
              {isEmojiPickerOpen && (
                <Picker
                  onEmojiSelect={handleEmojiSelect}
                  data={client.user.language === 'es' ? dataES : data}
                  locale={client.user.language === 'es' ? 'es' : 'en'}
                  style={{
                    position: 'absolute',
                    marginTop: '465px',
                    marginLeft: -40,
                    maxWidth: '320px',
                    borderRadius: '20px',
                  }}
                  theme="light"
                  skinTonePosition="none"
                  previewPosition="none"
                />
              )}
            </Box>
          </Box>
          <textarea
            ref={inputRef}
            maxLength={1200}
            className={`${styles.inputStyle} ${
              isSending ? styles.inputStyleDisabled : ''
            }`}
            type="text"
            value={text}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            placeholder={Trans(translations, 'InputMessagePlaceholder')}
            disabled={isSending}
          />
          <AttachmentUploadButton onPropertySelect={setSelectedProperty} />
          <button
            type="submit"
            aria-label="send"
            onClick={handleFormSubmit}
            className={styles.sendButton}
          >
            <Icon icon="icon-enviar" fontSize="24px" />
          </button>
        </Box>
      )}
    </Box>
  );
}

export default CustomInput;
