import { useEditor } from '@tiptap/react';
import { Box } from '@oneloop/jopijs';
import Placeholder from '@tiptap/extension-placeholder';
import Trans from '../shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import getExtensions from './extensions';
import WysiwygEditorContext from './hooks/useWysiwygEditor';
import {
  EditorCompound,
  QuickResponsesCompound,
  ToolbarCompound,
} from './compound';
import styles from './styles.module.scss';
import './reset.scss';
import './fonts.scss';

const INITIAL_CONTENT = '';
const DEFAULT_BUTTONS = [
  'bold',
  'italic',
  'underline',
  'fontSize',
  'fontFamily',
  'color',
  'lineHeight',
  'alignment',
  'orderedList',
  'unorderedList',
  'verticalAlign',
  'link',
  'image',
  'dynamicTags',
];

/**
 * WysiwygEditor component that provides a rich text editor using TipTap.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - Child components to be rendered within the editor context.
 * @param {Function} props.onChange - Callback function to handle content changes in the editor.
 * @param {Boolean} props.highlight - Boolean value to highlight (or not) the entire editor box.
 * @param {Array<string>} [props.buttonsBlackList] - List of unavailable extensions and buttons for the toolbar
 * @returns {JSX.Element|null} The rendered WysiwygEditor component or null if the editor is not initialized.
 */
export default function WysiwygEditor({
  children,
  onChange,
  highlight,
  buttonsBlackList = [],
}) {
  const placeholderConfiguration = Placeholder.configure({
    emptyEditorClass: 'is-wysiwygempty',
    placeholder: Trans(translations, 'TypeYourMessage'),
  });
  const allowedButtons = DEFAULT_BUTTONS.filter(
    (button) => !buttonsBlackList.includes(button)
  );
  const extensions = getExtensions(allowedButtons);

  const editorTipTap = useEditor({
    extensions: [...extensions, placeholderConfiguration],
    content: INITIAL_CONTENT,
    immediatelyRender: false,
    onUpdate: ({ editor }) => {
      if (onChange) onChange(editor.state.doc.textContent, editor.getHTML());
    },
  });

  if (!editorTipTap) return null;

  return (
    <WysiwygEditorContext.Provider
      // eslint-disable-next-line
      value={{ editor: editorTipTap, allowedButtons }}
    >
      <Box
        className={`${styles['tiptap-wrapper']} ${
          highlight ? styles['tiptap-wrapper--highlight'] : ''
        }`}
      >
        {children}
      </Box>
    </WysiwygEditorContext.Provider>
  );
}

WysiwygEditor.Toolbar = ToolbarCompound;
WysiwygEditor.Editor = EditorCompound;
WysiwygEditor.QuickResponses = QuickResponsesCompound;
