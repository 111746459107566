import { Box, Button, ButtonIcon, Icon } from '@oneloop/jopijs';
import { useDispatch } from 'react-redux';
import styles from '../ChatMessenger.module.scss';
import { setShowModalWhatsapp } from '@/store/slices/components/chat/MessengerSlice';

function WhatsappAd() {
  const dispatch = useDispatch();
  function LinktTo() {
    dispatch(setShowModalWhatsapp({ key: 'showQRModal', value: true }));
  }
  function closeWhatsappAd() {
    dispatch(setShowModalWhatsapp({ key: 'showWhatsappAd', value: false }));
    dispatch(setShowModalWhatsapp({ key: 'showQRModal', value: false }));
  }
  return (
    <Box className={styles.linkWhatsappBox}>
      <Box className={styles.linkWhatsappInnerBox}>
        <Box className={styles.linkWhatsappCard}>
          <Icon icon="icon-whatsapp" fontSize="24px" color="white" />
        </Box>
        <Box style={{ display: 'flex', width: '200px' }}>
          <p className={styles.linkWithText}>
            Ahora puedes vincular WhatsApp a Tokko Messenger
          </p>
          <ButtonIcon
            onClick={closeWhatsappAd}
            style={{
              color: '#00D186',
              position: 'absolute',
              right: '-25px',
              bottom: '10px',
            }}
            icon="icon-cerrar"
            variant={['subtleTrasnparentIconClearButton', 'iconMedium']}
          />
        </Box>
      </Box>
      <Button
        onClick={LinktTo}
        variant={['medium']}
        className={styles.greenButton}
      >
        Vincular
      </Button>
    </Box>
  );
}

export default WhatsappAd;
