import { useEffect, useRef, useState } from 'react';
import { Box, Button, Icon, Text } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import isAValidLink from '@/components/Sender/utils/isAValidLink';
import styles from './styles.module.scss';

function ImagePicker({ editor, handleClose }) {
  const inputRef = useRef(null);
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const [inputIsDirty, setInputIsDirty] = useState(false);
  const [focusingLink, setFocusingLink] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const isValidLink = isAValidLink(link);
  const isError = !isValidLink;
  const showError =
    inputIsDirty &&
    ((wasSubmitted && !isValidLink) || (!focusingLink && !isValidLink));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setWasSubmitted(false);
    if (link) {
      setInputIsDirty(true);
    }
  }, [link]);

  const handlePressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      handleClose();
    }
  };

  const handleFocus = (event) => {
    event.target.select();
    setFocusingLink(true);
  };

  const handleBlur = () => {
    setFocusingLink(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    setWasSubmitted(true);
    setInputIsDirty(true);

    if (isError) return;

    const img = new Image();
    const alt = 'image';
    const src = link;

    img.onload = function () {
      editor.chain().focus().setImage({ alt, src, preserve: false }).run();
      editor.chain().focus().setHardBreak().run();
      handleClose();
      setIsLoading(false);
    };

    img.onerror = function () {
      setFetchError(true);
      setIsLoading(false);
      setWasSubmitted(false);
    };

    img.src = src;
  };

  return (
    <Box
      className={styles.container}
      onKeyDown={handlePressEscape}
      data-testid="image-picker"
    >
      <Box className={styles.header}>
        <Text className={styles.title}>
          {Trans(translations, 'AttachImage')}
        </Text>
        <Icon
          icon="icon-cerrar"
          fontSize="12px"
          onClick={handleClose}
          className={styles['close-icon']}
        />
      </Box>
      <Text className={styles.subtitle}>
        {Trans(translations, 'AttachOrPaste')}
      </Text>
      <Text className={styles.description}>
        {Trans(translations, 'YouCanUpload')}
      </Text>

      {fetchError && (
        <Box className={styles['error-container']}>
          <Icon icon="icon-error" fontSize="16px" color="#A71601" />
          <Box className={styles['error-text-wrapper']}>
            <Text className={styles['error-title']}>
              {Trans(translations, 'ImageCanNotBeAttached')}
            </Text>
            <Text className={styles['error-body']}>
              {Trans(translations, 'EnterAPublicImage')}
            </Text>
          </Box>
          <Button
            variant="subtleText"
            className={styles['got-it-button']}
            onClick={() => setFetchError(false)}
          >
            {Trans(translations, 'GotIt')}
          </Button>
        </Box>
      )}

      {!fetchError && (
        <Box as="form" className={styles.form} onSubmit={handleSubmit}>
          <Box className={styles['input-and-buttons']}>
            <Box
              __css={{ maxWidth: '204px' }}
              className={`${styles['input-wrapper']} ${
                showError ? styles['input-wrapper--error'] : ''
              }`}
              data-filled={Boolean(link)}
            >
              <Box
                ref={inputRef}
                as="input"
                placeholder={Trans(translations, 'ImageLink')}
                value={link}
                onChange={(event) => setLink(event.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className={styles.input}
              />

              {showError && (
                <Icon icon="icon-error" fontSize="16px" color="#a71601" />
              )}
            </Box>
            <Box className={styles['button-group']}>
              <Box
                as="button"
                type="button"
                className={styles['cancel-button']}
                onClick={handleClose}
              >
                {Trans(translations, 'Cancel')}
              </Box>
              <Box
                as="button"
                type="submit"
                className={`${styles['accept-button']} ${
                  isError || isLoading ? styles['accept-button--disabled'] : ''
                }`}
              >
                {Trans(translations, 'AttachImage')}
              </Box>
            </Box>
          </Box>

          {showError && (
            <Box className={styles['error-message']}>
              {!link
                ? Trans(translations, 'AddALink')
                : Trans(translations, 'InvalidLink')}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ImagePicker;
