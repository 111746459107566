import { Box, Text, Icon, Heading } from '@oneloop/jopijs';
import { useChatContext } from 'stream-chat-react';
import { useSelector, useDispatch } from 'react-redux';
import LinkWithQR from './LinkWithQR';
import styles from '../CustomHeader/CustomHeader.module.scss';
import { setShowModalWhatsapp } from '@/store/slices/components/chat/MessengerSlice';
import translations from './LinkWithPanel.trans.json';
import Trans from '@/components/shared/Trans';

function LinkWithPanel() {
  const { client } = useChatContext();
  const { showQRModal } = useSelector((state) => state.MessengerSlice.whatsapp);
  const dispatch = useDispatch();

  if (!showQRModal) {
    return null;
  }

  client.on('user.updated', (event) => {
    if (event.user.whatsapp.synchronized === true) {
      dispatch(setShowModalWhatsapp({ key: 'showQRModal', value: false }));
    }
  });

  return (
    <Box className={styles.linkWithWhatsappBox}>
      <Box style={{ display: 'flex' }}>
        <Icon icon="icon-whatsapp" fontSize="36px" color="#25d366" />
        <Text
          style={{
            fontWeight: '800',
            fontSize: '25px',
            color: '#25d366',
            alignItems: 'center',
          }}
        >
          WhatsApp
        </Text>
      </Box>
      <Heading as="h4" variant="heading.3">
        {Trans(translations, 'ScanDevice')}
      </Heading>
      <Box
        style={{
          width: '240px',
          height: '240px',
          borderRadius: '32px',
          border: '2px solid #00D186',
          background: '#FFF',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LinkWithQR />
      </Box>
      <Text
        variant="body.fontSize16"
        style={{ width: '420px', textAlign: 'center', color: '#344149' }}
      >
        {Trans(translations, 'ModalQRText')}
      </Text>
    </Box>
  );
}

export default LinkWithPanel;
