/* Retorna un string de la siguiente forma:
  (Hace 20 días, 6 hr)
  (Hace 1 día, 6 hr)
  (Hace 6 hr, 10 minutos)
  (Hace 10 minutos, 6 segundos)
  (Hace 6 segundos)
 */
export function getStringDoesFromDateJson(dateJson) {
  const dateToday = new Date();
  const date = new Date(dateJson);
  const differenceDays = dateToday.getTime() - date.getTime();
  const totalDays = Math.floor(differenceDays / (1000 * 3600 * 24));
  const totalhours =
    Math.floor(Math.abs(dateToday - date) / 36e5) - totalDays * 24;
  const minutesDifference = Math.round(
    (((dateToday - date) % 86400000) % 3600000) / 60000
  );
  const secondsDifference = Math.abs(
    dateToday.getSeconds() - date.getSeconds()
  );
  if (totalDays > 0) {
    return `Hace ${totalDays} ${totalDays > 1 ? 'días' : 'día'}`;
  }
  if (totalhours > 0) {
    return `Hace ${totalhours} hr`;
  }
  if (minutesDifference > 0) {
    return `Hace ${minutesDifference} minutos`;
  }
  return `Hace ${secondsDifference} segundos`;
}
// Recibe un dato en formato Date ej. 2023-11-07T18:18:41.565284Z
// Retorna un string en formato:
// DD/MM/YY
export function formatDateToDDMMYY(dateString) {
  const dateObj = new Date(dateString);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}

// Recibe un dato en formato Date ej. Fri Mar 22 2024 12:04:38 GMT-0300 (hora estándar de Argentina)
// Retorna un string en formato:
// martes at 14:15
export function formatDateToDayTime(dateString) {
  const date = new Date(dateString);

  const dayOptions = { weekday: 'long' };
  const dayFormat = new Intl.DateTimeFormat('es-ES', dayOptions);

  const hourOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
  const hourFormat = new Intl.DateTimeFormat('es-ES', hourOptions);

  const weekDay = dayFormat.format(date);
  const hour = hourFormat.format(date);

  const weekDayCapitalized = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);

  // Crear el string final en el formato deseado
  return `${weekDayCapitalized} a las ${hour}`;
}

export function getTimeStamp(currentChannel) {
  const lastMessageDate = currentChannel.state.last_message_at;
  if (!lastMessageDate) {
    return '';
  }

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const lastMessageDay = lastMessageDate.getDate();
  const lastMessageMonth = lastMessageDate.getMonth();
  const lastMessageYear = lastMessageDate.getFullYear();
  const yesterdayDay = yesterday.getDate();
  const yesterdayMonth = yesterday.getMonth();
  const yesterdayYear = yesterday.getFullYear();

  if (
    lastMessageDay === now.getDate() &&
    lastMessageMonth === now.getMonth() &&
    lastMessageYear === now.getFullYear()
  ) {
    const lastHours = lastMessageDate.getHours();
    const lastMinutes = lastMessageDate.getMinutes();

    const formattedHours = lastHours.toString().padStart(2, '0');
    const formattedMinutes = lastMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }
  if (
    lastMessageDay === yesterdayDay &&
    lastMessageMonth === yesterdayMonth &&
    lastMessageYear === yesterdayYear
  ) {
    return 'Ayer';
  }

  const displayMonth = lastMessageMonth + 1;
  const displayYear = lastMessageYear.toString().slice(-2);
  return `${lastMessageDay}/${displayMonth}/${displayYear}`;
}
