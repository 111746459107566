import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, Icon, Text } from '@oneloop/jopijs';
import Radio from '@/components/radio/Radio';
import { setPdfOptionData } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

const LAYOUT_TYPES = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  GRID: 'grid',
};

const ICONS = {
  horizontal: 'icon-pdfhorizontal',
  vertical: 'icon-pdfvertical',
  grid: 'icon-pdfgrilla',
};

const options = [
  { id: 'pdf-horizontal', value: LAYOUT_TYPES.HORIZONTAL, label: 'Horizontal' },
  { id: 'pdf-vertical', value: LAYOUT_TYPES.VERTICAL, label: 'Vertical' },
  { id: 'pdf-grid', value: LAYOUT_TYPES.GRID, label: 'Grid' },
];

function PdfOptions() {
  const dispatch = useDispatch();
  const { pdfOption, properties } = useSelector((state) => state.SenderSlice);
  const includeMapDisabled = pdfOption.value === LAYOUT_TYPES.GRID;

  useEffect(() => {
    const DEFAULT_PDF_OPTION = LAYOUT_TYPES.HORIZONTAL;
    dispatch(setPdfOptionData({ key: 'value', value: DEFAULT_PDF_OPTION }));

    return () => {
      dispatch(setPdfOptionData({ key: 'value', value: null }));
    };
  }, []);

  const handleChangeRadio = (event) => {
    const { value } = event.target.dataset;
    dispatch(setPdfOptionData({ key: 'value', value }));

    if (value === LAYOUT_TYPES.GRID) {
      dispatch(setPdfOptionData({ key: 'includeMap', value: false }));
    }
  };

  const toggleIncludeMap = (event) => {
    if (includeMapDisabled) return;

    const { checked } = event.target;
    dispatch(setPdfOptionData({ key: 'includeMap', value: checked }));
  };

  if (properties.selected.length === 0) return null;

  return (
    <Box className={styles['pdf-options']}>
      <Box className={styles['radio-options-wrapper']}>
        {options.map((option) => (
          <Box
            key={option.value}
            as="label"
            data-testid={`sender-pdf-option-${option.value}`}
            className={styles['radio-option']}
            data-active={pdfOption.value === option.value}
          >
            <Radio
              id={option.id}
              checked={pdfOption.value === option.value}
              onChange={handleChangeRadio}
              data-value={option.value}
            />

            <Box className={styles['radio-content']}>
              <Icon
                icon={ICONS[option.value]}
                fontSize="16px"
                color={pdfOption.value === option.value ? '#1A4958' : '#485C66'}
              />
              <Text className={styles['radio-text']}>{option.label}</Text>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        as="label"
        data-testid="sender-include-map"
        className={`${styles['include-map']} ${
          includeMapDisabled ? styles['include-map--disabled'] : ''
        } ${pdfOption.includeMap ? styles['include-map--checked'] : ''}`}
      >
        <Checkbox onChange={toggleIncludeMap} checked={pdfOption.includeMap} />
        <Text className={styles['map-text']}>
          {Trans(translations, 'IncludeMap')}
        </Text>
      </Box>
    </Box>
  );
}

export default PdfOptions;
