import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import ToolTip from '@/components/ToolTip/ToolTip';
import Trans from '@/components/shared/Trans';
import useOnClickOutside from '@/components/shared/Hooks';
import translations from '@/components/Sender/Sender.trans.json';
import EmailsDropdown from '../SelectedItem/EmailsDropdown';
import styles from './styles.module.scss';
import store from '@/store/store';

const getTagStatus = (contact) => {
  const { show } = store.getState().SenderSlice;

  if (
    (show === 'email' && !contact.isValid) ||
    (show === 'whatsapp' && contact.isValidated && !contact.isValid)
  ) {
    return 'invalid';
  }

  if (show === 'whatsapp' && !contact.isValidated) {
    return 'pending';
  }

  return null;
};

const getTestId = (status) => {
  switch (status) {
    case 'pending':
      return 'tag-pending';
    case 'invalid':
      return 'tag-invalid';
    default:
      return 'tag';
  }
};

const mapStatusClasses = {
  invalid: styles['tag--invalid'],
  pending: styles['tag--pending'],
};

function Tag({
  contact,
  tooltipContainerId,
  handleRemove,
  hide,
  selected,
  setSelected,
  ...props
}) {
  const { show } = useSelector((state) => state.SenderSlice);
  const { className, ...rest } = props;
  const tagRef = useRef(null);
  const emailsRef = useRef(null);
  const [emailsIsOpen, setEmailsIsOpen] = useState(false);
  useOnClickOutside(emailsRef, emailsIsOpen, setEmailsIsOpen);

  const tooltip =
    show === 'email'
      ? contact.emails
          .filter((email) => email.active)
          .map((email) => email.value)
          .join(', ')
      : contact.cellphone;
  const correctTooltip = !contact.isRaw && contact.isValid ? tooltip : null;
  const tagStatus = getTagStatus(contact);
  const tagStatusClass = mapStatusClasses[tagStatus] || '';

  useEffect(() => {
    if (emailsIsOpen) return;

    const isAllEmailsUnchecked = contact.emails.every((elem) => !elem.active);
    const contactHaveEmails = contact.emails.length > 0;
    if (!isAllEmailsUnchecked || !contactHaveEmails) return;

    const newValue = selected.filter((elem) => elem.id !== contact.id);
    setSelected(newValue);
  }, [emailsIsOpen]);

  const toggleCheckbox = (event) => {
    const { value, checked } = event.target;

    const updatedEmails = contact.emails.map((email) =>
      email.value === value ? { ...email, active: checked } : email
    );

    const updatedContact = { ...contact, emails: updatedEmails };
    const newValue = selected.map((elem) =>
      elem.id === contact.id ? updatedContact : elem
    );

    setSelected(newValue);
  };

  const toggleEmailsOpen = (event) => {
    event.stopPropagation();
    setEmailsIsOpen((prevState) => !prevState);
  };

  return (
    <Box
      ref={tagRef}
      aria-label="tag"
      data-testid={getTestId(tagStatus)}
      aria-hidden={hide}
      className={`${styles.container} ${
        hide ? styles['container--hide'] : ''
      } ${
        contact.emails.length > 1
          ? styles['container--with-chevron']
          : styles['container--without-chevron']
      } ${className || ''}`}
      {...rest}
    >
      <ToolTip title={correctTooltip} outSideModal={tooltipContainerId}>
        <Box className={`${styles.tag} ${tagStatusClass}`}>
          <Text className={styles['tag-text']}>
            {contact.name || Trans(translations, 'ContactWithoutName')}
          </Text>

          {handleRemove && (
            <Box
              as="button"
              data-testid="remove-tag-button"
              className={styles['close-button']}
              onClick={handleRemove}
            >
              <Icon
                icon="icon-cerrar"
                fontSize="12px"
                color={tagStatus === 'pending' ? '#aaaaaa' : '#344149'}
              />
            </Box>
          )}
        </Box>
      </ToolTip>

      {contact.emails.length > 1 && (
        <Box ref={emailsRef}>
          <Box
            as="button"
            data-testid="tag-contact-chevron"
            className={styles['chevron-button']}
            onClick={toggleEmailsOpen}
          >
            <Icon icon="icon-dropdown" fontSize="12px" />
          </Box>
          {emailsIsOpen && (
            <EmailsDropdown
              parentRef={tagRef}
              emails={contact.emails}
              handleToggle={toggleCheckbox}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default Tag;
