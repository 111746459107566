/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Modal } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import style from './QuickDisplay.module.scss';
import HeaderQD from './headerQD/HeaderQD';
import Kebab from './kebab/Kebab';
import PropertyDescription from './propertyDescription/PropertyDescription';
import {
  getQuickDevData,
  getQuickOpenNaventData,
  getQuickPropData,
} from '@/store/slices/components/quickDisplay/services';
import { tokkoModalActions } from '../TokkoActions/tokkoActions';

export default function QuickDisplay() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { waffle_flags } = useSelector((state) => state.AuthSlice.permissions);
  const { show, id, isDevelopment, isOpenNavent, showMsn } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(true);

  if (!waffle_flags.includes('new_quick_display')) {
    return null;
  }

  const fetchData = async (getDataFunction) => {
    const res = await dispatch(getDataFunction(id));

    if (res.payload) {
      const { data, edited } = res.payload;
      tokkoModalActions(
        {
          msg: 'propData',
          data: {
            ...data,
            isDevelopment: isDevelopment && isDevelopment,
            versionFile: edited?.has_edition && 'edited',
          },
        },
        tokkoBrokerHost
      );
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTabActive(document.visibilityState === 'visible');
    };
    if (id && tabActive && !showMsn) {
      if (isOpenNavent) {
        fetchData(getQuickOpenNaventData);
      } else {
        const getDataFunction = isDevelopment
          ? getQuickDevData
          : getQuickPropData;
        fetchData(getDataFunction);
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [id, tabActive]);

  const closeQuick = () => {
    dispatch(showQuickDisplay(false));
    tokkoModalActions({ msg: 'closeModal' }, tokkoBrokerHost);
  };

  return (
    <>
      {show && (
        <Modal
          className={style.quickDisplay}
          modalBG
          blockScroll
          fixedCLoseBtn
          closeModal={closeQuick}
          id="QuickDisplay"
          scrollHeight="fit-content"
          paddings="16px 16px 0px 24px"
        >
          <Modal.Body>
            <Box
              id="QuickDisplayBody"
              className={style.QDBody}
              __css={{ height: window.innerWidth > 1440 ? '80vh' : '90vh' }}
            >
              <HeaderQD />
              <Box className={style.qDContainer}>
                <PropertyDescription />
                <Kebab />
              </Box>
            </Box>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
