import Tag from '../Tag';

function SelectedItem({
  selected,
  setSelected,
  contact,
  tooltipContainerId,
  shouldHide,
}) {
  const handleRemove = (event) => {
    event.stopPropagation();
    const newValue = selected.filter((elem) => elem.id !== contact.id);
    setSelected(newValue);
  };

  return (
    <Tag
      __css={{ maxWidth: 'calc(50% - 4px)' }}
      contact={contact}
      tooltipContainerId={tooltipContainerId}
      hide={shouldHide}
      selected={selected}
      setSelected={setSelected}
      handleRemove={handleRemove}
    />
  );
}

export default SelectedItem;
