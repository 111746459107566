/* eslint-disable no-bitwise */
import React from 'react';
import getInitials from './getInitials';

const userColorMap = {};

// Colores predefinidos
const predefinedColors = [
  '#A71601',
  '#1473A9',
  '#D05432',
  '#143742',
  '#109F5B',
  '#674DC5',
  '#E7377B',
  '#A24026',
  '#25697E',
  '#344149',
];

function AvatarGenerator({ name, foregroundColor, bgColor }) {
  const text = getInitials(name);

  const avatarStyle = {
    width: '39px',
    height: '39px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: foregroundColor,
    backgroundColor: bgColor,
  };

  return (
    <div style={avatarStyle}>
      <span style={{ fontSize: '17px' }}>{text}</span>
    </div>
  );
}

// Función independiente para calcular un hash de un string
function stringToHashCode(string) {
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

// Función para seleccionar un color para un usuario basado en su ID
function getColorForUser(userId) {
  if (!userColorMap[userId]) {
    // Usa la función stringToHashCode para calcular un índice basado en el userId
    const index =
      Math.abs(stringToHashCode(userId.toString())) % predefinedColors.length;
    userColorMap[userId] = predefinedColors[index];
  }
  return userColorMap[userId];
}

export { getColorForUser, AvatarGenerator };
