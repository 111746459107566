import { Box, Icon, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { toggleKebab } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import {
  getDevelopmentFiles,
  getFiles,
} from '@/store/slices/components/quickDisplay/services';
import Skeletons from '@/components/Skeletons/Skeletons';
import DevelopmentFiles from './DevelopmentFiles';

export default function MyFiles() {
  const dispatch = useDispatch();
  const { id, files, developmentFiles, isDevelopment, quickData } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const getMyFiles = (fileID) => dispatch(getFiles(fileID));
  const getMyDevFiles = (fileID) => dispatch(getDevelopmentFiles(fileID));

  useEffect(() => {
    if (isDevelopment) {
      getMyDevFiles(id);
    } else {
      getMyFiles(id);
      if (quickData.data.development) {
        getMyDevFiles(quickData.data.development.id);
      }
    }
  }, [id]);

  const closeHeader = () => (
    <Box className={style.kebabHeader}>
      <Icon
        icon="icon-cerrar"
        fontSize="16px"
        onClick={() => dispatch(toggleKebab(1))}
      />
      <Text variant="bodyBold.fontSize16">
        {Trans(translations, isDevelopment ? 'DevelopmentFiles' : 'Files')}
      </Text>
    </Box>
  );

  if (files.loading || developmentFiles.loading) {
    return (
      <Box __css={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {closeHeader()}
        <Skeletons
          count={3}
          style={{ maxWidth: '336px', height: '49px', borderRadius: '16px' }}
        />
      </Box>
    );
  }

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = `${tokkoBrokerHost}${file.url}`;
    link.download = file.name;
    link.click();
  };

  const data = isDevelopment ? developmentFiles.data : files.data;

  return (
    <Box className={style.kebabCont}>
      {closeHeader()}
      {data ? (
        <Box className={style.sendigsCont}>
          {data.files.map((item) => (
            <Box className={style.filesItem} onClick={() => downloadFile(item)}>
              <Box
                className={`${style.filesIcon} ${
                  style[item.icon.substring(5)]
                }`}
              >
                <Icon icon={item.icon} fontSize="16px" />
              </Box>

              <Box __css={{ flex: '1' }} className={style.filesText}>
                <Text variant={['body.fontSize14', 'ellipsis']}>
                  {item.name}
                </Text>
              </Box>

              <Box className={style.downloadItem}>
                <Icon icon="icon-descargar" fontSize="16px" />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Text className={style.gray1} variant="body.fontSize14">
          {Trans(
            translations,
            isDevelopment
              ? 'EmptyStateFilesDevelopment'
              : 'EmptyStateFilesProperty'
          )}
        </Text>
      )}

      {quickData.data.development && (
        <DevelopmentFiles downloadFile={downloadFile} />
      )}
    </Box>
  );
}
