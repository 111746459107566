/* eslint-disable react/no-array-index-key */
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';
import style from '../QuickDisplay.module.scss';
import ToolTip from '@/components/ToolTip/ToolTip';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import NoWebTooltip from './NoWebTooltip';
import { handleSender } from '@/store/slices/components/sender/SenderSlice';
import tokkoModalActions from '@/components/TokkoModalActions/TokkoModalActions';

export default function Share() {
  const [showDD, setShowDD] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const contRef = useRef(null);
  const dispatch = useDispatch();
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { waffle_flags } = useSelector((state) => state.AuthSlice.permissions);
  const {
    quickData,
    isDevelopment,
    id,
    isOpenNavent,
    versionFile,
    active,
    isContact,
  } = useSelector((state) => state.QuickDisplaySlice);
  const { data } = quickData;
  const Options = [
    {
      icon: 'icon-email',
      text: 'Email',
      action: () => {
        if (waffle_flags.includes('new_sender')) {
          const field = isDevelopment
            ? 'preSelectedDevelopments'
            : 'preSelectedProperties';
          return dispatch(
            handleSender({
              show: 'email',
              preSelectedContacts: isContact ? [isContact.id] : null,
              [field]: [id],
              fromOuterNet: isOpenNavent,
              isDefaultEdited: versionFile === 'edited',
            })
          );
        }

        return tokkoModalActions({ msg: 'openMailSender' }, tokkoBrokerHost);
      },
    },
    {
      icon: 'icon-whatsapp',
      text: 'Whatsapp',
      action: () => {
        if (waffle_flags.includes('new_sender')) {
          const field = isDevelopment
            ? 'preSelectedDevelopments'
            : 'preSelectedProperties';
          return dispatch(
            handleSender({
              show: 'whatsapp',
              preSelectedContacts: isContact ? [isContact.id] : null,
              [field]: [id],
              fromOuterNet: isOpenNavent,
              isDefaultEdited: versionFile === 'edited',
            })
          );
        }

        return tokkoModalActions(
          { msg: 'openWhatsAppSender' },
          tokkoBrokerHost
        );
      },
    },
  ];

  const openDD = () => {
    if (isDevelopment && !data.web_url) {
      setShowTooltip(true);
    } else {
      setShowDD(true);
    }
  };

  return (
    <Box className={`${!active ? style.propertyDeleted : ''}`}>
      <ToolTip title={Trans(translations, 'Share')} outSideModal="QuickDisplay">
        <Box ref={contRef} className={style.itemHeader} onClick={openDD}>
          <Box className={style.iconText}>
            <Icon icon="icon-compartir" fontSize="16px" />
          </Box>
        </Box>
      </ToolTip>

      <NoWebTooltip
        style={{ right: '30px', top: '55px' }}
        title={Trans(translations, 'ToShareItMustHaveWebsite')}
        state={showTooltip}
        seter={setShowTooltip}
        arrowPosition="370px"
      />

      <List
        clickable
        open={showDD}
        seter={setShowDD}
        className={style.shareList}
      >
        {Options.map((option, i) => (
          <Box
            as="li"
            key={option.text + i}
            __css={{ display: 'flex', gap: '8px' }}
            className={style.neutral2}
            onClick={() => {
              option.action();
              setShowDD(false);
            }}
          >
            <Icon icon={option.icon} fontSize="16px" />
            <Text variant="body.fontSize12">{option.text}</Text>
          </Box>
        ))}
      </List>
    </Box>
  );
}
