import React, { useState } from 'react';
import {
  Box,
  Drawer,
  ButtonIcon,
  Text,
  Image,
  Icon,
  Input,
  Heading,
} from '@oneloop/jopijs';
import styles from '../CustomChannelHeader.module.scss';
import { formatDateToDDMMYY } from '@/components/shared/DateTransform';

function GroupDrawer({
  isOpen,
  channel,
  toggle,
  channelName,
  title,
  numberOfUsers,
  setActiveChannel,
  client,
}) {
  const overlay = false;
  const [searchTerm, setSearchTerm] = useState('');
  const [findUsers, setFindUsers] = useState('');

  const filteredMembers = Object.keys(channel?.state?.members || {})
    .filter((memberId) => {
      const memberName = channel?.state?.members[memberId]?.user?.name;
      return memberName
        ? memberName.toLowerCase().includes(searchTerm?.toLowerCase())
        : false;
    })
    .sort((a, b) => {
      const nameA = channel.state.members[a]?.user?.name || '';
      const nameB = channel.state.members[b]?.user?.name || '';
      return nameA.localeCompare(nameB);
    });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleUserClick = (memberId) => {
    memberId = channel.state.members[memberId].user.id;
    const ch = client.channel('team', {
      members: [client.user.id, memberId],
    });
    ch.create().then(() => {
      setActiveChannel(ch);
    });
  };

  return (
    <Box>
      <Drawer
        isOpen={isOpen}
        screenSide="right"
        animationMinWidth={315}
        animationWidth={315}
        overlay={overlay}
        heightMin="580px"
        animationDuration={10}
        borderRadiusClosed="20px"
        marginTop="0px"
      >
        <Drawer.Header className={styles.companyProfileBox}>
          <Box>
            <ButtonIcon
              icon="icon-cerrar"
              variant="mainButtonIcon"
              onClick={toggle}
            />
          </Box>
        </Drawer.Header>
        <Drawer.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Box className={styles.companyProfileInfoBox}>
            <Image
              className={styles.avatarProfile}
              src={
                channel.data.type.startsWith('WhatsApp')
                  ? channel.data.guest_image
                  : channel.data.image || 'invalid_route'
              }
              variant="circleBig"
            />
            {channel.data.type.startsWith('WhatsApp') && (
              <Box className={styles.contactTypeIcon}>
                <Box className={styles.iconWhatsapBox}>
                  <Icon icon="icon-whatsapp" fontSize="16px" color="white" />
                </Box>
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3px',
              }}
            >
              {channel.data.type === 'WhatsAppGuest' ? (
                <Box>
                  {channel?.data?.channel_header?.description && (
                    <Text className={styles.companyProfileName}>
                      {channel.data.channel_header.description}
                    </Text>
                  )}
                  <Text
                    className={`${styles.companyProfileName} ${styles.Number}`}
                  >
                    {channel.data.guest_user}
                  </Text>
                </Box>
              ) : (
                <Text className={styles.companyProfileName}>
                  {channelName || title}
                </Text>
              )}
              {channel.data.type !== 'WhatsAppGuest' && (
                <Text className={styles.groupAmountProfile}>
                  Grupo - {numberOfUsers} participantes
                </Text>
              )}
            </Box>
            <Text className={styles.groupCreatedBy}>
              Creado por {channel.data.created_by.name} el{' '}
              {formatDateToDDMMYY(channel.data.created_at)}
            </Text>
          </Box>
          {channel.data.type !== 'WhatsAppGuest' && (
            <Box className={styles.companyProfileUserAmount}>
              <Box className={styles.groupAmountProfileSearch}>
                <Text className={styles.groupNumberOfUsers}>
                  {numberOfUsers} Participantes
                </Text>
                <Icon
                  style={{ cursor: 'pointer' }}
                  icon="icon-buscar"
                  fontSize="20px"
                  onClick={() => setFindUsers((prevState) => !prevState)}
                />
              </Box>
              {findUsers && (
                <Input
                  variantSize="inputMedium"
                  type="text"
                  suffix={
                    searchTerm !== '' ? (
                      <ButtonIcon
                        variant={['transparentIcon', 'iconExtraSmall22px']}
                        icon="icon-cerrar"
                        onClick={() => setSearchTerm('')}
                      />
                    ) : undefined
                  }
                  placeholder="Buscar usuarios"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              )}
              <Box
                style={{ height: findUsers ? '235px' : '280px' }}
                className={styles.groupMemberBox}
              >
                <div>
                  {filteredMembers.length > 0 ? (
                    filteredMembers.map((member) => (
                      <Box
                        onClick={() => handleUserClick(member)}
                        className={styles.contactBox}
                        key={member}
                      >
                        <Image
                          className={styles.profileCompanyAvatar}
                          src={
                            channel.state.members[member].user.image ||
                            'invalid_route'
                          }
                          variant="avatar"
                        />
                        <Text className={styles.profileCompanyUser}>
                          {channel.state.members[member].user.name}
                        </Text>
                      </Box>
                    ))
                  ) : (
                    <Heading
                      as="h4"
                      variant="heading.3"
                      style={{ textAlign: 'center' }}
                    >
                      No hay resultados para: {searchTerm}
                    </Heading>
                  )}
                </div>
              </Box>
            </Box>
          )}
        </Drawer.Body>
        <Drawer.Footer
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Box className={styles.companyContactBox} />
        </Drawer.Footer>
      </Drawer>
    </Box>
  );
}

export default GroupDrawer;
