import Skeletons from '@/components/Skeletons/Skeletons';

export default function SharePropertySkeleton() {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Skeletons
          height={54}
          width={580}
          count={5}
          borderRadius={12}
          style={{ marginBottom: '8px' }}
        />
      </div>
    </div>
  );
}
