import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonIcon, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import SearchItemContact from './SearchItems/SearchItemContact';
import SearchItemPropertie from './SearchItems/SearchItemPropertie';
import SearchItemDevelopment from './SearchItems/SearchItemDevelopment';
import SearchItemAgent from './SearchItems/SearchItemAgent';
import getWidthOfElementOnWindowResize from '@/components/shared/LayoutEffects';
import styles from './Search.module.scss';
import translations from './Search.trans.json';
import Trans from '@/components/shared/Trans';

function Search({ text, searchs }) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const widthInput = getWidthOfElementOnWindowResize('inputHeader', 700);
  const [aditionalActionSeeMoreResults, setAditionalActionSeeMoreResults] =
    useState(undefined);
  const [aditionalActionCreateNewContact, setAditionalActionCreateNewContact] =
    useState(undefined);
  useEffect(() => {
    setAditionalActionSeeMoreResults(
      searchs.filter(
        (search) => search.aditional_actions && search.name !== text
      )[0]
    );
    setAditionalActionCreateNewContact(
      searchs.filter(
        (search) =>
          search.aditional_actions &&
          search.link !== undefined &&
          search.link.includes('/contact/add')
      )[0]
    );
  }, []);

  return (
    <Box className={styles.containerSearch} width={widthInput}>
      {searchs.length > 0 &&
        searchs
          .filter((search) => search.is_agent_result !== undefined)
          .map((agent) => <SearchItemAgent agent={agent} key={agent.name} />)}
      {searchs.length > 0 &&
        searchs
          .filter((search) => search.devel_status !== undefined)
          .map((development) => (
            <SearchItemDevelopment
              development={development}
              key={development.name}
            />
          ))}
      {searchs.length > 0 &&
        searchs
          .filter((search) => search.operations !== undefined)
          .map((propertie) => (
            <SearchItemPropertie propertie={propertie} key={propertie.name} />
          ))}
      {searchs.length > 0 &&
        searchs
          .filter((search) => search.is_single_contact !== undefined)
          .map((contact) => (
            <SearchItemContact contact={contact} key={contact.id} />
          ))}
      {aditionalActionSeeMoreResults !== undefined && (
        <Box className={styles.containerRowSeeResults}>
          <Box className={styles.containerColumnSeeResults}>
            <Text
              variant="body600.fontSize12"
              className={styles.colorNeutralGray3}
            >
              {aditionalActionSeeMoreResults.description}
            </Text>
            <Link
              href={`${tokkoBrokerHost}${aditionalActionSeeMoreResults.link}`}
            >
              <Button variant="primaryText" className={styles.textRed}>
                {Trans(translations, 'HeaderSearch.Seemoresimilarresults')}
              </Button>
            </Link>
          </Box>
        </Box>
      )}
      {aditionalActionCreateNewContact !== undefined && (
        <Link
          href={`${tokkoBrokerHost}${aditionalActionCreateNewContact.link}`}
        >
          <Box className={styles.itemEnd}>
            <ButtonIcon
              icon="icon-agregar"
              variant={['iconPrimary', 'roundIconMedium']}
            />
            <Box className={styles.containerColumn}>
              <Text
                variant="bodyBold.fontSize14"
                className={styles.colorNeutralGray1}
              >
                {text}
              </Text>
              <Text
                variant="body.fontSize11"
                className={styles.colorNeutralGray3}
              >
                {aditionalActionCreateNewContact.description}
              </Text>
            </Box>
          </Box>
        </Link>
      )}
    </Box>
  );
}

export default Search;
