import { useState, useRef, useEffect } from 'react';
import { Box, Icon, Loader, Text } from '@oneloop/jopijs';
import ContactsService from '@/services/ContactsService';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';
import SenderService from '@/services/SenderService';

function WhatsappForContact({ contact, handleAddWhatsapp }) {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingWhatsapp, setIsAddingWhatsapp] = useState(false);
  const [whatsapp, setWhatsapp] = useState(contact.cellphone || '');

  const [initialInvalidWhatsappError, setInitialInvalidWhatsappError] =
    useState(contact.cellphone && contact.isValidated && !contact.isValid);
  const [isWhatsappValidationError, setIsWhatsappValidationError] =
    useState(false);
  const [inputIsDirty, setInputIsDirty] = useState(false);
  const [focusingInput, setFocusingInput] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const isValidWhatsapp = !isWhatsappValidationError;
  const isError = !isValidWhatsapp;
  const showError =
    initialInvalidWhatsappError ||
    (inputIsDirty &&
      ((wasSubmitted && !isValidWhatsapp) ||
        (!focusingInput && !isValidWhatsapp)));

  useEffect(() => {
    if (!inputRef.current) return;

    if (!initialInvalidWhatsappError) {
      inputRef.current.focus();
    }
  }, [inputRef.current, isAddingWhatsapp]);

  useEffect(() => {
    setWasSubmitted(false);
    setIsWhatsappValidationError(false);
    if (whatsapp) {
      setInputIsDirty(true);
    }
  }, [whatsapp]);

  const toggleAddingWhatsapp = () => {
    setIsAddingWhatsapp((prevState) => !prevState);
  };

  const handlePressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      setIsAddingWhatsapp(false);
    }
  };

  const handleChange = (event) => {
    setWhatsapp(event.target.value);
  };

  const handleBlur = () => {
    setInputIsDirty(true);
    setFocusingInput(false);
  };

  const handleFocus = () => {
    setFocusingInput(true);
    setInitialInvalidWhatsappError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputIsDirty(true);
    setWasSubmitted(true);

    if (isError) return;

    try {
      setIsLoading(true);

      const data = {
        id: contact.id,
        body: {
          cellphone: whatsapp,
        },
      };

      await SenderService.validateWhatsapp(whatsapp);
      const result = await ContactsService.update(data);
      handleAddWhatsapp(result);
    } catch (error) {
      console.log(error);
      setIsWhatsappValidationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <Box className={styles['outer-container']} onKeyDown={handlePressEscape}>
        <Box className={styles['inner-container']}>
          <Box className={styles['left-side']}>
            <Text className={styles.title}>
              <Box as="span" className={styles['title--highlighted']}>
                {Trans(translations, 'ThisContact')}
              </Box>
              <Box as="span">
                {!contact.cellphone
                  ? Trans(translations, 'doesNotHaveAssociatedNumber')
                  : Trans(translations, 'doesNotHaveAValidNumber')}
              </Box>
            </Text>

            <Text className={styles.subtitle}>
              {Trans(translations, 'YouMustPrepend')}
            </Text>

            {isAddingWhatsapp && (
              <Box as="form" className={styles.form} onSubmit={handleSubmit}>
                <Box
                  className={`${styles['input-wrapper']} ${
                    showError ? styles['input-wrapper--error'] : ''
                  }`}
                >
                  <Box
                    as="input"
                    ref={inputRef}
                    className={styles.input}
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder={Trans(translations, 'WhatsappNumber')}
                    value={whatsapp}
                    onChange={handleChange}
                  />
                  {showError && (
                    <Icon icon="icon-error" fontSize="16px" color="#a71601" />
                  )}
                </Box>

                {isLoading && (
                  <Box __css={{ border: 'none', padding: '0 8px' }}>
                    <Loader
                      variant="spinner"
                      style={{
                        width: '24px',
                        height: '24px',
                        backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                      }}
                    />
                  </Box>
                )}

                {!isLoading && (
                  <Box
                    as="button"
                    type="submit"
                    className={`${styles.button} ${
                      isError || isLoading ? styles['button--disabled'] : ''
                    }`}
                  >
                    {Trans(translations, 'AddWhatsapp')}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {!isAddingWhatsapp && (
            <Box className={styles['right-side']}>
              <Box
                as="button"
                type="button"
                className={styles.button}
                onClick={toggleAddingWhatsapp}
              >
                {Trans(translations, 'AddWhatsapp')}
              </Box>
            </Box>
          )}
        </Box>

        {isAddingWhatsapp && showError && !initialInvalidWhatsappError && (
          <Text className={styles['error-message']}>
            {whatsapp.length === 0
              ? Trans(translations, 'AddAWhatsappNumber')
              : Trans(translations, 'InvalidWhatsappNumber')}
          </Text>
        )}
      </Box>
    </>
  );
}

export default WhatsappForContact;
