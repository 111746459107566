import { Box } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import { formatDistance, formatRelative, isToday, subDays } from 'date-fns';
import { es, en } from 'date-fns/locale';
import styles from './CustomDateSeparator.module.scss';
import translations from './CustomDateSeparator.trans.json';
import Trans from '@/components/shared/Trans';

const localeMap = {
  en,
  es,
};

export default function CustomDateSeparator(props) {
  const { date } = props;

  const { user_language, company_language } = useSelector(
    (state) => state.UserSlice.data
  );

  const handleLanguage = () => {
    const lang = user_language || company_language;

    return lang.split('-')[0];
  };

  const language = handleLanguage(); // Obtengo el codigo del idioma en formato 'aa'
  const locale = localeMap[language];

  const dateObj = new Date(date);
  let displayDate;

  if (isToday(dateObj)) {
    displayDate = Trans(translations, 'Today');
  } else if (dateObj > subDays(new Date(), 7)) {
    displayDate = formatDistance(dateObj, new Date(), {
      addSuffix: true,
      locale,
    });
  } else {
    displayDate = formatRelative(dateObj, new Date(), { locale });
  }

  return (
    <Box style={{ justifyContent: 'center', display: 'flex', margin: '10px' }}>
      <Box className={styles.dateSeparatorBox}>{displayDate}</Box>
    </Box>
  );
}
