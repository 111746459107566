import ImageNext from '@/components/shared/ImageNext';
import NumberedList from '../../../public/images/numbered-list.png';

export function AlignCenterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="17" x2="7" y1="12" y2="12" />
      <line x1="19" x2="5" y1="18" y2="18" />
    </svg>
  );
}

export function AlignLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="15" x2="3" y1="12" y2="12" />
      <line x1="17" x2="3" y1="18" y2="18" />
    </svg>
  );
}

export function AlignRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="21" x2="9" y1="12" y2="12" />
      <line x1="21" x2="7" y1="18" y2="18" />
    </svg>
  );
}

export function JustifyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="21" x2="3" y1="6" y2="6" />
      <line x1="21" x2="3" y1="12" y2="12" />
      <line x1="21" x2="3" y1="18" y2="18" />
    </svg>
  );
}

export function BoldIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 12a4 4 0 0 0 0-8H6v8" />
      <path d="M15 20a4 4 0 0 0 0-8H6v8Z" />
    </svg>
  );
}

export function HighlightIcon(props) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.75" width="16" height="16" rx="3" fill="#EBFF08" />
      <path
        d="M13.2132 13.2627C13.2132 13.4893 13.1199 13.6827 12.9399 13.8427C12.7599 14.0027 12.5465 14.0827 12.3065 14.0827C12.1465 14.0827 11.9999 14.0427 11.8599 13.956C11.7199 13.8693 11.6132 13.7427 11.5332 13.5627L10.6399 11.5627H5.38655L4.49321 13.5627C4.41321 13.7427 4.30655 13.876 4.16655 13.956C4.02655 14.0427 3.87321 14.0827 3.70655 14.0827C3.47988 14.0827 3.26655 14.0027 3.07988 13.8427C2.89321 13.6827 2.79321 13.4893 2.79321 13.2627C2.79321 13.136 2.81988 13.0027 2.87988 12.876L6.99988 4.02935C7.08655 3.82935 7.22655 3.67602 7.41321 3.57602C7.59988 3.47602 7.79321 3.41602 7.99988 3.41602C8.20655 3.41602 8.40655 3.46935 8.58655 3.57602C8.76655 3.68268 8.90655 3.82935 8.99988 4.02935L13.1332 12.876C13.1932 13.0027 13.2199 13.136 13.2199 13.2627H13.2132ZM9.97988 10.0693L8.01321 5.63602L6.04655 10.0693H9.98655H9.97988Z"
        fill="#2F373D"
      />
    </svg>
  );
}

export function ImageIcon(props) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1799 4.91667C14.7599 3.61667 13.5666 2.75 12.2199 2.75H3.77991C2.05991 2.75 0.659912 4.17 0.659912 5.91667V11.5833C0.659912 13.33 2.05991 14.75 3.77325 14.75H12.2199C13.9399 14.75 15.3332 13.33 15.3332 11.5833V5.91667C15.3332 5.57667 15.2799 5.23667 15.1732 4.91667H15.1799ZM3.77325 4.08333H12.2199C12.9866 4.08333 13.6666 4.58333 13.9132 5.33667C13.9732 5.52333 14.0066 5.71667 14.0066 5.91667V8.20333L12.4932 6.91667C11.6932 6.23667 10.4666 6.25667 9.69324 6.96333L6.55325 9.83667C6.27991 10.0833 5.83991 10.1033 5.54658 9.87667L4.13325 8.79667C3.51991 8.33 2.69991 8.24333 1.99991 8.51667V5.91667C1.99991 4.90333 2.79991 4.08333 3.77991 4.08333H3.77325ZM12.2199 13.4167H3.77991C2.79991 13.4167 1.99991 12.5967 1.99991 11.5833V10.1033L2.27991 9.87667C2.57325 9.63667 3.02658 9.63 3.32658 9.85667L4.73991 10.9367C5.11325 11.2233 5.57325 11.3633 6.02658 11.3633C6.54658 11.3633 7.05991 11.1767 7.45325 10.8167L10.5932 7.94333C10.8732 7.68333 11.3399 7.67667 11.6332 7.93L13.8266 9.79C13.8799 9.83667 13.9466 9.86333 14.0132 9.89V11.5767C14.0132 12.59 13.2132 13.41 12.2332 13.41L12.2199 13.4167Z"
        fill="#344149"
      />
      <path
        d="M5.11991 8.43667C6.17991 8.43667 7.03991 7.57667 7.03991 6.51667C7.03991 5.45667 6.17991 4.59667 5.11991 4.59667C4.05991 4.59667 3.19991 5.45667 3.19991 6.51667C3.19991 7.57667 4.05991 8.43667 5.11991 8.43667ZM5.11991 5.93C5.44658 5.93 5.70658 6.19667 5.70658 6.51667C5.70658 6.83667 5.43991 7.10333 5.11991 7.10333C4.79991 7.10333 4.53325 6.83667 4.53325 6.51667C4.53325 6.19667 4.79991 5.93 5.11991 5.93Z"
        fill="#344149"
      />
    </svg>
  );
}

export function ItalicIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="19" x2="10" y1="4" y2="4" />
      <line x1="14" x2="5" y1="20" y2="20" />
      <line x1="15" x2="9" y1="4" y2="20" />
    </svg>
  );
}

export function LineHeightIcon(props) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00006 5.87359H14.0001C14.3667 5.87359 14.6667 5.57359 14.6667 5.20693C14.6667 4.84026 14.3667 4.54026 14.0001 4.54026H8.00006C7.63339 4.54026 7.33339 4.84026 7.33339 5.20693C7.33339 5.57359 7.63339 5.87359 8.00006 5.87359Z"
        fill="#344149"
      />
      <path
        d="M14.0001 7.62693H8.00006C7.63339 7.62693 7.33339 7.92693 7.33339 8.29359C7.33339 8.66026 7.63339 8.96026 8.00006 8.96026H14.0001C14.3667 8.96026 14.6667 8.66026 14.6667 8.29359C14.6667 7.92693 14.3667 7.62693 14.0001 7.62693Z"
        fill="#344149"
      />
      <path
        d="M14.0001 10.7069H8.00006C7.63339 10.7069 7.33339 11.0069 7.33339 11.3736C7.33339 11.7403 7.63339 12.0403 8.00006 12.0403H14.0001C14.3667 12.0403 14.6667 11.7403 14.6667 11.3736C14.6667 11.0069 14.3667 10.7069 14.0001 10.7069Z"
        fill="#344149"
      />
      <path
        d="M5.06672 6.75359C5.33339 7.00693 5.75339 6.99359 6.00672 6.72693C6.26006 6.46026 6.24672 6.04026 5.98006 5.78693L4.23339 4.12693L4.22006 4.11359C4.22006 4.11359 4.16006 4.08026 4.13339 4.06026C4.09339 4.03359 4.06006 4.00026 4.01339 3.98693C3.93339 3.95359 3.84672 3.93359 3.76006 3.93359C3.67339 3.93359 3.59339 3.95359 3.51339 3.98693C3.47339 4.00693 3.44006 4.03359 3.40006 4.06026C3.36672 4.08026 3.33339 4.09359 3.30672 4.12026L1.54672 5.80026C1.28006 6.05359 1.26672 6.47359 1.52006 6.74026C1.65339 6.88026 1.82672 6.94693 2.00006 6.94693C2.16672 6.94693 2.33339 6.88693 2.46006 6.76026L3.09339 6.15359V10.9069L2.46006 10.3003C2.19339 10.0469 1.77339 10.0603 1.52006 10.3269C1.26672 10.5936 1.27339 11.0136 1.54672 11.2669L3.30672 12.9469C3.30672 12.9469 3.37339 12.9869 3.40672 13.0069C3.44672 13.0336 3.48006 13.0603 3.52006 13.0803C3.60006 13.1136 3.68672 13.1336 3.77339 13.1336C3.86006 13.1336 3.94672 13.1136 4.02672 13.0803C4.07339 13.0603 4.10672 13.0336 4.14672 13.0069C4.17339 12.9869 4.20672 12.9736 4.23339 12.9536L4.24672 12.9403L5.99339 11.2803C6.26006 11.0269 6.27339 10.6069 6.02006 10.3403C5.76672 10.0736 5.34672 10.0603 5.08006 10.3136L4.44006 10.9203V6.16026L5.08006 6.76693L5.06672 6.75359Z"
        fill="#344149"
      />
    </svg>
  );
}

export function UnorderedListIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="8" x2="21" y1="6" y2="6" />
      <line x1="8" x2="21" y1="12" y2="12" />
      <line x1="8" x2="21" y1="18" y2="18" />
      <line x1="3" x2="3.01" y1="6" y2="6" />
      <line x1="3" x2="3.01" y1="12" y2="12" />
      <line x1="3" x2="3.01" y1="18" y2="18" />
    </svg>
  );
}

export function OrderedListIcon(props) {
  return <ImageNext alt="ordered-list-icon" src={NumberedList} {...props} />;
}

export function TextIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 6.1H3" />
      <path d="M21 12.1H3" />
      <path d="M15.1 18H3" />
    </svg>
  );
}

export function UnderlineIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 4v6a6 6 0 0 0 12 0V4" />
      <line x1="4" x2="20" y1="20" y2="20" />
    </svg>
  );
}
