import dynamic from 'next/dynamic';
import { createTourSteps, generateTourSteps } from './utils';
import styles from './styles.module.scss';

const Tour = dynamic(() => import('reactour'), { ssr: false });

function SenderTourComponent({
  type = 'initial',
  updateTourStatus,
  handleSkipTour,
  isOpen,
  textIsGotIt,
}) {
  const handleFinishStep = () => {
    const newStatus = { [type]: true };
    updateTourStatus(newStatus);
  };

  const options = createTourSteps(type, textIsGotIt);
  const steps = generateTourSteps(options, handleFinishStep, handleSkipTour);

  return (
    <Tour
      className={styles.tour}
      maskClassName={styles.mask}
      maskSpace={4}
      rounded={8}
      wrapper="#sender"
      steps={steps}
      isOpen={isOpen}
      showCloseButton={false}
      showNumber={false}
      showNavigation={false}
      showButtons={false}
      closeWithMask={false}
      onRequestClose={() => {}}
      disableInteraction
    />
  );
}

export default SenderTourComponent;
