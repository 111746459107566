/* eslint-disable no-return-assign */
import {
  Box,
  Button,
  Datepicker,
  Dropdown,
  InputNumber,
  Modal,
  Radio,
  Text,
} from '@oneloop/jopijs';
import { es } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './ModalRepeatEvent.module.scss';
import DaysBtns from './DaysBtns';
import {
  changeEndEvent,
  changeMonthValue,
  changeNumberRepeat,
  changerValue,
  createMonthArray,
  setRepeat,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './ModalRepeatEvent.trans.json';
import Trans from '@/components/shared/Trans';

export default function ModalRepeatEvent({ closeModal }) {
  const store = useSelector((state) => state.CalendarEvents);
  const { event, repeat } = store;
  const dispatch = useDispatch();
  const { days } = repeat;
  const [myDays, setMyDays] = useState(days);

  const {
    repeatItems,
    valueItems,
    numberRepeat,
    monthItems,
    valueMonth,
    endEvent,
    endDatePicker,
  } = repeat;

  const createRepeatEvent = () => {
    closeModal();

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'dropDownRepeatEventIndex',
        value: 4,
      })
    );

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'days',
        value: myDays,
      })
    );

    dispatch(setRepeat(4));
  };

  useEffect(() => {
    dispatch(createMonthArray(event[0].start));
    if (!endDatePicker) {
      dispatch(
        changerValue({
          block: 'repeat',
          key: 'endDatePicker',
          value: event[0].start,
        })
      );
    }

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'valueItems',
        value: { key: 'día', text: Trans(translations, 'day') },
      })
    );
  }, []);

  return (
    <Modal id="CalendarEvents">
      <Modal.Header
        text={Trans(translations, 'Personalize')}
        variant={['start', 'l']}
      />
      <Modal.Body className={style.bodyCont}>
        {/* Number repeat */}
        <Box className={style.rowLabel}>
          <Text variant="bodyBold.fontSize14">
            {Trans(translations, 'RepeatEach')}
          </Text>
          <Box className={style.row}>
            <InputNumber
              startVal={numberRepeat}
              min={1}
              val={(e) => dispatch(changeNumberRepeat(e))}
            />
            <Dropdown height="32px" width="174px">
              <Dropdown.Button
                type="button"
                variant="dropdownDefault"
                text={valueItems.text}
                filled={valueItems.text}
              />
              <Dropdown.Items width="174px" clickClose>
                {repeatItems.map((item) => (
                  <Dropdown.Default
                    onClick={() =>
                      dispatch(
                        changerValue({
                          block: 'repeat',
                          key: 'valueItems',
                          value: item,
                        })
                      )
                    }
                    key={item.text}
                  >
                    {item.text}
                  </Dropdown.Default>
                ))}
              </Dropdown.Items>
            </Dropdown>
          </Box>
        </Box>

        {/* Each repeat */}
        {valueItems.key === 'sem' && (
          <Box className={style.rowLabel}>
            <Text variant="bodyBold.fontSize14">
              {Trans(translations, 'ItRepeats')}
            </Text>
            <DaysBtns myDays={myDays} setMyDays={setMyDays} />
          </Box>
        )}
        {valueItems.key === 'mes' && (
          <Box className={style.rowLabel}>
            <Text variant="bodyBold.fontSize14">
              {Trans(translations, 'ItRepeats')}
            </Text>
            <Dropdown height="32px" width="210px">
              <Dropdown.Button
                type="button"
                variant="dropdownDefault"
                text={valueMonth}
                filled={valueMonth}
              />
              <Dropdown.Items width="210px" height="75px">
                {monthItems.map((item, index) => (
                  <Dropdown.Default
                    onClick={() => dispatch(changeMonthValue(index))}
                    key={item}
                  >
                    {item}
                  </Dropdown.Default>
                ))}
              </Dropdown.Items>
            </Dropdown>
          </Box>
        )}

        {/* End */}
        <Box className={style.rowLabel}>
          <Text variant="bodyBold.fontSize14">
            {Trans(translations, 'Ends')}
          </Text>
          <Box className={style.rowEnd}>
            <Radio
              name="nunca"
              checked={endEvent.nunca}
              onChange={(e) => dispatch(changeEndEvent(e.target.name))}
            />
            <Text variant="body.fontSize14">
              {Trans(translations, 'Never')}
            </Text>
          </Box>
          <Box className={style.rowEnd}>
            <Radio
              name="el"
              checked={endEvent.el}
              onChange={(e) => dispatch(changeEndEvent(e.target.name))}
            />
            <Text variant="body.fontSize14">
              {Trans(translations, 'modalRepeatEvent.On')}
            </Text>
            <Datepicker
              width="200px"
              showPopperArrow={false}
              dateFormat="dd/MM/yyyy"
              locale={es}
              selected={endDatePicker}
              minDate={event[0].start}
              onChange={(date) =>
                dispatch(
                  changerValue({
                    block: 'repeat',
                    key: 'endDatePicker',
                    value: date,
                  })
                )
              }
            />
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer variant="end">
        <Button variant="secondaryText" onClick={closeModal}>
          {Trans(translations, 'Cancel')}
        </Button>
        <Button variant="primaryText" onClick={createRepeatEvent}>
          {Trans(translations, 'Acept')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
