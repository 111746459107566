import { useEffect, useState } from 'react';

export default function UseEventData(calendarEventsState) {
  const [data, setData] = useState(null);

  useEffect(() => {
    let repeat_in = {};
    const {
      event,
      properties,
      types,
      description,
      agents,
      contacts,
      notifications,
      repeat,
      edit,
      reports,
    } = calendarEventsState;

    const activeCodes = repeat.days.reduce((prev, current) => {
      if (current.active) {
        prev.push(current.code);
      }
      return prev;
    }, []);

    const monthFrecuency = {
      0: { FREQ: 'MONTHLY' },
      1: {
        FREQ: 'MONTHLY',
        BYSETPOS: repeat.monthDayPos,
        BYDAY: [repeat.monthDayCode],
      },
    };

    const currentFrequency = {
      día: { FREQ: 'DAILY' },
      sem: { FREQ: 'WEEKLY', BYDAY: activeCodes },
      mes: monthFrecuency[repeat.monthItems.indexOf(repeat.valueMonth)],
      año: { FREQ: 'YEARLY' },
    };

    const frequency = {
      1: { FREQ: 'DAILY' },
      2: { FREQ: 'WEEKLY', BYDAY: activeCodes },
      3: { FREQ: 'YEARLY' },
      4: currentFrequency[repeat.valueItems.key],
    };

    if (repeat.isCustomRepeat) {
      repeat_in = {
        DTSTART: event[0].start,
        INTERVAL: repeat.numberRepeat,
        ...frequency[repeat.dropDownRepeatEventIndex],
      };
    }

    if (repeat.endEvent.el) {
      repeat_in.UNTIL = repeat.endDatePicker;
    }

    const obj = {
      users: agents.agentsSelected.map((p) => p.id),
      event_settings: {
        mail_agent_end: notifications.agentEmailAfter,
        notify_contact_start: notifications.contactEmailBefore,
        notify_agent_start: notifications.agentNotioficationBefore,
        mail_agent_start: notifications.agentEmailBefore,
        notify_agent_end: notifications.agentNotificationAfter,
      },
      properties: properties.propertiesSelected.map((p) => p.id),
      event_data: {
        date_from: event[0].start,
        description: description.text,
        event_type_id: types.typeSelected?.id || null,
        name: event[0].title,
        date_to: event[0].end,
        repeat_in,
      },
      contacts: contacts.contactsSelected.map((p) => p.id),
    };

    if (edit.editItem) {
      const addReports = {
        send_report_to_contact: reports.sendReportToContact,
        body_template: reports.bodyTemplate,
        contact_body_template: reports.text,
        need_report: reports.needReport,
      };

      obj.event_settings = { ...obj.event_settings, ...addReports };
    }

    setData(obj);
  }, [calendarEventsState]);

  return data;
}
