import { Box, InputHours, Label, Text } from '@oneloop/jopijs';
import {
  set,
  format,
  addHours,
  differenceInMinutes,
  addMinutes,
} from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import style from './InputsHours.module.scss';
import {
  changeEvent,
  changeInitialTime,
  handleEndTimeSelected,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './InputsHours.trans.json';
import Trans from '@/components/shared/Trans';

export default function InputsHours() {
  const store = useSelector((state) => state.CalendarEvents.event[0]);
  const { endTimeSelected, dragTime, errors } = useSelector(
    (state) => state.CalendarEvents
  );
  const [endTime, setEndTime] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (store.start && !endTimeSelected) {
      setEndTime(addHours(store.start, 1));
    }
  }, [store.start]);

  const parseHourToString = (horaString) => {
    const [hora, minuto] = horaString.split(':');
    if (hora && minuto) {
      return {
        hora: parseInt(hora, 10),
        minuto: parseInt(minuto, 10),
      };
    }
    return null;
  };

  const differenceTime = (hora, minuto) => {
    if (store.start) {
      const dateLeft = set(store.start, { hours: hora, minutes: minuto });
      const dateRight = store.start;

      let diff = differenceInMinutes(dateLeft, dateRight);

      if (diff >= 60) {
        diff = `${diff / 60} h`;
      } else {
        diff = `${diff} min`;
      }

      return diff;
    }

    return null;
  };

  const handleEndArray = (horaInicial) => {
    const opciones = [];
    const horaInicialObj = parseHourToString(horaInicial);

    if (horaInicialObj) {
      let { hora } = horaInicialObj;
      let { minuto } = horaInicialObj;
      let difference = differenceTime(hora, minuto);

      while (hora <= 23) {
        const horaString = `${hora.toString().padStart(2, '0')}:${minuto
          .toString()
          .padStart(2, '0')} (${difference})`;

        opciones.push(horaString);

        minuto += 15;

        if (minuto >= 60) {
          hora++;
          minuto -= 60;
        }
        difference = differenceTime(hora, minuto);
      }
    }

    return opciones;
  };

  return (
    <Box className={style.row}>
      <Box className={style.label}>
        <Label id="start-input" className={style.labelColor}>
          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'Starts')}
          </Text>
        </Label>
        {/* Start Time Input */}
        <InputHours
          key={dragTime.start}
          aria-labelledby="start-input"
          val={(e) => {
            dispatch(changeInitialTime(e));
          }}
          inputTime={store.start || new Date()}
          isSelected={store.start}
        />
      </Box>

      <Box className={style.label}>
        <Label className={style.labelColor}>
          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'Ends')}
          </Text>
        </Label>
        {/* End Time Input */}
        <InputHours
          key={store.start}
          isSelected={store.end}
          arrayInput={handleEndArray(
            format(store.start || new Date(), 'HH:mm')
          )}
          val={(e) => {
            dispatch(changeEvent({ end: e }));

            if (store.end) {
              const difference = Math.abs(differenceInMinutes(e, store.start));
              dispatch(handleEndTimeSelected(difference));

              if (difference === 1380) {
                dispatch(handleEndTimeSelected(60));
                const endDif = addMinutes(e, difference);
                dispatch(changeEvent({ end: endDif }));
              }
            }
          }}
          inputTime={store.end || new Date()}
          listenTime={endTime}
          keepArray
          error={errors?.endDate}
        />
      </Box>
    </Box>
  );
}
