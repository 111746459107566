import { Box } from '@oneloop/jopijs';
import styles from './styles.module.scss';

function InputForContact({
  inputAlreadyExists,
  isCollapsed,
  testId,
  ...props
}) {
  const { className, ...rest } = props;
  return (
    <Box
      as="input"
      data-testid={testId}
      className={`${styles.input} ${className} ${
        inputAlreadyExists ? styles['input--invalid'] : ''
      }`}
      size={isCollapsed ? 1 : 20}
      {...rest}
    />
  );
}

export default InputForContact;
