import React from 'react';
import { Box } from '@oneloop/jopijs';
import styles from './Code401.module.scss';

function Code401() {
  return (
    <Box className={styles.container}>Error 401 - Acceso no permitido</Box>
  );
}

export default Code401;
