import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import PropertiesSearch from '../PropertiesSearch';
import SelectedProperties from '../SelectedProperties';
import PropertiesReports from '../PropetiesReports';
import PropertiesSkeleton from '../PropertiesSkeleton';
import { setFromOuterNet } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function PropertiesContent() {
  const dispatch = useDispatch();
  const {
    fromOuterNet,
    preSelectedProperties,
    preSelectedDevelopments,
    addressees,
    properties,
    pdfOption,
    warning,
  } = useSelector((state) => state.SenderSlice);
  const scrollRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showAddMore, setShowAddMore] = useState(false);
  const [showGradient, setShowGradient] = useState(false);

  const updateGradient = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowGradient(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => updateGradient();

    scrollContainer.addEventListener('scroll', handleScroll);
    updateGradient();

    // eslint-disable-next-line
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [properties.selected.length, pdfOption]);

  useEffect(() => {
    if (preSelectedProperties.length > 0 || preSelectedDevelopments.length > 0)
      return;

    if (properties.selected.length === 0) {
      dispatch(setFromOuterNet(false));
      setShowButton(true);
      setShowAddMore(false);
    } else {
      setShowButton(false);
      setShowAddMore(true);
    }

    setShowInput(false);
  }, [
    properties.selected.length,
    preSelectedProperties,
    preSelectedDevelopments,
  ]);

  const getTitle = () => {
    const countDevelopments = properties.selected.filter(
      (prop) => prop.is_development
    ).length;
    const countProperties = properties.selected.length - countDevelopments;
    let title = '';

    if (countProperties > 0) {
      title += `${countProperties} ${
        countProperties === 1
          ? Trans(translations, 'property')
          : Trans(translations, 'properties')
      } `;
    }

    if (countDevelopments > 0) {
      title += `${countDevelopments} ${
        countDevelopments === 1
          ? Trans(translations, 'development')
          : Trans(translations, 'developments')
      }`;
    }

    return title;
  };

  const handleClickSearchButton = () => {
    setShowInput(true);
    setShowButton(false);
  };

  const handleClickAddMore = () => {
    setShowInput(true);
    setShowAddMore(false);
    scrollRef.current.scrollTop = 0;
  };

  const handleShowButton = () => {
    setShowInput(false);
    if (properties.selected.length > 0) {
      setShowAddMore(true);
    } else {
      setShowButton(true);
    }
  };

  return (
    <Box className={styles.container}>
      {(preSelectedProperties.length > 0 ||
        preSelectedDevelopments.length > 0) && <PropertiesSkeleton />}

      {preSelectedProperties.length === 0 &&
        preSelectedDevelopments.length === 0 && (
          <>
            {properties.selected.length > 0 && !showInput && (
              <Text className={styles['highlighted-text']}>{getTitle()}</Text>
            )}

            <Box
              ref={scrollRef}
              id="sender-right-side-content"
              className={styles['scroll-wrapper']}
            >
              {!fromOuterNet && (
                <PropertiesSearch
                  showButton={showButton}
                  handleShowButton={handleShowButton}
                  handleClickButton={handleClickSearchButton}
                  showInput={showInput}
                />
              )}

              {properties.selected.length > 0 && (
                <SelectedProperties
                  showAddMore={showAddMore}
                  handleClickAddMore={handleClickAddMore}
                  withBorder={!showInput}
                />
              )}

              {addressees.selected.length === 1 &&
                !addressees.selected[0]?.isRaw && (
                  <PropertiesReports contact={addressees.selected[0]} />
                )}
            </Box>
          </>
        )}
      <Box
        className={`${styles.gradient} ${
          showGradient ? styles['gradient--visible'] : ''
        }`}
        __css={{ bottom: warning ? '0px' : '18px' }}
      />
    </Box>
  );
}

export default PropertiesContent;
