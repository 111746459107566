import { useState, useEffect, useRef } from 'react';
import { Box, Icon, Loader, Text } from '@oneloop/jopijs';
import QuickResponsesModal from '../QuickResponsesModal';
import SenderService from '@/services/SenderService';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import styles from './styles.module.scss';
import sanitizeHtml from '../utils/sanitizeHtml';

/**
 * @param {Object} props
 * @param {Object} [props.editor] - Editor instance from wysiwyg lib. If is not provided, quick responses would not be triggered
 * @returns {JSX.Element}
 * @example
 * <QuickResponses />
 * <QuickResponses editor={editor} />
 *
 * @todo translations
 */
export default function QuickResponses({ onSelect }) {
  const [quickResponses, setQuickResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef.current, dropdownRef.current]);

  const fetchQuickResponses = async () => {
    try {
      setIsLoading(true);
      const result = await SenderService.getQuickResponses();
      setQuickResponses(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuickResponses();
  }, []);

  const handleClickQuickResponse = (quickResponse) => {
    const { body, subject } = quickResponse;
    const html = sanitizeHtml(body);

    onSelect({ subject, html });
    setIsModalOpen(false);
  };

  if (isLoading)
    return (
      <Box className={styles['quick-responses-wrapper']}>
        <Box
          as="button"
          type="button"
          className={`${styles['quick-responses-button']} ${styles['button--loading']}`}
          aria-label="quick-responses-loading"
        >
          <Loader
            variant="spinner"
            style={{
              margin: 0,
              width: '16px',
              height: '16px',
              backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
            }}
          />
        </Box>
      </Box>
    );

  return (
    <>
      <Box className={styles['quick-responses-wrapper']} ref={wrapperRef}>
        <Box
          as="button"
          id="quick-responses"
          type="button"
          className={styles['quick-responses-button']}
          onClick={() => setIsModalOpen((prevState) => !prevState)}
          aria-label="quick-responses"
          data-active={isModalOpen}
        >
          <Box className={styles['button-icon']}>
            <Icon icon="icon-respuestasrapidas" fontSize="12px" />
          </Box>
          <Text className={styles['button-text']}>
            {Trans(translations, 'QuickResponses')}
          </Text>
        </Box>
      </Box>

      {isModalOpen && (
        <Box
          className={styles['quick-responses-dropdown-wrapper']}
          ref={dropdownRef}
        >
          <QuickResponsesModal
            quickResponses={quickResponses}
            onSelect={handleClickQuickResponse}
          />
        </Box>
      )}
    </>
  );
}
