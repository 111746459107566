import { Box, Icon, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useChannelStateContext } from 'stream-chat-react';
import styles from '../CustomChannelHeader.module.scss';
import translations from './AsignHeaderButton.trans.json';
import Trans from '@/components/shared/Trans';
import { showRightbar } from '@/store/slices/components/chat/MessengerSlice';
import UseChannelTypeActions from '@/components/Messenger/Hooks/useChannelTypeActions';

export default function AsignHeaderButton({ isDisabled }) {
  const dispatch = useDispatch();
  const { channel } = useChannelStateContext();
  const { id: userID } = useSelector((state) => state.UserSlice.data);
  const channelTypeLabel = UseChannelTypeActions(channel);

  if (
    Number(channel.data.web_contact?.generated_by) === userID ||
    !channelTypeLabel
  ) {
    return null;
  }

  const label = channelTypeLabel.labelAssign;

  const hasPermission = channelTypeLabel.checkPermission;

  if (!hasPermission) {
    return null;
  }

  return (
    <Box
      onClick={() =>
        dispatch(
          showRightbar({
            component: 'AsignInquiry',
            title: Trans(translations, 'AssignInquiry'),
          })
        )
      }
      className={`${styles.AsignButton} ${
        isDisabled ? styles.AsignButtonDisabled : ''
      }`}
    >
      <Icon icon="icon-reasignar" fontSize="16px" />
      <Text variant="bodyBold.fontSize12">{label}</Text>
    </Box>
  );
}
