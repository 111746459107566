import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { convert } from 'html-to-text';
import { Box } from '@oneloop/jopijs';
import { setMessage } from '@/store/slices/components/sender/SenderSlice';
import QuickResponses from '@/components/WysiwygEditor/QuickResponses';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function WhatsappTextArea() {
  const dispatch = useDispatch();
  const { warning } = useSelector((state) => state.SenderSlice);
  const textareaRef = useRef(null);
  const isWarning = warning && warning.fields.includes('message');

  const debouncedHandleOnChange = useCallback(
    debounce((text) => {
      dispatch(setMessage({ text }));
    }, 300),
    [dispatch]
  );

  const onSelect = ({ html }) => {
    const text = convert(html, {
      selectors: [{ selector: 'img', format: 'skip' }],
    });
    textareaRef.current.value = text;
    dispatch(setMessage({ text }));
  };

  return (
    <Box
      className={`${styles.container} ${
        isWarning ? styles['container--highlight'] : ''
      }`}
    >
      <Box
        as="textarea"
        ref={textareaRef}
        __css={{ height: null, width: null }}
        className={styles.textarea}
        placeholder={Trans(translations, 'WriteAMessage')}
        onChange={(event) => debouncedHandleOnChange(event.target.value)}
      />
      <QuickResponses onSelect={onSelect} />
    </Box>
  );
}

export default WhatsappTextArea;
