import React, { useState } from 'react';
import { Box, ButtonIcon } from '@oneloop/jopijs';
import { useMessageContext, useChannelStateContext } from 'stream-chat-react';
import styles from './CustomMessageActions.module.scss';
import translations from './CustomMessageActions.trans.json';
import Trans from '@/components/shared/Trans';

function CustomMessageActions({ setMessageReply, client }) {
  const { message } = useMessageContext();
  const { channel } = useChannelStateContext();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.text || '');
    setShowMenu(false);
  };

  const replyToMessage = () => {
    setMessageReply(message);
    setShowMenu(false);
  };
  const getLastMessage = () => {
    const { messages } = channel.state;
    if (messages.length > 0) {
      return messages[messages.length - 1];
    }
    return null;
  };
  const lastMessage = getLastMessage();

  return (
    message.attachments.length <= 1 && (
      <Box style={{ position: 'relative', height: '0' }}>
        <Box className={styles.prueba}>
          <ButtonIcon
            icon="icon-trespunticos"
            variant={['dropdownTransparent', 'iconLarge']}
            onClick={toggleMenu}
          />
        </Box>
        {showMenu && (
          <Box
            className={
              message.user.id !== client.userID
                ? styles.messageActionsBox
                : styles.messageActionsBoxClient
            }
            style={{
              transform:
                // eslint-disable-next-line no-nested-ternary
                message.user.id !== client.userID &&
                lastMessage.id === message.id
                  ? 'translate3d(30px, -90px, 0px)'
                  : lastMessage.id === message.id
                  ? 'translate3d(-90px, -90px, 0px)'
                  : null,
            }}
          >
            {!message.attachments.length > 0 && (
              <button
                className={styles.messageActionButton}
                type="button"
                onClick={copyToClipboard}
              >
                {Trans(translations, 'CopyText')}
              </button>
            )}
            <button
              className={styles.messageActionButton}
              type="button"
              onClick={replyToMessage}
            >
              {Trans(translations, 'ReplyText')}
            </button>
          </Box>
        )}
      </Box>
    )
  );
}

export default CustomMessageActions;
