import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setLayoutUrl } from '@/store/slices/LayoutSlice';
import ToastComponent from '../ToastComponent/ToastComponent';
import CalendarEvents from '../calendarEvents/CalendarEvents';
import QuickDisplay from '../quickDisplay/QuickDisplay';
import Messenger from '@/components/Messenger/Messenger';
import Sender from '../Sender';
import Notification from '../Notification';

export default function CustomModalsWrapped({ children }) {
  const dispatch = useDispatch();
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const { showModal: showCal } = useSelector((state) => state.CalendarEvents);
  const { show: showQD } = useSelector((state) => state.QuickDisplaySlice);
  const { show: showChat } = useSelector((state) => state.MessengerSlice);
  const { show: showSender } = useSelector((state) => state.SenderSlice);

  const [modalOrder, setModalOrder] = useState([]);

  const handleOrder = (modalKey, show) => {
    if (show) {
      setModalOrder([...modalOrder, modalKey]);
    } else {
      const newOrder = modalOrder.filter((item) => item !== modalKey);
      setModalOrder(newOrder);
    }
  };

  const handleMessageModal = (event) => {
    if (event.origin.indexOf(tokkoBrokerHost) === 0) {
      if (event?.data?.url) {
        dispatch(setLayoutUrl(event.data.url));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessageModal);
    return () => {
      window.removeEventListener('message', handleMessageModal);
    };
  }, []);

  useEffect(() => {
    handleOrder('showCal', showCal);
  }, [showCal]);

  useEffect(() => {
    handleOrder('showQD', showQD);
  }, [showQD]);

  useEffect(() => {
    handleOrder('showChat', showChat);
  }, [showChat]);

  useEffect(() => {
    handleOrder('showSender', showSender);
  }, [showSender]);

  const RenderModal = {
    showCal: <CalendarEvents />,
    showQD: <QuickDisplay />,
    showChat: <Messenger />,
    showSender: <Sender />,
  };

  return (
    <>
      {children}
      <ToastComponent />
      <Notification />
      {modalOrder.map((modal) => (
        <div key={modal}>{RenderModal[modal]}</div>
      ))}
    </>
  );
}
