import RawSuggestion from './RawSuggestion';
import ContactSuggestion from './ContactSuggestion';

function Suggestion({ suggestion, handleSelectSuggestion }) {
  if (suggestion.isRaw) return <RawSuggestion suggestion={suggestion} />;

  return (
    <ContactSuggestion
      suggestion={suggestion}
      handleSelectSuggestion={handleSelectSuggestion}
    />
  );
}

export default Suggestion;
