import { Box, Icon } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import {
  toggleFavourite,
  toggleFavouriteDevelopment,
} from '@/store/slices/components/quickDisplay/services';
import { tokkoModalActions } from '../../TokkoActions/tokkoActions';
import ToolTip from '@/components/ToolTip/ToolTip';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import { showToast } from '@/store/slices/components/toast/ToastSlice';

export default function Favourites() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const dispatch = useDispatch();
  const { id, quickData, isDevelopment } = useSelector(
    (state) => state.QuickDisplaySlice
  );

  const { favourite } = quickData;

  const handleFavourite = () => {
    if (isDevelopment) {
      const data = {
        id,
        status: quickData.data.status,
        name: quickData.data.name,
        address: quickData.data.address,
        picture:
          quickData.data.pictures.images.length > 0
            ? quickData.data.pictures.images[0]
            : '/media/img/placeholderemprendimiento.svg',
      };

      const toastText = Trans(
        translations,
        favourite
          ? 'QDMsn.RemovedDevelopmentFavorites'
          : 'QDMsn.AddedDevelopmentFavorites'
      );

      return (
        dispatch(toggleFavouriteDevelopment(id)),
        tokkoModalActions({ msg: 'favouriteDev', data }, tokkoBrokerHost),
        dispatch(showToast({ text: toastText }))
      );
    }

    return dispatch(toggleFavourite(id)).then((res) => {
      tokkoModalActions(
        { msg: 'favouriteProp', data: res.payload.property_info },
        tokkoBrokerHost
      );
      const toastText = Trans(
        translations,
        favourite
          ? 'QDMsn.RemovedPropertyFavorites'
          : 'QDMsn.AddedPropertyFavorites'
      );

      dispatch(showToast({ text: toastText }));
    });
  };

  return (
    <ToolTip
      key={favourite}
      title={Trans(
        translations,
        favourite ? 'RemoveFromMyFavorites' : 'AddToMyFavorites'
      )}
      outSideModal="QuickDisplay"
    >
      <Box className={style.itemHeader} onClick={handleFavourite}>
        <Icon
          icon={favourite ? 'icon-favoritossolido' : 'icon-favoritos'}
          fontSize="16px"
        />
      </Box>
    </ToolTip>
  );
}
