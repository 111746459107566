/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Icon, Image, Text } from '@oneloop/jopijs';
import SearchItems from '../SearchItems/SearchItems';
import {
  addItem,
  changerValue,
  removeItem,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import style from './PropertiesData.module.scss';
import translations from './PropertiesData.trans.json';
import Trans from '@/components/shared/Trans';

export default function PropertiesData({ handleAutoComplete }) {
  const { properties, errors } = useSelector((state) => state.CalendarEvents);
  const { propertiesData, loading, propertiesSelected } = properties;
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const dispatch = useDispatch();
  const [cProperties, setCProperties] = useState([]);

  useEffect(() => {
    // add properties clickfn
    const nuevasProperties = propertiesSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/property/${item.id}`),
    }));

    setCProperties(nuevasProperties);
  }, [propertiesSelected]);

  return (
    <SearchItems
      title={Trans(translations, 'Properties')}
      error={errors?.properties}
      loading={loading}
      icon={<Icon icon="icon-propiedades" fontSize="18px" />}
      items={cProperties}
      itemsKeys={{ id: 'id', text: 'address', clickFn: 'clickFn' }}
      onChange={(e) => handleAutoComplete(e.target.value, 'properties')}
      deleteItem={(i) =>
        dispatch(
          removeItem({
            block: 'properties',
            key: 'propertiesSelected',
            value: i.id,
          })
        )
      }
      data={propertiesData.length !== 0}
      dismountSearch={() =>
        dispatch(
          changerValue({
            block: 'properties',
            key: 'propertiesData',
            value: [],
          })
        )
      }
    >
      {propertiesData.map((item) => {
        if (item.id) {
          const {
            is_default_picture,
            picture_url,
            address,
            ref_code,
            status,
            operations,
          } = item;
          return (
            <Box
              key={ref_code}
              as="li"
              className={`${style.contItem} ${style.contProps}`}
              onClick={() =>
                dispatch(
                  addItem({
                    block: 'properties',
                    key: 'propertiesSelected',
                    value: item,
                  })
                )
              }
            >
              {is_default_picture ? (
                <Box className={style.placeHolder}>
                  <Icon icon="icon-propiedades" fontSize="21px" />
                </Box>
              ) : (
                <Image
                  src={picture_url}
                  alt={address}
                  variant="rows"
                  width={60}
                  height={40}
                />
              )}
              <Box className={style.adressCont}>
                <Box className={style.itemPropCenter}>
                  <Box
                    as="span"
                    className={style[status.split(' ').join('')]}
                  />
                  <Text variant="body.fontSize16">{address}</Text>
                </Box>
                <Text variant="body.fontSize12" className={style.propIdText}>
                  {ref_code}
                </Text>
              </Box>
              <Box className={style.contType}>
                {operations.map((op) => (
                  <Box
                    key={ref_code + op.type}
                    as="span"
                    className={style.btnType}
                  >
                    {op.type}
                  </Box>
                ))}
              </Box>
            </Box>
          );
        }
        return (
          <Box as="li" className={style.contItem}>
            <Text variant="body.fontSize14" className={style.noDataColor}>
              {Trans(translations, item.noData)}
            </Text>
          </Box>
        );
      })}
    </SearchItems>
  );
}
