import { useRef, useState } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ToolbarButton from '../ToolbarButton';
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  JustifyIcon,
} from '../icons';
import AlignmentPicker from '../pickers/AlignmentPicker';

const ALIGNMENT_ICONS = {
  left: <AlignLeftIcon />,
  center: <AlignCenterIcon />,
  right: <AlignRightIcon />,
  justify: <JustifyIcon />,
};

export default function AlignmentButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [alignmentSelected, setAlignmentSelected] = useState('left');
  useOnClickOutside(ref, isOpen, setIsOpen);

  const handleChangeAlignment = (value) => {
    setAlignmentSelected(value);
    editor.chain().focus().setTextAlign(value).run();
    setIsOpen(false);
  };

  return (
    <Box ref={ref} __css={{ position: 'relative' }}>
      <ToolbarButton
        onClick={() => setIsOpen((prevState) => !prevState)}
        aria-label="editor-alignment"
      >
        {ALIGNMENT_ICONS[alignmentSelected]}
        <Icon icon="icon-dropdown" fontSize="12px" />
      </ToolbarButton>
      {isOpen && <AlignmentPicker onClick={handleChangeAlignment} />}
    </Box>
  );
}
