import { Box, Switch, Text, Textarea } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import style from './ReportsTab.module.scss';
import TokkoTips from '@/components/TokkoTips/TokkoTips';
import {
  changerValue,
  fieldsValidation,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './ReportsTab.trans.json';
import Trans from '@/components/shared/Trans';

export default function ReportTabs() {
  const dispatch = useDispatch();
  const { reports, errors } = useSelector((state) => state.CalendarEvents);

  const { bodyTemplate, sendReportToContact, text } = reports;

  const handleChange = (key, value) => {
    dispatch(
      changerValue({
        block: 'reports',
        key,
        value,
      })
    );

    if (key === 'text') {
      dispatch(fieldsValidation('needReport'));
    }
  };

  return (
    <Box __css={{ marginTop: '30px' }}>
      <Text className={style.colorText} variant="body.fontSize14">
        {Trans(translations, 'FinaleReport')}
      </Text>
      <Textarea
        className={`${errors?.needReport && style.error}`}
        marginTop="14px"
        marginBottom="14px"
        width="538px"
        height="59px"
        placeholder="Escribe aquí"
        value={text}
        onChange={(e) => handleChange('text', e.target.value)}
      />
      <Box className={style.row}>
        <Text className={style.colorText} variant="body.fontSize14">
          {Trans(translations, 'SendOwnerReport')}
        </Text>

        <Switch
          aria-checked={sendReportToContact}
          onClick={() =>
            handleChange('sendReportToContact', !sendReportToContact)
          }
        />
      </Box>

      <Box
        className={`${style.container} ${sendReportToContact && style.show}`}
      >
        <Textarea
          className={style.textarea}
          marginTop="14px"
          marginBottom="14px"
          width="538px"
          height="120px"
          placeholder="Escribe aquí"
          value={bodyTemplate}
          onChange={(e) => handleChange('bodyTemplate', e.target.value)}
        />

        <TokkoTips />
      </Box>
    </Box>
  );
}
