/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import SearchItems from '../SearchItems/SearchItems';
import {
  addItem,
  changerValue,
  removeItem,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import style from './contactsData.module.scss';
import translations from './ContactsData.trans.json';
import Trans from '@/components/shared/Trans';

export default function ContactsData({ handleAutoComplete }) {
  const { contacts, errors } = useSelector((state) => state.CalendarEvents);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { contactsData, loading, contactsSelected } = contacts;
  const dispatch = useDispatch();
  const [cContacts, setCContacts] = useState([]);

  useEffect(() => {
    // add contacts clickfn
    const nuevosContactos = contactsSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/contact/${item.id}`),
    }));

    setCContacts(nuevosContactos);
  }, [contactsSelected]);

  return (
    <SearchItems
      title={Trans(translations, 'Contacts')}
      error={errors?.contacts}
      loading={loading}
      icon={<Icon icon="icon-contactos" fontSize="18px" />}
      items={cContacts}
      itemsKeys={{ id: 'id', text: 'name', clickFn: 'clickFn' }}
      onChange={(e) => handleAutoComplete(e.target.value, 'contacts')}
      deleteItem={(i) =>
        dispatch(
          removeItem({
            block: 'contacts',
            key: 'contactsSelected',
            value: i.id,
          })
        )
      }
      data={contactsData.length !== 0}
      dismountSearch={() =>
        dispatch(
          changerValue({
            block: 'contacts',
            key: 'contactsData',
            value: [],
          })
        )
      }
    >
      {contactsData.map((item) => {
        if (item.id) {
          return (
            <Box
              as="li"
              key={item.id}
              className={`${style.contItem} ${style.contContacts}`}
              style={{ flexDirection: 'column' }}
              onClick={() =>
                dispatch(
                  addItem({
                    block: 'contacts',
                    key: 'contactsSelected',
                    value: item,
                  })
                )
              }
            >
              <Text variant="body.fontSize16">{item.name}</Text>
              {item.contact_info?.email && (
                <Box className={style.iconText}>
                  <Icon icon="icon-email" fontSize="12px" />
                  <Text variant="body.fontSize11">
                    {item.contact_info.email}
                  </Text>
                </Box>
              )}
              <Box className={style.iconTextCont}>
                {item.contact_info?.phone && (
                  <Box className={style.iconText}>
                    <Icon icon="icon-telefono" fontSize="12px" />
                    <Text variant="body.fontSize11">
                      {item.contact_info.phone}
                    </Text>
                  </Box>
                )}
                {item.contact_info?.cellphone && (
                  <Box className={style.iconText}>
                    <Icon icon="icon-whatsapp" fontSize="12px" />
                    <Text variant="body.fontSize11">
                      {item.contact_info.cellphone}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }
        return (
          <Box as="li" className={style.contItem}>
            <Text variant="body.fontSize14" className={style.noDataColor}>
              {Trans(translations, item.noData)}
            </Text>
          </Box>
        );
      })}
    </SearchItems>
  );
}
