import { useState, useRef } from 'react';
import { Icon, Box } from '@oneloop/jopijs';
import ToolTip from '@/components/ToolTip/ToolTip';
import useOnClickOutside from '@/components/shared/Hooks';
import DynamicTagsPicker from '../DynamicTagsPicker';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import styles from './styles.module.scss';

export default function DynamicTags({ handleSelect }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box ref={ref}>
      <ToolTip title={Trans(translations, 'DynamicTags')}>
        <Box
          as="button"
          id="dynamic-tags"
          aria-label="editor-dynamic-tags"
          className={styles['outer-wrapper']}
          onClick={() => setIsOpen((prevState) => !prevState)}
          tabIndex="-1"
        >
          <Box className={styles['inner-wrapper']}>
            <Icon icon="icon-tokkotip" fontSize="16px" color="#344149" />
          </Box>
        </Box>
      </ToolTip>

      {isOpen && (
        <DynamicTagsPicker
          handleSelect={handleSelect}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
}
