import { Text } from '@oneloop/jopijs';
import SelectedItem from '../SelectedItem';
import styles from './styles.module.scss';

function SelectedItems({
  isCollapsed,
  howManyShowCollapsed,
  selected,
  setSelected,
  tooltipContainerId,
}) {
  const restOfElements = isCollapsed
    ? selected.slice(howManyShowCollapsed)
    : [];

  const shouldShowSingleChip = isCollapsed && restOfElements.length > 0;

  return (
    <>
      {selected.map((contact, index) => (
        <SelectedItem
          key={contact.id}
          contact={contact}
          selected={selected}
          setSelected={setSelected}
          tooltipContainerId={tooltipContainerId}
          shouldHide={isCollapsed && index + 1 > howManyShowCollapsed}
        />
      ))}

      {shouldShowSingleChip && (
        <Text className={styles['rest-of-selected']}>
          {`+ ${restOfElements.length}`}
        </Text>
      )}
    </>
  );
}

export default SelectedItems;
