import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@oneloop/jopijs';
import NoResults from './NoResults';
import Suggestions from './Suggestions';
import LoadingResults from './LoadingResults';
import useOnClickOutside from '@/components/shared/Hooks';
import isAValidEmail from '../../utils/isAValidEmail';
import AddNewContact from './NewContact';
import styles from './styles.module.scss';

function SearchResults({
  row,
  input,
  handleAddNewContact,
  isLoading = false,
  onSelect,
  results,
}) {
  const { show } = useSelector((state) => state.SenderSlice);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);
  const isAValidInput = show === 'email' ? isAValidEmail(input) : true;
  const showLoading = isLoading;
  const showNoResults = results?.length === 0 && !isAValidInput;
  const showAddContact = results?.length === 0 && isAValidInput;
  const showSuggestions = results?.length > 0;
  const shouldShowNothing = !results && !isLoading;

  useEffect(() => {
    setIsOpen(true);
  }, [isLoading, results]);

  const clickToStopPropagation = (event) => {
    event.stopPropagation();
  };

  if (!isOpen || shouldShowNothing) return null;

  return isOpen ? (
    <Box
      ref={ref}
      className={styles.container}
      onClick={clickToStopPropagation}
    >
      {showLoading && <LoadingResults />}
      {showNoResults && <NoResults />}
      {showAddContact && row === 'cc' && <NoResults />}
      {showAddContact && row !== 'cc' && (
        <AddNewContact
          handleAddNewContact={handleAddNewContact}
          input={input}
          type={show}
        />
      )}
      {showSuggestions && (
        <Suggestions
          fetchedSuggestions={results}
          handleSelectSuggestion={onSelect}
        />
      )}
    </Box>
  ) : null;
}

export default SearchResults;
