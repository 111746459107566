import { useState, useRef } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ToolbarButton from '../ToolbarButton';
import { FontSizePicker } from '../pickers';

const DEFAULT_FONT_SIZE = 14;

export default function FontSizeButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  function handleChangeFontSize(value) {
    editor.chain().focus().setFontSize(`${value}px`).run();
    setIsOpen(false);
  }

  const selectionFontSize = editor.getAttributes('textStyle')?.fontSize
    ? editor.getAttributes('textStyle')?.fontSize.split('px')[0]
    : DEFAULT_FONT_SIZE;

  return (
    <Box ref={ref} __css={{ minWidth: 'unset', position: 'relative' }}>
      <ToolbarButton
        onClick={() => setIsOpen((prevState) => !prevState)}
        disabled={!editor.can().chain().focus().setFontSize().run()}
        aria-label="editor-font-size"
      >
        <Box
          as="span"
          __css={{
            color: '#344149',
            fontSize: '0.7rem',
            fontWeight: 'bold',
            lineHeight: '16px',
          }}
        >
          {selectionFontSize}
        </Box>
        <Icon icon="icon-dropdown" fontSize="12px" />
      </ToolbarButton>

      {isOpen && <FontSizePicker onClick={handleChangeFontSize} />}
    </Box>
  );
}
