import { Box, Icon, Text } from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import QuickDisplayService from '@/services/QuickDisplayService';
import { toggleFileData } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import { tokkoModalActions } from '../../TokkoActions/tokkoActions';

export default function File() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showDD, setShowDD] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const contRef = useRef(null);
  const { quickData, id, versionFile, active } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const { edited, data } = quickData;
  const dispatch = useDispatch();
  const Options = [
    { id: 0, text: Trans(translations, 'LastEdition'), key: 'edited' },
    { id: 1, text: Trans(translations, 'Original'), key: 'original' },
  ];

  const [optionSelected, setOptionSelected] = useState(Options[0]);

  const handleOptionsActions = (option) => {
    setOptionSelected(option);
    dispatch(toggleFileData(option.key));

    tokkoModalActions(
      { msg: 'propData', data: { ...data, versionFile: option.key } },
      tokkoBrokerHost
    );
  };

  useEffect(() => {
    if (edited.has_edition) {
      dispatch(toggleFileData(optionSelected.key));
    }
  }, []);

  const goToFile = async () => {
    const dataFile = {
      id,
      edited: versionFile === 'edited' ? 'True' : 'False',
      colleage: 'False',
      isEditable: 'True',
    };
    setDisableClick(true);

    QuickDisplayService.getFichaInfoUrl(dataFile).then((res) => {
      const jwt = localStorage.getItem('jwt').split(' ')[1];
      const isEdited = versionFile === 'edited' ? 'True' : 'False';
      const url = `${res.ficha_info_url}?isEdited=${isEdited}&jwt=${jwt}`;
      window.open(url, '_blank');
      setDisableClick(false);
    });
  };

  return (
    <Box __css={{ position: 'relative' }}>
      <Box
        ref={contRef}
        className={`${style.itemHeader} ${!active ? style.noneEvents : ''}`}
      >
        <Box
          style={{ pointerEvents: disableClick ? 'none' : 'initial' }}
          className={`${style.iconText} ${
            !active ? style.propertyDeleted : ''
          }`}
          onClick={goToFile}
        >
          <Icon icon="icon-editarfichainfo" fontSize="16px" />
          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'File')}
          </Text>
        </Box>

        {edited.has_edition && (
          <Box
            className={`${style.optionCont} ${
              !active ? style.propertyDeleted : ''
            }`}
          >
            <Text variant="body.fontSize12">{optionSelected.text}</Text>
            <Icon
              icon="icon-dropdown"
              fontSize="16px"
              onClick={() => setShowDD(!showDD)}
            />
          </Box>
        )}
      </Box>

      <List
        className={!active ? style.noneEvents : ''}
        open={showDD}
        seter={setShowDD}
        width={`${contRef.current?.offsetWidth}px`}
      >
        {Options.map((option) => (
          <Box
            as="li"
            key={option.text}
            className={style.neutral2}
            onClick={() => handleOptionsActions(option)}
          >
            {option.text}
          </Box>
        ))}
      </List>
    </Box>
  );
}
