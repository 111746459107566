import { useLayoutEffect, useState } from 'react';

export default function getWidthOfElementOnWindowResize(
  idElement,
  initialWidth = 0
) {
  const [width, setWidth] = useState(initialWidth);
  if (!document.getElementById(idElement)) {
    return initialWidth;
  }
  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(document.getElementById(idElement).offsetWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}
