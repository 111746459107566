import ToolbarButton from '../ToolbarButton';
import { BoldIcon } from '../icons';

export default function BoldButton({ editor }) {
  return (
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleBold().run()}
      disabled={!editor.can().chain().focus().toggleBold().run()}
      data-active={editor.isActive('bold')}
      aria-label="editor-bold"
      tabIndex="0"
    >
      <BoldIcon />
    </ToolbarButton>
  );
}
