/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { useMessageInputContext } from 'stream-chat-react';
import { useToggle } from '@oneloop/hooks';
import { Icon, Box } from '@oneloop/jopijs';
import styles from './CustomInput.module.scss';
import SharePropertiesModal from '../ShareProperties/SharePropertiesModal.js';

function AttachmentUploadButton({ onPropertySelect }) {
  const { uploadNewFiles } = useMessageInputContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [open, toggle] = useToggle(false);

  function handleChange(e) {
    const { files } = e.currentTarget;

    if (files && files.length > 0) {
      uploadNewFiles(files);
      e.currentTarget.value = '';
    }
    setIsDropdownOpen(false);
  }

  function handleButtonClick() {
    setIsDropdownOpen((prev) => !prev);
  }

  function handleOptionClick() {
    toggle();
    setIsDropdownOpen(false);
  }

  function handlePropertySelectInternal(property) {
    onPropertySelect(property);
    toggle();
  }

  return (
    <Box styles={{ display: 'flex', position: 'relative' }}>
      <button
        aria-label="Adjuntar"
        type="button"
        onClick={handleButtonClick}
        style={{
          width: '20px',
          display: 'flex',
          position: 'absolute',
          right: '115px',
          bottom: '23px',
          cursor: 'pointer',
          zIndex: '1',
          background: 'none',
          border: 'none',
        }}
      >
        <Icon color="#566A75" icon="icon-adjuntar" fontSize="24px" />
      </button>
      {isDropdownOpen && (
        <Box style={{}} className={styles.dropDownShareProperty}>
          <label type="text" className={styles.dropDownSharePropertyButton}>
            <input
              className={styles.inputFilePicker}
              type="file"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
            <Icon color="#566A75" icon="icon-adjuntar" fontSize="16px" />
            Archivo / Imagen
          </label>
          <button
            aria-label="Adjuntar"
            type="button"
            onClick={() => handleOptionClick('share')}
            className={styles.dropDownSharePropertyButton}
          >
            <Icon color="#566A75" icon="icon-propiedades" fontSize="16px" />
            Propiedad
          </button>
        </Box>
      )}
      {open && (
        <SharePropertiesModal
          toggle={toggle}
          onPropertySelect={handlePropertySelectInternal}
        />
      )}
    </Box>
  );
}

export default AttachmentUploadButton;
