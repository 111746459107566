import store from '@/store/store';

function getAreVisibleErrors() {
  const { addressees, cc, whatsapp } = store.getState().SenderSlice;

  const addresseesInvalid = addressees.selected.some((el) => !el.isValid);
  const ccInvalid = cc.selected.some((el) => !el.isValid);
  const whatsappInvalid = whatsapp.selected.some(
    (el) => !el.isValidated || !el.isValid
  );

  const areVisibleErrors = addresseesInvalid || ccInvalid || whatsappInvalid;

  return areVisibleErrors;
}

export default getAreVisibleErrors;
