import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Tags } from '@oneloop/jopijs';
import { isBefore } from 'date-fns';
import Skeletons from '../Skeletons/Skeletons';
import styles from './calendarEvents.module.scss';
import {
  changerValue,
  resetErrors,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import ToolTip from '../ToolTip/ToolTip';
import translations from './CalendarEvents.trans.json';
import Trans from '@/components/shared/Trans';

export default function TitleModal() {
  const { edit, event } = useSelector((state) => state.CalendarEvents);
  const dispatch = useDispatch();

  if (edit.editLoader) {
    return <Skeletons width={400} />;
  }

  const togglePanel = (btn) => {
    dispatch(
      changerValue({
        block: 'edit',
        key: 'editPanel',
        value: btn === 'cancel',
      })
    );
    dispatch(resetErrors());
  };

  if (edit.editItem) {
    return (
      <Box className={styles.editTitle}>
        {event[0].title}{' '}
        {!edit.done && edit.active && edit.editPanel && (
          <ToolTip title={Trans(translations, 'EditEvent')}>
            <Icon
              icon="icon-editar"
              fontSize="18px"
              onClick={() => togglePanel('save')}
            />
          </ToolTip>
        )}
        {edit.done && edit.active && (
          <Tags noClick variant="success">
            {Trans(translations, 'EventEnded')}
          </Tags>
        )}
        {!edit.active && (
          <Tags noClick variant="dark">
            {Trans(translations, 'DeletedEvent')}
          </Tags>
        )}
        {!edit.done &&
          edit.active &&
          event[0].start &&
          isBefore(event[0].start, new Date()) && (
            <Tags noClick variant="warning">
              {Trans(translations, 'UnfinishedEvent')}
            </Tags>
          )}
      </Box>
    );
  }

  return event[0].title || Trans(translations, 'NewEvent');
}
