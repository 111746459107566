import { Box } from '@oneloop/jopijs';
import style from '../QuickDisplay.module.scss';

export default function UderLineItem({ text, url }) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const openNewTab = () => {
    window.open(`${tokkoBrokerHost}${url}`, '_blank');
  };

  return (
    <Box as="span" className={style.underline} onClick={openNewTab}>
      {text}
    </Box>
  );
}
