import { Box } from '@oneloop/jopijs';
import { useEffect, useRef } from 'react';
import style from '../QuickDisplay.module.scss';
import useOnClickOutside from '@/components/shared/Hooks';

export default function List({
  open = false,
  seter = () => {},
  children,
  width,
  clickable = false,
  ...props
}) {
  const listRef = useRef(null);

  useOnClickOutside(listRef, open, seter);

  const handleScroll = () => {
    seter(false);
  };

  useEffect(() => {
    const modal = document.querySelector('#QuickDisplayBody');
    if (process.env.NODE_ENV !== 'test') {
      modal.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (process.env.NODE_ENV !== 'test') {
        modal.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Box
      {...props}
      ref={listRef}
      as="ul"
      className={`${style.list} ${props.className}`}
      onClick={clickable ? null : () => seter((prev) => !prev)}
      width={width}
    >
      {children}
    </Box>
  );
}
