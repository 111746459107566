import {
  Box,
  ButtonIcon,
  Icon,
  Input,
  Loader,
  Text,
  Wrapped,
} from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import style from './SearchItems.module.scss';
import './searchItems.css';
import useOnClickOutside from '@/components/shared/Hooks';
import translations from './SearchItems.trans.json';
import Trans from '@/components/shared/Trans';

export default function SearchItems({
  children,
  title,
  loading,
  data = false,
  icon,
  deleteItem,
  items = [],
  onChange,
  itemsKeys = { id: 'id', text: 'text' },
  error,
  dismountSearch = () => {},
}) {
  const [showSearch, setShowSearch] = useState(false);
  const { agents, properties, contacts, edit } = useSelector(
    (state) => state.CalendarEvents
  );

  const containerRef = useRef();
  const itemRef = useRef();
  const addBtnRef = useRef();
  const searchRef = useRef();
  const inputSearchRef = useRef();
  const ulRef = useRef();
  const indexList = useRef();

  useOnClickOutside(searchRef, showSearch, setShowSearch);

  const closeSearch = () => {
    setShowSearch(!showSearch);

    if (!showSearch) {
      dismountSearch();
    }
  };

  useEffect(() => {
    indexList.current = -1;
    if (showSearch) {
      inputSearchRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    setShowSearch(false);
  }, [
    agents.agentsSelected,
    properties.propertiesSelected,
    contacts.contactsSelected,
  ]);

  const handleNavegation = (key) => {
    const list = ulRef.current;
    const elements = ulRef.current.children;

    if (indexList.current >= 0) {
      elements[indexList.current].classList.remove('selected');
    }

    const ACTIONS = {
      ArrowDown: () => {
        indexList.current = Math.min(
          elements.length - 1,
          indexList.current + 1
        );
      },
      ArrowUp: () => {
        indexList.current = Math.max(0, indexList.current - 1);
      },
      Enter: () =>
        indexList.current >= 0 && elements[indexList.current].click(),
    };

    ACTIONS[key]();

    if (indexList.current >= 0) {
      list.scrollTop =
        elements[indexList.current].offsetTop -
        elements[indexList.current].offsetHeight;
      elements[indexList.current].classList.add('selected');
    }
  };

  return (
    <Box className={style.container} ref={containerRef}>
      <Box className={style.pplCont} ref={itemRef}>
        {icon}
        <Text className={style.text} variant="bodyBold.fontSize14">
          {title}
        </Text>
        {!edit.editPanel && (
          <Box
            className={`${style.addBtn} ${error && style.error}`}
            placeholder="addBtn"
            ref={addBtnRef}
            onClick={closeSearch}
          >
            <Box>
              <Icon icon="icon-agregar" fontSize="14px" />
            </Box>
          </Box>
        )}
      </Box>
      {itemRef.current && (
        <Wrapped
          key={
            (agents.agentsSelected,
            properties.propertiesSelected,
            contacts.contactsSelected)
          }
          fnClose={deleteItem}
          items={items}
          widthRestItemsWindow={containerRef.current?.offsetWidth}
          style={{
            width: `calc(100% - ${itemRef.current.offsetWidth + 16}px)`,
          }}
          printKey={itemsKeys.text}
          idKey={itemsKeys.id}
          clickFn={itemsKeys.clickFn}
          tagVariant="secondary"
        />
      )}

      {/*  Search */}
      {showSearch && (
        <Box
          className={style.search}
          style={{ left: `${addBtnRef.current.offsetLeft}px` }}
          ref={searchRef}
        >
          <Input
            ref={inputSearchRef}
            className="inputSearch"
            onChange={onChange}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' ||
                e.key === 'ArrowDown' ||
                e.key === 'ArrowUp'
              ) {
                handleNavegation(e.key);
              } else if (e.key === 'Escape') {
                closeSearch();
              }
            }}
            prefix={<Icon icon="icon-buscar" fontSize="14px" />}
            suffix={
              loading ? (
                <Loader
                  variant="spinner"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                  }}
                />
              ) : (
                <ButtonIcon
                  variant={['transparentIcon', 'iconExtraSmall22px']}
                  icon="icon-cerrar"
                  onClick={closeSearch}
                />
              )
            }
            placeholder={Trans(translations, 'Search')}
            variant="inputSearch"
            width={`${
              containerRef.current.offsetWidth -
              (itemRef.current.offsetWidth - 26)
            }px`}
          />
          {data && (
            <Box
              as="ul"
              ref={ulRef}
              className={style.list}
              style={{
                width: `${
                  containerRef.current.offsetWidth -
                  (itemRef.current.offsetWidth - 26)
                }px`,
              }}
            >
              {children}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
