import { Alert, Box, Modal, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import CalendarComponent from './CalendarComponent/CalendarComponent';
import TabsModal from './TabsModal/TabsModal';
import styles from './calendarEvents.module.scss';
import {
  changeInitialTime,
  changerValue,
  fieldsValidation,
  toggleModal,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import {
  createEvent,
  deleteEvent,
  doneOneInstance,
  editEvent,
  editOneInstance,
  eventDone,
  getEvent,
} from '@/store/slices/components/calendarEvents/services';
import TitleModal from './TitleModal';
import BtnsGroup from './BtnsGroup';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { tokkoModalActions } from './tokkoActions';
import OneInstanceModal from './OneInstanceModal';
import UseEventData from './hooks/UseEventData';
import translations from './CalendarEvents.trans.json';
import Trans from '@/components/shared/Trans';
import { setScroll } from '../../store/slices/components/calendarEvents/CalendarEventsSlice';
import Skeletons from '../Skeletons/Skeletons';
import { showToast } from '@/store/slices/components/toast/ToastSlice';

export default function CalendarEvents({ bgTransparent }) {
  const calendarEventsState = useSelector((state) => state.CalendarEvents);
  const {
    event,
    errors,
    edit,
    loading,
    reports,
    exludeDate,
    hasUnsavedChanges,
    showModal,
  } = calendarEventsState;
  const eventData = UseEventData(calendarEventsState);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [createEventClick, setCreateEventClick] = useState(0);
  const [doneEventClick, setDoneEventClick] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [showOI, setShowOI] = useState(false);
  const [confirmModalSettings, setConfirmModalSettings] = useState();
  const [confOI, setConfOI] = useState({
    action: '',
    oneFnc: () => {},
    allFnc: () => {},
  });

  const setTextAlert = () => {
    const errorText = `${Trans(translations, 'FillRequiredInfo')}: `;
    const values = Object.values(errors);
    const concatenatedErrors = errorText + values.join(', ');
    setAlertText(concatenatedErrors);
    setShowAlert(true);
  };

  const tokkoDeleteEvent = () => {
    dispatch(deleteEvent(edit.editItem)).then(() => {
      tokkoModalActions('eventDeleted', tokkoBrokerHost);
      dispatch(showToast({ text: Trans(translations, 'EventDeleted') }));
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setShowAlert(false);
    } else {
      setTextAlert();
    }
  }, [errors]);

  useEffect(() => {
    if (createEventClick) {
      if (Object.keys(errors).length > 0) {
        setTextAlert();
        setCreateEventClick(0);
      } else if (edit.editItem) {
        if (event[0].repeat_in) {
          setShowOI((prev) => !prev);
          setConfOI({
            action: 'change',
            title: Trans(translations, 'EditRecEvent'),
            allFnc: () => {
              dispatch(editEvent({ id: edit.editItem, body: eventData })).then(
                () => {
                  tokkoModalActions('eventEdited', tokkoBrokerHost);
                  dispatch(
                    showToast({ text: Trans(translations, 'EventEdited') })
                  );

                  setShowOI(false);
                }
              );
            },
            oneFnc: () => {
              dispatch(
                editOneInstance({
                  id: edit.editItem,
                  body: {
                    event: { ...eventData },
                    excluded_dates: [exludeDate],
                  },
                })
              ).then(() => {
                tokkoModalActions('eventEdited', tokkoBrokerHost);
                dispatch(
                  showToast({ text: Trans(translations, 'EventEdited') })
                );

                setShowOI(false);
              });
            },
          });
        } else {
          dispatch(editEvent({ id: edit.editItem, body: eventData })).then(
            () => {
              tokkoModalActions('eventEdited', tokkoBrokerHost);
              dispatch(showToast({ text: Trans(translations, 'EventEdited') }));
            }
          );
        }
      } else {
        dispatch(createEvent(eventData)).then(() => {
          tokkoModalActions('eventCreated', tokkoBrokerHost);
          dispatch(showToast({ text: Trans(translations, 'EventCreated') }));
        });
      }
    }
  }, [createEventClick]);

  useEffect(() => {
    if (doneEventClick) {
      if (Object.keys(errors).length > 0) {
        setTextAlert();
        setDoneEventClick(!doneEventClick);
      } else {
        const { text, bodyTemplate, sendReportToContact } = reports;
        const id = edit.editItem;
        const report = {
          report: text,
          body_template: bodyTemplate || '[[reporte]]',
          send_report_to_contact: sendReportToContact,
        };

        if (event[0].repeat_in) {
          dispatch(doneOneInstance({ id, body: eventData })).then(() => {
            tokkoModalActions('eventFinished', tokkoBrokerHost);
            dispatch(showToast({ text: Trans(translations, 'EventFinished') }));
          });
        } else {
          dispatch(eventDone({ id, body: report })).then(() => {
            tokkoModalActions('eventFinished', tokkoBrokerHost);
            dispatch(showToast({ text: Trans(translations, 'EventFinished') }));
          });
        }
      }
    }
  }, [doneEventClick]);

  useEffect(() => {
    if (edit.editPanel) {
      dispatch(getEvent(edit.editItem)).then(() => {
        if (edit.dateInstance) {
          dispatch(changeInitialTime(parseISO(edit.dateInstance)));
          dispatch(setScroll(parseISO(edit.dateInstance)));
        }
      });
    }
  }, [edit.editPanel]);

  useEffect(() => {
    const sing = [
      { key: 'día', text: Trans(translations, 'day') },
      { key: 'sem', text: Trans(translations, 'week') },
      { key: 'mes', text: Trans(translations, 'month') },
      { key: 'año', text: Trans(translations, 'year') },
    ];

    const plural = [
      { key: 'día', text: Trans(translations, 'days') },
      { key: 'sem', text: Trans(translations, 'weeks') },
      { key: 'mes', text: Trans(translations, 'months') },
      { key: 'año', text: Trans(translations, 'years') },
    ];

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'repeatItemsSingular',
        value: sing,
      })
    );

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'repeatItems',
        value: sing,
      })
    );

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'repeatItemsPlural',
        value: plural,
      })
    );

    dispatch(
      changerValue({
        block: 'repeat',
        key: 'valueItems',
        value: { key: 'día', text: Trans(translations, 'day') },
      })
    );
  }, []);

  const handleCreateEvent = () => {
    dispatch(fieldsValidation());
    setCreateEventClick((prev) => prev + 1);
  };

  const handleDeleteEvent = () => {
    if (event[0].repeat_in) {
      setShowOI((prev) => !prev);
      setConfOI({
        ...confOI,
        action: 'delete',
        title: Trans(translations, 'DeleteRecEvent'),
      });
    } else {
      setShowConfirm((prev) => !prev);

      setConfirmModalSettings({
        title: Trans(translations, 'WantDeleteEvent'),
        clickSecondary: () => setShowConfirm((prev) => !prev),
        clickPrimary: tokkoDeleteEvent,
        btnTextSecond: Trans(translations, 'NoGoBack'),
        btnTextPri: Trans(translations, 'YesDelete'),
      });
    }
  };

  const handleDoneEvent = () => {
    dispatch(fieldsValidation('needReport'));
    setDoneEventClick(!doneEventClick);
  };

  const tokkoToggleModal = () => {
    tokkoModalActions('closeModal', tokkoBrokerHost);
    dispatch(toggleModal());
  };

  const exitModal = () => {
    const sonIguales = _.isEqual(eventData, currentEvent);

    if (
      (edit.editItem &&
        !sonIguales &&
        Object.keys(errors).length === 0 &&
        !edit.editPanel) ||
      hasUnsavedChanges
    ) {
      setConfirmModalSettings({
        title: (
          <Box style={{ textAlign: 'center' }}>
            <Text>{Trans(translations, 'WantSaveBeforeExiting')}</Text>
          </Box>
        ),
        clickSecondary: tokkoToggleModal,
        clickPrimary: () => {
          if (event[0].repeat_in) {
            setShowConfirm(false);
            handleCreateEvent();
          } else {
            dispatch(editEvent({ id: edit.editItem, body: eventData })).then(
              () => {
                tokkoModalActions('eventEdited', tokkoBrokerHost);
                dispatch(
                  showToast({ text: Trans(translations, 'EventEdited') })
                );

                setShowConfirm(false);
              }
            );
          }
        },
        btnTextSecond: Trans(translations, 'ExitWithoutsaving'),
        btnTextPri: Trans(translations, 'Save'),
      });
      setShowConfirm((prev) => !prev);
    } else {
      tokkoToggleModal();
    }
  };

  useEffect(() => {
    setCurrentEvent(eventData);
  }, [edit.editPanel]);

  if (!showModal) return null;

  return (
    <>
      {showOI && (
        <OneInstanceModal
          bgTransparent={bgTransparent}
          close={setShowOI}
          load={loading}
          title={confOI.title}
          action={confOI.action}
          oneFnc={confOI.oneFnc}
          allFnc={confOI.allFnc}
        />
      )}
      {showConfirm && (
        <ConfirmModal
          title={confirmModalSettings.title}
          clickSecondary={confirmModalSettings.clickSecondary}
          clickPrimary={confirmModalSettings.clickPrimary}
          btnTextSecond={confirmModalSettings.btnTextSecond}
          btnTextPri={confirmModalSettings.btnTextPri}
          load={loading}
          bgTransparent={bgTransparent}
        />
      )}
      {!showConfirm && !showOI && (
        <Modal
          modalBG
          blockScroll
          width="900px"
          id="CalendarEvents"
          className={styles.modalCalendar}
          closeModal={exitModal}
        >
          <Modal.Header
            closeIcon={exitModal}
            text={<TitleModal handleCreateEvent={handleCreateEvent} />}
            variant={['start', 'l']}
            className={`${styles.header} ${styles.printHeader}`}
          />
          <Modal.Body className={styles.modalBody}>
            <TabsModal />
            {edit.editLoader ? (
              <Skeletons height={384} width={270} borderRadius={12} />
            ) : (
              <CalendarComponent />
            )}
          </Modal.Body>
          <Modal.Footer variant="end">
            {showAlert ? (
              <Alert
                style={{ zIndex: '1' }}
                text={alertText}
                closeAlert={() => setShowAlert(false)}
              />
            ) : (
              <BtnsGroup
                exitModal={exitModal}
                handleCreateEvent={handleCreateEvent}
                handleDeleteEvent={handleDeleteEvent}
                handleDoneEvent={handleDoneEvent}
              />
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
