import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useEffect } from 'react';
import style from '../QuickDisplay.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import { changeId } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';

export default function TitleProperty() {
  const { quickData, isDevelopment, id, active } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const dispatch = useDispatch();
  const { data } = quickData;

  const scrollToBottom = (reset) => {
    const modal = document.querySelector('#QuickDisplayBody');

    modal.scrollTo({
      top: reset ? 0 : modal.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      scrollToBottom(true);
    }
  }, [id]);

  const status = {
    1: 'Appraisal',
    2: 'Available',
    3: 'Reserved',
    4: 'NotAvailable',
  };

  const statusDev = {
    1: 'DataLoading',
    2: 'Commercialization',
    3: 'Archived',
  };

  if (!data) {
    return (
      <Box className={style.titlePropertyContainer}>
        <Box __css={{ display: 'flex', gap: '8px' }}>
          <Skeletons width={83} borderRadius={50} height={16} />
          <Skeletons width={146} borderRadius={50} height={16} />
        </Box>
        <Skeletons width={380} borderRadius={50} height={28} />
        <Box __css={{ display: 'flex', gap: '8px' }}>
          <Skeletons width={410} borderRadius={50} height={16} />
          <Skeletons width={121} borderRadius={50} height={16} />
        </Box>
      </Box>
    );
  }

  const handleBadges = (constructionStatus, deadline) => (
    <Box className={style.badgeCont}>
      <Box
        __css={{ borderColor: '#C1B2F3', backgroundColor: '#EFEBFF' }}
        as="span"
        className={style.badgeDevInfo}
      >
        {constructionStatus}
      </Box>

      {deadline && (
        <Box
          __css={{ borderColor: '#C7D1D7', backgroundColor: '#F2F8FA' }}
          as="span"
          className={style.badgeDevInfo}
        >
          {Trans(translations, 'DeliveredDate')} {deadline}
        </Box>
      )}
    </Box>
  );
  return (
    <Box className={style.titlePropertyContainer}>
      <Text
        className={`${style.availability} ${
          isDevelopment
            ? style[statusDev[data.status]]
            : style[status[data.status]]
        }`}
        variant="bodyBold.fontSize14"
      >
        {!active ? (
          <Box __css={{ opacity: 1 }} className={style.deletedPropertyTag}>
            {Trans(
              translations,
              `${isDevelopment ? 'Development' : 'Property'}Deleted`
            )}
          </Box>
        ) : (
          Trans(
            translations,
            isDevelopment ? statusDev[data.status] : status[data.status]
          )
        )}
        <Box
          className={`${!active ? style.propertyDeleted : ''}`}
          as="span"
        >{`${data.reference} | ${data.type}`}</Box>
      </Text>
      <Box className={`${!active ? style.propertyDeleted : ''}`}>
        <Text className={style.title} variant="bodyBold.fontSize24">
          {isDevelopment ? data.name : data.address}
        </Text>

        <Text className={style.address} variant="body.fontSize14">
          {isDevelopment ? `${data.address} | ${data.location}` : data.location}
          <Box
            className={style.seeMap}
            as="span"
            onClick={() => scrollToBottom(false)}
          >
            <Icon icon="icon-ubicacion" fontSize="16px" />
            <Text variant="bodyBold.fontSize12">
              {Trans(translations, 'ViewMap')}
            </Text>
          </Box>
        </Text>
      </Box>

      {data.development && (
        <Box
          className={`${style.devInfo} ${!active ? style.propertyDeleted : ''}`}
          __css={{ marginTop: '20px' }}
        >
          {!data?.is_from_network && (
            <Box
              className={style.badgeCont}
              __css={{ color: '#1473A9', cursor: 'pointer' }}
              onClick={() =>
                dispatch(
                  changeId({ id: data.development.id, development: true })
                )
              }
            >
              <Text variant="bodyBold.fontSize14">
                {Trans(translations, 'GoToDvelopment')}
              </Text>
              <Icon icon="icon-ir" fontSize="16px" />
            </Box>
          )}

          {handleBadges(
            data.development.construction_status,
            data.development.deadline
          )}
        </Box>
      )}

      {isDevelopment && (
        <Box
          className={`${style.devInfo} ${!active ? style.propertyDeleted : ''}`}
          __css={{ marginTop: '20px', marginLeft: '8px' }}
        >
          {data.web_url && (
            <Box
              className={style.badgeCont}
              __css={{ color: '#566A75', cursor: 'pointer', maxWidth: '470px' }}
              onClick={() => {
                window.open(`${data.web_url}`, '_blank');
              }}
            >
              <Icon icon="icon-sitioweb" fontSize="16px" />
              <Text variant="bodyBold.fontSize12">{data.web_url}</Text>
            </Box>
          )}

          {handleBadges(data.construction_status, data.deadline)}
        </Box>
      )}
    </Box>
  );
}
