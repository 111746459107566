/* eslint-disable react/no-array-index-key */
import { Box, Icon, Image, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { changeId } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';

export default function UnitList() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);
  const dispatch = useDispatch();

  const Operations = {
    Sale: Trans(translations, 'Sale'),
    Rent: Trans(translations, 'Rent'),
    Temporary: Trans(translations, 'Temporary'),
  };

  if (!data) {
    return null;
  }

  const handleChangeID = (id) => dispatch(changeId({ id, development: false }));

  return data.properties.length > 0 ? (
    <Box __css={{ marginTop: '40px' }}>
      <Text className={style.BITitle} variant="bodyBold.fontSize14">
        {data.properties.length} {Trans(translations, 'UnitsAvailable')}
      </Text>
      <Box className={style.unitAtributeCont}>
        {data.properties.map((item) => (
          <Box
            key={item.id}
            className={style.unitAtributeItem}
            onClick={() => handleChangeID(item.id)}
          >
            <Image
              className={style.unitCardImg}
              src={item.image}
              variant="smallCard"
            />

            <Box __css={{ flex: '1' }} className={style.unitsDetails}>
              <Text
                style={{ marginBottom: '2px' }}
                className={style.gray1}
                variant="body.fontSize12"
              >
                {item.ref_code} | {item.type}
              </Text>
              <Text
                className={style.gray1}
                variant={['bodyBold.fontSize14', 'ellipsis']}
              >
                {item.address}
              </Text>

              <Box className={style.unitsAttr} __css={{ marginTop: '6px' }}>
                {item.attributes.map((attr, index) => (
                  <Box key={index} className={style.atrubutesUnitsItem}>
                    <Icon icon={attr.icon} fontSize="16px" />
                    <Text variant={['bodyBold.fontSize12', 'ellipsis']}>
                      {attr.value}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>

            {Object.keys(item.operations).length > 0 && (
              <Box as="span" className={style.separator} />
            )}

            <Box className={style.unitsPriceCont}>
              {Object.keys(item.operations).map((op, index) => (
                <Box
                  key={op + index}
                  __css={{ maxWidth: '130px' }}
                  className={style.pricePropertyItem}
                >
                  <Box as="span" className={style.typeOperationTag}>
                    {Operations[op]}
                  </Box>
                  <Text
                    className={`${style.gray1} ${style.unitsPriceText}`}
                    variant={['body.fontSize14', 'ellipsis']}
                  >
                    {item.operations[op][0]}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
}
