import { Box, Icon, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import Map from '../../map/Map';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import UseResizeWindows from '@/components/shared/Hooks/ResizeWindows';

export default function Location() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);
  const winWidth = UseResizeWindows();

  if (!data) {
    return null;
  }

  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${data.geolocation.lat},${data.geolocation.lng}`;

  const handleLinkClick = () => window.open(googleMapsUrl, '_blank');

  return (
    <Box className={style.locationCont}>
      <Box __css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text className={style.BITitle} variant="bodyBold.fontSize14">
          {Trans(translations, 'Location')}
        </Text>

        <Box
          marginBottom="16px"
          className={style.redirectGoogleMaps}
          onClick={handleLinkClick}
        >
          <Text variant="bodyBold.fontSize14">
            {Trans(translations, 'OpenInGoogleMaps')}
          </Text>
          <Icon icon="icon-ir" fontSize="16px" />
        </Box>
      </Box>

      <Map
        lat={Number(data.geolocation.lat)}
        lng={Number(data.geolocation.lng)}
        width={winWidth >= 1280 ? 804 : 561}
        height={winWidth >= 1280 ? 386 : 310}
      />
    </Box>
  );
}
