import { Box, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from './ReportsTabEdit.module.scss';
import translations from './ReportsTabEdit.trans.json';
import Trans from '@/components/shared/Trans';

export default function ReportsTabEdit() {
  const { reports } = useSelector((state) => state.CalendarEvents);

  const { bodyTemplate, text } = reports;

  return (
    <Box className={style.container}>
      {text && (
        <Box className={style.row}>
          <Text className={style.colorText} variant="bodyBold.fontSize12">
            {Trans(translations, 'FinaleReport')}
          </Text>
          <Text variant="body.fontSize14">{text}</Text>
        </Box>
      )}

      {bodyTemplate !== '[[reporte]]' && (
        <Box className={style.row}>
          <Text className={style.colorText} variant="bodyBold.fontSize12">
            {Trans(translations, 'SendOwnerReport')}
          </Text>
          <Text variant="body.fontSize14">{bodyTemplate}</Text>
        </Box>
      )}
    </Box>
  );
}
