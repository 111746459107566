import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import { setSelectedProperties } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function TogglePropertyButton({ property }) {
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.SenderSlice);
  const { isEditedActive } = property;

  const toggleIsEditedActive = () => {
    const updatedProperties = properties.selected.map((selectedProperty) =>
      selectedProperty.id === property.id
        ? {
            ...selectedProperty,
            isEditedActive: !selectedProperty.isEditedActive,
          }
        : selectedProperty
    );

    dispatch(setSelectedProperties(updatedProperties));
  };

  return (
    <Box
      as="button"
      className={`${styles['data-toggle-wrapper']} ${
        styles[`data-toggle-wrapper--${isEditedActive ? 'edited' : 'original'}`]
      }`}
      onClick={toggleIsEditedActive}
    >
      <Box
        className={`${styles.toggle} ${
          styles[`toggle--${isEditedActive ? 'edited' : 'original'}`]
        }`}
      />
      <Text
        className={`${styles['toggle-button']} ${
          styles['toggle-button__edited']
        } ${
          isEditedActive
            ? styles['toggle-button--active']
            : styles['toggle-button__edited--hide']
        }`}
      >
        {Trans(translations, 'Edited')}
      </Text>
      <Text
        className={`${styles['toggle-button']} ${
          styles['toggle-button__original']
        } ${
          !isEditedActive
            ? styles['toggle-button--active']
            : styles['toggle-button__original--hide']
        }`}
      >
        {Trans(translations, 'Original')}
      </Text>
    </Box>
  );
}

export default TogglePropertyButton;
