/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Icon, Image, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import styles from './SearchItemContact.module.scss';
import translations from './SearchItemContact.trans.json';
import Trans from '@/components/shared/Trans';

function SearchItemContact({ contact }) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  return (
    <Link href={`${tokkoBrokerHost}${contact.link}`}>
      <Box className={styles.item}>
        {contact.image === undefined ||
        contact.image.includes('/img/user.png') ? (
          <Icon
            icon="icon-perfil"
            fontSize="40px"
            className={styles.colorNeutralGray6}
          />
        ) : (
          <Image variant="avatar" alt="logo" src={contact.image} unoptimized />
        )}
        <Box className={styles.containerDescription}>
          <Text
            variant="bodyBold.fontSize14"
            className={styles.colorNeutralGray1}
          >
            {contact.name}
          </Text>
          <Box
            className={`${styles.containerRows} ${styles.colorNeutralGray3}`}
          >
            <Box className={styles.containerRow}>
              <Text variant="body.fontSize11">{contact.description}</Text>
            </Box>
            {/* contact.cel !== undefined && (
            <Box className={styles.containerRow}>
              <Icon icon="icon-whatsapp" fontSize="13px" />
              <Text variant="body.fontSize11">{contact.cel}</Text>
            </Box>
          )}
          {contact.email !== undefined && (
            <Box className={styles.containerRow}>
              <Icon icon="icon-email" fontSize="13px" />
              <Text variant="body.fontSize11">{contact.email}</Text>
            </Box>
          )}
          {contact.tel !== undefined && (
            <Box className={styles.containerRow}>
              <Icon icon="icon-telefono" fontSize="13px" />
              <Text variant="body.fontSize11">{contact.tel}</Text>
            </Box>
          )
          */}
          </Box>
        </Box>
        <Box className={styles.containerEndRow}>
          <Text variant="body.fontSize12" className={styles.colorNeutralGray1}>
            {contact.agent}
          </Text>
          <Text variant="body.fontSize11" className={styles.colorNeutralGray3}>
            {contact.u_date === undefined
              ? Trans(
                  translations,
                  'HeaderSearchItemContact.DateCreation',
                  contact.c_date
                )
              : Trans(
                  translations,
                  'HeaderSearchItemContact.DateCreationAndUpdate',
                  [contact.c_date, contact.u_date]
                )}
          </Text>
        </Box>
      </Box>
    </Link>
  );
}

export default SearchItemContact;
