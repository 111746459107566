import { Box, Text, Wrapped } from '@oneloop/jopijs';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import style from './DetailEventEdit.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';

export default function ItemsComp({ text, items, icon, printKey, clickFn }) {
  const { edit } = useSelector((state) => state.CalendarEvents);
  const contRef = useRef();

  const { editLoader } = edit;

  return (
    <Box className={style.containerItem} ref={contRef}>
      <Box className={style.item}>
        {icon}
        <Text className={style.text} variant="bodyBold.fontSize14">
          {text}
        </Text>
      </Box>
      {editLoader ? (
        <Skeletons width={400} />
      ) : (
        <Wrapped
          items={items}
          style={{
            flexGrow: '1',
          }}
          widthRestItemsWindow={contRef.current?.offsetWidth}
          printKey={printKey}
          idKey="id"
          tagVariant="secondary"
          clickFn={clickFn}
        />
      )}
    </Box>
  );
}
