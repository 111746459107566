/* eslint-disable @next/next/no-img-element */
import { Box } from '@oneloop/jopijs';
import { GoogleMap, LoadScript, Circle, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import style from './Map.module.scss';

function Map({
  lat = 0,
  lng = 0,
  width = 770,
  height = 386,
  fakeAddress = false,
  addresRadious = 250,
}) {
  const [showStaticMap, setShowStaticMap] = useState(true);
  const [circleRadious, setCircleRadious] = useState(250);
  const center = { lat, lng };
  const apiKey = 'AIzaSyAMVr6k7A9mqGsaSfhMWzVacpYj73d7djA';

  useEffect(() => {
    setCircleRadious(
      640 / width >= 1 ? addresRadious : addresRadious * (640 / width)
    );
  }, [width]);

  function GMapCircle(latitud, longitud, rad, detail = 8) {
    const uri = 'https://maps.googleapis.com/maps/api/staticmap?';
    let staticMapSrc = `center=${latitud},${longitud}`;
    staticMapSrc += `&size=${width}x${height}&zoom=15&scale=1&key=${apiKey}`;
    staticMapSrc += '&path=fillcolor:0xff00002D|color:0xf96332ff|weight:3';

    const r = 6371;
    const pi = Math.PI;
    const newLat = (latitud * pi) / 180;
    const newLng = (longitud * pi) / 180;
    const d = rad / 1000 / r;

    for (let i = 0; i <= 360; i += detail) {
      const brng = (i * pi) / 180;
      let pLat = Math.asin(
        Math.sin(newLat) * Math.cos(d) +
          Math.cos(newLat) * Math.sin(d) * Math.cos(brng)
      );
      const pLng =
        ((newLng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(newLat),
            Math.cos(d) - Math.sin(newLat) * Math.sin(pLat)
          )) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;
      staticMapSrc += `|${pLat},${pLng}`;
    }
    return uri + encodeURI(staticMapSrc);
  }
  const urlStaticMapCircle = GMapCircle(center.lat, center.lng, circleRadious);
  const urlStaticMapMarker = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&scale=2&markers=color:red%size:tiny%7C${lat},${lng}&key=${apiKey}&zoom=15&size=${width}x${height}`;

  return (
    <Box
      __css={{
        display: 'flex',
        justifyContent: 'center',
        width,
        maxWidth: { width },
        overflow: 'hidden',
        height: `${height}px`,
        background: '#F3F6F8',
      }}
    >
      {showStaticMap ? (
        <Box
          onClick={() => setShowStaticMap(false)}
          __css={{
            cursor: 'pointer',
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            className={style.imgMap}
            id="static-map"
            src={fakeAddress ? urlStaticMapCircle : urlStaticMapMarker}
            alt="Mapa"
          />
        </Box>
      ) : (
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={{
              height,
              width,
            }}
            center={center}
            zoom={15}
            id="mapAPI"
          >
            {fakeAddress ? (
              <Circle
                center={center}
                radius={addresRadious}
                options={{
                  strokeColor: '#f96332',
                  fillColor: '#f96332',
                  strokeWidth: 1,
                }}
              />
            ) : (
              <Marker position={center} />
            )}
          </GoogleMap>
        </LoadScript>
      )}
    </Box>
  );
}

export default Map;
