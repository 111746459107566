import { Box } from '@oneloop/jopijs';
import Suggestion from './Suggestion';
import styles from './styles.module.scss';

function Suggestions({ fetchedSuggestions, handleSelectSuggestion }) {
  return (
    <Box className={styles.container}>
      <Box
        className={styles['scrollbar-wrapper']}
        aria-label="addresees-suggestions"
      >
        {fetchedSuggestions.map((suggestion) => (
          <Suggestion
            key={suggestion.id}
            suggestion={suggestion}
            handleSelectSuggestion={handleSelectSuggestion}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Suggestions;
