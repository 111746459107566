import { Box, ButtonIcon, Drawer } from '@oneloop/jopijs';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUserPreferencesState,
  setRightbarCollapseState,
} from '@/store/slices/UserPreferencesSlice';
import styles from './Rightbar.module.scss';
import Actions from './Actions/Actions';
import Favorites from './Favorites/Favorites';
import Calendar from './Calendar/Calendar';
import UserService from '@/services/UserService';

function Rightbar() {
  const dispatch = useDispatch();
  const { rightbarCollapse } = useSelector(selectUserPreferencesState);

  const updateCollapse = () => {
    const requestData = {
      user_config_key: 'rightbar_collapse',
      user_config_value: rightbarCollapse ? '0' : '1',
      user_config_type: 'boolean',
    };
    UserService.postConfigSetting(requestData);
    dispatch(setRightbarCollapseState(!rightbarCollapse));
  };
  const actionsData = [];

  return (
    <div
      className={
        rightbarCollapse ? styles.contDrawer : styles.contDrawerCollapse
      }
    >
      {rightbarCollapse && (
        <Box className={styles.overlay} onClick={() => updateCollapse()} />
      )}
      <Drawer
        isCollapse={rightbarCollapse}
        screenSide="right"
        animationWidth={262}
        animationMinWidth={70}
        overlay={false}
        heightMin={actionsData && actionsData.length > 0 ? '147px' : '102px'}
        transparent
        borderRadiusClosed="5px"
        marginTop="76px"
        heightMax={102}
      >
        <ButtonIcon
          onClick={updateCollapse}
          isRounded
          icon="icon-atras"
          variant={
            !rightbarCollapse ? 'collapseButtonOpen' : 'collapseButtonClosed'
          }
          className={styles.buttonIcon}
          style={{ position: 'absolute' }}
        />
        <Drawer.Body>
          {rightbarCollapse ? (
            <Box className={styles.containerMenus}>
              {actionsData && actionsData.length > 0 && (
                <Actions actionsData={actionsData} />
              )}
              <Calendar />
              <Favorites />
            </Box>
          ) : (
            <Box className={styles.contButtonIcons}>
              {actionsData && actionsData.length > 0 && (
                <ButtonIcon
                  icon="icon-acciones"
                  variant={['subtleIconButton', 'iconSmall']}
                  onClick={updateCollapse}
                />
              )}
              <ButtonIcon
                icon="icon-calendario"
                variant="mainButtonIcon"
                onClick={updateCollapse}
                className={styles.contButtonIcon}
              />
              <ButtonIcon
                icon="icon-favoritos"
                variant="mainButtonIcon"
                onClick={updateCollapse}
                className={styles.contButtonIcon}
              />
            </Box>
          )}
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Rightbar;
