import { Box, Icon } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import style from './Checkbox.module.scss';

export default function Checkbox({
  checked,
  iconType = 'icon-aceptar',
  ...props
}) {
  const { className, onClick, ...rest } = props;
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <Box
      className={`${style.container} ${check ? style.checked : ''} ${
        className || ''
      }`}
      onClick={() => setCheck((prev) => !prev)}
      {...rest}
    >
      {check && <Icon icon={iconType} fontSize="12px" />}
    </Box>
  );
}
