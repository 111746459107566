import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useGetDataUser(channel) {
  const { id: userID } = useSelector((state) => state.UserSlice.data);
  const [user, setUser] = useState();

  useEffect(() => {
    const otherMember = Object.values(channel.state.members).find(
      (member) => Number(member?.user?.id) !== userID
    );

    setUser(otherMember?.user);
  }, [channel]);

  return user;
}
