import React from 'react';
import { Box, Icon, Image, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import styles from './SearchItemAgent.module.scss';

function SearchItemAgent({ agent }) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  return (
    <Link href={`${tokkoBrokerHost}${agent.link}`}>
      <Box className={styles.item}>
        {agent.image === undefined || agent.image.includes('/img/user.png') ? (
          <Icon
            icon="icon-perfil"
            fontSize="40px"
            className={styles.colorNeutralGray6}
          />
        ) : (
          <Image variant="avatar" alt="logo" src={agent.image} unoptimized />
        )}
        <Box className={styles.column}>
          <Box className={styles.row}>
            <Text
              variant="bodyBold.fontSize14"
              className={styles.colorNeutralGray1}
            >
              {agent.name}
            </Text>
            {/*
            <Badge variant={['badgeInlineSmall', 'badgeRounded', 'inlineInfoSubtle']} >
              {agent.type === 'contact' ? 'Contacto' : 'Empresa'}
            </Badge>
          */}
          </Box>
          <Box className={`${styles.row} ${styles.colorNeutralGray3}`}>
            {agent.description !== undefined && (
              <Box className={styles.rowData}>
                <Text variant="body.fontSize11">{agent.description}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
}

export default SearchItemAgent;
