import { Box } from '@oneloop/jopijs';
import Skeletons from '@/components/Skeletons/Skeletons';

export default function SkeletonFavorite() {
  return (
    <Box __css={{ overflow: 'hidden', height: '214px' }}>
      <Skeletons width={224} height={34} borderRadius={10} />
      <Skeletons count={8} width={224} height={64} borderRadius={10} />
    </Box>
  );
}
