/* eslint-disable react/no-array-index-key */
import { Box, Carousel, Icon, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import DescriptionBox from '@/components/descriptionBox/DescriptionBox';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';

export default function DevelopmentInfo() {
  const { active, quickData } = useSelector((state) => state.QuickDisplaySlice);
  const { development } = quickData.data;

  const { description, pictures, videos, name, web } = development;

  const handleCoverImg = (type) => {
    if (!pictures.front_cover_image) {
      return false;
    }

    if (type === 'img') {
      return (
        !pictures.front_cover_image.is_blueprint &&
        pictures.front_cover_image.url
      );
    }

    return (
      pictures.front_cover_image.is_blueprint && pictures.front_cover_image.url
    );
  };

  return (
    <Box
      className={`${style.cardCont} ${!active ? style.propertyDeleted : ''}`}
    >
      <Box>
        <Text className={style.gray1} variant="bodyBold.fontSize14">
          {Trans(translations, 'DevelopmentInformation')}
        </Text>

        <Text className={style.devInfoName}>{name}</Text>

        {web && (
          <Box
            __css={{ cursor: 'pointer' }}
            className={`${style.neutral2} ${style.iconText}`}
            onClick={() => {
              window.open(`${web}`, '_blank');
            }}
          >
            <Icon icon="icon-sitioweb" fontSize="16px" />
            <Text
              style={{ flex: 1 }}
              variant={['bodyBold.fontSize14', 'ellipsis']}
            >
              {web}
            </Text>
          </Box>
        )}
      </Box>

      {description && (
        <Box className={style.bItem} __css={{ marginTop: 24 }}>
          <Text className={style.BITitle} variant="bodyBold.fontSize14">
            {Trans(translations, 'Description')}
          </Text>
          <DescriptionBox text={description} lines={11} />
        </Box>
      )}

      {Boolean(development.internal_comments) && (
        <Box __css={{ marginTop: '24px' }} className={style.comment}>
          <Text className={style.internalText} variant="bodyBold.fontSize14">
            {Trans(translations, 'InternalCommentsFromTheDevelopment')}
          </Text>
          <Box>
            {development.internal_comments.split(/\n/).map((line, index) =>
              line ? (
                <Text
                  key={`line${index}`}
                  className={style.neutral2}
                  variant="body.fontSize14"
                >
                  {line}
                </Text>
              ) : (
                <br key={`line${index}`} />
              )
            )}
          </Box>
        </Box>
      )}

      {(pictures.images.length > 0 ||
        pictures.blueprints.length > 0 ||
        pictures.front_cover_image?.url) && (
        <Carousel
          frontCoverImg={handleCoverImg('img')}
          frontCoverBlueprints={handleCoverImg('blueprint')}
          images={pictures.images}
          planos={pictures.blueprints}
          video={videos.normal}
          video360={videos['360']}
          otherComponent={
            <Box
              className={style.exportButton}
              __css={{
                width: 'fit-content',
                padding: '6px 12px',
                marginTop: 24,
              }}
            >
              <Text variant="bodyBold.fontSize12">
                {Trans(translations, 'ViewPhotos')}
              </Text>
            </Box>
          }
        />
      )}
    </Box>
  );
}
