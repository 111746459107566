import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ButtonIcon, Icon, Input, Loader } from '@oneloop/jopijs';
import { getProperties } from '@/store/slices/components/sender/services';
import {
  setPropertiesData,
  setShowStarred,
} from '@/store/slices/components/sender/SenderSlice';
import SuggestedProperties from '../SuggestedProperties';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function PropertiesSearch({
  showButton,
  handleShowButton,
  handleClickButton,
  showInput,
}) {
  const dispatch = useDispatch();
  const inputWrapperRef = useRef(null);
  const { addressees, whatsapp, properties, warning } = useSelector(
    (state) => state.SenderSlice
  );
  const [focusInput, setFocusInput] = useState(false);
  const isWarning = warning && warning.fields.includes('properties');

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputWrapperRef.current &&
        !inputWrapperRef.current.contains(event.target) &&
        !properties.input
      ) {
        handleShowButton();
        dispatch(setPropertiesData({ key: 'input', value: '' }));
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputWrapperRef, properties.input, properties.selected.length]);

  useEffect(() => {
    const addresseesWithStarred = addressees.selected.filter(
      (addressee) => addressee.starred?.length > 0
    );

    const whatsappWithStarred = whatsapp.selected.filter(
      (addressee) => addressee.starred?.length > 0
    );

    const showAddresseesStarred =
      addresseesWithStarred.length > 0 && addressees.selected.length <= 2;
    const showWhatsappStarred =
      whatsappWithStarred.length > 0 && whatsapp.selected.length === 1;

    if (
      showInput &&
      focusInput &&
      !properties.input &&
      (showAddresseesStarred || showWhatsappStarred)
    ) {
      dispatch(setShowStarred(true));
    }
  }, [
    showInput,
    focusInput,
    properties.input,
    addressees.selected,
    whatsapp.selected,
  ]);

  let debounceTimer;
  const handleChange = useCallback((event) => {
    const { value } = event.target;
    dispatch(setPropertiesData({ key: 'input', value }));
    const clearQuery = value.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        dispatch(getProperties(clearQuery));
      }, 1000);
    }
  }, []);

  const cleanUpText = () => {
    dispatch(setPropertiesData({ key: 'input', value: '' }));
  };

  if (!showButton && !showInput) return null;

  return (
    <Box
      className={styles.container}
      data-inputactive={showInput}
      id="properties-search-container"
    >
      {showButton && (
        <ButtonIcon
          data-testid="properties-search-main-button"
          text={Trans(translations, 'PropertiesOrDevelopments')}
          icon="icon-agregar"
          onClick={handleClickButton}
          className={`${styles['search-button']} ${
            isWarning ? styles['search-button--highlight'] : ''
          }`}
          variant={['secondaryOutlined', 'large']}
        />
      )}

      {showInput && (
        <Box ref={inputWrapperRef}>
          <Input
            id="properties-search-input"
            data-testid="properties-search-input"
            className={styles['search-input']}
            autoComplete="off"
            prefix={<Icon icon="icon-buscar" fontSize="16px" />}
            placeholder={Trans(translations, 'SearchForPropOrDevs')}
            variant="inputSearchOutlined"
            width="100%"
            onFocus={() => setFocusInput(true)}
            onBlur={() => setFocusInput(false)}
            onChange={handleChange}
            value={properties.input}
            autoFocus
            suffix={
              // eslint-disable-next-line
              properties.isLoading ? (
                <Loader
                  variant="spinner"
                  style={{
                    margin: 0,
                    width: '16px',
                    height: '16px',
                    backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                  }}
                />
              ) : properties.input !== '' ? (
                <ButtonIcon
                  variant={['transparentIcon', 'iconExtraSmall22px']}
                  icon="icon-cerrar"
                  onClick={cleanUpText}
                  className={styles['search-cross-icon']}
                />
              ) : undefined
            }
          />
          <SuggestedProperties />
        </Box>
      )}
    </Box>
  );
}

export default PropertiesSearch;
