import { Box } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function ToastRedirectLink() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const href = `${tokkoBrokerHost}/options`;

  return (
    <Box as="a" className={styles.reconnect} href={href} target="_blank">
      {Trans(translations, 'Reconnect')}
    </Box>
  );
}

export default ToastRedirectLink;
