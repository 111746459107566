import { useMemo } from 'react';
import { Box } from '@oneloop/jopijs';
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  JustifyIcon,
} from '../../icons';
import styles from '../styles.module.scss';

const alignments = ['left', 'center', 'right', 'justify'];

const ICONS = {
  left: <AlignLeftIcon />,
  center: <AlignCenterIcon />,
  right: <AlignRightIcon />,
  justify: <JustifyIcon />,
};

export default function AlignmentPicker({ onClick }) {
  const handleOnClick = (event) => {
    if (!onClick) return;

    const alignment = event.target.dataset.value;
    if (alignment) {
      onClick(alignment);
    }
  };

  const renderAlignmentButton = (alignment, onClickHandler) => (
    <Box
      key={alignment}
      as="button"
      className={styles['dropdown-item']}
      onClick={onClickHandler}
      data-event="backAlignment"
      data-value={alignment}
    >
      <Box as="span" style={{ pointerEvents: 'none' }}>
        {ICONS[alignment]}
      </Box>
    </Box>
  );

  const renderAlignments = useMemo(() => {
    const dropdown = [];
    for (let i = 0; i < alignments.length; i++) {
      const alignment = alignments[i];
      dropdown.push(renderAlignmentButton(alignment, handleOnClick));
    }

    return dropdown;
  }, [alignments]);

  return (
    <Box className={styles['dropdown-wrapper']} data-testid="alignment-picker">
      <Box className={styles.dropdown}>{renderAlignments}</Box>
    </Box>
  );
}
