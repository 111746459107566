import ApiService from '@/services/ApiService';

class SearchService {
  static async getSearch(text, filter, contResults) {
    const c = filter === '' ? '' : `&c=${filter}`;
    const composedURL = `/api3/search?searchword=${text}${c}&cont_results=${contResults}`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default SearchService;
