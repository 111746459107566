import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import {
  setSelectedWhatsApp,
  setWhatsappData,
} from '@/store/slices/components/sender/SenderSlice';
import {
  getContactsWhatsapp,
  validateWhatsapp,
} from '@/store/slices/components/sender/services';
import InputForContact from '../InputForContact';
import SearchResults from '../SearchResults';
import Tag from '../Tag';
import createContact from '../../utils/createContact';
import WhatsappForContact from '../SearchResults/NewContact/WhatsappForContact';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from '../styles.module.scss';

let debounceTimer;
let searchingSignal;

function WhatsappTo() {
  const dispatch = useDispatch();
  const { whatsapp, preSelectedContacts, warning } = useSelector(
    (state) => state.SenderSlice
  );
  const selectedWrapperRef = useRef(null);
  const [isValidating, setIsValidating] = useState(false);
  const contact = whatsapp.selected[0];
  const isWarning = warning && warning.fields.includes('contact');
  const showInvalidWhatsapp =
    contact && contact.isValidated && contact.isRaw && !contact.isValid;
  const showAddWhatsapp =
    contact &&
    !contact.isRaw &&
    (!contact.cellphone || (contact.isValidated && !contact.isValid));

  const cancelPendentOperations = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      dispatch(setWhatsappData({ key: 'fetchedSuggestions', value: null }));
    }

    if (searchingSignal) {
      searchingSignal.abort();
    }
  };

  const addRawContact = () => {
    if (isValidating) return;
    cancelPendentOperations();

    try {
      setIsValidating(true);

      const rawWhatsApp = createContact.raw.whatsapp(whatsapp.input, {
        isValidated: false,
      });
      const updatedSelectedWhatsapp = [...whatsapp.selected, rawWhatsApp];
      dispatch(setSelectedWhatsApp(updatedSelectedWhatsapp));
    } catch (error) {
      console.log(error);
    } finally {
      setIsValidating(false);
    }
  };

  useEffect(() => {
    const addRemainingContact = (event) => {
      if (event.target.classList.contains('.sender-send-button')) {
        cancelPendentOperations();
        return;
      }

      if (
        selectedWrapperRef.current &&
        !selectedWrapperRef.current.contains(event.target)
      ) {
        if (whatsapp.input) {
          addRawContact();
        }
      }
    };

    document.addEventListener('click', addRemainingContact);

    return () => {
      document.removeEventListener('click', addRemainingContact);
    };
  }, [whatsapp.input]);

  useEffect(() => {
    const addressee = whatsapp.selected[0];
    if (!addressee) return;

    if (!addressee.cellphone) {
      const updatedSelected = whatsapp.selected.map((selected) =>
        String(addressee.id) === String(selected.id)
          ? { ...addressee, isValid: false, isValidated: true }
          : selected
      );
      dispatch(setSelectedWhatsApp(updatedSelected));
      return;
    }

    if (!addressee.isValidated) {
      dispatch(validateWhatsapp(addressee.cellphone));
    }
  }, [whatsapp.selected.length]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    dispatch(setWhatsappData({ key: 'input', value }));
    const clearQuery = value.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        console.log();
        searchingSignal = dispatch(getContactsWhatsapp(value));
      }, 1000);
    } else {
      dispatch(setWhatsappData({ key: 'fetchedSuggestions', value: null }));
    }
  }, []);

  const handleSelectSuggestion = (newSelected) => {
    const selectedContact = createContact.suggestion.whatsapp(newSelected);
    const updatedSelected = [...whatsapp.selected, selectedContact];
    dispatch(setSelectedWhatsApp(updatedSelected));
  };

  const handleRemoveContact = (contactToRemove) => {
    const updatedSelected = whatsapp.selected.filter(
      (element) => element.id === contactToRemove.id
    );
    dispatch(setSelectedWhatsApp(updatedSelected));
  };

  const handleAddNewContact = (newContactCreated) => {
    const parsedContact = createContact.new(newContactCreated);
    const updatedSelected = [...whatsapp.selected, parsedContact];
    dispatch(setSelectedWhatsApp(updatedSelected));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    addRawContact();
  };

  const handleAddWhatsapp = (contactModified) => {
    const updatedSelected = whatsapp.selected.map((selected) =>
      String(selected.id) === String(contactModified.id)
        ? {
            ...selected,
            cellphone: contactModified.cellphone,
            isValidated: true,
            isValid: true,
          }
        : selected
    );

    dispatch(setSelectedWhatsApp(updatedSelected));
  };

  const setSelected = (newValue) => {
    dispatch(setWhatsappData(newValue));
  };

  return (
    <Box className={styles.container}>
      <Box
        id="sender-whatsapp-contacts"
        className={`${styles.row} ${
          isWarning ? styles['row--warning-highlight'] : ''
        }`}
      >
        <Text className={styles['row-label']}>{Trans(translations, 'To')}</Text>
        <Box ref={selectedWrapperRef} className={styles['content-wrapper']}>
          {preSelectedContacts.length === 0 && (
            <>
              {whatsapp.selected.map((selected) => (
                <Tag
                  key={selected.id}
                  tooltipContainerId="sender-whatsapp-contacts"
                  handleRemove={handleRemoveContact}
                  contact={selected}
                  selected={whatsapp.selected}
                  setSelected={setSelected}
                />
              ))}

              {whatsapp.selected.length === 0 && (
                <Box as="form" className={styles.form} onSubmit={handleSubmit}>
                  <InputForContact
                    className={styles.input}
                    value={whatsapp.input}
                    onChange={handleChange}
                    testId="whatsapp-input"
                  />
                </Box>
              )}
            </>
          )}

          {preSelectedContacts.length > 0 && (
            <>
              <Box
                data-testid="sender-contact-skeleton"
                className={styles.skeleton}
              />
              <Box
                data-testid="sender-contact-skeleton"
                className={styles.skeleton}
              />
            </>
          )}
        </Box>

        <SearchResults
          row="whatsapp"
          onSelect={handleSelectSuggestion}
          results={whatsapp.fetchedSuggestions}
          isLoading={whatsapp.isLoading}
          input={whatsapp.input}
          handleAddNewContact={handleAddNewContact}
        />

        {showAddWhatsapp && (
          <Box className={styles['add-whatsapp-wrapper']}>
            <WhatsappForContact
              contact={contact}
              handleAddWhatsapp={handleAddWhatsapp}
            />
          </Box>
        )}
      </Box>

      {showInvalidWhatsapp && (
        <Text className={styles['whatsapp-input-error']}>
          {Trans(translations, 'YouMustAddTheCodeAreaAndCountry')}
        </Text>
      )}
    </Box>
  );
}

export default WhatsappTo;
