import { Box, Image, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import ImageNext from '@/components/shared/ImageNext';
import logoTokko from '../../../public/images/quickDisplay/logoTokko.svg';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import ChatButton from './ChatButton';

export default function TokkoBrokerNetwork() {
  const { active, quickData } = useSelector((state) => state.QuickDisplaySlice);
  const { network_information } = quickData.data;

  const {
    logo,
    company_name,
    branch_name,
    network_share,
    branch_email,
    branch_phones,
    contact_time,
    total_tokko_network_props,
    information,
  } = network_information;

  return (
    <Box
      className={`${style.cardCont} ${!active ? style.propertyDeleted : ''}`}
    >
      <Box className={style.networkHeader} __css={{ marginBottom: '8px' }}>
        <ImageNext alt="logo" src={logoTokko} unoptimized />
        <Box>
          <Text className={style.gray1} variant="bodyBold.fontSize14">
            {Trans(translations, 'TokkoBrokerNetwork')}
          </Text>
          <Text className={style.neutral2} variant="body.fontSize12">
            {total_tokko_network_props} {Trans(translations, 'properties')}
          </Text>
        </Box>
      </Box>

      <Box className={style.networkBody}>
        <Box className={style.networkHeader}>
          <Image src={logo} variant="smallCard" className={style.devImg} />
          <Box>
            <Text className={style.gray1} variant="bodyBold.fontSize14">
              {company_name}
            </Text>
            <Text className={style.neutral2} variant="body.fontSize12">
              {branch_name}
            </Text>
          </Box>
        </Box>

        <hr />

        {information && (
          <Box className={style.bItem}>
            <Text className={style.gray1} variant="bodyBold.fontSize14">
              {Trans(translations, 'NetworkInformation')}
            </Text>
            <Text className={style.neutral2} variant="body.fontSize12">
              {information}
            </Text>
          </Box>
        )}

        <Box as="span" className={style.networkCommision}>
          {Trans(translations, 'Commission')} {network_share}%
        </Box>

        <Box className={style.networkContactData}>
          <Text variant="body.fontSize12">{branch_email}</Text>
          <Text variant="body.fontSize12">{branch_phones}</Text>
          <Text variant="body.fontSize12">{contact_time}</Text>
        </Box>
      </Box>

      <ChatButton />
    </Box>
  );
}
