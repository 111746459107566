import { Box } from '@oneloop/jopijs';
import { useMemo } from 'react';
import styles from '../styles.module.scss';

const fontSizes = ['14', '16', '24', '36'];

export default function FontSizePicker({ onClick }) {
  const handleOnClick = (event) => {
    if (!onClick) return;

    const fontSize = event.target.dataset.value;
    if (fontSize) {
      onClick(fontSize);
    }
  };

  const renderFontSizeButton = (fontSize, onClickHandler) => (
    <Box
      key={fontSize}
      as="button"
      className={styles['dropdown-item']}
      onClick={onClickHandler}
      data-event="backFontSize"
      data-value={fontSize}
    >
      {fontSize}
    </Box>
  );

  const renderDropdownItems = useMemo(() => {
    const dropdown = [];
    for (let i = 0; i < fontSizes.length; i++) {
      const fontSize = fontSizes[i];
      dropdown.push(renderFontSizeButton(fontSize, handleOnClick));
    }

    return dropdown;
  }, [fontSizes]);

  return (
    <Box className={styles['dropdown-wrapper']} data-testid="font-size-picker">
      <Box className={styles.dropdown}>{renderDropdownItems}</Box>
    </Box>
  );
}
