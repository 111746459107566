import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import SearchItems from '../SearchItems/SearchItems';
import {
  addItem,
  changerValue,
  removeItem,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import style from './AgentsData.module.scss';
import translations from './AgentsData.trans.json';
import Trans from '@/components/shared/Trans';

export default function AgentsData({ handleAutoComplete }) {
  const { agents, errors } = useSelector((state) => state.CalendarEvents);
  const { agentsData, loading, agentsSelected } = agents;
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [cAgents, setCAgents] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // add agents clickfn
    const nuevosAgentes = agentsSelected.map((item) => ({
      ...item,
      clickFn: () => window.open(`${tokkoBrokerHost}/user/${item.id}/details`),
    }));

    setCAgents(nuevosAgentes);
  }, [agentsSelected]);

  return (
    <SearchItems
      title={Trans(translations, 'Agents')}
      error={errors?.agents}
      loading={loading}
      icon={<Icon icon="icon-agente" fontSize="18px" />}
      items={cAgents}
      itemsKeys={{ id: 'id', text: 'full_name', clickFn: 'clickFn' }}
      onChange={(e) => handleAutoComplete(e.target.value, 'agents')}
      data={agentsData.length !== 0}
      deleteItem={(i) =>
        dispatch(
          removeItem({
            block: 'agents',
            key: 'agentsSelected',
            value: i.id,
          })
        )
      }
      dismountSearch={() =>
        dispatch(
          changerValue({
            block: 'agents',
            key: 'agentsData',
            value: [],
          })
        )
      }
    >
      {agentsData.map((item) => {
        if (item.id) {
          return (
            <Box
              as="li"
              className={style.contItem}
              key={item.full_name + item.id}
              onClick={() => {
                dispatch(
                  addItem({
                    block: 'agents',
                    key: 'agentsSelected',
                    value: item,
                  })
                );
              }}
            >
              <Text variant="body.fontSize16">{item.full_name}</Text>
            </Box>
          );
        }
        return (
          <Box as="li" className={style.contItem}>
            <Text variant="body.fontSize14" className={style.noDataColor}>
              {Trans(translations, item.noData)}
            </Text>
          </Box>
        );
      })}
    </SearchItems>
  );
}
