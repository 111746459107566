import { useState, useRef } from 'react';
import { Box, Text, Icon, Loader } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import FilesDropdown from '../FilesDropdown';
import styles from './styles.module.scss';

function FilesButton({ property }) {
  const filesRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useOnClickOutside(filesRef, isDropdownOpen, setIsDropdownOpen);
  const { files, developmentFiles, isLoadingFiles } = property;

  const activeFiles =
    files && developmentFiles
      ? [...files, ...developmentFiles].filter((file) => file.active)
      : [];

  return (
    <Box ref={filesRef} className={styles['files-outer-wrapper']}>
      <Box
        as="button"
        className={`${styles['files-inner-wrapper']} ${
          property.files?.length > 0 || property.developmentFiles?.length > 0
            ? 'property-files-button'
            : ''
        }`}
        disabled={
          !files ||
          !developmentFiles ||
          (property.files.length === 0 &&
            property.developmentFiles.length === 0)
        }
        onClick={() => setIsDropdownOpen((prevState) => !prevState)}
      >
        {isLoadingFiles && (
          <Loader
            variant="spinner"
            style={{
              margin: 0,
              width: '12px',
              height: '12px',
              backgroundImage: 'conic-gradient(#878C9B ,#FFFFFF)',
            }}
          />
        )}

        {!isLoadingFiles &&
          ((files && files.length > 0) ||
            (developmentFiles && developmentFiles.length > 0)) && (
            <>
              <Icon icon="icon-adjuntar" fontSize="12px" color="#344149" />
              {activeFiles.length > 0 && (
                <Text className={styles['files-text']}>
                  {activeFiles.length}
                </Text>
              )}
            </>
          )}
      </Box>
      {isDropdownOpen && <FilesDropdown property={property} />}
    </Box>
  );
}

export default FilesButton;
