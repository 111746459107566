import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import {
  getStarredForEmail,
  getStarredForWhatsapp,
} from '@/store/slices/components/sender/services';
import SuggestionPropertyCard from '../PropertyCard/variants/SuggestionPropertyCard';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function StarredProperties() {
  const dispatch = useDispatch();
  const { show, addressees, whatsapp, properties } = useSelector(
    (state) => state.SenderSlice
  );
  const addresseesWithStarred = addressees.selected.filter(
    (addressee) => addressee.starred?.length > 0
  );
  const whatsappWithStarred = whatsapp.selected.filter(
    (addressee) => addressee.starred?.length > 0
  );

  useEffect(() => {
    if (addressees.selected.length > 2) return;

    for (let i = 0; i < addressees.selected.length; i++) {
      const addressee = addressees.selected[i];
      if (!addressee.starred && !addressee.isRaw)
        dispatch(getStarredForEmail(addressee.id));
    }
  }, [addressees.selected.length]);

  useEffect(() => {
    // should be always one addressee at least on whatsapp sender
    for (let i = 0; i < whatsapp.selected.length; i++) {
      const addressee = whatsapp.selected[i];
      if (!addressee.starred && !addressee.isRaw)
        dispatch(getStarredForWhatsapp(addressee.id));
    }
  }, [whatsapp.selected.length]);

  if (!properties.showStarred) return null;

  return (
    <>
      {show === 'email' &&
        addresseesWithStarred.map((addressee) => (
          <Box key={addressee.id}>
            <Text className={styles.title}>
              {Trans(translations, 'StarredOf')} {addressee.name}
            </Text>
            {addressee.starred.map((property) => (
              <SuggestionPropertyCard key={property.id} property={property} />
            ))}
          </Box>
        ))}

      {show === 'whatsapp' &&
        whatsappWithStarred.map((addressee) => (
          <Box key={addressee.id}>
            <Text className={styles.title}>
              {Trans(translations, 'StarredOf')} {addressee.name}
            </Text>
            {addressee.starred.map((property) => (
              <SuggestionPropertyCard key={property.id} property={property} />
            ))}
          </Box>
        ))}
    </>
  );
}

export default StarredProperties;
