import { Box, ButtonIcon, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import styles from './RightbarChat.module.scss';
import { showRightbar } from '@/store/slices/components/chat/MessengerSlice';
import AsignInquiry from '../Asign/AsignInquiry';

export default function RightbarChat() {
  const dispatch = useDispatch();
  const { rightbar } = useSelector((state) => state.MessengerSlice);
  const [component, setComponent] = useState(rightbar);
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (rightbar && ref.current && !ref.current.contains(e.target)) {
        dispatch(showRightbar(false));
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [rightbar]);

  const RENDER = {
    AsignInquiry: <AsignInquiry />,
  };

  useEffect(() => {
    if (!rightbar) {
      setTimeout(() => {
        setComponent(false);
      }, 200);
    } else {
      setComponent(rightbar);
    }
  }, [rightbar]);

  return (
    <Box
      className={`${styles.rightbarContainer} ${rightbar ? styles.open : ''}`}
      ref={ref}
    >
      <Box className={styles.header}>
        <Box style={{ position: 'absolute', top: '12px', left: '12px' }}>
          <ButtonIcon
            icon="icon-cerrar"
            variant="mainButtonIcon"
            onClick={() => dispatch(showRightbar(false))}
          />
        </Box>

        {component?.title && (
          <Text variant="bodyBold.fontSize16">{component.title}</Text>
        )}
      </Box>

      {RENDER[component.component]}
    </Box>
  );
}
