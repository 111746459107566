import { Box, Icon } from '@oneloop/jopijs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { toggleKebab } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import SimilarSearch from './SimilarSearch';

export default function IconKebab() {
  const [showDD, setShowDD] = useState(false);
  const dispatch = useDispatch();
  const { isDevelopment, id, quickData, isOpenNavent } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const changeKebabId = (item) => {
    setShowDD(false);
    dispatch(toggleKebab(item));
  };

  const downloadExcel = () => {
    setShowDD(false);
    const link = document.createElement('a');
    link.href = `${tokkoBrokerHost}/development/${id}/export_excel`;
    link.click();
  };

  return (
    <Box __css={{ position: 'relative' }}>
      <Box className={style.itemHeader} onClick={() => setShowDD(!showDD)}>
        <Icon icon="icon-trespunticos" fontSize="16px" />
      </Box>

      <List
        __css={{ left: '-260px' }}
        open={showDD}
        seter={setShowDD}
        clickable
        width="286px"
      >
        <Box as="li" className={style.kebabLi} onClick={() => changeKebabId(2)}>
          <Icon icon="icon-enviar" fontSize="16px" />
          {Trans(translations, 'MySendings')}
        </Box>

        {!quickData.data.is_from_network && !isOpenNavent && (
          <Box
            as="li"
            className={style.kebabLi}
            onClick={() => changeKebabId(3)}
          >
            <Icon icon="icon-archivos" fontSize="16px" />
            {Trans(translations, 'Files')}
          </Box>
        )}

        {isDevelopment && (
          <Box as="li" className={style.kebabLi} onClick={downloadExcel}>
            <Icon icon="icon-excel" fontSize="16px" />
            {Trans(translations, 'DownloadUnits')}
          </Box>
        )}

        {!isDevelopment && <SimilarSearch />}
      </List>
    </Box>
  );
}
