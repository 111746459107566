import store from '@/store/store';
import { getAllSafetyContacts } from './getSafetyContact';

function getPdfId() {
  const { pdfOption } = store.getState().SenderSlice;
  const { value, includeMap } = pdfOption;

  switch (value) {
    case 'horizontal':
      return includeMap ? 2 : 1;
    case 'grid':
      return 3;
    case 'vertical':
      return includeMap ? 5 : 4;
    default:
      return -1;
  }
}

function getBody() {
  const { message, properties, fromOuterNet } = store.getState().SenderSlice;

  if (!fromOuterNet) {
    return message.html;
  }

  const zpProperties = properties.selected
    .map(
      (property) => `
        <p>
          <span> ${property.reference_code} </span>
          <span> ${property.address} </span>
          <span> ${property.location} </span>
          <a href="https://ficha.info/net/posting/${property.id}/public/"> Click aqui para ver la ficha de la propiedad </a>
        </p>`
    )
    .join('<br>');

  const body = `${message.html}\n${zpProperties}`;
  return body;
}

function getProperties() {
  const { properties, fromOuterNet } = store.getState().SenderSlice;

  if (fromOuterNet) {
    return properties.selected.map((property) => property.id);
  }

  return properties.selected
    .filter((property) => !property.is_development)
    .map((property) => ({
      id: property.id,
      is_edited: property.isEditedActive,
    }));
}

function getDevelopments() {
  const { properties } = store.getState().SenderSlice;

  return properties.selected
    .filter((property) => property.is_development)
    .map((property) => property.id);
}

function getOwnerData(safetySelectedAddressees) {
  let ownerData = {};

  if (
    safetySelectedAddressees.length === 1 &&
    safetySelectedAddressees[0]?.reports?.filter((report) => report.active)
      .length > 0
  ) {
    const contact_owner = safetySelectedAddressees[0].id;
    const owner_pdfs = safetySelectedAddressees[0].reports
      .filter((report) => report.active)
      .map((report) => report.id);
    ownerData = { contact_owner, owner_pdfs };
  }

  return ownerData;
}

function getAddresseesEmailList(safetySelectedAddressees) {
  return safetySelectedAddressees.map((selectedAddressee) => {
    const emails = selectedAddressee.emails
      .filter((email) => email.active)
      .map((email) => email.value);
    const contact_id = selectedAddressee.id;

    return { emails, contact_id };
  });
}

function getCCEmailList(safetySelectedCC) {
  return safetySelectedCC.map((selectedCC) => {
    const emails = selectedCC.emails
      .filter((email) => email.active)
      .map((email) => email.value);
    const contact_id = selectedCC.id;

    return { emails, contact_id };
  });
}

function getPropertiesFiles() {
  const { properties } = store.getState().SenderSlice;

  const propertiesWithNestedFiles = properties.selected
    .filter((property) => !property.is_development)
    .map((property) => {
      const filesIds = property.files
        .filter((file) => file.active)
        .map((file) => file.id);

      return filesIds;
    });

  return propertiesWithNestedFiles.flat();
}

function getDevelopmentsFiles() {
  const { properties } = store.getState().SenderSlice;

  const propertiesWithNestedFiles = properties.selected
    .filter((property) => property.is_development)
    .map((property) => {
      const filesIds = property.files
        .filter((file) => file.active)
        .map((file) => file.id);

      return filesIds;
    });

  return propertiesWithNestedFiles.flat();
}

async function getEmailPayload(senderState) {
  const safetySelectedAddressees = await getAllSafetyContacts(
    senderState.addressees.selected
  );
  const safetySelectedCC = await getAllSafetyContacts(senderState.cc.selected);

  const { subject, language } = senderState;
  const include_agent = senderState.sendCompanyData;
  const change_status_to = senderState.afterSending.selected.id;
  const from_opennavent = senderState.fromOuterNet;
  const body = getBody();
  const pdf_config = getPdfId();
  const props = getProperties();
  const devs = getDevelopments();
  const Pfiles = getPropertiesFiles();
  const Dfiles = getDevelopmentsFiles();
  const owner_data = getOwnerData(safetySelectedAddressees);
  const email_list = getAddresseesEmailList(safetySelectedAddressees);
  const email_cc = getCCEmailList(safetySelectedCC);

  const payload = {
    body,
    from_opennavent,
    props,
    devs,
    include_agent,
    owner_data,
    email_list,
    email_cc,
    Pfiles,
    change_status_to,
    subject,
    language,
    Dfiles,
    pdf_config,
  };

  return payload;
}

export default getEmailPayload;
