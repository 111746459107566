import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getWarning from '../utils/getWarning';

function useListeningWarnings() {
  const senderState = useSelector((state) => state.SenderSlice);
  const { addressees, cc, whatsapp, subject, properties, message, warning } =
    senderState;
  const [lastBlockerWarning, setLastBlockerWarning] = useState(null);
  const [hotWarning, setHotWarning] = useState(null);
  const isPreviousWarningActive =
    lastBlockerWarning && hotWarning && lastBlockerWarning.id === hotWarning.id;

  useEffect(() => {
    const obtainedWarning = getWarning(senderState);

    setHotWarning(obtainedWarning);
  }, [addressees, cc, whatsapp, subject, message, properties, warning]);

  useEffect(() => {
    if (!warning) return;

    if (warning.isBlocker) {
      setLastBlockerWarning(warning);
    }

    if (!warning.isBlocker) {
      setLastBlockerWarning(null);
    }
  }, [warning]);

  return {
    isPreviousWarningActive,
    lastBlockerWarning,
    hotWarning,
    setHotWarning,
  };
}

export default useListeningWarnings;
