import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function Skeletons({ ...props }) {
  return (
    <div data-testid="card-skeleton">
      <Skeleton baseColor="#F3F6F8" highlightColor="#FFFFFF" {...props} />
    </div>
  );
}
