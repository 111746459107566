import { Box, Icon } from '@oneloop/jopijs';
import { useRef, useState, useEffect } from 'react';
import style from './collapse.module.scss';
import useOnClickOutside from '../shared/Hooks';

export default function Collapse({
  title,
  children,
  listItem = false,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const bodyRef = useRef(null);
  const containerRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (bodyRef.current) {
      setContentHeight(bodyRef.current.children[0].offsetHeight);
    }
  }, [children]);

  const toggle = () => setOpen((prev) => !prev);

  useOnClickOutside(containerRef, open, setOpen);

  return (
    <Box
      ref={containerRef}
      className={`${style.container} ${listItem ? style.containerItem : ''}`}
      {...props}
    >
      <Box
        className={`${style.titleCont} ${listItem ? style.listItemTitle : ''}`}
        onClick={toggle}
      >
        {title}
        <Icon
          className={`${style.iconCollapse} ${open ? style.rotate : ''}`}
          icon="icon-dropdown"
          fontSize="16px"
        />
      </Box>
      <Box
        ref={bodyRef}
        style={{
          height: open ? `${contentHeight}px` : '0px',
        }}
        className={`${style.bodyContainer} ${open ? style.openBody : ''}`}
      >
        {children}
      </Box>
    </Box>
  );
}
