import React, { useState, useEffect, useContext } from 'react';
import { useChatContext, ChatContext } from 'stream-chat-react';
import { Box, Badge, Tags, Icon } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CustomChannelPreview.module.scss';
import './CustomChannelPreview.scss';
import { getTimeStamp } from '@/components/shared/DateTransform';
import DisplayName from './DisplayName/DisplayName';
import translations from './CustomChannelPreview.trans.json';
import Trans from '@/components/shared/Trans';
import { setShowModalWhatsapp } from '@/store/slices/components/chat/MessengerSlice';
import AvatarGroup from '../Components/AvatarGroup/AvatarGroup';
import UseChannelTypeActions from '../../Hooks/useChannelTypeActions';

function CustomChannelPreview(props) {
  const {
    channel,
    setActiveChannel,
    displayTitle,
    messageDeliveryStatus,
    lastMessage, // lastMessage valida si hay mensajes, si viene undefined son chats vacios que no traen mensaje
  } = props;
  const { client } = useChatContext();
  const { messages } = channel.state;
  const dispatch = useDispatch();
  const { waffle_flags } = useSelector((state) => state.AuthSlice.permissions);
  const { showQRModal } = useSelector((state) => state.MessengerSlice.whatsapp);
  const ChannelActions = UseChannelTypeActions(channel);

  const messagePreview = messages[messages.length - 1]?.text?.slice(0, 30);
  const messagePreviewSistemMessage = messages[messages.length - 1];

  let displayText = messagePreviewSistemMessage;
  if (displayText?.subtype === 'assignment') {
    if (channel.data.type === 'WhatsAppGuest') {
      if (client.userID === displayText?.assigning_user) {
        displayText = displayText?.assigning_user_text;
      } else {
        displayText = displayText?.attending_users_message;
      }
    } else if (displayText?.assigned_user === client.userID) {
      displayText = displayText?.assigned_user_text;
    } else if (displayText?.assigning_user === client.userID) {
      displayText = displayText?.assigning_user_text;
    } else if (displayText?.inquiry_generator_user === client.userID) {
      displayText = displayText?.inquiry_generator_user_text;
    } else {
      displayText = Trans(translations, 'SystemMessage');
    }
  }
  const handleOnClick = () => {
    // Marcar el canal como leído si hay un badge visible
    channel.markRead();
    setActiveChannel(channel);
    if (waffle_flags.includes('whatsapp_chat') && showQRModal === true) {
      dispatch(setShowModalWhatsapp({ key: 'showQRModal', value: false }));
    }
  };

  const [dataBranch, setDataBranch] = useState();
  const [dataUser, setDataUser] = useState(null);
  useEffect(() => {
    // Guarda data del usuario, verifica en el channel si es distinto a mi ID y trae la data del usuario
    // Este caso me sirve para un chat entre dos personas, pero no estoy contemplando si el tipo es team o colleague
    if (Object.keys(channel?.state?.members).length === 2) {
      const memberData =
        client?.userID !== Object.values(channel?.state?.members)[0].user_id
          ? Object.values(channel?.state?.members)[0]
          : Object.values(channel?.state?.members)[1];
      setDataUser(memberData);
      setDataBranch(
        dataUser?.user?.userProfileExtended?.company
          ? dataUser?.user?.userProfileExtended?.company?.name
          : dataUser?.user?.company?.name
      );
    }
  }, [channel, dataUser, dataBranch]);
  const { channel: activeChannel } = useContext(ChatContext);

  return (
    lastMessage && (
      <Box
        onClick={() => handleOnClick()}
        className={
          channel?.id === activeChannel?.id
            ? styles.channelCardSelected
            : styles.channelCard
        }
      >
        <AvatarGroup channel={channel} />

        <Box className={styles.contactListContainer}>
          {client?.user?.userProfileExtended.company.name !==
            client?.user?.company.name && (
            <Tags variant="dark" noClick style={{ width: 'fit-content' }}>
              {client?.user?.userProfileExtended.company.name}
            </Tags>
          )}

          <Box className={styles.nameDateContainer}>
            <Box>
              {ChannelActions && ChannelActions.badgeComponent}
              <DisplayName channel={channel} displayTitle={displayTitle} />
            </Box>

            <p
              className={styles.datePreview}
              style={{
                margin: '0',
                display: 'flex',
              }}
            >
              {getTimeStamp(channel)}
            </p>
          </Box>

          <Box className={styles.unreadContainer}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {messageDeliveryStatus === 'read' && (
                <Icon icon="icon-doblecheck" fontSize="14px" color="#4268EF" />
              )}

              {messageDeliveryStatus === 'delivered' && (
                <Icon icon="icon-doblecheck" fontSize="14px" color="gray" />
              )}

              <p className={styles.messagePreview}>
                {messagePreviewSistemMessage?.subtype === 'assignment'
                  ? displayText
                  : messagePreview}
              </p>

              {messages[messages.length - 1]?.attachments &&
                !messagePreview && (
                  <p className={styles.messagePreview}>
                    <Icon
                      icon={
                        messages[messages.length - 1]?.attachments[0]?.title
                          ? 'icon-imagenes'
                          : 'icon-archivos'
                      }
                      fontSize="12px"
                    />
                    {messages[messages.length - 1]?.attachments[0]?.title ||
                      'archivo...'}
                  </p>
                )}
            </Box>

            {channel.state.unreadCount > 0 && (
              <Badge variant={['badgeNormal', 'secondary']}>
                {channel.state.unreadCount}
              </Badge>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
}

export default CustomChannelPreview;
