import { Box, Loader, Text } from '@oneloop/jopijs';
import styles from '../CustomChannelHeader.module.scss';

export default function AssignButton({ loading, text, ...props }) {
  return (
    <Box
      className={`${styles.asignInquiryButton} ${
        loading ? styles.inquiryButtonLoading : ''
      }`}
      {...props}
    >
      {loading ? (
        <Loader
          variant="spinner"
          style={{
            backgroundImage: 'conic-gradient(#869498 ,#FFFFFF)',
            margin: '0px',
            width: '28px',
            height: '28px',
          }}
        />
      ) : (
        <Text variant={['bodyBold.fontSize14', 'ellipsis']}>{text}</Text>
      )}
    </Box>
  );
}
