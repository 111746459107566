import { Box } from '@oneloop/jopijs';
import DynamicTags from '../DynamicTags';
import { useWysiwygEditor } from '../hooks/useWysiwygEditor';
import ButtonsGroup from './ButtonsGroup';
import styles from './styles.module.scss';

function Toolbar({ editor }) {
  const { allowedButtons } = useWysiwygEditor();

  const handleSelectDynamicTag = (tag) => {
    const { value } = tag;
    const BLANK_SPACE = ' ';

    editor
      .chain()
      .focus()
      .insertContent(value)
      .insertContent(BLANK_SPACE)
      .run();
  };

  return (
    <Box className={styles['toolbar-container']}>
      <ButtonsGroup
        buttons={[
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'lineHeight',
        ]}
      />
      <ButtonsGroup buttons={['color']} />
      <ButtonsGroup buttons={['unorderedList', 'orderedList', 'alignment']} />
      <ButtonsGroup buttons={['image', 'link']} />
      {allowedButtons.includes('dynamicTags') && (
        <DynamicTags handleSelect={handleSelectDynamicTag} />
      )}
    </Box>
  );
}

export default Toolbar;
