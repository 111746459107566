import Skeletons from '@/components/Skeletons/Skeletons';

export default function ChatSkeletons() {
  return (
    <div style={{ display: 'flex', height: '580px', width: '982px' }}>
      <div
        style={{
          width: '30%',
          padding: '10px',
          borderRight: '1px solid #e0e0e0',
        }}
      >
        <Skeletons
          circle
          height={50}
          width={50}
          style={{ marginBottom: '10px' }}
        />
        <Skeletons height={20} width="80%" style={{ marginBottom: '10px' }} />
        <Skeletons height={20} width="60%" style={{ marginBottom: '10px' }} />
        <Skeletons height={20} width="70%" style={{ marginBottom: '10px' }} />
      </div>
      <div
        style={{
          flexGrow: 1,
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Skeletons height={40} width="50%" style={{ marginBottom: '10px' }} />
          <Skeletons
            height={300}
            width={580}
            style={{ marginBottom: '10px' }}
          />
          <Skeletons height={40} width="30%" style={{ marginBottom: '10px' }} />
          <Skeletons height={40} width="40%" style={{ marginBottom: '10px' }} />
        </div>
        <div>
          <Skeletons height={50} width={580} />
        </div>
      </div>
    </div>
  );
}
