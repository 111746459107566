import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleSender,
  handleWarning,
  resetWarning,
  setIsSendingEmail,
  setSelectedAddressees,
  setSelectedCC,
} from '@/store/slices/components/sender/SenderSlice';
import SenderService from '@/services/SenderService';
import getEmailPayload from '../utils/getEmailPayload';
import createContact from '../utils/createContact';
import { showToast } from '@/store/slices/components/toast/ToastSlice';
import getWarning from '../utils/getWarning';
import tokkoModalActions from '@/components/TokkoModalActions/TokkoModalActions';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';

function useSendEmail() {
  const dispatch = useDispatch();
  const senderState = useSelector((state) => state.SenderSlice);
  const {
    openedInContactFile,
    addressees,
    cc,
    afterSending,
    warning: previousWarning,
  } = senderState;
  const { isContact } = useSelector((state) => state.QuickDisplaySlice);
  const { url } = useSelector((state) => state.LayoutSlice);
  const [isAddingRemainingContact, setIsAddingRemainingContact] =
    useState(false);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const openedFromContactUrl = openedInContactFile || Boolean(isContact);
  const isARemainingContact = addressees.input || cc.input;

  useEffect(() => {
    if (!isAddingRemainingContact) return;

    if (!addressees.input && !cc.input) {
      setIsAddingRemainingContact(false);
      // eslint-disable-next-line
      send();
    }
  }, [addressees.input, cc.input, isAddingRemainingContact]);

  const isWarning = () => {
    const currentWarning = getWarning(senderState);

    if (currentWarning && currentWarning.isBlocker) {
      dispatch(handleWarning(currentWarning));
      return true;
    }

    if (!currentWarning || (currentWarning && previousWarning)) {
      dispatch(resetWarning());
      return false;
    }

    dispatch(handleWarning(currentWarning));
    return true;
  };

  const notify = () => {
    const toastMessage = Trans(translations, 'EmailSuccess');

    if (openedFromContactUrl) {
      const foundAfterSending = afterSending.data.find(
        (element) => element.id === afterSending.selected.id
      );
      const ids = addressees.selected.map((contact) => contact.id);

      tokkoModalActions(
        {
          msg: 'changeLeadStatus',
          data: {
            ids,
            text: foundAfterSending.text,
            backgroundColor: foundAfterSending.color,
          },
        },
        tokkoBrokerHost
      );
    }

    if (url.includes('/leads/')) {
      tokkoModalActions({ msg: 'updateLeads' }, tokkoBrokerHost);
    }

    if (window.location.href.includes('sender')) {
      tokkoModalActions(
        { msg: 'successSenderEmail', data: toastMessage },
        tokkoBrokerHost
      );
    } else {
      dispatch(
        showToast({
          text: toastMessage,
          type: 'success',
        })
      );
    }
  };

  const send = async () => {
    if (isWarning()) return;

    dispatch(setIsSendingEmail(true));
    const payload = await getEmailPayload(senderState);

    try {
      const response = await SenderService.sendEmail(payload);
      if (response.errors) {
        throw new Error(
          errors[0]?.error_msg || Trans(translations, 'DefaultError')
        );
      }

      notify();
    } catch (error) {
      console.log(error);
      const toastMessage =
        error.response?.errors?.find((err) => err.error_msg)?.error_msg ||
        error.message;

      if (window.location.href.includes('sender')) {
        tokkoModalActions(
          {
            msg: 'errorSenderEmail',
            data: toastMessage,
          },
          tokkoBrokerHost
        );
      } else {
        dispatch(
          showToast({
            text: toastMessage,
            type: 'danger',
            time: 10000,
          })
        );
      }
    } finally {
      if (window.location.href.includes('sender')) {
        tokkoModalActions({ msg: 'closeSender' }, tokkoBrokerHost);
      }
      dispatch(handleSender({ show: false }));
    }
  };

  const addRemainingContactAndThenSend = () => {
    // this state will trigger the send later
    setIsAddingRemainingContact(true);

    if (addressees.input) {
      const newAddressee = createContact.raw.email(addressees.input);
      const updatedAddressees = [...addressees.selected, newAddressee];
      dispatch(setSelectedAddressees(updatedAddressees));
    }

    if (cc.input) {
      const newCC = createContact.raw.email(cc.input);
      const updatedCC = [...cc.selected, newCC];
      dispatch(setSelectedCC(updatedCC));
    }
  };

  const handleEmail = async () => {
    if (!isARemainingContact) {
      send();
      return;
    }

    addRemainingContactAndThenSend();
  };

  return { handleEmail };
}

export default useSendEmail;
