import { useSelector } from 'react-redux';
import { Box, Button, Loader } from '@oneloop/jopijs';
import getAreVisibleErrors from '../utils/getAreVisibileErrors';
import useListeningWarnings from '../hooks/useListeningWarnings';
import useSend from '../hooks/useSend';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function Controlls({ handleBack }) {
  const { warning, isSendingEmail, isPreparingWhatsapp } = useSelector(
    (state) => state.SenderSlice
  );
  const { isPreviousWarningActive } = useListeningWarnings();
  const { handleSend } = useSend();
  const areVisibleErrors = getAreVisibleErrors();
  const isLoading = isSendingEmail || isPreparingWhatsapp;

  if (warning) return null;

  return (
    <Box className={styles['buttons-wrapper']}>
      <Button
        className={styles.button}
        variant={['secondaryText', 'large']}
        onClick={handleBack}
      >
        {Trans(translations, 'Back')}
      </Button>
      <Button
        className={`${styles.button} ${
          isLoading ? styles['button--loading'] : ''
        } .sender-send-button`}
        data-testid="sender-send-button"
        variant={['primary', 'large']}
        onClick={handleSend}
        disabled={areVisibleErrors || isPreviousWarningActive}
      >
        {Trans(translations, 'Send')}
        {isLoading && (
          <Box className={styles.spinnerCont}>
            <Loader
              variant="spinner"
              style={{
                width: '16px',
                height: '16px',
                margin: 0,
                backgroundImage: ' conic-gradient(#878C9B ,#878C9B00)',
              }}
            />
          </Box>
        )}
      </Button>
    </Box>
  );
}

export default Controlls;
