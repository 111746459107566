/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Chat,
  Channel,
  ChannelList,
  MessageList,
  Window,
  MessageInput,
  InfiniteScroll,
  Attachment,
} from 'stream-chat-react';
import { Box, ButtonIcon } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import useClient from '../Hooks/useClient';
import CustomHeader from './CustomHeader/CustomHeader';
import CustomChannelPreview from './CustomChannelPreview/CustomChannelPreview';
import CustomChannelHeader from './CustomChannelHeader/CustomChannelHeader';
import CustomInput from './CustomInput/CustomInput';
import CustomSearch from './CustomSearch/CustomSearch';
import CustomMessage from './CustomMessage/CustomMessage';
import WhatsappAd from './WhatsappModals/WhatsappAd';
import styles from './ChatMessenger.module.scss';
import 'stream-chat-react/dist/css/v2/index.css';
import 'src/components/Messenger/ChatMessenger/ChatMessenger.scss';
import CustomSystemMessage from './CustomSystemMessage/CustomSystemMessage';
import MessengerService from '@/services/MessengerService';
import LinkWithPanel from './LinkWithWhatsapp/LinktWithPanel';
import ChatSkeletons from '../ChatSkeletons/ChatSkeletons';
import CustomDateSeparator from './CustomDateSeparator/CustomDateSeparator';
import FiltersComponents from './FiltersComponent/FiltersComponent';

function ChatMessenger(userData) {
  const chatClient = useClient(userData);
  const [messageReply, setMessageReply] = useState(undefined);
  const [showChannelList, setShowChannelList] = useState(true); // Estado para controlar la visibilidad de ChannelList .
  const { whatsapp, filters } = useSelector((state) => state.MessengerSlice);

  const { showWhatsappAd } = whatsapp;
  const { waffle_flags } = useSelector((state) => state.AuthSlice.permissions);

  useEffect(() => {
    MessengerService.deleteUnreadsMessages();
  }, []);

  if (!chatClient) {
    return <ChatSkeletons />;
  }

  return (
    <Chat theme="" client={chatClient}>
      <Box className={styles.chatContainer}>
        <Box className={styles.channelListContainer}>
          <Box>
            <CustomHeader />
          </Box>
          <CustomSearch setShowChannelList={setShowChannelList} />

          {!chatClient.user?.whatsapp.synchronized &&
            showWhatsappAd &&
            waffle_flags.includes('whatsapp_chat') && <WhatsappAd />}

          <FiltersComponents />

          <LinkWithPanel />
          {showChannelList && (
            <ChannelList
              filters={filters.config}
              sort={{ last_message_at: -1 }}
              Preview={CustomChannelPreview}
              Paginator={InfiniteScroll}
            />
          )}
        </Box>
        <Box className={styles.channelContainer}>
          <Channel
            MessageSystem={CustomSystemMessage}
            Message={(props) => (
              <CustomMessage {...props} setMessageReply={setMessageReply} />
            )}
            DateSeparator={CustomDateSeparator}
          >
            <Window>
              <CustomChannelHeader />
              <MessageList
                messageActions={['']}
                returnAllReadData
                theme="str-chat__channel-list"
              />
              {messageReply && (
                <Box className={styles.responseContainer}>
                  <Box className={styles.responseData}>
                    <p className={styles.responseUserName}>
                      {messageReply.user.name}
                    </p>
                    <p>{messageReply.text}</p>
                    {messageReply?.attachments &&
                      messageReply?.attachments.map((attachment) =>
                        attachment.type === 'image' ? (
                          <img
                            style={{ width: '150px' }}
                            alt={messageReply.text}
                            src={
                              attachment?.image_url ||
                              messageReply?.attachments[0]?.thumb_url
                            }
                          />
                        ) : (
                          <Attachment attachments={[attachment]} />
                        )
                      )}
                  </Box>
                  <Box style={{ margin: '10px' }}>
                    <ButtonIcon
                      icon="icon-cerrar"
                      variant={[
                        'subtleTrasnparentIconClearButton',
                        'iconLarge',
                      ]}
                      onClick={() => setMessageReply(undefined)}
                    />
                  </Box>
                </Box>
              )}
              <MessageInput
                Input={(props) => (
                  <CustomInput
                    {...props}
                    setMessageReply={setMessageReply}
                    messageReply={messageReply}
                  />
                )}
              />
            </Window>
          </Channel>
        </Box>
      </Box>
    </Chat>
  );
}

export default ChatMessenger;
