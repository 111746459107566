/* eslint-disable no-bitwise */
import React from 'react';
import {
  Box,
  Drawer,
  Button,
  ButtonIcon,
  Text,
  Image,
  Heading,
  Icon,
} from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import styles from '../CustomChannelHeader.module.scss';
import { AvatarGenerator } from '@/components/shared/AvatarGenerator';
import InquiriesComponent from '../Inquiry/InquiryComponent';
import getInitials from '@/components/shared/getInitials';
import UseChannelTypeActions from '@/components/Messenger/Hooks/useChannelTypeActions';

function UserProfile({
  isOpen,
  toggle,
  members,
  channelName,
  title,
  inquiries,
  totalInquiries,
  getCleanImage,
  formatDate,
  client,
  toggleOpportunities,
  userID,
  channel,
  // setIsOpenFiles,
}) {
  const channelActions = UseChannelTypeActions(channel);
  const overlay = false;
  const memberData =
    userID !== Object.values(channel.state.members)[0].user_id
      ? Object.values(channel.state.members)[0]
      : Object.values(channel.state.members)[1];
  let users = '';

  if (
    client.user &&
    client.user.groups &&
    client.user.groups[0].preview_members
  ) {
    const previewMembers = client.user.groups[0].preview_members;

    if (previewMembers.length > 3) {
      users = `${previewMembers.slice(0, 3).join(', ')} ...`;
    } else {
      users = previewMembers.join(', ');
    }
  }
  const mapeoColoresAPastel = {
    '#F65139': '#FFE8E5',
    '#25697E': '#CCE8F0',
    '#987FF1': '#F2EFFD',
    '#E7377B': '#FCE6EE',
    '#1B90D2': '#E8F4FB',
    // Si es necesario; agregar mas colores a la lista para mapear
  };
  const obtenerColorPastel = (color) =>
    mapeoColoresAPastel[color.toUpperCase()] || color;

  const coloresIcono = ['#F65139', '#25697E', '#987FF1', '#E7377B', '#1B90D2'];

  const obtenerColorDeAzul = (id) => {
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Usa el valor hash para seleccionar un color de la lista de tonos de azul
    const index = Math.abs(hash) % coloresIcono.length;
    return coloresIcono[index];
  };
  const obtenerColor = (id) => {
    const colorBase = obtenerColorDeAzul(id);
    return obtenerColorPastel(colorBase);
  };

  const clientNetworksInfo = client?.user?.networks?.map((network) => ({
    name: network.name,
    id: network.id,
  }));

  const memberNetworksInfo = memberData?.user?.networks?.map((network) => ({
    name: network.name,
    id: network.id,
  }));

  const redesComunes = clientNetworksInfo?.filter((clientNetwork) =>
    memberNetworksInfo?.some(
      (memberNetwork) => memberNetwork?.id === clientNetwork?.id
    )
  );
  const { user_perms } = useSelector((state) => state.AuthSlice.permissions);

  // Función para encontrar el miembro del canal que NO soy yo
  const findOtherMember = (clientData, channelData) => {
    const myUserId = clientData.userID;
    const membersArray = Object.values(channelData.state.members);

    return membersArray.find((member) => member.user.id !== myUserId);
  };

  const redirectValidation = (channelData, userPerm, clientData) => {
    const contactId = channelData?.data?.contact?.id;
    const frozen = channelData?.data?.frozen;
    const canSeeContacts = userPerm?.contacts?.includes(
      'can_see_contacts_from_other_agents'
    );

    if (contactId && (!frozen || (frozen && canSeeContacts))) {
      return `${localStorage.getItem('tokkoBrokerHost')}/contact/${contactId}`;
    }

    if (!contactId && channel.data.type === 'team') {
      const otherMember = findOtherMember(clientData, channelData);
      if (otherMember) {
        return `${localStorage.getItem('tokkoBrokerHost')}/user/${
          otherMember.user.id
        }/details`;
      }
    }

    return '#';
  };

  return (
    <Box className={styles.contDrawer}>
      <Drawer
        isOpen={isOpen}
        screenSide="right"
        animationMinWidth={315}
        animationWidth={315}
        overlay={overlay}
        heightMin="580px"
        animationDuration={10}
        borderRadiusClosed="20px"
        marginTop="0px"
      >
        <Box className={styles.userProfileCard}>
          <Drawer.Header className={styles.companyProfileBox}>
            <Box>
              <ButtonIcon
                icon="icon-cerrar"
                variant="mainButtonIcon"
                onClick={toggle}
              />
            </Box>
          </Drawer.Header>
          <Drawer.Body
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Box className={styles.companyProfileInfoBox}>
              {getCleanImage(members[0]) ||
              channel.data.type.startsWith('WhatsApp') ? (
                <Image
                  className={styles.avatarProfile}
                  src={getCleanImage(members[0]) || channel.data.guest_image}
                  variant="circleBig"
                />
              ) : (
                <div
                  className={styles.initialsCircle} // Asegúrate de definir este estilo
                  style={{
                    width: '82px',
                    height: '82px',
                    borderRadius: '50%', // Esto crea un círculo
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ddd', // Puedes elegir un color de fondo
                    color: '#222',
                    lineHeight: '1',
                    fontSize: '28px',
                    fontWeight: '400',
                  }}
                >
                  {getInitials(channelName || title || 'Unnamed Channel')}
                </div>
              )}
              {channel.data.type.startsWith('WhatsApp') && (
                <Box className={styles.contactTypeIcon}>
                  <Box
                    style={{
                      position: 'absolute',
                      borderRadius: '100%',
                      width: '31px',
                      height: '31px',
                      border: '4px solid white',
                      background: '#25D366',
                      left: '170px',
                      top: '115px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="icon-whatsapp" fontSize="16px" color="white" />
                  </Box>
                </Box>
              )}
              {channel.data.guest_user ? (
                <Box>
                  {channel?.data?.channel_header?.description && (
                    <Box __css={{ textAlign: 'center' }}>
                      <a
                        className={styles.companyProfileName}
                        href={redirectValidation(channel, user_perms)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {channel.data.type === 'WhatsAppContact'
                          ? `${channel.data.channel_header.description}`
                          : `~${channel.data.channel_header.description}`}
                      </a>
                      <Text className={styles.companyProfileData}>
                        {channel.data.guest_user}
                      </Text>
                    </Box>
                  )}
                </Box>
              ) : (
                <a
                  className={styles.companyProfileName}
                  href={redirectValidation(channel, user_perms, client)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {channelName || title}
                </a>
              )}
              {members[0]?.user?.phone_number && (
                <Box style={{ display: 'flex', gap: '4px' }}>
                  {channel.data.type.startsWith('WhatsApp') && (
                    <Text className={styles.companyProfileData}>
                      {channel.data.guest_user}
                    </Text>
                  )}
                </Box>
              )}
              <Text className={styles.companyProfileData}>
                {members[0]?.user?.email}
              </Text>
            </Box>
          </Drawer.Body>
          <Drawer.Footer
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {channelActions && channelActions.showLeadStatusProfile}

            {channel.type !== 'WhatsAppContact' && (
              <Box className={styles.profileCompanyBox}>
                <Image
                  className={styles.profileCompanyIcon}
                  src={
                    client.user?.userProfileExtended?.company?.logo ||
                    'invalid_route'
                  }
                  variant="avatar"
                />
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignSelf: 'center',
                  }}
                >
                  <Text className={styles.userProfileName}>
                    {members[0]?.user.company.name}
                  </Text>
                  <Text className={styles.profileCompanySuc}>
                    {client.user?.userProfileExtended?.company?.branch?.name}
                  </Text>
                </Box>
              </Box>
            )}

            {memberData?.user?.userProfileExtended?.company?.id ===
              client.user?.userProfileExtended?.company?.id && (
              <Box style={{ width: '270px' }}>
                <Heading
                  as="h6"
                  variant="heading.5"
                  style={{ paddingTop: '10px' }}
                >
                  1 grupo en comun
                </Heading>
                <Box className={styles.profileGroupCard}>
                  <Image
                    className={styles.profileCompanyCardIcon}
                    src={memberData?.user?.company?.logo}
                    variant="avatar"
                  />
                  <Box>
                    <Heading as="h5" variant="heading.4">
                      {memberData?.user?.company?.name}
                    </Heading>
                    <Text
                      variant="body.fontSize14"
                      className={styles.lightGray}
                    >
                      {users}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={styles.opportunitiesBox}>
              {totalInquiries > 0 && (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 7px 5px 10px',
                  }}
                >
                  <Text className={styles.opportunitiesText}>Consultas</Text>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                    onClick={toggleOpportunities}
                  >
                    <Button
                      variant="mainItemSmall"
                      style={{ color: '#344149', padding: 0 }}
                    >
                      Ver todas ({totalInquiries})
                      <Icon
                        style={{ transform: 'scaleX(-1)' }}
                        icon="icon-atras"
                        fontSize="12px"
                      />
                    </Button>
                  </Box>
                </Box>
              )}

              {totalInquiries > 0 && (
                <div
                  style={{
                    width: '315px',
                    marginLeft: '-11px',
                  }}
                >
                  {inquiries.slice(0, 2).map((inquiry) => (
                    <InquiriesComponent
                      inquiries={inquiries}
                      totalInquiries={totalInquiries}
                      formatDate={formatDate}
                      inquiry={inquiry}
                      toggleIsOpen={toggle}
                    />
                  ))}
                </div>
              )}
            </Box>
            {redesComunes?.length > 0 && (
              <Box style={{ width: '270px' }}>
                <Heading
                  as="h6"
                  variant="heading.5"
                  style={{ paddingTop: '10px' }}
                >
                  Redes privadas
                </Heading>
                {redesComunes?.map((network) => (
                  <Box
                    className={styles.privateNetwork}
                    style={{ backgroundColor: obtenerColor(network.id) }}
                  >
                    <AvatarGenerator
                      name={network.name}
                      foregroundColor="white"
                      bgColor={obtenerColorDeAzul(network.id)}
                    />
                    <Box>
                      <Heading as="h6" variant="heading.5" key={network.id}>
                        {network.name}
                      </Heading>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Drawer.Footer>
        </Box>
      </Drawer>
    </Box>
  );
}

export default UserProfile;
