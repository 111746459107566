import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sanitize } from 'dompurify';
import SenderService from '@/services/SenderService';
import {
  handleSender,
  handleWarning,
  resetWarning,
  setIsPreparingWhatsapp,
  setSelectedWhatsApp,
} from '@/store/slices/components/sender/SenderSlice';
import tokkoModalActions from '@/components/TokkoModalActions/TokkoModalActions';
import { showToast } from '@/store/slices/components/toast/ToastSlice';
import { getSafetyContact } from '../utils/getSafetyContact';
import createContact from '../utils/createContact';
import getWarning from '../utils/getWarning';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';

function useSendWhatsapp() {
  const dispatch = useDispatch();
  const senderState = useSelector((state) => state.SenderSlice);
  const {
    openedInContactFile,
    fromOuterNet,
    afterSending,
    whatsapp,
    properties,
    language,
    message,
    warning: previousWarning,
  } = senderState;
  const { isContact } = useSelector((state) => state.QuickDisplaySlice);
  const { url } = useSelector((state) => state.LayoutSlice);
  const [isAddingRemainingContact, setIsAddingRemainingContact] =
    useState(false);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const openedFromContactUrl = openedInContactFile || Boolean(isContact);
  const isARemainingContact = whatsapp.input;

  useEffect(() => {
    const addressee = whatsapp.selected[0];
    if (!isAddingRemainingContact) return;
    if (
      !whatsapp.input &&
      addressee &&
      addressee.isValidated &&
      addressee.isValid
    ) {
      setIsAddingRemainingContact(false);
      // eslint-disable-next-line
      send();
    }
  }, [whatsapp.input, whatsapp.selected, isAddingRemainingContact]);

  const getDevelopmentFichaInfoData = async (development) => ({
    ids_from_my_inmo: [development.id],
    address: development.address,
    ficha_info_url: development.link,
  });

  const getNetPropertyFichaInfoData = async (property) => {
    const netFichaInfoLink = `https://ficha.info/net/posting/${property.id}/public/`;
    return {
      ids_from_my_inmo: [property.id],
      address: property.address,
      ficha_info_url: netFichaInfoLink,
    };
  };

  const getPlainPropertyFichaInfoData = async (property, contact) => {
    const data = {
      contactId: contact.id,
      id: property.id,
      isEdited: Boolean(property.isEditedActive),
      lang: language === 'es' ? 'es-AR' : 'en-EN',
    };

    const promise = SenderService.getFichaInfoUrl(data);
    return promise;
  };

  const getFichaInfoData = async (property, contact) => {
    if (property.fromOuterNet) {
      return getNetPropertyFichaInfoData(property);
    }

    if (property.is_development) {
      return getDevelopmentFichaInfoData(property);
    }

    return getPlainPropertyFichaInfoData(property, contact);
  };

  const getPropertiesFichaInfo = async (contact) => {
    try {
      const promises = [];

      for (let i = 0; i < properties.selected.length; i++) {
        const property = properties.selected[i];
        const promise = getFichaInfoData(property, contact);

        promises.push(promise);
      }
      const fichaInfoResponses = await Promise.all(promises);

      return fichaInfoResponses;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getMessageWithPropertiesInfo = async (safetyMessage, safetyContact) => {
    const propertiesInfo = await getPropertiesFichaInfo(safetyContact);
    const LINE_BREAK = '\n';
    let newMessage = safetyMessage;

    propertiesInfo.forEach((property) => {
      newMessage += `${LINE_BREAK}${LINE_BREAK}${property.address}${LINE_BREAK}${property.ficha_info_url}`;
    });

    return propertiesInfo.length === 0 ? safetyMessage : newMessage;
  };

  const getMessageWithoutDynamicTags = async (contact) => {
    if (whatsapp.selected.length > 1) {
      throw new Error(
        'Should not be more than 1 contact selected to send the message.'
      );
    }

    try {
      const data = {
        contactId: contact.id,
        message: message.text,
      };
      const response = await SenderService.replaceWhatsappMessage(data);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getWhatsappPayloadsToTrack = (
    safetyContact,
    messageWithoutEncoding
  ) => {
    const propertiesIds = [];
    const developmentsIds = [];

    if (!fromOuterNet) {
      properties.selected.forEach((property) => {
        if (property.is_development) {
          developmentsIds.push(property.id);
        } else {
          propertiesIds.push(property.id);
        }
      });
    }

    const propertiesPayload = {
      cellphone: safetyContact.cellphone,
      lead_status: afterSending.selected.id,
      mine: [], // TODO: change it when the backend finish the migration
      contact_id: safetyContact.id,
      is_development: 'false',
      properties_ids: propertiesIds,
      message: messageWithoutEncoding,
      whatsapp_type: whatsapp.app === 'web' ? 'web' : 'installed',
    };

    const developmentsPayload = {
      cellphone: safetyContact.cellphone,
      lead_status: afterSending.selected.id,
      mine: [], // TODO: change it when the backend finish the migration
      contact_id: safetyContact.id,
      is_development: 'development',
      properties_ids: developmentsIds,
      message: messageWithoutEncoding,
      whatsapp_type: whatsapp.app === 'web' ? 'web' : 'installed',
    };

    return { propertiesPayload, developmentsPayload };
  };

  const trackMessage = async (propertiesPayload, developmentsPayload) => {
    try {
      let sentDevs = false;
      const promises = [];

      if (developmentsPayload.properties_ids.length > 0) {
        promises.push(SenderService.trackWhatsappMessage(developmentsPayload));
        sentDevs = true;
      }

      if (!sentDevs || propertiesPayload.properties_ids.length > 0) {
        promises.push(SenderService.trackWhatsappMessage(propertiesPayload));
      }

      await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  };

  const sendWhatsappToContact = (paramMessage, cellphone) => {
    const sanitizedPhone = cellphone.replace(/[^\d]/g, '');
    const sanitizedText = sanitize(paramMessage);

    const webUrl = `https://web.whatsapp.com/send?phone=${encodeURIComponent(
      sanitizedPhone
    )}&text=${encodeURIComponent(sanitizedText)}`;

    const desktopUrl = `https://wa.me/${encodeURIComponent(
      sanitizedPhone
    )}?text=${encodeURIComponent(sanitizedText)}`;

    const targetUrl = whatsapp.app === 'web' ? webUrl : desktopUrl;

    const win = window.open(targetUrl, '_blank');
    win.focus();
  };

  const showToastAndCloseSender = () => {
    const toastMessage = Trans(translations, 'WhatsappSuccess');
    if (window.location.href.includes('sender')) {
      tokkoModalActions(
        { msg: 'successSenderEmail', data: toastMessage },
        tokkoBrokerHost
      );
    } else {
      dispatch(
        showToast({
          text: toastMessage,
          type: 'success',
        })
      );
    }

    if (url.includes('/leads/')) {
      tokkoModalActions({ msg: 'updateLeads' }, tokkoBrokerHost);
    }
  };

  const changeLeadStatusIfIsNecessary = () => {
    if (openedFromContactUrl) {
      const foundAfterSending = afterSending.data.find(
        (element) => element.id === afterSending.selected.id
      );
      const ids = whatsapp.selected.map((contact) => contact.id);

      tokkoModalActions(
        {
          msg: 'changeLeadStatus',
          data: {
            ids,
            text: foundAfterSending.text,
            backgroundColor: foundAfterSending.color,
          },
        },
        tokkoBrokerHost
      );
    }
  };

  const isWarning = () => {
    const currentWarning = getWarning(senderState);

    if (currentWarning && currentWarning.isBlocker) {
      dispatch(handleWarning(currentWarning));
      return true;
    }

    if (!currentWarning || (currentWarning && previousWarning)) {
      dispatch(resetWarning());
      return false;
    }

    dispatch(handleWarning(currentWarning));
    return true;
  };

  const send = async () => {
    if (isWarning()) return;

    try {
      dispatch(setIsPreparingWhatsapp(true));
      const safetyContact = await getSafetyContact(whatsapp.selected[0]);
      const messageWithoutDynamicTags = await getMessageWithoutDynamicTags(
        safetyContact
      );
      const messageWithoutEncoding = await getMessageWithPropertiesInfo(
        messageWithoutDynamicTags,
        safetyContact
      );

      const { cellphone } = safetyContact;
      const { propertiesPayload, developmentsPayload } =
        getWhatsappPayloadsToTrack(safetyContact, messageWithoutEncoding);

      await trackMessage(propertiesPayload, developmentsPayload);
      sendWhatsappToContact(messageWithoutEncoding, cellphone);
      changeLeadStatusIfIsNecessary();

      showToastAndCloseSender();
    } catch (error) {
      console.log(error);
    } finally {
      if (window.location.href.includes('sender')) {
        tokkoModalActions({ msg: 'closeSender' }, tokkoBrokerHost);
      }
      dispatch(handleSender({ show: false }));
    }
  };

  const addRemainingContactAndThenSend = () => {
    // this state will trigger the send later
    setIsAddingRemainingContact(true);

    if (whatsapp.input) {
      const newWhatsapp = createContact.raw.whatsapp(addressees.input);
      const updatedWhatsapp = [...whatsapp.selected, newWhatsapp];
      dispatch(setSelectedWhatsApp(updatedWhatsapp));
    }
  };

  const handleWhatsapp = () => {
    if (!isARemainingContact) {
      send();
      return;
    }

    addRemainingContactAndThenSend();
  };

  return { handleWhatsapp };
}

export default useSendWhatsapp;
