/* eslint-disable react/no-array-index-key */
import { Box, Collapsible } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import { tokkoModalActions } from '../../TokkoActions/tokkoActions';

export default function SimilarSearch() {
  const [open1, setOpen1] = useState(false);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);
  const dispatch = useDispatch();

  if (!data) return null;

  const { similar_searches } = data;

  const goToSearch = (search) => {
    tokkoModalActions({ msg: 'similarSearch', data: search }, tokkoBrokerHost);
    dispatch(showQuickDisplay(false));
  };

  return (
    <Collapsible isOpen={open1}>
      <Collapsible.Button
        className={`${style.liSearchSimilar} ${open1 ? style.onDD : ''}`}
        isMainButton
        onClick={() => setOpen1(!open1)}
        variant="mainButton"
        icon="icon-buscar"
        text={Trans(translations, 'SimilarSearch')}
        isExpanded={open1}
        isCollapsible
        maxWidth
        holdPress
      />
      <Collapsible.Body>
        {similar_searches.map((item, index) => (
          <Box
            __css={{ padding: '8px 34px !important' }}
            key={item.operation + index}
            className={`${style.neutral2} ${style.subItem}`}
            as="li"
            onClick={() => goToSearch(item.search)}
          >
            {item.operation} {item.currency}
          </Box>
        ))}
      </Collapsible.Body>
    </Collapsible>
  );
}
