import React, { useEffect, useState, useRef } from 'react';
import { Box, ButtonIcon, Drawer, Heading, Text } from '@oneloop/jopijs';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import { useToggle } from '@oneloop/hooks';
import { useDispatch, useSelector } from 'react-redux';
import GroupDrawer from './Profiles/GroupProfile';
import UserProfile from './Profiles/UserProfile';
import AvatarGroup from '../Components/AvatarGroup/AvatarGroup';
import styles from './CustomChannelHeader.module.scss';
import translations from './CustomChannelHeader.trans.json';
import Trans from '@/components/shared/Trans';
import MessengerService from '@/services/MessengerService';
import InquiriesComponent from './Inquiry/InquiryComponent';
import FilesInProfile from './FilesInProfile/FilesInProfile';
import useOnClickOutside from '@/components/shared/Hooks';
import AsignHeaderButton from './AsignHeaderButton/AsignHeaderButton.js';
import { setChannelData } from '@/store/slices/components/chat/MessengerSlice';
import RightbarChat from './RightBarChat/RightbarChat';
import UseChannelTypeActions from '../../Hooks/useChannelTypeActions';
import useGetDataUser from '../../Hooks/useGetDataUser';

function CustomChannelHeader() {
  const dispatch = useDispatch();
  const { client, setActiveChannel } = useChatContext();
  const { channel } = useChannelStateContext();
  const channelName = channel.data?.name || '';
  const [title, setTitle] = useState('');
  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id
  );
  const [inquiries, setInquiries] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const [isOpenFiles, setIsOpenFiles] = useToggle(false);

  const { user_perms } = useSelector((state) => state.AuthSlice.permissions);
  const ChannelActions = UseChannelTypeActions(channel);
  const user = useGetDataUser(channel);

  useEffect(() => {
    dispatch(setChannelData(channel));
  }, [channel]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inquiryData = await MessengerService.getInquiry(
          channel.data.id,
          1
        );
        setInquiries(inquiryData.contacts);
        setPageInfo(inquiryData.page_info);
      } catch (error) {
        console.error('Error al obtener datos del grupo:', error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!channelName) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || 'Unnamed User'
          )
          .join(', ')
      );
    }
  }, [channelName, members]);
  // cambiar el toggle por setIsOpen
  const [isOpen, toggle] = useToggle(false);
  const [moreOpportunities, toggleOpportunities] = useToggle(false);
  const overlay = false;
  const numberOfUsers = Object.keys(channel.state.members).length;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();

    const options = { weekday: 'short', day: 'numeric', month: 'long' };

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Hoy';
    }

    return new Intl.DateTimeFormat('es-ES', options).format(date);
  }

  const [openedOptionId, setOpenedOptionId] = useState(0);

  const totalInquiries = inquiries.length;
  const getCleanImage = (member) => {
    const cleanImage = member?.user?.image || '';
    return cleanImage;
  };
  const [inquiryNumber, setInquiryNumber] = useState(2);
  //  Se definen los usuarios para el preview de cantidad de usuarios en el grupo
  const handleGetInquiryWithIncrement = async () => {
    try {
      if (pageInfo.num_pages >= inquiryNumber) {
        const inquiryData = await MessengerService.getInquiry(
          channel.data.id,
          inquiryNumber
        );
        setInquiries((prevInquiries) => [
          ...prevInquiries,
          ...inquiryData.contacts,
        ]);
        setInquiryNumber((prevNumber) => prevNumber + 1);
      }
    } catch (error) {
      console.error('Error al obtener datos del grupo:', error);
    }
  };

  function handleScroll(event) {
    const container = event.target;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      handleGetInquiryWithIncrement();
    }
  }
  const refHeader = useRef();
  useOnClickOutside(refHeader, isOpen, toggle);

  const [dataBranch, setDataBranch] = useState();
  const [dataUser, setDataUser] = useState(null);
  useEffect(() => {
    // Guarda data del usuario, verifica en el channel si es distinto a mi ID y trae la data del usuario
    // Este caso me sirve para un chat entre dos personas, pero no estoy contemplando si el tipo es team o colleague
    if (Object.keys(channel?.state?.members).length === 2) {
      const memberData =
        client.userID !== Object.values(channel?.state?.members)[0].user_id
          ? Object.values(channel?.state?.members)[0]
          : Object.values(channel?.state?.members)[1];
      setDataUser(memberData);
      setDataBranch(
        dataUser?.user?.userProfileExtended?.company
          ? dataUser?.user?.userProfileExtended?.company?.name
          : dataUser?.user?.company?.name
      );
    }
  }, [channel, dataUser, dataBranch]);

  const isChannelFrozenOrWhatsAppUnlinked = () => {
    const isFrozen = channel.data?.frozen;

    const isWhatsAppUnlinkedAndUnfrozen =
      channel.data?.type.startsWith('WhatsApp') &&
      client?.user?.whatsapp?.synchronized === false &&
      !channel.data?.frozen;

    return isFrozen || isWhatsAppUnlinkedAndUnfrozen;
  };

  const isDisabled = isChannelFrozenOrWhatsAppUnlinked();

  return (
    <Box ref={refHeader} className={styles.container}>
      <Box
        onClick={toggle}
        style={{
          display: 'flex',
          width: '100%',
          cursor: 'pointer',
          alignItems: 'center',
          height: '70px',
        }}
      >
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            width: '83px',
            height: '68px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AvatarGroup channel={channel} />
        </Box>

        <Box className={styles.userInfoAsign}>
          <Box>
            {ChannelActions && ChannelActions.badgeComponent}
            <Text variant="bodyBold.fontSize16">
              {ChannelActions
                ? ChannelActions.displayName
                : channel.data?.name || user?.name}
            </Text>
          </Box>
        </Box>
      </Box>
      <AsignHeaderButton
        isDisabled={isDisabled}
        channelType={channel.data.type}
        userPerms={user_perms}
      />
      {isOpen && numberOfUsers <= 2 && (
        <UserProfile
          isOpen={isOpen}
          toggle={toggle}
          members={members}
          channelName={channelName}
          title={title}
          inquiries={inquiries}
          totalInquiries={pageInfo.total}
          setOpenedOptionId={setOpenedOptionId}
          getCleanImage={getCleanImage}
          formatDate={formatDate}
          client={client}
          channel={channel}
          toggleOpportunities={toggleOpportunities}
          userID={client.userID}
          setIsOpenFiles={setIsOpenFiles}
        />
      )}
      {isOpenFiles && <FilesInProfile setIsOpenFiles={setIsOpenFiles} />}
      {moreOpportunities && (
        <Box className={styles.contDrawer}>
          <Drawer
            isOpen={isOpen}
            screenSide="right"
            animationMinWidth={315}
            animationWidth={315}
            overlay={overlay}
            heightMin="580px"
            animationDuration={10}
            borderRadiusClosed="20px"
            marginTop="0px"
          >
            <Drawer.Header className={styles.companyProfileBox}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonIcon
                  icon="icon-atras"
                  variant={['subtleTrasnparentIconClearButton', 'iconLarge']}
                  onClick={toggleOpportunities}
                />
                <Heading as="h5" variant="heading.4">
                  {Trans(translations, 'Inquiries')}
                </Heading>
              </Box>
            </Drawer.Header>
            <Drawer.Body>
              <div
                id="inquiriesBox"
                onScroll={(e) => handleScroll(e)}
                style={{
                  width: moreOpportunities ? '324px' : '330px',
                  height: '505px',
                  overflowY: 'auto',
                  marginLeft: '-9px',
                }}
              >
                {inquiries.length > 0 ? (
                  inquiries.map((inquiry) => {
                    const isOptionOpened =
                      openedOptionId === inquiry.message_id;
                    return (
                      <InquiriesComponent
                        inquiries={inquiries}
                        totalInquiries={totalInquiries}
                        formatDate={formatDate}
                        isOptionOpened={isOptionOpened}
                        inquiry={inquiry}
                        toggleIsOpen={toggle}
                        toggleOpportunities={toggleOpportunities}
                      />
                    );
                  })
                ) : (
                  <Heading
                    as="h4"
                    variant="heading.3"
                    style={{ textAlign: 'center', color: '#6b7c85' }}
                  >
                    {Trans(translations, 'NoOportunities')}
                  </Heading>
                )}
              </div>
            </Drawer.Body>
          </Drawer>
        </Box>
      )}
      {/* grupos */}
      {isOpen && numberOfUsers > 2 && (
        <GroupDrawer
          channel={channel}
          isOpen={isOpen}
          toggle={toggle}
          members={members}
          channelName={channelName}
          title={title}
          inquiries={inquiries}
          totalInquiries={totalInquiries}
          setOpenedOptionId={setOpenedOptionId}
          numberOfUsers={numberOfUsers}
          getCleanImage={getCleanImage}
          formatDate={formatDate}
          setActiveChannel={setActiveChannel}
          client={client}
        />
      )}

      <RightbarChat />
    </Box>
  );
}

export default CustomChannelHeader;
