import { Box, Calendar } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { format, set } from 'date-fns';
import { useEffect } from 'react';
import styles from './calendarComponent.module.scss';
import './styleCalendar.css';
import {
  addItem,
  changeDragTime,
  changeEvent,
  setCurrentEvent,
  setScroll,
  setUser,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import { getEvents } from '@/store/slices/components/calendarEvents/services';

export default function CalendarComponent() {
  const dispatch = useDispatch();
  const { event, agents, edit, scroll } = useSelector(
    (state) => state.CalendarEvents
  );
  const user = useSelector((state) => state.UserSlice.data);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const { start } = event[0];
  const { agentsSelected } = agents;

  const scrollElement = document.getElementsByClassName(
    'fc-scroller-liquid-absolute'
  )[0] || { scrollTop: 0 };
  const heightHora = 48;

  const scrollRange = (hour) => {
    const scrollHour = Number(hour) * heightHora;
    const fin = scrollElement.scrollTop + heightHora * 6.5;

    return scrollHour >= scrollElement.scrollTop && scrollHour <= fin;
  };

  useEffect(() => {
    if (!edit.editItem) {
      dispatch(
        addItem({
          block: 'agents',
          key: 'agentsSelected',
          value: {
            ...user,
            clickFn: () =>
              window.open(`${tokkoBrokerHost}/user/${user.id}/details`),
          },
        })
      );
    }

    dispatch(setUser(user));
  }, []);

  useEffect(() => {
    if (agentsSelected.length && !edit.done && edit.active) {
      const mapAgents = agentsSelected.map((agent) => agent.id);
      dispatch(getEvents(mapAgents));
    } else {
      dispatch(setCurrentEvent());
    }
  }, [agentsSelected]);

  useEffect(() => {
    if (start) {
      const hora = format(start, 'HH');

      if (!scrollRange(hora)) {
        scrollElement.scrollTop = Number(hora) * heightHora;
      }
    }

    if (start && !scroll) {
      dispatch(setScroll(start));
    }
  }, [event]);

  const handleEventSelect = (eventInfo) => {
    dispatch(changeDragTime({ start: eventInfo.start, end: eventInfo.end }));
  };

  const handleEventDrop = (eventDrop) => {
    dispatch(
      changeDragTime({ start: eventDrop.event.start, end: eventDrop.event.end })
    );
  };

  const handleEventResize = (eventResize) => {
    const seg = Number(format(start, 'ss'));

    dispatch(
      changeEvent({
        start: set(eventResize.event.start, { seconds: seg > 0 ? 0 : 1 }),
        end: set(eventResize.event.end, { seconds: 10 }),
      })
    );
  };

  return (
    <Box className={styles.containerCal}>
      <Calendar
        key={scroll}
        height={395}
        variant="days"
        events={event}
        initialDate={start || new Date()}
        scrollTime={scroll ? `${format(scroll, 'HH')}:00:00` : ''}
        moreOptions={{
          eventDrop: (e) => handleEventDrop(e),
          eventResize: (e) => handleEventResize(e),
          select: (e) => handleEventSelect(e),
          scrollTimeReset: true,
          selectable: !edit.editPanel,
          droppable: !edit.editPanel,
          editable: !edit.editPanel,
          headerToolbar: {
            start: edit.done || !edit.active ? '' : 'prev',
            center: 'title',
            end: edit.done || !edit.active ? '' : 'next',
          },
        }}
      />
    </Box>
  );
}
