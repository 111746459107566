/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import {
  Box,
  Image,
  ButtonIcon,
  Icon,
  Text,
  ParentSubmenu,
  Submenu,
} from '@oneloop/jopijs';
import { useChannelActionContext } from 'stream-chat-react';
import { isMouseOutParent, useToggle } from '@oneloop/hooks';
import styles from '../CustomChannelHeader.module.scss';
import useOnClickOutside from '@/components/shared/Hooks';

function InquiriesComponent({
  formatDate,
  inquiry,
  toggleIsOpen,
  toggleOpportunities,
}) {
  const [openedOptionId, setOpenedOptionId] = useState(0);
  const inquiryRef = useRef(null);
  const { jumpToMessage } = useChannelActionContext();

  useOnClickOutside(inquiryRef, openedOptionId, setOpenedOptionId);
  function closeDrawerProfile() {
    toggleIsOpen();
    if (toggleOpportunities) {
      toggleOpportunities();
    }
  }
  const [hover, setHover] = useState(false);
  const [BgHover, BgSetHover] = useState(false);
  const [active, setActive] = useToggle(false);
  return (
    <div
      key={inquiry.id}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        className={styles.opportunitiesCard}
        style={{
          backgroundColor: active || BgHover ? '#F3F6F8' : 'white',
          cursor: BgHover ? 'pointer' : 'default',
        }}
        onMouseOver={() => BgSetHover(true)}
        onMouseOut={() => BgSetHover(false)}
      >
        <Box className={styles.opportunitiesCardHeader}>
          <Image src={inquiry.image} variant="rows" alt="inquiry" />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '175px',
              }}
            >
              <Text className={styles.companyProfileLabel}>
                {formatDate(inquiry.date)}
              </Text>
              {/* <ButtonIcon
                variant={['subtleIcon', 'iconExtraSmall22px']}
                icon="icon-trespunticos"
                onClick={() => setOpenedOptionId(inquiry.message_id)}
              /> */}
              <div style={{ height: '20px', width: '20px' }}>
                <ParentSubmenu
                  onMouseOver={() => setHover(true)}
                  onMouseOut={(e) =>
                    setHover(isMouseOutParent(e, 'parent6', 'left'))
                  }
                  id="parent6"
                  aria-describedby="tooltip6"
                  setHover={setHover}
                  hover={hover}
                  offset={20}
                  placement="left"
                >
                  <ButtonIcon
                    variant={['subtleIcon', 'iconExtraSmall22px']}
                    icon="icon-trespunticos"
                    holdPress
                    active={active}
                    badgeVariant="primary"
                    onClick={() => setActive(active)}
                  />
                </ParentSubmenu>
                <div
                  id="tooltip6"
                  style={{
                    width: 'fit-content',
                    visibility: hover || active ? 'visible' : 'hidden',
                    right: '150px',
                    position: 'relative',
                  }}
                >
                  <Submenu
                    parentId="parent6"
                    childrenId="tooltip6"
                    width="151px"
                    offset={20}
                    placement="left-start"
                  >
                    <ButtonIcon
                      variant="submenu"
                      text="Ver en el chat"
                      onClick={async () => {
                        jumpToMessage(inquiry.message_id);
                        closeDrawerProfile();
                      }}
                      maxWidth
                    />
                    {/* Este código crea un elemento de enlace (<a>) dinámicamente 
                    y simula un clic en él, lo que abrirá el enlace en una nueva pestaña, se hizo así
                    porque utilizando el window.open el chat se desconectaba porque interferia con
                    el funcionamiento del useClient */}
                    <ButtonIcon
                      variant="submenu"
                      text="Ir a la propiedad"
                      onClick={() => {
                        const linkElement = document.createElement('a');
                        linkElement.href = inquiry.link;
                        linkElement.target = '_blank';
                        linkElement.rel = 'noopener noreferrer';
                        linkElement.click();
                      }}
                      maxWidth
                    />
                  </Submenu>
                </div>
              </div>
            </Box>
            <Text className={styles.opportunitiesAddress}>
              {inquiry.address}
            </Text>
            <Text className={styles.opportunitiesText}>
              {inquiry.operations?.Rent ||
                inquiry.operations?.Sale ||
                'Sin precio'}
            </Text>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            gap: '7px',
            borderTop: '1px solid #DDE5E9',
            paddingTop: '8px',
            alignItems: 'center',
          }}
        >
          <Icon icon="icon-etiquetas" fontSize="16px" />
          {inquiry.tags.slice(0, 3).map((tag, index) => (
            <Box
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Text className={styles.opportunitiesLabel}>{tag}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}

export default InquiriesComponent;
