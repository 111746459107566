/* eslint-disable react/no-array-index-key */
import { Box, MultiRangeCalendar, Table, Text } from '@oneloop/jopijs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import '../QuickDisplay.css';
import UseResizeWindows from '@/components/shared/Hooks/ResizeWindows';
import { showToast } from '@/store/slices/components/toast/ToastSlice';

export default function Temporary() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);
  const [temporaryError, setTemporaryError] = useState(false);
  const dispatch = useDispatch();

  const winWidth = UseResizeWindows();

  if (!data.temporary) {
    return null;
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const translatedMonths = months.map((month) => Trans(translations, month));
  const translatedWeekDays = weekDays.map((days) => Trans(translations, days));

  const { temporary, occupation } = data;

  const Columns = [Trans(translations, 'Period'), ...temporary.currencies];

  const handlePeriods = () => {
    const newArray = [];

    try {
      for (let index = 0; index < temporary.periods.length; index++) {
        const element = [];
        const currencies = {};

        for (let i = 0; i < temporary.periods[index].length; i++) {
          if (i === 0) {
            element.push(temporary.periods[index][i]);
          } else {
            const value = temporary.periods[index][i];
            const currency = value.slice(0, 3);

            if (currencies[currency]) {
              currencies[currency].push(value);
            } else {
              currencies[currency] = [value];
            }
          }
        }

        // Add the concatenated prices to the element array
        for (let i = 1; i < Columns.length; i++) {
          const currency = Columns[i].slice(-3);
          if (currencies[currency]) {
            element.push(currencies[currency].join(', '));
          } else {
            element.push('');
          }
        }

        newArray.push(element);
      }
    } catch (error) {
      setTemporaryError(true);
      dispatch(
        showToast({
          text: 'Error al cargar alquileres temporales',
          type: 'danger',
          time: 5000,
        })
      );
      return [];
    }

    return newArray;
  };

  return (
    <Box __css={{ marginTop: '40px' }}>
      <Text className={style.BITitle} variant="bodyBold.fontSize14">
        {Trans(translations, 'AvailabilityAndPrices')}
      </Text>

      <Box __css={{ height: '330px' }}>
        <MultiRangeCalendar
          value={occupation}
          range
          multiple
          shadow={false}
          numberOfMonths={winWidth >= 1280 ? 2 : 1}
          format="YYYY-MM-DD"
          readOnly
          months={translatedMonths}
          weekDays={translatedWeekDays}
          hideYear
        />
      </Box>

      {!temporaryError && (
        <Box __css={{ marginTop: '32px' }}>
          <Table>
            <Table.Header>
              {Columns.map((column, ci) => (
                <Table.HeaderItemDefault
                  __css={{
                    paddingTop: '21px',
                    paddingBottom: '21px',
                  }}
                  key={`Col${ci}`}
                >
                  <Text variant="bodyBold.fontSize12">{column}</Text>
                </Table.HeaderItemDefault>
              ))}
            </Table.Header>
            <Table.Rows>
              {handlePeriods().map((row, ri) => (
                <Table.RowDefault id={ri} key={`Row${ri}`} variant="minimalist">
                  {row.map((item, rowsi) => (
                    <Table.RowItemDefault id={ri} key={`RowItem${rowsi}`}>
                      <Text variant="body.fontSize12">{item}</Text>
                    </Table.RowItemDefault>
                  ))}
                </Table.RowDefault>
              ))}
            </Table.Rows>
          </Table>
        </Box>
      )}
    </Box>
  );
}
