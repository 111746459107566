/* eslint-disable react/no-array-index-key */
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import style from './DropDownRepeatEvent.module.scss';
import useOnClickOutside from '@/components/shared/Hooks';
import { setRepeat } from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './DropDownRepeatEvent.trans.json';
import Trans from '@/components/shared/Trans';

export default function DropDownRepeatEvent({ toggleRepeatModal }) {
  const store = useSelector((state) => state.CalendarEvents);
  const dispatch = useDispatch();
  const { repeat } = store;
  const { itemsDropDownRepeatEvent, valueDropDownRepeatEvent } = repeat;
  const [showDD, setShowDD] = useState(false);
  const refDD = useRef(null);
  const contRef = useRef(null);

  useOnClickOutside(refDD, showDD, setShowDD);

  return (
    <Box
      className={`${style.eventRepeat} ${showDD && style.open}`}
      onClick={() => setShowDD(!showDD)}
      ref={contRef}
    >
      <Icon icon="icon-appexchange" fontSize="14px" />
      <Text variant="bodyBold.fontSize12" className={style.repeatText}>
        {valueDropDownRepeatEvent}
      </Text>
      <Icon icon="icon-dropdown" fontSize="14px" />
      {showDD && (
        <Box
          as="ul"
          ref={refDD}
          className={style.ul}
          style={{ top: `${contRef.current.offsetHeight + 2}px` }}
        >
          {itemsDropDownRepeatEvent.map((item, i) => (
            <Box
              key={i}
              as="li"
              onClick={() => {
                dispatch(setRepeat(i));
              }}
            >
              {item}
            </Box>
          ))}
          <Box as="li" onClick={toggleRepeatModal}>
            {Trans(translations, 'Personalize')}
          </Box>
        </Box>
      )}
    </Box>
  );
}
