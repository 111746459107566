import { Box, Icon, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { toggleKebab } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import {
  getDevelopmentSents,
  getSents,
} from '@/store/slices/components/quickDisplay/services';
import Skeletons from '@/components/Skeletons/Skeletons';

export default function MySendings() {
  const dispatch = useDispatch();
  const { id, sents, isDevelopment } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  useEffect(() => {
    if (isDevelopment) {
      dispatch(getDevelopmentSents(id));
    } else {
      dispatch(getSents(id));
    }
  }, []);

  const closeHeader = () => (
    <Box className={style.kebabHeader}>
      <Icon
        icon="icon-cerrar"
        fontSize="16px"
        onClick={() => dispatch(toggleKebab(1))}
      />
      <Text variant="bodyBold.fontSize16">
        {Trans(translations, 'MySendings')}
      </Text>
    </Box>
  );

  if (sents.loading) {
    return (
      <Box __css={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {closeHeader()}
        <Skeletons width={200} borderRadius={16} height={20} />
        <Skeletons
          count={3}
          style={{ maxWidth: '336px', height: '104px', borderRadius: '16px' }}
        />
      </Box>
    );
  }

  if (!sents.data) {
    return (
      <Box __css={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {closeHeader()}
        <Text className={style.gray1} variant="body.fontSize14">
          {Trans(
            translations,
            isDevelopment
              ? 'EmptyStateSendigsDevelopments'
              : 'EmptyStateSendigsProperty'
          )}
        </Text>
      </Box>
    );
  }

  const openContact = (cont) =>
    window.open(`${tokkoBrokerHost}/contact/${cont}`, '_blank');

  const mapStatus = {
    Sent: Trans(translations, 'Sent'),
    Seen: Trans(translations, 'Seen'),
    Liked: Trans(translations, 'Liked'),
    Disliked: Trans(translations, 'Disliked'),
  };

  const { total_sents, sent_history } = sents.data;
  return (
    <Box className={style.kebabCont}>
      {closeHeader()}

      <Text className={style.kebabItemText} variant="body.fontSize14">
        {`${Trans(
          translations,
          isDevelopment ? 'YouSentThisDevelopment' : 'YouSentThisProperty'
        )} ${total_sents} ${Trans(translations, 'times')}`}
      </Text>
      <Box className={style.sendigsCont}>
        {sent_history.map((sent) => (
          <Box key={sent.id} className={style.sendingsItem}>
            <Box className={style.sendigTexts}>
              <Box className={style.sendingsItemTitle}>
                <Text
                  className={style.sendingsItemName}
                  variant="bodyBold.fontSize14"
                  onClick={() => openContact(sent.contact_id)}
                >
                  {sent.contact}
                </Text>
                <Box
                  as="span"
                  className={isDevelopment ? style.sent : style[sent.status]}
                >
                  <Text variant="body.fontSize12">
                    {isDevelopment
                      ? Trans(translations, 'QDMale.Sent')
                      : mapStatus[
                          sent.status[0].toUpperCase() +
                            sent.status.substring(1)
                        ]}
                  </Text>
                </Box>
              </Box>
              <Box className={style.sentDate}>
                <Icon icon="icon-enviar" fontSize="16px" />
                <Text variant="body.fontSize12">{sent.sent_date}</Text>
              </Box>
            </Box>

            {!isDevelopment && (
              <Box className={style.sendingsSeenCont}>
                <Text variant="bodyBold.fontSize12">{sent.version}</Text>
                <Box className={style.sentDate} __css={{ marginLeft: 'auto' }}>
                  <Box as="span">
                    <Icon icon="icon-ver" fontSize="16px" />
                    {sent.views}
                  </Box>

                  {sent.last_seen_date && (
                    <Text variant="body.fontSize12">{sent.last_seen_date}</Text>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
