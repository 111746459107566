import { Box, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FiltersComponent.module.scss';
import { setFilter } from '@/store/slices/components/chat/MessengerSlice';
import translation from './FiltersComponents.trans.json';
import Trans from '@/components/shared/Trans';

export default function FiltersComponents() {
  const { filters, permissions } = useSelector((state) => state.MessengerSlice);
  const dispatch = useDispatch();

  const FilterList = [
    {
      label: Trans(translation, 'Active'),
      id: 'active',
      perm: true,
    },
    {
      label: Trans(translation, 'Unreads'),
      id: 'unreads',
      perm: true,
    },
    {
      label: Trans(translation, 'Assigned'),
      id: 'assigned',
      perm: true,
    },
    {
      label: Trans(translation, 'Unassigned'),
      id: 'unassigned',
      perm: permissions.canSeeWebContacts,
    },
  ];

  return (
    <Box className={styles.filtesContainer}>
      {FilterList.map((filter) =>
        filter.perm ? (
          <Box
            key={filter.id}
            className={`${styles.filterButton} ${
              filters.id === filter.id ? styles.active : ''
            }`}
            onClick={() => dispatch(setFilter(filter.id))}
          >
            <Text key={filter.id} variant="bodyBold.fontSize12">
              {filter.label}
            </Text>
          </Box>
        ) : null
      )}
    </Box>
  );
}
