/* eslint-disable react/no-array-index-key */
import { Box, Text } from '@oneloop/jopijs';
import style from '../QuickDisplay.module.scss';
import Collapse from '@/components/collapse/Collapse';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import UderLineItem from './UnderLineItem';

export default function InternalInfoProp({
  information,
  internal_info,
  development,
}) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const redirectAgent = (data) => {
    if (data.key === 'Agente') {
      return window.open(
        `${tokkoBrokerHost}/user/${data.id}/details`,
        '_blank'
      );
    }

    return false;
  };

  return internal_info.propietaries.length > 0 ? (
    <Collapse
      title={
        <Text className={style.internalText} variant="bodyBold.fontSize14">
          {Trans(translations, 'InternalInformation')}
        </Text>
      }
      style={{ backgroundColor: '#F2F8FA' }}
    >
      <Box className={style.contInternalInfo}>
        <Box className={style.internalInfo}>
          {development?.users_in_charge.length > 0 && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'UsersInCharge')}:{' '}
              {development.users_in_charge.map((item, i) => (
                <Box as="span" key={`UsersInCharge${i}`}>
                  {i !== 0 && ', '}
                  <UderLineItem
                    text={item.name}
                    url={`/user/${item.id}/details`}
                  />
                </Box>
              ))}
            </Text>
          )}

          {development && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'Developer')}:{' '}
              <UderLineItem
                text={development.developer.name}
                url={`/contact/${development.developer.id}`}
              />
            </Text>
          )}

          {information.maintenance_user && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'MaintenanceUser')}:{' '}
              <UderLineItem
                text={information.maintenance_user.name}
                url={`/user/${information.maintenance_user.id}/details`}
              />
            </Text>
          )}

          <Text variant="body.fontSize14">
            {Trans(translations, 'KeyLocation')}: {information.key_location}
            {information.key_location_reference && (
              <Box as="span">
                {' - '}
                {information.key_location_reference.id ? (
                  <Box
                    as="span"
                    className={
                      information.key_location_reference.key === 'Agente'
                        ? style.underline
                        : ''
                    }
                    onClick={() =>
                      redirectAgent(information.key_location_reference)
                    }
                  >
                    {information.key_location_reference.value}
                  </Box>
                ) : (
                  `Cod: ${information.key_location_reference.value}`
                )}
              </Box>
            )}
          </Text>

          {information.cotization_users.length > 0 && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'Appraiser')}:{' '}
              {information.cotization_users.map((item, i) => (
                <Box as="span" key={`Appraiser${i}`}>
                  {i !== 0 && ', '}
                  <UderLineItem
                    text={item.name}
                    url={`/user/${item.id}/details`}
                  />
                </Box>
              ))}
            </Text>
          )}

          {information.commission && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'OperationCommission')}:{' '}
              {information.commission}
            </Text>
          )}

          {information.producer_commission && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'ProducerCommission')}:{' '}
              {information.producer_commission}
            </Text>
          )}

          {internal_info.propietaries && (
            <Box className={style.tagProp}>
              <Box style={{ marginRight: '4px' }} as="span">
                <Box style={{ fontWeight: '700' }} as="span">
                  {Trans(translations, 'Owner')}
                </Box>{' '}
                {internal_info.propietaries.map((item, i) => (
                  <Box as="span" key={`Owner${i}`}>
                    {i !== 0 && ', '}
                    <UderLineItem
                      text={item.name}
                      url={`/contact/${item.id}`}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {internal_info.internal_comments && (
          <Box className={style.comment}>
            <Text className={style.internalText} variant="bodyBold.fontSize14">
              {Trans(translations, 'InternalComments')}
            </Text>
            <Box>
              {internal_info.internal_comments.split(/\n/).map((line, index) =>
                line ? (
                  <Text
                    key={`line${index}`}
                    className={style.neutral2}
                    variant="body.fontSize14"
                  >
                    {line}
                  </Text>
                ) : (
                  <br key={`line${index}`} />
                )
              )}
            </Box>
          </Box>
        )}

        {internal_info.transaction_requirements && (
          <Box className={style.comment}>
            <Text className={style.internalText} variant="bodyBold.fontSize14">
              {Trans(translations, 'TransactionRequirements')}
            </Text>
            <Text className={style.internalInfo} variant="body.fontSize14">
              {internal_info.transaction_requirements}
            </Text>
          </Box>
        )}
      </Box>
    </Collapse>
  ) : null;
}
