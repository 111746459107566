function getPropertyStatusInKebabCase(id) {
  const STATUS_BY_ID = {
    1: 'appraising',
    2: 'available',
    3: 'reserved',
    4: 'not-available',
  };

  return STATUS_BY_ID[id];
}

export default getPropertyStatusInKebabCase;
