import { useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import CheckboxRowCC from './CheckboxRowCC';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function DropdownCC() {
  const { related } = useSelector((state) => state.SenderSlice);

  return (
    <Box className={styles.dropdown}>
      <Text className={styles['dropdown-title']}>
        {Trans(translations, 'RelatedContacts')}
      </Text>
      <Box className={styles['dropdown-body']}>
        {related.data.map((contact) => (
          <CheckboxRowCC key={contact.id} contact={contact} />
        ))}
      </Box>
    </Box>
  );
}

export default DropdownCC;
