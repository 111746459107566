import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Icon, Input, Text } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import styles from './styles.module.scss';

export default function QuickResponsesModal({ quickResponses, onSelect }) {
  const permissions = useSelector((state) => state.AuthSlice.permissions);
  const [filteredQuickResponses, setFilteredQuickResponses] =
    useState(quickResponses);
  const userIsAdmin = permissions.is_company_admin;
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const handleOnChange = (event) => {
    const lowerCaseKeyword = event.target.value.toLowerCase();
    const filtered = quickResponses.filter((quickResponse) => {
      const lowerCaseTitle = quickResponse.name.toLowerCase();
      return lowerCaseTitle.includes(lowerCaseKeyword);
    });

    setFilteredQuickResponses(filtered);
  };

  const handleClickOption = (selectedOption) => {
    if (!onSelect) return;

    onSelect(selectedOption);
  };

  if (quickResponses.length === 0) {
    return (
      <Box
        className={`${styles.container} ${styles['container--empty']}`}
        aria-label="quick-responses-dropdown"
        __css={{
          height: 'fit-content',
          maxWidth: '240px',
          width: 'fit-content',
        }}
      >
        {userIsAdmin && (
          <>
            <Text className={styles['no-saved-text']}>
              {Trans(translations, 'NoQuickResponsesSaved')}
            </Text>
            <Box
              as="a"
              href={`${tokkoBrokerHost}/fast_reply/config/`}
              target="_blank"
              className={styles['link-wrapper']}
            >
              <Icon icon="icon-agregar" fontSize="12px" color="#344149" />
              <Box as="span">{Trans(translations, 'AddQuickResponse')}</Box>
            </Box>
          </>
        )}

        {!userIsAdmin && (
          <>
            <Text
              className={`${styles['no-saved-text']} ${styles['no-saved-text--bold']}`}
            >
              {Trans(translations, 'NoQuickResponsesSaved')}
            </Text>
            <Text className={styles.explanation}>
              {Trans(translations, 'AdminQuickResponse')}
            </Text>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box
      className={styles.container}
      aria-label="quick-responses-dropdown"
      __css={{ padding: '8px' }}
    >
      <Input
        autoFocus
        className={styles['quick-responses-search-input']}
        placeholder={Trans(translations, 'SearchQuickResponses')}
        onChange={handleOnChange}
        prefix={
          <Icon
            icon="icon-buscar"
            fontSize="16px"
            className={styles['input-icon']}
          />
        }
      />
      <Box className={styles.list}>
        {filteredQuickResponses.map((response) => (
          <Box
            as="button"
            key={response.id}
            data-testid="quick-responses-item"
            type="button"
            onClick={() => handleClickOption(response)}
            className={styles['list-item']}
          >
            {response.name}
          </Box>
        ))}
        {filteredQuickResponses.length === 0 && (
          <Text
            className={styles['no-quick-responses-found']}
            data-testid="quick-responses-no-result"
          >
            {Trans(translations, 'NoResultsFoundForYourSearch')}
          </Text>
        )}
      </Box>
    </Box>
  );
}
