// plain
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import FontSize from 'tiptap-extension-font-size';
import TextAlign from '@tiptap/extension-text-align';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
// customized
import {
  CustomHardBreak,
  CustomHighlight,
  CustomImageResize,
  CustomLineHeight,
  CustomLink,
  CustomParagraph,
  CustomUnderline,
} from './extensions';

const requiredExtensions = {
  // starter kit
  starter: StarterKit.configure({
    paragraph: false,
    hardBreak: false,
    bulletList: false,
    orderedList: false,
    bold: false,
    italic: false,
  }),
  // from @tiptap
  textStyle: TextStyle.configure(),
  // custom that override the starter kit
  paragraph: CustomParagraph.configure(),
  hardBreak: CustomHardBreak.configure(),
  fontColor: CustomHighlight.configure({
    multicolor: true,
  }),
};

const optionalExtensions = {
  // from @tiptap
  bold: Bold.configure(),
  italic: Italic.configure(),
  fontFamily: FontFamily.configure(),
  fontSize: FontSize.configure(),
  color: Color.configure({
    types: [TextStyle.name, ListItem.name],
  }),
  alignment: TextAlign.configure({
    types: ['paragraph', 'heading'],
  }),
  unorderedList: BulletList.configure(),
  orderedList: OrderedList.configure(),

  // custom
  underline: CustomUnderline.configure(),
  link: CustomLink.configure({
    validate: false,
    linkOnPaste: false,
    autolink: false,
    openOnClick: 'whenNotEditable',
  }),
  lineHeight: CustomLineHeight.configure({
    types: ['paragraph', 'heading'],
  }),
  image: CustomImageResize.configure({
    inline: true,
    allowBase64: true,
  }),
};

const getExtensions = (allowedButtons) => {
  const filteredOptionalExtensions = Object.keys(optionalExtensions).filter(
    (key) => allowedButtons.includes(key)
  );

  return [
    ...Object.values(requiredExtensions),
    ...filteredOptionalExtensions.map((key) => optionalExtensions[key]),
  ];
};

export default getExtensions;
