import ToolbarButton from '../ToolbarButton';
import { OrderedListIcon } from '../icons';

export default function OrderedListButton({ editor }) {
  return (
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      disabled={!editor.can().chain().focus().toggleOrderedList().run()}
      data-active={editor.isActive('orderedList')}
      aria-label="editor-ordered-list"
    >
      <OrderedListIcon style={{ maxHeight: 16, maxWidth: 16 }} />
    </ToolbarButton>
  );
}
