import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Collapsible, Icon, Box } from '@oneloop/jopijs';
import { getContactReports } from '@/store/slices/components/sender/services';
import { setSelectedAddressees } from '@/store/slices/components/sender/SenderSlice';
import Checkbox from '@/components/checkbox/Checkbox';
import isAValidLink from '../../utils/isAValidLink';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function PropertiesReports({ contact }) {
  const dispatch = useDispatch();
  const { addressees } = useSelector((state) => state.SenderSlice);
  const [isOpen, setIsOpen] = useState(false);
  const allChecked = contact.reports?.every((report) => report.active);
  const allUnchecked = contact.reports?.every((report) => !report.active);
  const activeReports = contact.reports?.filter((report) => report.active);

  useEffect(() => {
    if (!contact.reports) {
      dispatch(getContactReports(contact.id));
    }
  }, []);

  const toggleCollapsible = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleToggleSingleReport = (selectedReport) => {
    const updatedReports = contact.reports.map((report) =>
      report.id === selectedReport.id
        ? { ...report, active: !selectedReport.active }
        : report
    );

    const updatedSelected = addressees.selected.map((selected) =>
      String(selected.id) === String(contact.id)
        ? { ...selected, reports: updatedReports }
        : selected
    );

    dispatch(setSelectedAddressees(updatedSelected));
  };

  const handleToggleSelectAll = () => {
    const allCheckboxNewState = !allChecked;

    const updatedReports = contact.reports.map((report) => ({
      ...report,
      active: allCheckboxNewState,
    }));

    const updatedSelected = addressees.selected.map((selected) =>
      String(selected.id) === String(contact.id)
        ? { ...selected, reports: updatedReports }
        : selected
    );
    dispatch(setSelectedAddressees(updatedSelected));
  };

  if (!contact?.reports || contact?.reports?.length === 0) return null;

  return (
    <Box
      id="owner-reports"
      className={`${styles.container} ${
        isOpen ? styles['container--open'] : ''
      }`}
    >
      <Collapsible width="100%" isOpen={isOpen}>
        <Collapsible.Button
          variant="default"
          className={styles['collapsible-button']}
          onClick={toggleCollapsible}
        >
          <Icon icon="icon-reportes" fontSize="16px" color="#2F373D" />
          <Text className={styles.title}>
            {Trans(translations, 'IncludeReports')}
          </Text>

          <Box
            as="span"
            className={`${styles['reports-count']} ${
              isOpen || activeReports.length === 0
                ? styles['reports-count--hide']
                : ''
            }`}
          >
            {activeReports.length}
          </Box>
          <Icon
            icon="icon-colapsar"
            className={`${styles.arrow} ${
              isOpen ? styles['arrow--up'] : styles['arrow--down']
            }`}
            fontSize="16px"
          />
        </Collapsible.Button>

        <Collapsible.Body>
          <Box className={styles['collapsible-body-box']}>
            {contact.reports && (
              <Box
                as="label"
                onClick={handleToggleSelectAll}
                className={styles['select-all-wrapper']}
              >
                <Checkbox
                  checked={!allUnchecked}
                  iconType={
                    !allChecked && !allUnchecked
                      ? 'icon-mostrarmenos'
                      : 'icon-aceptar'
                  }
                />
                <Text className={styles.title}>
                  {Trans(translations, 'SelectAll')}
                </Text>
              </Box>
            )}

            {contact.reports.map((report) => (
              <Box
                key={report.id}
                as="label"
                onClick={() => handleToggleSingleReport(report)}
                className={`${styles['row-item']} ${
                  report.active ? styles['row-item--checked'] : ''
                }`}
              >
                <Box className={styles['checkbox-wrapper']}>
                  <Checkbox checked={report.active} />
                </Box>
                {isAValidLink(report.picture) ? (
                  <Box
                    as="img"
                    src={report.picture}
                    className={styles.picture}
                  />
                ) : (
                  <Box className={styles['placeholder-wrapper']}>
                    <Icon
                      icon="icon-propiedades"
                      fontSize="24px"
                      color="#DDE5E9"
                    />
                  </Box>
                )}

                <Text className={styles.address}>{report.address}</Text>
              </Box>
            ))}
          </Box>
        </Collapsible.Body>
      </Collapsible>
    </Box>
  );
}

export default PropertiesReports;
