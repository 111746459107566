import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import { setSubject } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import parentStyles from '../styles.module.scss';
import localStyles from './styles.module.scss';

function Subject() {
  const dispatch = useDispatch();
  const { subject, warning } = useSelector((state) => state.SenderSlice);
  const isWarning = warning && warning.fields.includes('subject');

  const handleChange = (event) => {
    const { value } = event.target;
    dispatch(setSubject(value));
  };

  return (
    <Box
      as="section"
      className={localStyles.container}
      aria-label="sender-subject"
    >
      <Box
        className={`${parentStyles.row} ${
          isWarning ? parentStyles['row--warning-highlight'] : ''
        }`}
      >
        <Text className={parentStyles['row-label']}>
          {Trans(translations, 'Subject')}
        </Text>
        <Box className={localStyles.wrapper}>
          <Box
            as="input"
            className={localStyles.input}
            value={subject}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Subject;
