import { useDispatch, useSelector } from 'react-redux';
import { sanitize } from 'dompurify';
import { Box, Icon } from '@oneloop/jopijs';
import { resetWarning } from '@/store/slices/components/sender/SenderSlice';
import getAreVisibleErrors from '../utils/getAreVisibileErrors';
import useListeningWarnings from '../hooks/useListeningWarnings';
import useSend from '../hooks/useSend';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function FooterWarning() {
  const dispatch = useDispatch();
  const { warning } = useSelector((state) => state.SenderSlice);
  const { isPreviousWarningActive } = useListeningWarnings();
  const { handleSend } = useSend();
  const areVisibleErrors = getAreVisibleErrors();

  const handleResetWarning = () => {
    dispatch(resetWarning());
  };

  return (
    <Box className={styles.footer}>
      <Box className={styles.content}>
        <Icon icon="icon-alertas" fontSize="16px" color="#0f567f" />
        <Box
          as="p"
          dangerouslySetInnerHTML={{
            __html: sanitize(Trans(translations, warning.messageKey)),
          }}
        />
      </Box>

      <Box className={styles['buttons-wrapper']}>
        <Box as="button" className={styles.button} onClick={handleResetWarning}>
          {Trans(translations, warning.firstButtonKey)}
        </Box>

        {warning.secondButtonKey && (
          <Box
            as="button"
            data-testid="sender-send-button"
            className={`${styles.button} .sender-send-button`}
            onClick={handleSend}
            disabled={areVisibleErrors || isPreviousWarningActive}
          >
            {Trans(translations, warning.secondButtonKey)}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FooterWarning;
