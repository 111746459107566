import { useState, useEffect, useCallback } from 'react';
import { Input, Icon, Modal, Box, Image, Text, Heading } from '@oneloop/jopijs';
import styles from './SharePropertiesModal.module.scss';
import MessengerService from '@/services/MessengerService.js';
import SharePropertySkeleton from '../../ChatSkeletons/SharePropertySkeleton.js';
import translations from './SharePropertiesModal.trans.json';
import Trans from '@/components/shared/Trans';

function SharePropertiesModal({ toggle, onPropertySelect }) {
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  const fetchProperties = async (query, page) => {
    setLoading(true);
    try {
      const data = await MessengerService.getPropertiesToShare(query, page);
      setProperties((prevProperties) => [
        ...prevProperties,
        ...data.properties,
      ]);
      setHasNextPage(data.page_info.has_next);
    } catch (error) {
      console.error('Error al obtener propiedades para compartir:', error);
    }
    setLoading(false);
  };

  // Handle search term with debounce
  let debounceTimer;
  const handleAutoComplete = useCallback((q) => {
    setSearchTerm(q);
    const clearQuery = q.trim();
    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        setProperties([]);
        setCurrentPage(1);
        fetchProperties(clearQuery, 1);
      }, 1500);
    } else {
      clearTimeout(debounceTimer);
    }
  }, []);

  // Fetch initial properties
  useEffect(() => {
    fetchProperties('', 1);
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchProperties(searchTerm.trim(), currentPage);
    }
  }, [currentPage]);

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (hasNextPage && !loading) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handlePropertyClick = (property) => {
    onPropertySelect(property);
  };

  const statusTranslations = {
    Available: {
      className: styles.Available,
      translation: Trans(translations, 'Available'),
    },
    Reserved: {
      className: styles.Reserved,
      translation: Trans(translations, 'Reserved'),
    },
    Appraising: {
      className: styles.Appraising,
      translation: Trans(translations, 'Appraising'),
    },
    'Not Available': {
      className: styles.NotAvailable,
      translation: Trans(translations, 'NotAvailable'),
    },
  };

  const getClassNameForStatus = (status) =>
    statusTranslations[status.split('').join('')]?.className || '';

  const getTranslationForStatus = (status) =>
    statusTranslations[status.split('').join('')]?.translation || status;

  return (
    <Modal width="648px" height="500px" modalBG variant="center">
      <Modal.Header
        variant={['start', 'sm']}
        closeIcon={toggle}
        text="Enviar propiedad"
      />
      <Modal.Body>
        <Box __css={{ width: '600px', height: '400px' }}>
          <Input
            prefix={<Icon icon="icon-buscar" fontSize="14px" />}
            placeholder="Buscar"
            width="100%"
            value={searchTerm}
            onChange={(e) => handleAutoComplete(e.target.value)}
          />
          <Box
            className={styles.searchResults}
            onScroll={handleScroll}
            __css={{ height: '375px', overflowY: 'auto' }}
          >
            {properties.map((property) => (
              <Box
                onClick={() => handlePropertyClick(property)}
                className={styles.propertyShareCard}
              >
                <Image src={property.picture_url} variant="rows" />
                <Box>
                  <Text
                    className={getClassNameForStatus(property.status)}
                    variant="bodyBold.fontSize10"
                  >
                    {getTranslationForStatus(property.status)}
                  </Text>
                  <Box __css={{ display: 'flex', flexDirection: 'column' }}>
                    <Text variant="subtitle.1">{property.address}</Text>
                    <Heading as="h5" variant="heading.4">
                      {property.type}
                    </Heading>
                  </Box>
                </Box>
              </Box>
            ))}
            {loading && (
              <Box __css={{ textAlign: 'center', padding: '10px' }}>
                <SharePropertySkeleton />
              </Box>
            )}
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
}

export default SharePropertiesModal;
