import { Box, Button, Modal, Radio, Text } from '@oneloop/jopijs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './calendarEvents.module.scss';
import {
  deleteEvent,
  excludeDates,
} from '@/store/slices/components/calendarEvents/services';
import { tokkoModalActions } from './tokkoActions';
import translations from './CalendarEvents.trans.json';
import Trans from '@/components/shared/Trans';
import { showToast } from '@/store/slices/components/toast/ToastSlice';

export default function OneInstanceModal({
  title,
  close,
  action,
  oneFnc,
  allFnc,
}) {
  const [options, setOptions] = useState({ one: true, all: false });
  const { loading, event, edit } = useSelector((state) => state.CalendarEvents);
  const dispatch = useDispatch();
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const handleOptions = (opt) =>
    setOptions({ one: opt === 'one', all: opt !== 'one' });

  const deleteOIEvent = () => {
    if (options.one) {
      const exclude = { excluded_dates: [event[0].start] };

      dispatch(excludeDates({ id: edit.editItem, body: exclude })).then(() => {
        tokkoModalActions('eventDeleted', tokkoBrokerHost);
        dispatch(showToast({ text: Trans(translations, 'EventDeleted') }));
      });
    } else {
      dispatch(deleteEvent(edit.editItem)).then(() => {
        tokkoModalActions('eventDeleted', tokkoBrokerHost);
        dispatch(showToast({ text: Trans(translations, 'EventDeleted') }));
      });
    }
  };

  const makeActions = () => {
    const ACTIONS = {
      delete: () => deleteOIEvent(),
      change: () => {
        if (options.one) {
          oneFnc();
        } else {
          allFnc();
        }
      },
    };

    ACTIONS[action]();
  };

  return (
    <Modal modalBG blockScroll variant="center" scrollHeight="fit-content">
      <Modal.Header variant={['start', 'l']} text={title} />
      <Modal.Body>
        <Box className={style.contRadio}>
          <Box className={style.radioItem}>
            <Radio
              checked={options.one}
              onChange={() => handleOptions('one')}
            />
            <Text variant="body.fontSize14">
              {Trans(translations, 'ThisEvent')}
            </Text>
          </Box>

          <Box className={style.radioItem}>
            <Radio
              checked={options.all}
              onChange={() => handleOptions('all')}
            />
            <Text variant="body.fontSize14">
              {Trans(translations, 'AllEvents')}
            </Text>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer variant="end">
        <Button
          variant={`${loading ? 'textDisabled' : 'secondaryText'}`}
          onClick={() => close((prev) => !prev)}
        >
          {Trans(translations, 'Cancel')}
        </Button>
        <Button
          variant={[`${loading ? 'textDisabled' : 'primaryText'}`, 'medium']}
          onClick={makeActions}
        >
          {Trans(translations, 'Acept')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
