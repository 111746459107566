/* eslint-disable @next/next/no-img-element */
import React, { useEffect } from 'react';
import { Icon, Loader, Box } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import styles from './LinkWithQR.module.scss';
import { getWhatsappQrCode } from '@/store/slices/components/chat/services';

function LinkWithQR() {
  const { loading, qr } = useSelector((state) => state.MessengerSlice.whatsapp);
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getWhatsappQrCode());
    }, 60000);
    dispatch(getWhatsappQrCode());
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <Loader variant="spinner" size="medium" />;
  }

  return (
    qr && (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <img src={qr} alt="qrImage" style={{ height: '200px' }} />

        <Box className={styles.qrBox}>
          <Icon icon="icon-whatsapp" fontSize="36px" color="black" />
        </Box>
      </div>
    )
  );
}

export default LinkWithQR;
