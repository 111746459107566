import { Box, Icon } from '@oneloop/jopijs';
import styles from './styles.module.scss';

// A raw-suggestion in fact isn't a suggestion, it's just a
// ui reminder that you cannot add a duplicated raw contact
function RawSuggestion({ suggestion }) {
  const { name } = suggestion;
  const email = suggestion.emails[0].value;

  return (
    <Box as="button" className={styles.suggestion} disabled>
      <Icon
        icon="icon-perfil"
        fontSize="32px"
        color="#C7D1D7"
        alignSelf="center"
        className={styles['disabled-opacity']}
      />
      <Box className={`${styles['left-side']} ${styles['disabled-opacity']}`}>
        <Box className={styles['left-side__name']}>{name}</Box>
        <Box className={styles['left-side__contact-info']}>
          <Icon icon="icon-email" fontSize="12px" color="#6B7C85" />
          {email}
        </Box>
      </Box>

      <Box className={styles.check}>
        <Icon icon="icon-check" fontSize="32px" color="#94A2AB" />
      </Box>
    </Box>
  );
}

export default RawSuggestion;
