/* eslint-disable react/no-array-index-key */
import { Box, Icon, Tags, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import File from './File';
import ExportToPdf from './ExportToPdf';
import LinkForColleage from './LinkForColleage';
import IconKebab from './IconKebab';
import HighlightProperty from './HighlightProperty';
import Favourites from './Favourites';
import Skeletons from '@/components/Skeletons/Skeletons';
import Share from './Share';
import WebSite from './WebSite';
import SearchAvailableUnits from './SearchAvailableUnits';

export default function HeaderQD() {
  const { id } = useSelector((state) => state.QuickDisplaySlice);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { quickData, isDevelopment, isOpenNavent } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const { data, can_edit, web_property_url } = quickData;

  if (!data) {
    return (
      <Box
        __css={{
          display: 'flex',
          gap: '24px',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <Skeletons width={80} borderRadius={6} height={26} />
        <Box __css={{ display: 'flex', gap: '24px', flexGrow: '1' }}>
          {Array(4)
            .fill()
            .map((arr, i) => (
              <Box key={`${i} + 100`} __css={{ display: 'flex', gap: '8px' }}>
                <Skeletons width={16} borderRadius={4} height={16} />
                <Skeletons width={70} borderRadius={50} height={8} />
              </Box>
            ))}
        </Box>

        <Box __css={{ display: 'flex', gap: '24px' }}>
          {Array(4)
            .fill()
            .map((arr, i) => (
              <Box key={i} __css={{ display: 'flex', gap: '16px' }}>
                <Skeletons width={16} borderRadius={4} height={16} />
              </Box>
            ))}
        </Box>
      </Box>
    );
  }

  const renderFileOrWeb = () => {
    if (data.is_from_network) {
      return <File />;
    }

    if (isDevelopment || web_property_url) {
      return <WebSite />;
    }

    return <File />;
  };

  return (
    <Box className={style.contHeader}>
      <Box className={`${style.firstCont} ${style.firstContLabels}`}>
        <Tags className={style.titleTag} variant="default" noClick>
          {Trans(translations, 'Preview')}
        </Tags>

        {isOpenNavent ? (
          <Box
            className={style.itemHeader}
            onClick={() => {
              window.open(quickData.data.ad_link, '_blank');
            }}
          >
            <Icon icon="icon-ir" fontSize="16px" />
            <Text variant="bodyBold.fontSize12">
              {Trans(translations, 'SeeinZonaprop')}
            </Text>
          </Box>
        ) : (
          <>
            {can_edit && !data.is_from_network && (
              <Box
                className={style.itemHeader}
                onClick={() => {
                  window.open(
                    `${tokkoBrokerHost}/${
                      isDevelopment ? 'development' : 'property'
                    }/${id}`,
                    '_blank'
                  );
                }}
              >
                <Icon
                  icon={`${
                    isDevelopment ? 'icon-emprendimientos' : 'icon-propiedades'
                  }`}
                  fontSize="16px"
                />
                <Text variant={['bodyBold.fontSize12', 'ellipsis']}>
                  {Trans(
                    translations,
                    isDevelopment ? 'GoToDevelopment' : 'GoToProperty'
                  )}
                </Text>
              </Box>
            )}

            {renderFileOrWeb()}

            {isDevelopment && <SearchAvailableUnits />}

            {!isDevelopment && !data.is_from_network && <LinkForColleage />}

            {!isDevelopment && <ExportToPdf />}
          </>
        )}
      </Box>

      {isOpenNavent ? (
        <Box className={style.firstCont}>
          <Share />
        </Box>
      ) : (
        <Box className={style.firstCont}>
          <Favourites />

          <HighlightProperty />

          <Share />

          <IconKebab />
        </Box>
      )}
    </Box>
  );
}
