import { isEqual } from 'lodash';

function isSenderWithChanges(initialState, actualState) {
  const differentAddressees =
    !isEqual(
      initialState.addressees.selected,
      actualState.addressees.selected
    ) || !isEqual(initialState.addressees.input, actualState.addressees.input);

  const differentCC =
    !isEqual(initialState.cc.selected, actualState.cc.selected) ||
    !isEqual(initialState.cc.input, actualState.cc.input);

  const differentWhatsapp =
    !isEqual(initialState.whatsapp, actualState.whatsapp) ||
    !isEqual(initialState.whatsapp.input, actualState.whatsapp.input);
  const differentSubject = !isEqual(initialState.subject, actualState.subject);

  const differentMessage = !isEqual(
    initialState.message.text,
    actualState.message.text
  );

  const differentProperties =
    !isEqual(
      initialState.properties.selected,
      actualState.properties.selected
    ) || !isEqual(initialState.properties.input, actualState.properties.input);

  return (
    differentAddressees ||
    differentCC ||
    differentWhatsapp ||
    differentSubject ||
    differentMessage ||
    differentProperties
  );
}

export default isSenderWithChanges;
