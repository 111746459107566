import React from 'react';
import { useToggle } from '@oneloop/hooks';
import { Box, ButtonIcon, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import { getStringDoesFromDateJson } from '@/components/shared/DateTransform';
import NotificationService from '@/services/NotificationService';
import styles from './NotificationItem.module.scss';

function NotificationItem({ notificationItem, updateNotifications }) {
  const [active, toggle] = useToggle(notificationItem.is_starred);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  let text = '';
  let link = '';
  if (notificationItem.type === 10) {
    text =
      notificationItem.text.split('> ')[1].split('</')[0] +
      notificationItem.text.split('</span> ')[1].split('<span')[0] +
      notificationItem.text.split('>')[3].split('</')[0];
    link = `${tokkoBrokerHost}${notificationItem.link}`;
  } else if (notificationItem.type === 12) {
    text =
      notificationItem.text.split('<a')[0] +
      notificationItem.text.split('>')[1].split('<')[0] +
      notificationItem.text.split('/a>')[1];
    link = `${tokkoBrokerHost}${
      notificationItem.text.split("href='/")[1].split("/'>")[0]
    }`;
  } else {
    text = notificationItem.text;
    link = `${tokkoBrokerHost}${notificationItem.link}`;
  }

  const tooglePinUp = async (isStarred) => {
    if (!isStarred) {
      await NotificationService.starNotification(notificationItem.id);
    } else {
      await NotificationService.unstarNotification(notificationItem.id);
    }
    updateNotifications();
    toggle(!active);
  };

  return (
    <Box className={styles.containerNotificationItem}>
      <Box className={styles.boxLine}>
        <Box className={styles.line} />
      </Box>
      <Box className={styles.containerRow}>
        <ButtonIcon
          icon={`icon-${notificationItem.icon}`}
          variant={['roundIconArrowRed', 'roundIconSmallIcon14px']}
          holdPress
          active={notificationItem.status === 1}
          className={styles.buttonIconTypeNotification}
        />
        <Link href={link}>
          <Box className={styles.containerText}>
            <Text variant="body.fontSize12" className={styles.textTitle}>
              {text}
            </Text>
            <Text variant="bodyBold.fontSize11" className={styles.textDate}>
              {getStringDoesFromDateJson(notificationItem.created_date)}
            </Text>
          </Box>
        </Link>
        <ButtonIcon
          icon="icon-pinup"
          variant={[
            notificationItem.is_starred
              ? 'subtlePetrokoIconPressed'
              : 'subtlePetrokoIcon',
            'iconSmallSize18px',
          ]}
          className={styles.buttonPinup}
          holdPress
          active={notificationItem.is_starred}
          onClick={() => {
            tooglePinUp(notificationItem.is_starred);
          }}
        />
      </Box>
    </Box>
  );
}

export default NotificationItem;
