import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@oneloop/jopijs';
import LeadStatusBadge from '@/components/LeadStatusBadge/LeadStatusBadge';
import Skeletons from '@/components/Skeletons/Skeletons';
import {
  changeLeadStatus,
  getLeadsStatus,
} from '@/store/slices/components/chat/services';
import LeadsStatusDropDown from '@/components/LeadsStatusDropDown/LeadsStatusDropDown';
import styles from './LeadStatusProfile.module.scss';
import useOnClickOutside from '@/components/shared/Hooks';
import { changeLeadToContact } from '@/store/slices/components/chat/MessengerSlice';
import { tokkoModalActions } from '@/components/TokkoActions/tokkoActions';
import UseGetContact from '@/components/Messenger/Hooks/useGetContact';

export default function LeadStatusProfile({ contactID }) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showDD, setShowDD] = useState(false);
  const { leadsStatusList } = useSelector((state) => state.MessengerSlice);
  const { url } = useSelector((state) => state.LayoutSlice);
  const currentContact = UseGetContact(contactID);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useOnClickOutside(ref, showDD, setShowDD);

  useEffect(() => {
    if (!leadsStatusList.length) {
      dispatch(getLeadsStatus());
    }
  }, []);

  const handleChangeLead = (status) => {
    const data = { id: currentContact.id, body: { lead_status: status.id } };
    dispatch(changeLeadToContact({ id: currentContact.id, status }));

    dispatch(changeLeadStatus(data)).then(() => {
      if (url.includes('/leads/')) {
        tokkoModalActions({ msg: 'updateLeads' }, tokkoBrokerHost);
      }
    });

    setShowDD(false);
  };

  return currentContact ? (
    <Box className={styles.container} ref={ref}>
      <LeadStatusBadge
        onClick={() => setShowDD(true)}
        text={currentContact.status.name}
        hexColor={`#${currentContact.status.color}`}
        big
        __css={{ marginBottom: '24px' }}
      />

      {showDD && (
        <LeadsStatusDropDown
          list={leadsStatusList}
          itemClick={handleChangeLead}
        />
      )}
    </Box>
  ) : (
    <Skeletons
      height={40}
      width={292}
      borderRadius={10}
      style={{ marginBottom: '24px' }}
    />
  );
}
