import React from 'react';
import { Box, Image } from '@oneloop/jopijs';
import { useChatContext, LoadingIndicator } from 'stream-chat-react';
import styles from './CustomSearch.module.scss';
import translations from './SearchResultsList.trans.json';
import Trans from '@/components/shared/Trans';

function SearchResultsList({ results, inputText, isLoading, setInputText }) {
  const { client, setActiveChannel } = useChatContext();

  const openChannel = async (channelId, channelType, contactId) => {
    if (channelId && channelType === 'Colleague') {
      try {
        const filter = {
          type: channelType,
          id: channelId,
        };
        const sort = { last_message_at: -1 };
        const [channel] = await client.queryChannels(filter, sort, {
          watch: true,
          state: true,
        });
        setActiveChannel(channel);
        setInputText('');
      } catch (error) {
        console.error('Error al buscar el canal existente:', error);
      }
    } else {
      const ch = client.channel(channelType, {
        members: [client?.user?.id, contactId],
      });
      try {
        await ch.create();
        setActiveChannel(ch);
        setInputText('');
      } catch (error) {
        console.error('Error al crear un nuevo canal:', error);
      }
    }
  };
  return (
    <Box>
      {results.length === 0 && !isLoading ? (
        <div className={styles.noContactContainer}>
          <p>
            {Trans(
              translations,
              'messengerComponent.NoResultsFoundFor',
              inputText
            )}
          </p>
        </div>
      ) : (
        results.map((contact) => (
          <Box
            onClick={() =>
              openChannel(contact.channel_id, contact.channel_type, contact.id)
            }
            className={styles.channelCard}
            key={contact?.channel_id || contact?.id}
          >
            <Image
              src={contact.image}
              variant="circleBig"
              className={styles.iconSearch}
            />
            <Box className={styles.contactListContainer}>
              <h4>{contact.name}</h4>
              <p>{contact.company.name}</p>
            </Box>
          </Box>
        ))
      )}
      {inputText.length >= 3 && isLoading && (
        <div className={styles.loaderBox}>
          <LoadingIndicator size={30} />
        </div>
      )}
    </Box>
  );
}

export default SearchResultsList;
