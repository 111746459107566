import { Box, Toast } from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeToast } from '@/store/slices/components/toast/ToastSlice';
import ToastRedirectLink from '../Sender/ToastRedirectLink';
import style from './ToastComponent.module.scss';
import './ToastComponent.css';

export default function ToastItem({
  id,
  text,
  time = 3000,
  type = 'success',
  hideCloseIcon = false,
  closeIconNeighbor = null,
  subtitle,
}) {
  const [mounted, setMounted] = useState(true);
  const contRef = useRef();
  const dispatch = useDispatch();

  const NEIGHBORS = {
    reconnect: <ToastRedirectLink />,
  };

  const TYPES = {
    success: {
      variant: 'check',
      icon: 'icon-check',
      loader: undefined,
    },
    alert: {
      variant: 'alert',
      icon: 'icon-alertas',
      loader: undefined,
    },
    danger: {
      variant: 'error',
      icon: 'icon-error',
      loader: undefined,
    },
    loading: {
      variant: 'loading',
      icon: undefined,
      loader: 'spinner',
    },
  };

  let TimeOut;
  const handleClose = (idToast) => {
    if (!contRef.current) return;

    const toastIndex = contRef.current.children[0];
    toastIndex.className = `${toastIndex.className} hideToast`;
    clearTimeout(TimeOut);
    setTimeout(() => {
      setMounted(false);
      dispatch(closeToast(idToast));
    }, 500);
  };

  useEffect(() => {
    TimeOut = setTimeout(() => {
      handleClose(id);
    }, time);
  }, [time]);

  return (
    <Box ref={contRef}>
      {mounted && (
        <Toast
          className={style.toast}
          variant={TYPES[type].variant}
          text={text}
          subtitle={subtitle}
          icon={TYPES[type].icon}
          closeFunction={() => handleClose(id)}
          variantLoader={TYPES[type].loader}
          hideCloseIcon={hideCloseIcon}
          closeIconNeighbor={NEIGHBORS[closeIconNeighbor]}
        />
      )}
    </Box>
  );
}
