/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  ButtonIcon,
  Input,
  Loader,
  ParentSubmenu,
  ParentTooltip,
  Submenu,
  Text,
  Tooltip,
} from '@oneloop/jopijs';
import { isMouseOutParent, useToggle } from '@oneloop/hooks';
import { useSelector, useDispatch } from 'react-redux';
import Link from '@/components/shared/Link';
import { selectBadgesState } from '@/store/slices/LayoutSlice';
import {
  selectUserPreferencesState,
  setSidebarCollapseState,
} from '@/store/slices/UserPreferencesSlice';
import useOnClickOutside from '@/components/shared/Hooks';
import Notification from './Notification/Notification';
import FeaturedNotification from './Notification/FeaturedNotification';
import styles from './Header.module.scss';
import ImageNext from '../../shared/ImageNext';
import logoTokko from '../../../public/images/logoTokko.svg';
import NotificationService from '@/services/NotificationService';
import SearchService from '@/services/SearchService';
import Search from './Search/Search';
import UserService from '@/services/UserService';
import translations from './Header.trans.json';
import Trans from '@/components/shared/Trans';

function Header({ showTooltipCreateContact, setShowTooltipCreateContact }) {
  const ref = useRef();
  const refSearch = useRef();
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const badges = useSelector(selectBadgesState);
  const { searchConfig } = useSelector(selectUserPreferencesState);
  const [filter, setFilter] = React.useState('');
  const [searchs, setSearchs] = useState({});
  const [hover0, setHover0] = useToggle(false);
  const [hover1, setHover1] = useToggle(false);
  const [hover2, setHover2] = useToggle(false);
  const [hover3, setHover3] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [active0Contacts, toggle0Contacts] = useToggle(false);
  const [active1Properties, toggle1Properties] = useToggle(false);
  const [active2Developments, toggle2Developments] = useToggle(false);
  const [active3Agents, toggle3Agents] = useToggle(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openFeaturedNotification, setOpenFeaturedNotification] =
    React.useState(false);
  const [openConfiguration, setConfiguration] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [text, setText] = React.useState('');
  const [notifications, setNotifications] = useState([]);
  const [starredNotifications, setStarredNotifications] = useState([]);
  const { profile_photo, full_name } = useSelector(
    (state) => state.UserSlice.data
  );

  const dispatch = useDispatch();
  const { sidebarCollapse } = useSelector(selectUserPreferencesState);

  async function getNotifications() {
    NotificationService.getUserNotifications().then((result) =>
      setNotifications(result)
    );
  }

  async function getStarredNotifications() {
    NotificationService.getUserStarredNotification().then((result) =>
      setStarredNotifications(result)
    );
  }

  async function getSearchs() {
    if (text.length > 2) {
      setIsLoading(true);
      SearchService.getSearch(text, filter, 8).then((result) => {
        setSearchs(result.searchs);
        setIsLoading(false);
      });
    }
  }

  async function updateNotifications() {
    getNotifications();
    getStarredNotifications();
  }

  useEffect(() => {
    setFilter(searchConfig);
    if (searchConfig?.includes('CO')) toggle0Contacts();
    if (searchConfig?.includes('P')) toggle1Properties();
    if (searchConfig?.includes('D')) toggle2Developments();
    if (searchConfig?.includes('U')) toggle3Agents();
    setTimeout(setFilter(searchConfig), [200]);
  }, [searchConfig]);

  useEffect(() => {
    getSearchs();
  }, [text, filter]);

  useEffect(() => {
    let textFilter = active0Contacts ? 'CO' : '';
    textFilter += active1Properties ? 'P' : '';
    textFilter += active2Developments ? 'D' : '';
    textFilter += active3Agents ? 'U' : '';
    setFilter(textFilter);
    const requestData = {
      c: textFilter,
    };
    UserService.postSearchParameters(requestData);
  }, [active0Contacts, active1Properties, active2Developments, active3Agents]);

  useEffect(() => {
    if (showTooltipCreateContact)
      setTimeout(() => {
        setShowTooltipCreateContact(false);
      }, [5000]);
  }, [showTooltipCreateContact]);

  useOnClickOutside(ref, openConfiguration, setConfiguration);
  useOnClickOutside(refSearch, openSearch, setOpenSearch);

  const toggleSetSidebarOpen = () => {
    const requestData = {
      user_config_key: 'sidebar_collapse',
      user_config_value: sidebarCollapse ? '0' : '1',
      user_config_type: 'boolean',
    };
    UserService.postConfigSetting(requestData);
    dispatch(setSidebarCollapseState(!sidebarCollapse));
  };

  return (
    <div className={styles.contHeader}>
      <Box
        position="relative"
        height="56px"
        bg="secondary"
        display="flex"
        flexDirection="row"
        alignItems="center"
        borderBottomLeftRadius="15px"
        borderBottomRightRadius="15px"
        className={styles.conteinerRowHeader}
      >
        <Box className={styles.conteinerBoxRow}>
          <Box className={styles.contImageLogo}>
            <Link href={`${tokkoBrokerHost}/home`}>
              <ImageNext alt="logo" src={logoTokko} unoptimized />
            </Link>
          </Box>
          <ButtonIcon
            onClick={toggleSetSidebarOpen}
            icon={sidebarCollapse ? 'icon-cerrar' : 'icon-menu'}
            variant={['secondary', 'iconSmallSize18px']}
            className={styles.sidebarIconMenu}
          />
        </Box>
        <Box className={styles.conteinerBoxRow}>
          <div
            className={
              text.length > 2 && openSearch
                ? styles.inputSearch
                : styles.contInputButtons
            }
            ref={refSearch}
          >
            <Input
              id="inputHeader"
              prefix={<span className="icon-buscar" />}
              suffix={
                <div className={styles.contSearchButton}>
                  {text !== '' ? (
                    isLoading ? (
                      <Loader variant="spinner" />
                    ) : (
                      <ButtonIcon
                        variant={[
                          'subtleTrasnparentIconClearButton',
                          'iconExtraSmall22px',
                        ]}
                        icon="icon-cerrar"
                        onClick={() => setText('')}
                      />
                    )
                  ) : undefined}
                  <Box className={styles.conteinerBoxFilters}>
                    <div>
                      <ParentTooltip
                        onMouseOver={() => setHover0(true)}
                        onMouseOut={(e) =>
                          setHover0(
                            isMouseOutParent(e, 'parentContactSearch', 'bottom')
                          )
                        }
                        id="parentContactSearch"
                        offset={10}
                        placement="bottom-start"
                      >
                        <ButtonIcon
                          variant={[
                            active0Contacts
                              ? 'subtleTrasnparentPretrokoPressed'
                              : 'subtleTrasnparentPretroko',
                            'iconExtraSmall24pxWithIcon14px',
                          ]}
                          icon="icon-contactos"
                          active={active0Contacts}
                          onClick={() => {
                            toggle0Contacts(!active0Contacts);
                          }}
                          id="Contacto"
                          holdPress
                        />
                      </ParentTooltip>
                    </div>
                    <div>
                      <ParentTooltip
                        onMouseOver={() => setHover1(true)}
                        onMouseOut={(e) =>
                          setHover1(
                            isMouseOutParent(
                              e,
                              'parentPropertieSearch',
                              'bottom'
                            )
                          )
                        }
                        id="parentPropertieSearch"
                        aria-describedby="tooltipPropertieSearch"
                        offset={10}
                        placement="bottom-start"
                      >
                        <ButtonIcon
                          variant={[
                            active1Properties
                              ? 'subtleTrasnparentPretrokoPressed'
                              : 'subtleTrasnparentPretroko',
                            'iconExtraSmall24pxWithIcon14px',
                          ]}
                          icon="icon-propiedades"
                          active={active1Properties}
                          onClick={() => {
                            toggle1Properties(!active1Properties);
                          }}
                          id="Propiedades"
                          holdPress
                        />
                      </ParentTooltip>
                    </div>
                    <div>
                      <ParentTooltip
                        onMouseOver={() => setHover2(true)}
                        onMouseOut={(e) =>
                          setHover2(
                            isMouseOutParent(
                              e,
                              'parentDevelopmentsSearch',
                              'bottom'
                            )
                          )
                        }
                        id="parentDevelopmentsSearch"
                        aria-describedby="tooltipDevelopmentsSearch"
                        offset={10}
                        placement="bottom-start"
                      >
                        <ButtonIcon
                          variant={[
                            active2Developments
                              ? 'subtleTrasnparentPretrokoPressed'
                              : 'subtleTrasnparentPretroko',
                            'iconExtraSmall24pxWithIcon14px',
                          ]}
                          icon="icon-emprendimientos"
                          active={active2Developments}
                          onClick={() => {
                            toggle2Developments(!active2Developments);
                          }}
                          id="Emprendimientos"
                          holdPress
                        />
                      </ParentTooltip>
                    </div>
                    <div>
                      <ParentTooltip
                        onMouseOver={() => setHover3(true)}
                        onMouseOut={(e) =>
                          setHover3(
                            isMouseOutParent(e, 'parentAgentSearch', 'bottom')
                          )
                        }
                        id="parentAgentSearch"
                        aria-describedby="tooltipAgentSearch"
                        offset={10}
                        placement="bottom-start"
                      >
                        <ButtonIcon
                          variant={[
                            active3Agents
                              ? 'subtleTrasnparentPretrokoPressed'
                              : 'subtleTrasnparentPretroko',
                            'iconExtraSmall24pxWithIcon14px',
                          ]}
                          icon="icon-agente"
                          active={active3Agents}
                          onClick={() => {
                            toggle3Agents(!active3Agents);
                          }}
                          id="Agente"
                          holdPress
                        />
                      </ParentTooltip>
                    </div>
                  </Box>
                </div>
              }
              placeholder={Trans(translations, 'header.placeholderSearch')}
              variant="inputSearch"
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text}
              onClick={() => setOpenSearch(true)}
            />
            {text.length > 2 && openSearch && searchs.length !== undefined && (
              <Search text={text} searchs={searchs} />
            )}
            <div
              id="tooltipCreateContact"
              style={{
                width: 'fit-content',
                visibility: showTooltipCreateContact ? 'visible' : 'hidden',
              }}
            >
              <Tooltip
                variant="tooltipBlack"
                txVariant="arrowTooltipBlack"
                parentId="inputHeader"
                childrenId="tooltipCreateContact"
                placement="bottom-start"
                arrow
                height="52px"
                width="450px"
              >
                <Text variant={['body.fontSize13', 'wordBreak']} width="450px">
                  {Trans(translations, 'header.tooltipCreateContact')}
                </Text>
              </Tooltip>
            </div>
          </div>
          <div
            id="tooltipContactSearch"
            data-testid="tooltipContactSearch"
            hidden={!hover0}
            className={styles.tooltip}
          >
            <Tooltip
              parentId="parentContactSearch"
              childrenId="tooltipContactSearch"
              offset={10}
              placement="bottom-start"
              arrow
            >
              <Text variant="body.fontSize13">
                {Trans(translations, 'header.tooltipIncludeContacts')}
              </Text>
            </Tooltip>
          </div>
          <div
            id="tooltipPropertieSearch"
            data-testid="tooltipPropertieSearch"
            hidden={!hover1}
            className={styles.tooltip}
          >
            <Tooltip
              parentId="parentPropertieSearch"
              childrenId="tooltipPropertieSearch"
              offset={10}
              placement="bottom-start"
              arrow
            >
              <Text variant="body.fontSize13">
                {Trans(translations, 'header.tooltipIncludeProperties')}
              </Text>
            </Tooltip>
          </div>
          <div
            id="tooltipDevelopmentsSearch"
            data-testid="tooltipDevelopmentsSearch"
            hidden={!hover2}
            className={styles.tooltip}
          >
            <Tooltip
              parentId="parentDevelopmentsSearch"
              childrenId="tooltipDevelopmentsSearch"
              offset={10}
              placement="bottom-start"
              arrow
            >
              <Text variant="body.fontSize13">
                {Trans(translations, 'header.tooltipIncludeDevelopments')}
              </Text>
            </Tooltip>
          </div>
          <div
            id="tooltipAgentSearch"
            data-testid="tooltipAgentSearch"
            hidden={!hover3}
            className={styles.tooltip}
          >
            <Tooltip
              parentId="parentAgentSearch"
              childrenId="tooltipAgentSearch"
              offset={10}
              placement="bottom-start"
              arrow
            >
              <Text variant="body.fontSize13">
                {Trans(translations, 'header.tooltipIncludeAgents')}
              </Text>
            </Tooltip>
          </div>
          <div>
            <ParentSubmenu
              id="parentNotification"
              aria-describedby="submenuNotification"
              offset={20}
              placement="bottom"
            >
              <ButtonIcon
                id="notificaciones"
                icon="icon-notificaciones"
                variant={['secondary', 'iconSmallSize18px']}
                badgeValue={badges?.notifications}
                onClick={() => {
                  setOpenNotification(!openNotification);
                  getNotifications();
                }}
              />
            </ParentSubmenu>
            <div
              id="submenuNotification"
              style={{
                width: 'fit-content',
                visibility: openNotification ? 'visible' : 'hidden',
              }}
            >
              <Submenu
                parentId="parentNotification"
                childrenId="submenuNotification"
                width="400px"
                offset={20}
                placement="bottom"
                variant="submenuNotification"
              >
                <Notification
                  listNotifications={notifications?.notifications}
                  openNotification={openNotification}
                  setOpenNotification={setOpenNotification}
                  updateNotifications={updateNotifications}
                />
              </Submenu>
            </div>
          </div>
          <div>
            <ParentSubmenu
              id="parentFeaturedNotification"
              aria-describedby="submenuFeaturedNotification"
              offset={20}
              placement="bottom"
            >
              <ButtonIcon
                id="pinup"
                icon="icon-pinup"
                variant={['secondary', 'iconSmallSize18px']}
                badgeValue={badges?.starred_notifications}
                onClick={() => {
                  setOpenFeaturedNotification(!openFeaturedNotification);
                  getStarredNotifications();
                }}
              />
            </ParentSubmenu>
            <div
              id="submenuFeaturedNotification"
              style={{
                width: 'fit-content',
                visibility: openFeaturedNotification ? 'visible' : 'hidden',
              }}
            >
              <Submenu
                parentId="parentFeaturedNotification"
                childrenId="submenuFeaturedNotification"
                width="400px"
                offset={20}
                placement="bottom"
                variant="submenuNotification"
              >
                <FeaturedNotification
                  listNotifications={starredNotifications?.notifications}
                  openFeaturedNotification={openFeaturedNotification}
                  setOpenFeaturedNotification={setOpenFeaturedNotification}
                  updateNotifications={updateNotifications}
                />
              </Submenu>
            </div>
          </div>
        </Box>
        <Box className={styles.conteinerBoxRow}>
          <Link href={`${tokkoBrokerHost}/profile/`}>
            <ButtonIcon
              variant="userButton"
              text={full_name}
              userImage
              srcImage={profile_photo}
            />
          </Link>
          <div>
            <ParentSubmenu
              id="parentConfiguration"
              aria-describedby="submenuConfiguration"
              offset={20}
              placement="bottom"
            >
              <ButtonIcon
                id="configuracion"
                icon="icon-configuracion"
                variant={['secondary', 'iconSmallSize18px']}
                onClick={() => {
                  setConfiguration(!openConfiguration);
                }}
              />
            </ParentSubmenu>
            <div
              id="submenuConfiguration"
              style={{
                width: 'fit-content',
                visibility: openConfiguration ? 'visible' : 'hidden',
              }}
              ref={ref}
            >
              <Submenu
                parentId="parentConfiguration"
                childrenId="submenuConfiguration"
                width="236px"
                offset={20}
                placement="bottom"
                variant="submenuNotification"
                className={styles.submenuConfiguration}
              >
                <Link href={`${tokkoBrokerHost}/options/`}>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(
                      translations,
                      'header.buttonConfig.Personalconfig'
                    )}
                    maxWidth
                    id={styles.buttonIconSubmenu}
                  />
                </Link>
                <Link href={`${tokkoBrokerHost}/change_password/`}>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(
                      translations,
                      'header.buttonConfig.Changepassword'
                    )}
                    maxWidth
                    id={styles.buttonIconSubmenu}
                  />
                </Link>
                <Link href={`${tokkoBrokerHost}/jopi/`}>
                  <ButtonIcon
                    variant="submenu"
                    text="Jopi!"
                    maxWidth
                    id={styles.buttonIconSubmenu}
                  />
                </Link>
                <Link href={`${tokkoBrokerHost}/byebye/`}>
                  <ButtonIcon
                    variant="submenu"
                    text={Trans(translations, 'header.buttonConfig.Logout')}
                    maxWidth
                    id={styles.buttonIconSubmenu}
                  />
                </Link>
              </Submenu>
            </div>
          </div>
          <Link href="http://dudas.tokkobroker.com/es/">
            <ButtonIcon
              id="dudas"
              icon="icon-dudas"
              variant={['secondary', 'iconSmallSize18px']}
            />
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default Header;
