import ToolbarButton from '../ToolbarButton';
import { UnorderedListIcon } from '../icons';

export default function UnorderedListButton({ editor }) {
  return (
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      disabled={!editor.can().chain().focus().toggleBulletList().run()}
      data-active={editor.isActive('bulletList')}
      aria-label="editor-unordered-list"
    >
      <UnorderedListIcon />
    </ToolbarButton>
  );
}
