/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import DescriptionBox from '../../descriptionBox/DescriptionBox';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import ListService from './ListService';
import UseResizeWindows from '@/components/shared/Hooks/ResizeWindows';

export default function BasicInformation() {
  const { quickData, isDevelopment, versionFile } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const { data } = quickData;
  const winWidth = UseResizeWindows();

  if (!data) {
    return null;
  }

  const { basic_info, measurement } = data;

  const chunkArray = (array, columns) => {
    const chunkedArray = [];
    const itemsPerColumn = Math.ceil(array.length / columns);

    for (let i = 0; i < columns; i++) {
      chunkedArray.push(
        array.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
      );
    }

    return chunkedArray;
  };

  const basicInfo = basic_info
    ? chunkArray(basic_info, winWidth >= 1280 ? 3 : 2)
    : [];

  const measurementInfo = measurement
    ? chunkArray(measurement, winWidth >= 1280 ? 3 : 2)
    : [];

  return (
    <Box className={style.basicInformationCont}>
      {basicInfo.length > 0 && (
        <Box className={style.bItem}>
          <Text className={style.BITitle} variant="bodyBold.fontSize14">
            {Trans(translations, 'BasicInformation')}
          </Text>
          <Box className={style.infoCont}>
            {basicInfo.map((column, columnIndex) => (
              <Box key={columnIndex + 1} className={style.BIItems}>
                {column.map(
                  (item, index) =>
                    item.name && (
                      <Text key={index} variant="body.fontSize14">
                        {item.name}: {item.value}
                      </Text>
                    )
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {measurementInfo.length > 0 && (
        <Box className={style.bItem}>
          <Text className={style.BITitle} variant="bodyBold.fontSize14">
            {Trans(translations, 'SurfacesAndMeasurements')}
          </Text>
          <Box className={style.infoCont}>
            {measurementInfo.map((column, columnIndex) => (
              <Box key={columnIndex + 1} className={style.BIItems}>
                {column.map(
                  (item, index) =>
                    item.name && (
                      <Text key={index} variant="body.fontSize14">
                        {item.name}: {item.value}
                      </Text>
                    )
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {data.description && (
        <Box className={style.bItem}>
          <Text className={style.BITitle} variant="bodyBold.fontSize14">
            {Trans(translations, 'Description')}
          </Text>
          <DescriptionBox key={versionFile} text={data.description} />
        </Box>
      )}

      <Box className={style.servAllContainers}>
        {data.services.length > 0 && (
          <ListService title="Services" array={data.services} />
        )}

        {!isDevelopment && data.rooms.length > 0 && (
          <ListService title="Rooms" array={data.rooms} />
        )}

        {data.additionals.length > 0 && (
          <ListService title="Additionals" array={data.additionals} />
        )}
      </Box>
    </Box>
  );
}
