import { useState, useRef } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import { ColorPicker } from '../../pickers';
import ToolbarButton from '../../ToolbarButton';
import { HighlightIcon } from '../../icons';
import styles from './styles.module.scss';

export default function ColorButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  const handleChangeFontColor = (color) => {
    editor.chain().focus().setColor(color).run();
    setIsOpen(false);
  };

  const handleChangeBackgroundColor = (color) => {
    editor.chain().focus().setHighlight({ color }).run();
    setIsOpen(false);
  };

  return (
    <Box ref={ref} __css={{ position: 'relative' }} data-testid="color-picker">
      <ToolbarButton
        onClick={() => setIsOpen((prevState) => !prevState)}
        disabled={!editor.can().chain().focus().setHighlight().run()}
        aria-label="editor-color"
      >
        <HighlightIcon />
        <Icon icon="icon-dropdown" fontSize="12px" />
      </ToolbarButton>
      {isOpen && (
        <Box className={styles['color-pickers-container']}>
          <ColorPicker
            title={Trans(translations, 'BackgroundColor')}
            resetColorText={Trans(translations, 'EstablishTransparent')}
            resetColorValue="#FFFFFF00"
            onChange={handleChangeBackgroundColor}
          />

          <ColorPicker
            title={Trans(translations, 'FontColor')}
            resetColorText={Trans(translations, 'RestoreByDefault')}
            resetColorValue="#000000"
            onChange={handleChangeFontColor}
          />
        </Box>
      )}
    </Box>
  );
}
