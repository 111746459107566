import { useState, useRef, useEffect } from 'react';
import { Box, Loader, Text } from '@oneloop/jopijs';
import ContactsService from '@/services/ContactsService';
import isAValidEmail from '@/components/Sender/utils/isAValidEmail';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function EmailForContact({ id, handleAddEmail }) {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingEmail, setIsAddingEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [inputIsDirty, setInputIsDirty] = useState(false);
  const [focusingInput, setFocusingInput] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const isValidEmail = isAValidEmail(email);
  const isError = !isValidEmail;
  const showError =
    inputIsDirty &&
    ((wasSubmitted && !isValidEmail) || (!focusingInput && !isValidEmail));

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, [inputRef.current, isAddingEmail]);

  useEffect(() => {
    setWasSubmitted(false);
    if (email) {
      setInputIsDirty(true);
    }
  }, [email]);

  const toggleAddingEmail = () => {
    setIsAddingEmail((prevState) => !prevState);
  };

  const handlePressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      setIsAddingEmail(false);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleBlur = () => {
    setInputIsDirty(true);
    setFocusingInput(false);
  };

  const handleFocus = () => {
    setFocusingInput(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputIsDirty(true);
    setWasSubmitted(true);

    if (isError) return;

    try {
      setIsLoading(true);

      const data = {
        id,
        body: {
          email,
        },
      };

      const result = await ContactsService.update(data);
      handleAddEmail(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <Box className={styles['outer-container']} onKeyDown={handlePressEscape}>
        <Box className={styles['inner-container']}>
          <Box className={styles['left-side']}>
            <Text className={styles.title}>
              <Box as="span" className={styles['title--highlighted']}>
                {Trans(translations, 'ThisContact')}
              </Box>
              <Box as="span">
                {Trans(translations, 'doesNotHaveAssociatedEmail')}
              </Box>
            </Text>

            <Text className={styles.subtitle}>
              {Trans(translations, 'YouWillNotBeAble')}
            </Text>

            {isAddingEmail && (
              <Box as="form" className={styles.form} onSubmit={handleSubmit}>
                <Box className={styles['input-wrapper']}>
                  <Box
                    as="input"
                    ref={inputRef}
                    className={styles.input}
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder={Trans(translations, 'ContactsEmail')}
                    value={email}
                    onChange={handleChange}
                  />
                </Box>

                {isLoading && (
                  <Box __css={{ border: 'none', padding: '0 8px' }}>
                    <Loader
                      variant="spinner"
                      style={{
                        width: '24px',
                        height: '24px',
                        backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                      }}
                    />
                  </Box>
                )}

                {!isLoading && (
                  <Box
                    as="button"
                    type="submit"
                    className={`${styles.button} ${
                      isError || isLoading ? styles['button--disabled'] : ''
                    }`}
                  >
                    {Trans(translations, 'AddEmail')}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {!isAddingEmail && (
            <Box className={styles['right-side']}>
              <Box
                as="button"
                className={styles.button}
                onClick={toggleAddingEmail}
              >
                {Trans(translations, 'AddEmail')}
              </Box>
            </Box>
          )}
        </Box>

        {showError && (
          <Text className={styles['error-message']}>
            {email.length === 0 ? 'Add an email' : 'Invalid email'}
          </Text>
        )}
      </Box>
    </>
  );
}

export default EmailForContact;
