import { useState, useEffect, useRef } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ToolbarButton from '../ToolbarButton';
import LinkPicker from '../PickerWithInput/LinkPicker';
import FloatingMenu from '../FloatingMenu';

export default function LinkButton({ editor }) {
  const ref = useRef(null);
  const [linkPickerIsOpen, setLinkPickerIsOpen] = useState(false);
  const [openedByBubble, setOpenedByBubble] = useState(false);
  useOnClickOutside(ref, linkPickerIsOpen, setLinkPickerIsOpen);

  useEffect(() => {
    if (!linkPickerIsOpen) {
      setOpenedByBubble(false);
    }
  }, [linkPickerIsOpen]);

  const handleEditLink = () => {
    setOpenedByBubble(true);
    setLinkPickerIsOpen(true);
  };

  const handleUnlink = () => {
    editor.chain().focus().toggleLink().run();
  };

  return (
    <Box ref={ref}>
      <ToolbarButton
        onClick={() => setLinkPickerIsOpen((prevState) => !prevState)}
        aria-label="editor-link"
        data-active={linkPickerIsOpen}
      >
        <Icon icon="icon-link" fontSize="16px" color="#2F373D" />
      </ToolbarButton>

      <FloatingMenu
        editor={editor}
        visibleFromOutside={!linkPickerIsOpen}
        handleEditLink={handleEditLink}
        handleUnlink={handleUnlink}
      />

      {linkPickerIsOpen && (
        <LinkPicker
          editor={editor}
          handleClose={() => setLinkPickerIsOpen(false)}
          shouldUseContext={openedByBubble}
        />
      )}
    </Box>
  );
}
