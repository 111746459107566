/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  ButtonIcon,
  Drawer,
  Collapsible,
  ParentSubmenu,
  ParentTooltip,
  Submenu,
  Text,
  Tooltip,
  Image,
} from '@oneloop/jopijs';
import { isMouseOutTooltip, isMouseOutParent } from '@oneloop/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { handleSender } from '@/store/slices/components/sender/SenderSlice';
import Link from '@/components/shared/Link';
import {
  selectUserPreferencesState,
  setSidebarCollapseState,
} from '@/store/slices/UserPreferencesSlice';
import { selectBadgesState } from '@/store/slices/LayoutSlice';
import styles from './Sidebar.module.scss';
import useOnClickOutside from '@/components/shared/Hooks';
import Restricted from '@/components/Restricted/Restricted';
import UserService from '@/services/UserService';
import translations from './Sidebar.trans.json';
import Trans from '@/components/shared/Trans';
import { showTokkoChat } from '@/store/slices/components/chat/MessengerSlice';

function Sidebar({ setShowTooltipCreateContact }) {
  const offset = 32;
  const ref = useRef();
  const dispatch = useDispatch();
  const router = useRouter();
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { sidebarCollapse } = useSelector(selectUserPreferencesState);
  const badges = useSelector(selectBadgesState);
  const { company_logo } = useSelector((state) => state.UserSlice.data);

  const [openCreate, setOpenCreate] = React.useState(false);
  const [open0, setOpen0] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open9, setOpen9] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);

  const [active0, setActive0] = React.useState(false);
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);
  const [active3, setActive3] = React.useState(false);
  const [active4, setActive4] = React.useState(false);
  const [active5, setActive5] = React.useState(false);
  const [active6, setActive6] = React.useState(false);
  const [active7, setActive7] = React.useState(false);
  const [active8, setActive8] = React.useState(false);
  const [active9, setActive9] = React.useState(false);
  const [active10, setActive10] = React.useState(false);

  const [hoverCreate, setHoverCreate] = React.useState(false);
  const [hover0, setHover0] = React.useState(false);
  const [hover1, setHover1] = React.useState(false);
  const [hover2, setHover2] = React.useState(false);
  const [hover3, setHover3] = React.useState(false);
  const [hover4, setHover4] = React.useState(false);
  const [hover5, setHover5] = React.useState(false);
  const [hover6, setHover6] = React.useState(false);
  const [hover7, setHover7] = React.useState(false);
  const [hover8, setHover8] = React.useState(false);
  const [hover9, setHover9] = React.useState(false);
  const [hover10, setHover10] = React.useState(false);

  const closeExpandExcep = (excep) => {
    setOpen0(excep === 0 ? !open0 : false);
    setOpen1(excep === 1 ? !open1 : false);
    setOpen2(excep === 2 ? !open2 : false);
    setOpen3(excep === 3 ? !open3 : false);
    setOpen4(excep === 4 ? !open4 : false);
    setOpen5(excep === 5 ? !open5 : false);
    setOpen6(excep === 6 ? !open6 : false);
    setOpen7(excep === 7 ? !open7 : false);
    setOpen8(excep === 8 ? !open8 : false);
    setOpen9(excep === 9 ? !open9 : false);
    setOpen10(excep === 10 ? !open10 : false);
    setOpenCreate(false);
  };

  const closeAllExcep = (excep) => {
    closeExpandExcep(excep);
    if (excep !== -1) {
      setActive0(excep === 0 || excep === 'home');
      setActive1(excep === 1 || excep === 'leads');
      setActive2(excep === 2 || excep === 'contacts');
      setActive3(excep === 3 || excep === 'properties');
      setActive4(excep === 4 || excep === 'developments');
      setActive5(excep === 5 || excep === 'company');
      setActive6(excep === 6 || excep === 'marketing');
      setActive7(excep === 7 || excep === 'portals');
      setActive8(excep === 8 || excep === 'reports');
      setActive9(excep === 9 || excep === 'webmanager');
      setActive10(excep === 10);
    }
  };

  useEffect(() => {
    try {
      closeAllExcep(router.pathname.split('/')[1]);
    } catch (e) {
      // console.log(e);
    }
  }, []);

  useOnClickOutside(ref, openCreate, setOpenCreate);

  const toggleSetSidebarOpen = () => {
    const requestData = {
      user_config_key: 'sidebar_collapse',
      user_config_value: sidebarCollapse ? '0' : '1',
      user_config_type: 'boolean',
    };
    UserService.postConfigSetting(requestData);
    dispatch(setSidebarCollapseState(!sidebarCollapse));
  };

  const toggleOpenSender = (option) => {
    dispatch(handleSender({ show: option }));
  };

  return (
    <div
      className={
        sidebarCollapse ? styles.contDrawer : styles.contDrawerCollapse
      }
    >
      {sidebarCollapse && (
        <Box className={styles.overlay} onClick={toggleSetSidebarOpen} />
      )}

      <Drawer
        isCollapse={sidebarCollapse}
        screenSide="left"
        animationWidth={sidebarCollapse ? 200 : 82}
        animationMinWidth={sidebarCollapse ? 200 : 82}
        overlay={false}
      >
        <ButtonIcon
          onClick={() => {
            toggleSetSidebarOpen();
            closeAllExcep(-1);
          }}
          icon="icon-atras"
          variant={
            sidebarCollapse ? 'collapseButtonOpen' : 'collapseButtonClosed'
          }
          id={styles.buttonIcon}
        />
        <div className={styles.scroll}>
          <div className={styles.insideScroll}>
            <Drawer.Header id={styles.drawerHeader}>
              {company_logo && (
                <Image
                  alt="default_logo"
                  src={company_logo}
                  priority={false}
                  unoptimized
                  width="130px"
                  className={
                    sidebarCollapse ? styles.image : styles.imageHidden
                  }
                />
              )}
            </Drawer.Header>
            <Box className={styles.contDrawerBody}>
              <Drawer.Body>
                <Box
                  className={
                    sidebarCollapse
                      ? styles.contInsideDrawerBody
                      : styles.contInsideDrawerBodyCollapse
                  }
                >
                  <Box className={styles.contButtons}>
                    <ParentSubmenu
                      id="parentCreate"
                      aria-describedby="tooltipCreate"
                      offset={offset}
                      onClick={() => setOpenCreate(!openCreate)}
                      onMouseOver={() => setHoverCreate(true)}
                      onMouseOut={(e) =>
                        setHoverCreate(
                          isMouseOutParent(e, 'parentCreate', 'right')
                        )
                      }
                      setHover={setHoverCreate}
                      hover={hoverCreate}
                    >
                      <ButtonIcon
                        icon="icon-agregar"
                        text={sidebarCollapse && Trans(translations, 'Create')}
                        variant={[
                          'primary',
                          sidebarCollapse ? 'medium' : 'roundIconMedium',
                        ]}
                        id={
                          sidebarCollapse
                            ? styles.buttonCreate
                            : styles.buttonCreateIcon
                        }
                      />
                    </ParentSubmenu>
                    <div
                      id="tooltipCreate"
                      data-testid="tooltipCreate"
                      onMouseOut={(e) =>
                        setHoverCreate(
                          isMouseOutTooltip(
                            e,
                            'tooltipCreate',
                            'parentCreate',
                            'right'
                          )
                        )
                      }
                      onMouseEnter={(e) =>
                        setHoverCreate(
                          isMouseOutTooltip(
                            e,
                            'tooltipCreate',
                            'parentCreate',
                            'right'
                          )
                        )
                      }
                      style={{
                        width: 'fit-content',
                        visibility:
                          openCreate || hoverCreate ? 'visible' : 'hidden',
                      }}
                      ref={ref}
                    >
                      <Submenu
                        parentId="parentCreate"
                        childrenId="tooltipCreate"
                        offset={offset}
                        width="202px"
                      >
                        <Text variant="submenuTitle">
                          {Trans(translations, 'Create')}
                        </Text>
                        <ButtonIcon
                          variant="submenu"
                          icon="icon-contactos"
                          text={Trans(translations, 'Contact')}
                          maxWidth
                          id={styles.buttonIconSubmenu}
                          onClick={() => setShowTooltipCreateContact(true)}
                        />
                        <Link
                          href={`${tokkoBrokerHost}/contacts/?create_group_company=true#top`}
                        >
                          <ButtonIcon
                            variant="submenu"
                            icon="icon-empresa"
                            text={Trans(translations, 'Company')}
                            maxWidth
                            id={styles.buttonIconSubmenu}
                          />
                        </Link>
                        <Link href={`${tokkoBrokerHost}/property/add/`}>
                          <ButtonIcon
                            variant="submenu"
                            icon="icon-propiedades"
                            text={Trans(translations, 'Property')}
                            maxWidth
                            id={styles.buttonIconSubmenu}
                          />
                        </Link>
                        <Link href={`${tokkoBrokerHost}/developments/add`}>
                          <ButtonIcon
                            variant="submenu"
                            icon="icon-emprendimientos"
                            text={Trans(translations, 'Development')}
                            maxWidth
                            id={styles.buttonIconSubmenu}
                          />
                        </Link>
                        <ButtonIcon
                          onClick={() => toggleOpenSender('email')}
                          variant="submenu"
                          icon="icon-email"
                          text={Trans(translations, 'Mail')}
                          maxWidth
                          id={styles.buttonIconSubmenu}
                        />
                        <ButtonIcon
                          onClick={() => toggleOpenSender('whatsapp')}
                          variant="submenu"
                          icon="icon-whatsapp"
                          text={Trans(translations, 'Whatsapp')}
                          maxWidth
                          id={styles.buttonIconSubmenu}
                        />
                      </Submenu>
                    </div>
                  </Box>
                  <Collapsible isOpen={open0}>
                    <Link href={`${tokkoBrokerHost}/home/`}>
                      <ParentTooltip
                        onMouseOver={() => setHover0(true)}
                        onMouseOut={(e) =>
                          setHover0(isMouseOutParent(e, 'parent0', 'right'))
                        }
                        id="parent0"
                        aria-describedby="tooltip0"
                        setHover={setHover0}
                        hover={hover0}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(0)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-noticias"
                          text={sidebarCollapse && Trans(translations, 'News')}
                          isExpanded={open0}
                          setExpand={() => closeExpandExcep(0)}
                          active={active0}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentTooltip>
                    </Link>
                    {!sidebarCollapse && (
                      <div
                        id="tooltip0"
                        data-testid="tooltip0"
                        hidden={!hover0}
                        onMouseEnter={(e) =>
                          setHover0(
                            isMouseOutTooltip(e, 'tooltip0', 'parent0', 'right')
                          )
                        }
                        onMouseOut={(e) =>
                          setHover0(
                            isMouseOutTooltip(e, 'tooltip0', 'parent0', 'right')
                          )
                        }
                      >
                        <Tooltip
                          parentId="parent0"
                          childrenId="tooltip0"
                          offset={offset}
                        >
                          <Text variant="body.fontSize13">
                            {Trans(translations, 'News')}
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                  </Collapsible>
                  <Restricted subList="waffle_flags" to="tokko_messenger">
                    <Collapsible isOpen={open10}>
                      <ParentTooltip
                        onMouseOver={() => setHover10(true)}
                        onMouseOut={(e) =>
                          setHover10(isMouseOutParent(e, 'parent10', 'right'))
                        }
                        id="parent10"
                        aria-describedby="tooltip10"
                        setHover={setHover10}
                        hover={hover10}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => {
                            closeAllExcep(10);
                            dispatch(showTokkoChat(true));
                          }}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          widthImage="16px"
                          heightImage="16px"
                          srcImage="https://static.tokkobroker.com/static/img/cec.icon.svg"
                          text={sidebarCollapse && Trans(translations, 'Chat')}
                          isExpanded={open10}
                          setExpand={() => closeExpandExcep(10)}
                          active={active10}
                          width={sidebarCollapse ? '182px' : '32px'}
                          badgeValue={badges?.messenger_unreads}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentTooltip>
                      {!sidebarCollapse && (
                        <div
                          id="tooltip10"
                          data-testid="tooltip10"
                          hidden={!hover10}
                          onMouseOut={(e) =>
                            setHover10(
                              isMouseOutTooltip(
                                e,
                                'tooltip10',
                                'parent10',
                                'right'
                              )
                            )
                          }
                          onMouseEnter={(e) =>
                            setHover10(
                              isMouseOutTooltip(
                                e,
                                'tooltip10',
                                'parent10',
                                'right'
                              )
                            )
                          }
                        >
                          <Tooltip
                            parentId="parent10"
                            childrenId="tooltip10"
                            offset={offset}
                          >
                            <Text variant="body.fontSize13">
                              {Trans(translations, 'Chat')}
                            </Text>
                          </Tooltip>
                        </div>
                      )}
                    </Collapsible>
                  </Restricted>
                  <Collapsible isOpen={open1}>
                    <Link href={`${tokkoBrokerHost}/leads/`}>
                      <ParentSubmenu
                        onMouseOver={() => setHover1(true)}
                        onMouseOut={(e) =>
                          setHover1(isMouseOutParent(e, 'parent1', 'right'))
                        }
                        id="parent1"
                        aria-describedby="tooltip1"
                        setHover={setHover1}
                        hover={hover1}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(1)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-oportunidades"
                          text={sidebarCollapse && Trans(translations, 'Leads')}
                          isExpanded={open1}
                          setExpand={() => closeExpandExcep(1)}
                          active={active1}
                          isCollapsible={
                            sidebarCollapse &&
                            badges?.pending_leads_states?.length > 0
                          }
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          badgeValue={badges?.pending_leads}
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentSubmenu>
                    </Link>
                    {sidebarCollapse && (
                      <Collapsible.Body>
                        {badges?.pending_leads_states?.map((pendingLeads) => (
                          <Link
                            href={`${tokkoBrokerHost}/leads/?only_with_perm=true`}
                            key={pendingLeads.name}
                          >
                            <ButtonIcon
                              variant="mainItemSmall"
                              id={styles.buttonIconSubmenuDescollapse}
                              badgeValue={pendingLeads.counter}
                              text={pendingLeads.name}
                            />
                          </Link>
                        ))}
                      </Collapsible.Body>
                    )}
                    {!sidebarCollapse && (
                      <div
                        id="tooltip1"
                        data-testid="tooltip1"
                        hidden={!open1 && !hover1}
                        onMouseEnter={(e) =>
                          setHover1(
                            isMouseOutTooltip(e, 'tooltip1', 'parent1', 'right')
                          )
                        }
                        onMouseOut={(e) =>
                          setHover1(
                            isMouseOutTooltip(e, 'tooltip1', 'parent1', 'right')
                          )
                        }
                      >
                        <Submenu
                          parentId="parent1"
                          childrenId="tooltip1"
                          offset={offset}
                          width="202px"
                        >
                          <Text variant="submenuTitle">
                            {Trans(translations, 'Leads')}
                          </Text>
                          {badges?.pending_leads_states?.map((pendingLeads) => (
                            <Link
                              href={`${tokkoBrokerHost}/leads/?only_with_perm=true`}
                              key={pendingLeads.name}
                            >
                              <ButtonIcon
                                variant="submenu"
                                text={pendingLeads.name}
                                maxWidth
                                id={styles.buttonIconSubmenu}
                                badgeValue={pendingLeads.counter}
                              />
                            </Link>
                          ))}
                        </Submenu>
                      </div>
                    )}
                  </Collapsible>
                  <Collapsible isOpen={open2}>
                    <Link href={`${tokkoBrokerHost}/contacts/`}>
                      <ParentSubmenu
                        onMouseOver={() => setHover2(true)}
                        onMouseOut={(e) =>
                          setHover2(isMouseOutParent(e, 'parent2', 'right'))
                        }
                        id="parent2"
                        aria-describedby="tooltip2"
                        setHover={setHover2}
                        hover={hover2}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(2)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-contactos"
                          text={
                            sidebarCollapse && Trans(translations, 'Contacts')
                          }
                          isExpanded={open2}
                          setExpand={() => closeExpandExcep(2)}
                          active={active2}
                          isCollapsible={sidebarCollapse}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentSubmenu>
                    </Link>
                    {sidebarCollapse && (
                      <Collapsible.Body>
                        <Link href={`${tokkoBrokerHost}/contacts/#top`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'List')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/contacts/tags`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Tags')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/migration/migrations`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Migrations')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/contacts/deleted#top`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Deleted')}
                          </Button>
                        </Link>
                      </Collapsible.Body>
                    )}
                    {!sidebarCollapse && (
                      <div
                        id="tooltip2"
                        data-testid="tooltip2"
                        hidden={!open2 && !hover2}
                        onMouseOut={(e) =>
                          setHover2(
                            isMouseOutTooltip(e, 'tooltip2', 'parent2', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover2(
                            isMouseOutTooltip(e, 'tooltip2', 'parent2', 'right')
                          )
                        }
                      >
                        <Submenu
                          parentId="parent2"
                          childrenId="tooltip2"
                          offset={offset}
                          width="202px"
                        >
                          <Text variant="submenuTitle">
                            {Trans(translations, 'Contacts')}
                          </Text>
                          <Link href={`${tokkoBrokerHost}/contacts/#top`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'List')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/contacts/tags`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Tags')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/migration/migrations`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Migrations')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/contacts/deleted#top`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Deleted')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                        </Submenu>
                      </div>
                    )}
                  </Collapsible>
                  <Collapsible isOpen={open3}>
                    <Link href={`${tokkoBrokerHost}/properties`}>
                      <ParentSubmenu
                        onMouseOver={() => setHover3(true)}
                        onMouseOut={(e) =>
                          setHover3(isMouseOutParent(e, 'parent3', 'right'))
                        }
                        id="parent3"
                        aria-describedby="tooltip3"
                        setHover={setHover3}
                        hover={hover3}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(3)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-propiedades"
                          text={
                            sidebarCollapse && Trans(translations, 'Properties')
                          }
                          isExpanded={open3}
                          setExpand={() => closeExpandExcep(3)}
                          active={active3}
                          isCollapsible={sidebarCollapse}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentSubmenu>
                    </Link>
                    {sidebarCollapse && (
                      <Collapsible.Body>
                        <Link href={`${tokkoBrokerHost}/properties`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Search')}
                          </Button>
                        </Link>
                        <Link
                          href={`${tokkoBrokerHost}/properties/customtags/`}
                        >
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Tags')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/properties/full_map`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Map')}
                          </Button>
                        </Link>
                        <Link
                          href={`${tokkoBrokerHost}/properties/reservations`}
                        >
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Reserves')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/asiprop/go`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            Asiprop
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/properties/quotes`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Quotes')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/properties/deleted`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Deleted')}
                          </Button>
                        </Link>
                        <Link href="/properties/propertyimporter">
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Propertyimporter')}
                          </Button>
                        </Link>
                      </Collapsible.Body>
                    )}
                    {!sidebarCollapse && (
                      <div
                        id="tooltip3"
                        data-testid="tooltip3"
                        hidden={!open3 && !hover3}
                        onMouseOut={(e) =>
                          setHover3(
                            isMouseOutTooltip(e, 'tooltip3', 'parent3', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover3(
                            isMouseOutTooltip(e, 'tooltip3', 'parent3', 'right')
                          )
                        }
                      >
                        <Submenu
                          parentId="parent3"
                          childrenId="tooltip3"
                          offset={offset}
                          width="202px"
                        >
                          <Text variant="submenuTitle">
                            {Trans(translations, 'Properties')}
                          </Text>
                          <Link href={`${tokkoBrokerHost}/properties`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Search')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/properties/customtags/`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Tags')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/properties/full_map`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Map')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/properties/reservations`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Reserves')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/asiprop/go`}>
                            <ButtonIcon
                              variant="submenu"
                              text="Asiprop"
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/properties/quotes`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Quotes')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/properties/deleted`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Deleted')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href="/properties/propertyimporter">
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Propertyimporter')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                        </Submenu>
                      </div>
                    )}
                  </Collapsible>
                  <Collapsible isOpen={open4}>
                    <Link href={`${tokkoBrokerHost}/developments/`}>
                      <ParentSubmenu
                        onMouseOver={() => setHover4(true)}
                        onMouseOut={(e) =>
                          setHover4(isMouseOutParent(e, 'parent4', 'right'))
                        }
                        id="parent4"
                        aria-describedby="tooltip4"
                        setHover={setHover4}
                        hover={hover4}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(4)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-emprendimientos"
                          text={
                            sidebarCollapse &&
                            Trans(translations, 'Developments')
                          }
                          isExpanded={open4}
                          setExpand={() => closeExpandExcep(4)}
                          active={active4}
                          isCollapsible={sidebarCollapse}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentSubmenu>
                    </Link>
                    {sidebarCollapse && (
                      <Collapsible.Body>
                        <Link href={`${tokkoBrokerHost}/developments/`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'List')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/developments/full_map`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Map')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/developments/deleted`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Deleted')}
                          </Button>
                        </Link>
                      </Collapsible.Body>
                    )}
                    {!sidebarCollapse && (
                      <div
                        id="tooltip4"
                        data-testid="tooltip4"
                        hidden={!open4 && !hover4}
                        onMouseOut={(e) =>
                          setHover4(
                            isMouseOutTooltip(e, 'tooltip4', 'parent4', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover4(
                            isMouseOutTooltip(e, 'tooltip4', 'parent4', 'right')
                          )
                        }
                      >
                        <Submenu
                          parentId="parent4"
                          childrenId="tooltip4"
                          offset={offset}
                          width="202px"
                        >
                          <Text variant="submenuTitle">
                            {Trans(translations, 'Developments')}
                          </Text>
                          <Link href={`${tokkoBrokerHost}/developments/`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'List')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/developments/full_map`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Map')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/developments/deleted`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Deleted')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                        </Submenu>
                      </div>
                    )}
                  </Collapsible>
                  <Collapsible isOpen={open5}>
                    <Link href={`${tokkoBrokerHost}/company/`}>
                      <ParentSubmenu
                        onMouseOver={() => setHover5(true)}
                        onMouseOut={(e) =>
                          setHover5(isMouseOutParent(e, 'parent5', 'right'))
                        }
                        id="parent5"
                        aria-describedby="tooltip5"
                        setHover={setHover5}
                        hover={hover5}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(5)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-empresa"
                          text={
                            sidebarCollapse && Trans(translations, 'Mycompany')
                          }
                          isExpanded={open5}
                          setExpand={() => closeExpandExcep(5)}
                          active={active5}
                          isCollapsible={sidebarCollapse}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentSubmenu>
                    </Link>
                    {sidebarCollapse && (
                      <Collapsible.Body>
                        <Link href={`${tokkoBrokerHost}/company/users`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Usermanagement')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/configuration`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Configuration')}
                          </Button>
                        </Link>
                        <Link
                          href={`${tokkoBrokerHost}/company/leads_configuration`}
                        >
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Leadsconfiguration')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/properties`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Propertiesconfiguration')}
                          </Button>
                        </Link>
                        <Link
                          href={`${tokkoBrokerHost}/company/configure_reservations`}
                        >
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Reservesconfiguration')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/billing`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Billing')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/file/manager`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Filesmanager')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/permissions`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Permissions')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/load_pfdconfig`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'PDFtemplates')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/fast_reply/config/`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Fastreplies')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/mailing/auto_mailing`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Automaticmonitoring')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/branches`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Branchesanddivisions')}
                          </Button>
                        </Link>
                        <Link href={`${tokkoBrokerHost}/company/event_types`}>
                          <Button
                            variant="mainItemSmall"
                            id={styles.buttonIconSubmenuDescollapse}
                          >
                            {Trans(translations, 'Eventtypes')}
                          </Button>
                        </Link>
                      </Collapsible.Body>
                    )}
                    {!sidebarCollapse && (
                      <div
                        id="tooltip5"
                        data-testid="tooltip5"
                        hidden={!open5 && !hover5}
                        onMouseOut={(e) =>
                          setHover5(
                            isMouseOutTooltip(e, 'tooltip5', 'parent5', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover5(
                            isMouseOutTooltip(e, 'tooltip5', 'parent5', 'right')
                          )
                        }
                      >
                        <Submenu
                          parentId="parent5"
                          childrenId="tooltip5"
                          offset={offset}
                          width="202px"
                        >
                          <Text variant="submenuTitle">Empresa</Text>
                          <Link href={`${tokkoBrokerHost}/company/users`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Usermanagement')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/company/configuration`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Configuration')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/company/leads_configuration`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Leadsconfiguration')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/company/properties`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(
                                translations,
                                'Propertiesconfiguration'
                              )}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/company/configure_reservations`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(
                                translations,
                                'Reservesconfiguration'
                              )}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/company/billing`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Billing')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/company/file/manager`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Filesmanager')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/company/permissions`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Permissions')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/load_pfdconfig`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'PDFtemplates')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/fast_reply/config/`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Fastreplies')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link
                            href={`${tokkoBrokerHost}/mailing/auto_mailing`}
                          >
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Automaticmonitoring')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/company/branches`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Branchesanddivisions')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                          <Link href={`${tokkoBrokerHost}/company/event_types`}>
                            <ButtonIcon
                              variant="submenu"
                              text={Trans(translations, 'Eventtypes')}
                              maxWidth
                              id={styles.buttonIconSubmenu}
                            />
                          </Link>
                        </Submenu>
                      </div>
                    )}
                  </Collapsible>
                  <Restricted to="marketing">
                    <Collapsible isOpen={open6}>
                      <Link href={`${tokkoBrokerHost}/marketing/webcontact/`}>
                        <ParentTooltip
                          onMouseOver={() => setHover6(true)}
                          onMouseOut={(e) =>
                            setHover6(isMouseOutParent(e, 'parent6', 'right'))
                          }
                          id="parent6"
                          aria-describedby="tooltip6"
                          setHover={setHover6}
                          hover={hover6}
                          offset={offset}
                        >
                          <Collapsible.Button
                            isMainButton={sidebarCollapse}
                            isSmallButtonIcon={!sidebarCollapse}
                            onClick={() => closeAllExcep(6)}
                            variant={
                              sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                            }
                            icon="icon-consultas"
                            text={
                              sidebarCollapse &&
                              Trans(translations, 'Inquiries')
                            }
                            isExpanded={open6}
                            setExpand={() => closeExpandExcep(6)}
                            active={active6}
                            width={sidebarCollapse ? '182px' : '32px'}
                            maxWidth
                            holdPress
                            badgeValue={badges?.inquiries}
                            className={
                              sidebarCollapse ? styles.transition : undefined
                            }
                          />
                        </ParentTooltip>
                      </Link>
                      {!sidebarCollapse && (
                        <div
                          id="tooltip6"
                          data-testid="tooltip6"
                          hidden={!hover6}
                          onMouseOut={(e) =>
                            setHover6(
                              isMouseOutTooltip(
                                e,
                                'tooltip6',
                                'parent6',
                                'right'
                              )
                            )
                          }
                          onMouseEnter={(e) =>
                            setHover6(
                              isMouseOutTooltip(
                                e,
                                'tooltip6',
                                'parent6',
                                'right'
                              )
                            )
                          }
                        >
                          <Tooltip
                            parentId="parent6"
                            childrenId="tooltip6"
                            offset={offset}
                          >
                            <Text variant="body.fontSize13">
                              {Trans(translations, 'Inquiries')}
                            </Text>
                          </Tooltip>
                        </div>
                      )}
                    </Collapsible>
                  </Restricted>
                  <Restricted
                    to="marketing"
                    toComponent="can_publish_in_portals"
                  >
                    <Collapsible isOpen={open7}>
                      <Link href={`${tokkoBrokerHost}/portals/portals`}>
                        <ParentSubmenu
                          onMouseOver={() => setHover7(true)}
                          onMouseOut={(e) =>
                            setHover7(isMouseOutParent(e, 'parent7', 'right'))
                          }
                          id="parent7"
                          aria-describedby="tooltip7"
                          setHover={setHover7}
                          hover={hover7}
                          offset={offset}
                        >
                          <Collapsible.Button
                            isMainButton={sidebarCollapse}
                            isSmallButtonIcon={!sidebarCollapse}
                            onClick={() => closeAllExcep(7)}
                            variant={
                              sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                            }
                            icon="icon-difusion"
                            text={
                              sidebarCollapse &&
                              Trans(translations, 'Diffusion')
                            }
                            isExpanded={open7}
                            setExpand={() => closeExpandExcep(7)}
                            active={active7}
                            isCollapsible={sidebarCollapse}
                            width={sidebarCollapse ? '182px' : '32px'}
                            maxWidth
                            holdPress
                            className={
                              sidebarCollapse ? styles.transition : undefined
                            }
                          />
                        </ParentSubmenu>
                      </Link>
                      {sidebarCollapse && (
                        <Collapsible.Body>
                          <Link href={`${tokkoBrokerHost}/marketing/carrousel`}>
                            <Button
                              variant="mainItemSmall"
                              id={styles.buttonIconSubmenuDescollapse}
                            >
                              {Trans(translations, 'Carrousel')}
                            </Button>
                          </Link>
                        </Collapsible.Body>
                      )}
                      {!sidebarCollapse && (
                        <div
                          id="tooltip7"
                          data-testid="tooltip7"
                          hidden={!open7 && !hover7}
                          onMouseOut={(e) =>
                            setHover7(
                              isMouseOutTooltip(
                                e,
                                'tooltip7',
                                'parent7',
                                'right'
                              )
                            )
                          }
                          onMouseEnter={(e) =>
                            setHover6(
                              isMouseOutTooltip(
                                e,
                                'tooltip7',
                                'parent7',
                                'right'
                              )
                            )
                          }
                        >
                          <Submenu
                            parentId="parent7"
                            childrenId="tooltip7"
                            offset={offset}
                            width="202px"
                          >
                            <Text variant="submenuTitle">
                              {Trans(translations, 'Diffusion')}
                            </Text>
                            <Link
                              href={`${tokkoBrokerHost}/marketing/carrousel`}
                            >
                              <ButtonIcon
                                variant="submenu"
                                text={Trans(translations, 'Carrousel')}
                                maxWidth
                                id={styles.buttonIconSubmenu}
                              />
                            </Link>
                          </Submenu>
                        </div>
                      )}
                    </Collapsible>
                  </Restricted>
                  <Collapsible isOpen={open8}>
                    <Link href={`${tokkoBrokerHost}/reports`}>
                      <ParentTooltip
                        onMouseOver={() => setHover8(true)}
                        onMouseOut={(e) =>
                          setHover8(isMouseOutParent(e, 'parent8', 'right'))
                        }
                        id="parent8"
                        aria-describedby="tooltip8"
                        setHover={setHover8}
                        hover={hover8}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(8)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-reportes"
                          text={
                            sidebarCollapse && Trans(translations, 'Reports')
                          }
                          isExpanded={open8}
                          setExpand={() => closeExpandExcep(8)}
                          active={active8}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentTooltip>
                    </Link>
                    {!sidebarCollapse && (
                      <div
                        id="tooltip8"
                        data-testid="tooltip8"
                        hidden={!hover8}
                        onMouseOut={(e) =>
                          setHover8(
                            isMouseOutTooltip(e, 'tooltip8', 'parent8', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover8(
                            isMouseOutTooltip(e, 'tooltip8', 'parent8', 'right')
                          )
                        }
                      >
                        <Tooltip
                          parentId="parent8"
                          childrenId="tooltip8"
                          offset={offset}
                        >
                          <Text variant="body.fontSize13">
                            {Trans(translations, 'Reports')}
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                  </Collapsible>
                  <Collapsible isOpen={open9}>
                    <Link href={`${tokkoBrokerHost}/webmanager`}>
                      <ParentTooltip
                        onMouseOver={() => setHover9(true)}
                        onMouseOut={(e) =>
                          setHover9(isMouseOutParent(e, 'parent9', 'right'))
                        }
                        id="parent9"
                        aria-describedby="tooltip9"
                        setHover={setHover9}
                        hover={hover9}
                        offset={offset}
                      >
                        <Collapsible.Button
                          isMainButton={sidebarCollapse}
                          isSmallButtonIcon={!sidebarCollapse}
                          onClick={() => closeAllExcep(9)}
                          variant={
                            sidebarCollapse ? 'mainButton' : 'mainButtonIcon'
                          }
                          icon="icon-sitioweb"
                          text={
                            sidebarCollapse && Trans(translations, 'Websites')
                          }
                          isExpanded={open9}
                          setExpand={() => closeExpandExcep(9)}
                          active={active9}
                          width={sidebarCollapse ? '182px' : '32px'}
                          maxWidth
                          holdPress
                          className={
                            sidebarCollapse ? styles.transition : undefined
                          }
                        />
                      </ParentTooltip>
                    </Link>
                    {!sidebarCollapse && (
                      <div
                        id="tooltip9"
                        data-testid="tooltip9"
                        hidden={!hover9}
                        onMouseOut={(e) =>
                          setHover9(
                            isMouseOutTooltip(e, 'tooltip9', 'parent9', 'right')
                          )
                        }
                        onMouseEnter={(e) =>
                          setHover9(
                            isMouseOutTooltip(e, 'tooltip9', 'parent9', 'right')
                          )
                        }
                      >
                        <Tooltip
                          parentId="parent9"
                          childrenId="tooltip9"
                          offset={offset}
                        >
                          <Text variant="body.fontSize13">
                            {Trans(translations, 'Websites')}
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                  </Collapsible>
                </Box>
              </Drawer.Body>
            </Box>
            <Drawer.Footer />
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Sidebar;
