import { useSelector } from 'react-redux';
import { Box } from '@oneloop/jopijs';
import PropertyInfo from './PopertyInfo';
import MyFiles from './MyFiles';
import MySendings from './MySendings';
import style from '../QuickDisplay.module.scss';
import ZonaPropForm from './ZonaPropForm';

export default function Kebab() {
  const { kebabId, isOpenNavent } = useSelector(
    (state) => state.QuickDisplaySlice
  );

  const Options = {
    1: isOpenNavent ? <ZonaPropForm /> : <PropertyInfo />,
    2: <MySendings />,
    3: <MyFiles />,
  };

  return <Box className={style.kebabCont}>{Options[kebabId]}</Box>;
}
