import { Box, Heading, Checkbox, Text, InputNumber } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import style from './NotificationsContent.module.scss';
import { changerValue } from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './NotificationsContent.trans.json';
import Trans from '@/components/shared/Trans';

export default function NotificationsContent() {
  const { notifications, edit } = useSelector((state) => state.CalendarEvents);
  const dispatch = useDispatch();
  const {
    contactEmailBefore,
    agentNotioficationBefore,
    agentEmailBefore,
    agentNotificationAfter,
    agentEmailAfter,
  } = notifications;

  return (
    <Box __css={{ marginTop: '30px' }} className={style.container}>
      <Box className={style.notifBefore}>
        <Heading as="h4" variant="heading.3">
          {Trans(translations, 'NotificationsBeforeEvents')}
        </Heading>
        <Box className={style.row}>
          <Checkbox
            style={{ width: '18px', height: '18px' }}
            checked={contactEmailBefore}
            onClick={() =>
              dispatch(
                changerValue({
                  block: 'notifications',
                  key: 'contactEmailBefore',
                  value: contactEmailBefore ? false : 60,
                })
              )
            }
          />
          <Text className={style.text} variant="body.fontSize14">
            {Trans(translations, 'ContactsByEmail')}
          </Text>
          <InputNumber
            disabled={edit.editPanel}
            text={Trans(translations, 'MinutesBefore')}
            startVal={contactEmailBefore || 0}
            val={(n) =>
              dispatch(
                changerValue({
                  block: 'notifications',
                  key: 'contactEmailBefore',
                  value: n === 0 ? false : n,
                })
              )
            }
          />
        </Box>

        <Box className={style.row}>
          <Checkbox
            style={{ width: '18px', height: '18px' }}
            checked={agentNotioficationBefore}
            onClick={() =>
              dispatch(
                changerValue({
                  block: 'notifications',
                  key: 'agentNotioficationBefore',
                  value: agentNotioficationBefore ? false : 60,
                })
              )
            }
          />
          <Text className={style.text} variant="body.fontSize14">
            {Trans(translations, 'AgentByNotifications')}
          </Text>
          <Box>
            <InputNumber
              disabled={edit.editPanel}
              text={Trans(translations, 'MinutesBefore')}
              startVal={agentNotioficationBefore || 0}
              val={(n) => {
                dispatch(
                  changerValue({
                    block: 'notifications',
                    key: 'agentNotioficationBefore',
                    value: n === 0 ? false : n,
                  })
                );

                if (!agentNotioficationBefore) {
                  dispatch(
                    changerValue({
                      block: 'notifications',
                      key: 'agentEmailBefore',
                      value: false,
                    })
                  );
                }
              }}
            />
            <Box className={style.byMail}>
              <Checkbox
                style={{ width: '18px', height: '18px' }}
                checked={agentEmailBefore}
                onClick={() => {
                  dispatch(
                    changerValue({
                      block: 'notifications',
                      key: 'agentEmailBefore',
                      value: !agentEmailBefore,
                    })
                  );

                  if (!agentEmailBefore) {
                    dispatch(
                      changerValue({
                        block: 'notifications',
                        key: 'agentNotioficationBefore',
                        value: agentNotioficationBefore || 60,
                      })
                    );
                  }
                }}
              />
              <Text className={style.text} variant="body.fontSize14">
                {Trans(translations, 'ByEmailAlso')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={style.notiAfter}>
        <Box className={style.tileNotiAfter}>
          <Heading as="h4" variant="heading.3">
            {Trans(translations, 'NotificationsAfterEvents')}
          </Heading>
          <Text variant="body.fontSize14" className={style.afterText}>
            {Trans(translations, 'SendNotificationsReportNotComplete')}
          </Text>
        </Box>
        <Box className={style.contAfterRow}>
          <Box className={style.row}>
            <Checkbox
              style={{ width: '18px', height: '18px' }}
              checked={agentNotificationAfter}
              onClick={() => {
                dispatch(
                  changerValue({
                    block: 'notifications',
                    key: 'agentNotificationAfter',
                    value: !agentNotificationAfter,
                  })
                );

                if (agentNotificationAfter) {
                  dispatch(
                    changerValue({
                      block: 'notifications',
                      key: 'agentEmailAfter',
                      value: false,
                    })
                  );
                }
              }}
            />
            <Text className={style.text} variant="body.fontSize14">
              {Trans(translations, 'AgentByNotifications')}
            </Text>
          </Box>
          <Box className={style.row}>
            <Checkbox
              style={{ width: '18px', height: '18px' }}
              checked={agentEmailAfter}
              onClick={() => {
                dispatch(
                  changerValue({
                    block: 'notifications',
                    key: 'agentEmailAfter',
                    value: !agentEmailAfter,
                  })
                );

                if (!agentEmailAfter) {
                  dispatch(
                    changerValue({
                      block: 'notifications',
                      key: 'agentNotificationAfter',
                      value: true,
                    })
                  );
                }
              }}
            />
            <Text className={style.text} variant="body.fontSize14">
              {Trans(translations, 'ByEmailAlso')}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
