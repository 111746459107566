import React from 'react';
import { ButtonIcon, ContainerMenu, List } from '@oneloop/jopijs';
import styles from './Actions.module.scss';
import translations from './Actions.trans.json';
import Trans from '@/components/shared/Trans';

function Actions({ actionsData }) {
  return (
    <ContainerMenu
      width="252px"
      prefixIcon="icon-acciones"
      titleText={Trans(translations, 'Actions')}
    >
      <List className={styles.containerActions}>
        {actionsData.map((action) => (
          <ButtonIcon
            icon={action.icon}
            variant="mainButtonList"
            text={action.text}
            key={action.id}
          />
        ))}
      </List>
    </ContainerMenu>
  );
}

export default Actions;
