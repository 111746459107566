/* eslint-disable max-len */
import { Box, Button, Card, Checkbox, Image, Text } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import styles from './Developments.module.scss';
import emptyDevelopment from '../../../../../public/images/layout/emptyDevelopment.svg';
import ImageNext from '@/components/shared/ImageNext';
import translations from './Developments.trans.json';
import Trans from '@/components/shared/Trans';
import SkeletonFavorite from '../SkeletonFavorite/SkeletonFavorite';

function Developments({
  dataDevelopments,
  listDevelopments,
  setListDevelopments,
  updateList,
  selectAll,
  isLoading,
}) {
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    if (dataDevelopments.length === 0) {
      setShowEmptyState(false);
      const timer = setTimeout(() => {
        setShowEmptyState(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    setShowEmptyState(true);
    return undefined;
  }, []);

  if (!showEmptyState || isLoading) return <SkeletonFavorite />;

  return showEmptyState && dataDevelopments.length === 0 ? (
    <Box className={styles.containerEmpty}>
      <ImageNext src={emptyDevelopment} unoptimized alt="emptyDevelopment" />
      <Text
        variant="body.fontSize12"
        className={`${styles.emptyText} ${styles.colorNeutralGray3}`}
      >
        {Trans(translations, 'favoritesDevelopments.Emptymessage')}
      </Text>
      <a
        target="_blank"
        href="https://dudas.tokkobroker.com/es/articles/2216621-como-marcar-una-propiedad-como-favorita"
        rel="noreferrer"
      >
        <Button variant="linkTwoLines">
          {Trans(translations, 'favoritesDevelopments.Howtosaveasfavorite')}
        </Button>
      </a>
    </Box>
  ) : (
    <Box className={styles.container}>
      <Card
        variant="normal"
        width="224px"
        active={listDevelopments.length === dataDevelopments.length}
      >
        <Checkbox
          key={listDevelopments}
          onClick={() =>
            selectAll(dataDevelopments, listDevelopments, setListDevelopments)
          }
          isChecked={listDevelopments.length === dataDevelopments.length}
        />
        <Text variant="body.fontSize12" className={styles.text}>
          {Trans(translations, 'Selectall')}
        </Text>
      </Card>
      {dataDevelopments.map((development) => (
        <Card
          id={development.id}
          key={development.id}
          variant="normal"
          width="224px"
          active={listDevelopments.includes(development.id)}
        >
          <Checkbox
            key={listDevelopments}
            onClick={() =>
              updateList(listDevelopments, setListDevelopments, development.id)
            }
            isChecked={listDevelopments.includes(development.id)}
          />

          <Image
            variant="rows"
            src={development.picture}
            alt={development.id}
            icon="icon-emprendimientos"
          />

          <Box className={styles.boxContainerText}>
            <Text
              variant="bodyBold.fontSize10"
              className={
                development.status === 1
                  ? styles.colorReserved
                  : styles.colorAvailable
              }
            >
              {development.status === 1 ? 'Cargando' : 'Disponible'}
            </Text>
            <Text variant="body.fontSize12" className={styles.name}>
              {development.name}
            </Text>
            <Text
              variant={['body.fontSize11', 'ellipsis']}
              className={styles.colorNeutralGray3}
            >
              {development.address}
            </Text>
          </Box>
        </Card>
      ))}
    </Box>
  );
}

export default Developments;
