import { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Image,
  Text,
} from '@oneloop/jopijs';
import { useDispatch } from 'react-redux';
import styles from './Properties.module.scss';
import emptyPropertie from '../../../../../public/images/layout/emptyPropertie.svg';
import ImageNext from '@/components/shared/ImageNext';
import translations from './Properties.trans.json';
import Trans from '@/components/shared/Trans';
import SkeletonFavorite from '../SkeletonFavorite/SkeletonFavorite';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';

function Properties({
  dataProperties,
  listProperties,
  setListProperties,
  updateList,
  selectAll,
  isLoading,
}) {
  if (isLoading) return <SkeletonFavorite />;
  const dispatch = useDispatch();
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    if (dataProperties.length === 0) {
      setShowEmptyState(false);
      const timer = setTimeout(() => {
        setShowEmptyState(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    setShowEmptyState(true);
    return undefined;
  }, []);

  if (!showEmptyState || isLoading) return <SkeletonFavorite />;

  const statusMapping = {
    1: { className: styles.colorAppraisal, translation: 'Appraisal' },
    2: { className: styles.colorAvailable, translation: 'Available' },
    3: { className: styles.colorReserved, translation: 'Reserved' },
    4: { className: styles.colorNotAvailable, translation: 'NotAvailable' },
  };

  return showEmptyState && dataProperties.length === 0 ? (
    <Box className={styles.containerEmpty}>
      <ImageNext src={emptyPropertie} unoptimized alt="emptyPropertie" />
      <Text
        variant="body.fontSize12"
        className={`${styles.emptyText} ${styles.colorNeutralGray3}`}
      >
        {Trans(translations, 'favoritesProperties.Emptymessage')}
      </Text>
      <a
        target="_blank"
        href="https://dudas.tokkobroker.com/es/articles/2216621-como-marcar-una-propiedad-como-favorita"
        rel="noreferrer"
      >
        <Button variant="linkTwoLines">
          {Trans(translations, 'favoritesProperties.Howtosaveasfavorite')}
        </Button>
      </a>
    </Box>
  ) : (
    <Box className={styles.container}>
      <Card
        variant="search"
        width="224px"
        active={listProperties.length === dataProperties.length}
      >
        <Checkbox
          key={listProperties}
          onClick={() =>
            selectAll(dataProperties, listProperties, setListProperties)
          }
          isChecked={listProperties.length === dataProperties.length}
        />
        <Text variant="body.fontSize12" className={styles.text}>
          {Trans(translations, 'Selectall')}
        </Text>
      </Card>
      {dataProperties.map((propertie) => {
        const { className, translation } =
          statusMapping[propertie.status] || {};

        return (
          <Card
            id={propertie.id}
            key={propertie.id}
            variant="normal"
            width="224px"
            active={listProperties.includes(propertie.id)}
          >
            <Checkbox
              key={listProperties}
              onClick={() =>
                updateList(listProperties, setListProperties, propertie.id)
              }
              isChecked={listProperties.includes(propertie.id)}
            />
            <Box
              className={styles.cont}
              onClick={() => dispatch(showQuickDisplay({ id: propertie.id }))}
            >
              <Image
                variant="rows"
                src={propertie.default_picture}
                alt={propertie.id}
                icon="icon-propiedades"
              />
              <Box className={styles.boxContainerText}>
                <Text variant="bodyBold.fontSize10" className={className}>
                  {Trans(translations, translation)}
                </Text>
                <Text variant="body.fontSize11">{propertie.ref_code}</Text>
                <Text
                  variant={['body.fontSize11', 'ellipsis']}
                  className={styles.colorNeutralGray3}
                >
                  {propertie.address}
                </Text>
                {propertie.network_share !== undefined &&
                  propertie.network_share > 0 && (
                    <Badge
                      variant={['badgeNormal', 'badgeTips']}
                      id={styles.badgeTips}
                    >
                      {propertie.network_share}%
                    </Badge>
                  )}
              </Box>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
}

export default Properties;
