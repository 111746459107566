import { Box } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import { Avatar } from 'stream-chat-react';
import styles from './AvatarGroup.module.scss';
import ImageNext from '@/components/shared/ImageNext';
import useGetDataUser from '../../../Hooks/useGetDataUser';
import UseChannelTypeActions from '@/components/Messenger/Hooks/useChannelTypeActions';

export default function AvatarGroup({ channel }) {
  const [image, setImage] = useState('');
  const user = useGetDataUser(channel);
  const ChannelIconMap = UseChannelTypeActions(channel);

  useEffect(() => {
    const getImage = () => {
      // Prioridad: imagen del canal o del invitado
      if (channel.data?.image) return channel.data.image;
      if (channel.data?.guest_image) return channel.data.guest_image;

      // Generar avatar con el nombre del canal si no hay imagen
      if (channel.data?.name)
        return `https://ui-avatars.com/api/?name=${channel.data.name}`;

      if (channel.data?.channel_header?.description) {
        return `https://ui-avatars.com/api/?name=${channel.data.channel_header.description}`;
      }

      return user?.image || '';
    };

    setImage(getImage());
  }, [channel, user]);

  const { frozen } = channel.data;

  return (
    <Box
      className={`${styles.avatarContainer} ${
        frozen ? styles.avatarGrayScale : ''
      }`}
    >
      {image ? <Avatar image={image} shape="rounded" size={46} /> : null}

      {ChannelIconMap && (
        <Box
          className={`${styles.profileIconContainer} ${
            styles[ChannelIconMap.class]
          }`}
        >
          <Box className={styles.iconContainer}>
            <ImageNext alt="logo" src={ChannelIconMap.fileIcon} unoptimized />
          </Box>
        </Box>
      )}
    </Box>
  );
}
