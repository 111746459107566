/* eslint-disable react/no-array-index-key */
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';

export default function Atributes() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);

  if (!data) {
    return (
      <Box
        __css={{
          display: 'flex',
          gap: '48px',
          alignItems: 'center',
          marginTop: '38px',
        }}
      >
        {Array(5)
          .fill()
          .map((arr, i) => (
            <Box
              key={`${i} + 100`}
              __css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Skeletons width={16} borderRadius={4} height={16} />
              <Skeletons width={53} borderRadius={50} height={8} />
            </Box>
          ))}
      </Box>
    );
  }

  return data.attributes_list.length > 0 ? (
    <Box className={style.atrubutesContainer}>
      {data.attributes_list.map((item, index) => (
        <Box key={index} className={style.atrubutesItem}>
          <Icon icon={item.icon} fontSize="20px" />
          <Text variant="bodyBold.fontSize12">{item.value}</Text>
        </Box>
      ))}
    </Box>
  ) : null;
}
