/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  ButtonIcon,
  Text,
  Tabs,
  Icon,
  Heading,
} from '@oneloop/jopijs';
import styles from '../CustomChannelHeader.module.scss';

const fetchImages = async () => [
  'https://us-east.stream-io-cdn.com/1212260/images/53d819ce-3324-4d19-8bee-2bceb2d39d1d.in-conversation-with-chatgpt-can-ai-design-a.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzUzZDgxOWNlLTMzMjQtNGQxOS04YmVlLTJiY2ViMmQzOWQxZC5pbi1jb252ZXJzYXRpb24td2l0aC1jaGF0Z3B0LWNhbi1haS1kZXNpZ24tYS5qcGcqIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzExNzIwNTY2fX19XX0_&Signature=kmXNfVyENwFkyydCUZ~uLtDWHHa0q6pl7LuQMs3V6zvV6T3E4cOQh1cYS0lCUe37Uf-c-aCKZCDQGVn0Wl9HpgYT~B3BjbZ3~OtRZ~DA0nKyv1Rzjevx1rCAqW9Mg5VLcv8S4SJVE~7Y2LSH52UQukfB8LHT4jSDIyCwu98G7wZcPdVv-YtnTsEVYUBZHeBOrQBNaLRhnFW6~GqLkXskscEQvyAibYjYy2pTvFb03Ecma-6OAA2QWSPlUtYKIYwwtEiBvQ5Ok9mcN1b2qLnMLmMElNrSoLnsemI5juDgYEy5AbQh3b0WaImPpQOFdazGlRxDclwdy8LPC74DsTaZGQ__',
  'https://us-east.stream-io-cdn.com/1212260/images/2f1b5d46-6da7-4a07-9f75-8eb49f7e6b8e.imagencasaaa.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzJmMWI1ZDQ2LTZkYTctNGEwNy05Zjc1LThlYjQ5ZjdlNmI4ZS5pbWFnZW5jYXNhYWEuanBnKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcxMTcyMDU1NH19fV19&Signature=hjIhUp6iF9Vin6hxkXyUK26sXD9cm5n4lAIglRSPimGe9UimqjF-tmG9vWVc3pY2l~cFBLSn3M81BBVn54muOmOsootUB3UosP~e64TP97MExUgbNgXlAKOprlbWdGWIdHHTVH9k6gGKuAui665djVychzGPRjJ64RIuDU-Ts1nNa06cdCQtIO5ruhthy5njwRc1Qu0r77nXMTCLKflipVXLZS0vLqb4ebbi0TGUlDdvhGZR3IOf8dzHO-KKJZYPHaA8iY1SC7mVbyF-XFIPfKnWCfald7H1MeaBVE8HRACaM2iUnwJFYhNcX1BhdkEtqludKcNcvcB3~1WhUkqqpA__',
  'https://us-east.stream-io-cdn.com/1212260/images/53d819ce-3324-4d19-8bee-2bceb2d39d1d.in-conversation-with-chatgpt-can-ai-design-a.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzUzZDgxOWNlLTMzMjQtNGQxOS04YmVlLTJiY2ViMmQzOWQxZC5pbi1jb252ZXJzYXRpb24td2l0aC1jaGF0Z3B0LWNhbi1haS1kZXNpZ24tYS5qcGcqIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzExNzIwNTY2fX19XX0_&Signature=kmXNfVyENwFkyydCUZ~uLtDWHHa0q6pl7LuQMs3V6zvV6T3E4cOQh1cYS0lCUe37Uf-c-aCKZCDQGVn0Wl9HpgYT~B3BjbZ3~OtRZ~DA0nKyv1Rzjevx1rCAqW9Mg5VLcv8S4SJVE~7Y2LSH52UQukfB8LHT4jSDIyCwu98G7wZcPdVv-YtnTsEVYUBZHeBOrQBNaLRhnFW6~GqLkXskscEQvyAibYjYy2pTvFb03Ecma-6OAA2QWSPlUtYKIYwwtEiBvQ5Ok9mcN1b2qLnMLmMElNrSoLnsemI5juDgYEy5AbQh3b0WaImPpQOFdazGlRxDclwdy8LPC74DsTaZGQ__',
  'https://us-east.stream-io-cdn.com/1212260/images/53d819ce-3324-4d19-8bee-2bceb2d39d1d.in-conversation-with-chatgpt-can-ai-design-a.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzUzZDgxOWNlLTMzMjQtNGQxOS04YmVlLTJiY2ViMmQzOWQxZC5pbi1jb252ZXJzYXRpb24td2l0aC1jaGF0Z3B0LWNhbi1haS1kZXNpZ24tYS5qcGcqIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzExNzIwNTY2fX19XX0_&Signature=kmXNfVyENwFkyydCUZ~uLtDWHHa0q6pl7LuQMs3V6zvV6T3E4cOQh1cYS0lCUe37Uf-c-aCKZCDQGVn0Wl9HpgYT~B3BjbZ3~OtRZ~DA0nKyv1Rzjevx1rCAqW9Mg5VLcv8S4SJVE~7Y2LSH52UQukfB8LHT4jSDIyCwu98G7wZcPdVv-YtnTsEVYUBZHeBOrQBNaLRhnFW6~GqLkXskscEQvyAibYjYy2pTvFb03Ecma-6OAA2QWSPlUtYKIYwwtEiBvQ5Ok9mcN1b2qLnMLmMElNrSoLnsemI5juDgYEy5AbQh3b0WaImPpQOFdazGlRxDclwdy8LPC74DsTaZGQ__',
  'https://us-east.stream-io-cdn.com/1212260/images/87d9ab13-0376-4442-a386-f51e7f8bb7be.planetas-de-cerca_5120x2880_xtrafondos.com.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzg3ZDlhYjEzLTAzNzYtNDQ0Mi1hMzg2LWY1MWU3ZjhiYjdiZS5wbGFuZXRhcy1kZS1jZXJjYV81MTIweDI4ODBfeHRyYWZvbmRvcy5jb20uanBnKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcxMTcyMjMzOH19fV19&Signature=Kkt%7E6gKe5hgfuAY08Z1Q9z7GMtaX1bCffIhr8q2GtK0eha2R5m78%7Ez1zXQWpdL0jD3PYcN0GVbyB0IInV3bnKIxIYE-jjTUIdMIFVKQSjzrU9x50xecIUvyyhjbQxtMhWO3Omje9NX932bZQDDi7LKhrYcuGPCeyQjQl%7E1aiizoFH1yqlXru6Myv%7E7L4u7Wi70uy4%7EnIafpYM51T64Knxe5XEa2EwebZsc198X6q5TZ-6ehV6SjcGURldqJ4olIaDSXU8DGUDx49n5x34Ejz6bgniOggNVun-MwkfZ9BxMWU71lxLAMeBT5L8%7E7gJaSbpizakxEyAbXApuCWBe2PQA__&h=952&w=952',
  'https://us-east.stream-io-cdn.com/1212260/images/87d9ab13-0376-4442-a386-f51e7f8bb7be.planetas-de-cerca_5120x2880_xtrafondos.com.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzLzg3ZDlhYjEzLTAzNzYtNDQ0Mi1hMzg2LWY1MWU3ZjhiYjdiZS5wbGFuZXRhcy1kZS1jZXJjYV81MTIweDI4ODBfeHRyYWZvbmRvcy5jb20uanBnKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcxMTcyMjMzOH19fV19&Signature=Kkt%7E6gKe5hgfuAY08Z1Q9z7GMtaX1bCffIhr8q2GtK0eha2R5m78%7Ez1zXQWpdL0jD3PYcN0GVbyB0IInV3bnKIxIYE-jjTUIdMIFVKQSjzrU9x50xecIUvyyhjbQxtMhWO3Omje9NX932bZQDDi7LKhrYcuGPCeyQjQl%7E1aiizoFH1yqlXru6Myv%7E7L4u7Wi70uy4%7EnIafpYM51T64Knxe5XEa2EwebZsc198X6q5TZ-6ehV6SjcGURldqJ4olIaDSXU8DGUDx49n5x34Ejz6bgniOggNVun-MwkfZ9BxMWU71lxLAMeBT5L8%7E7gJaSbpizakxEyAbXApuCWBe2PQA__&h=952&w=952',
  'https://us-east.stream-io-cdn.com/1212260/images/a1ede13c-dcbe-411a-a033-f48240cdcea7.imagenlarga.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzL2ExZWRlMTNjLWRjYmUtNDExYS1hMDMzLWY0ODI0MGNkY2VhNy5pbWFnZW5sYXJnYS5qcGcqIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzExNzIyMzQzfX19XX0_&Signature=f86LZTiJT8kqrLIvFOlve1xNx5WT8-OYyHEUJXiI7o-lL9WTJIXiZHMyZRCguiSgr1Dss8XnenByPgZWAH9vq52DAXJGYXE4-JsMGWZvLFrN4j55qYOjawsvczxNizHQXXnKCkuG7%7E9N0PtFVbjc8KZO6dQ-lsJky3Mle35GqgGzSh1mpH7TKhvirRqvGMuz9oQe5CKItssdHmM8R9I5FnCmoNsLJmCy0DLT6Fz6H0d9NvxRdNzkRSsvi01S4Q0C7eSpE1oFl1ZbU2FeYxAtNaCfXXO0fo5m5arNmCqE0%7EFef4TpISEe%7E%7ExZ6v0GZjMlPALlf6M246hdoeFridferQ__&h=952&w=952',
  'https://us-east.stream-io-cdn.com/1212260/images/a1ede13c-dcbe-411a-a033-f48240cdcea7.imagenlarga.jpg?Key-Pair-Id=APKAIHG36VEWPDULE23Q&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly91cy1lYXN0LnN0cmVhbS1pby1jZG4uY29tLzEyMTIyNjAvaW1hZ2VzL2ExZWRlMTNjLWRjYmUtNDExYS1hMDMzLWY0ODI0MGNkY2VhNy5pbWFnZW5sYXJnYS5qcGcqIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzExNzIyMzQzfX19XX0_&Signature=f86LZTiJT8kqrLIvFOlve1xNx5WT8-OYyHEUJXiI7o-lL9WTJIXiZHMyZRCguiSgr1Dss8XnenByPgZWAH9vq52DAXJGYXE4-JsMGWZvLFrN4j55qYOjawsvczxNizHQXXnKCkuG7%7E9N0PtFVbjc8KZO6dQ-lsJky3Mle35GqgGzSh1mpH7TKhvirRqvGMuz9oQe5CKItssdHmM8R9I5FnCmoNsLJmCy0DLT6Fz6H0d9NvxRdNzkRSsvi01S4Q0C7eSpE1oFl1ZbU2FeYxAtNaCfXXO0fo5m5arNmCqE0%7EFef4TpISEe%7E%7ExZ6v0GZjMlPALlf6M246hdoeFridferQ__&h=952&w=952',
];

function FilesInProfile({ setIsOpenFiles }) {
  // Estado para almacenar las URLs de las imágenes
  const [imageUrls, setImageUrls] = useState([]);

  // Cargar imágenes al montar el componente
  useEffect(() => {
    fetchImages().then(setImageUrls);
  }, []);

  return (
    <Box className={styles.contDrawer}>
      <Drawer
        screenSide="right"
        animationMinWidth={315}
        animationWidth={315}
        heightMin="580px"
        animationDuration={10}
        borderRadiusClosed="20px"
        marginTop="0px"
      >
        <Box
          style={{
            overflowY: 'scroll',
            marginRight: '-27px',
            paddingRight: '10px',
            width: '325px',
            height: '565px',
          }}
        >
          <Drawer.Header className={styles.companyProfileBox}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonIcon
                icon="icon-cerrar"
                variant={['subtleTrasnparentIconClearButton', 'iconLarge']}
                onClick={setIsOpenFiles}
              />
              <Heading as="h5" variant="heading.4">
                Archivos
              </Heading>
            </Box>
          </Drawer.Header>
          <Drawer.Body
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Box
              style={{
                width: '290px',
                height: '80px',
                padding: '5px',
              }}
            >
              <Tabs variant="minimal">
                <Tabs.Tab variant="minimal" id="item0" key="Tab0">
                  <Text variant="bodyBold.fontSize14">Fotos</Text>
                </Tabs.Tab>
                <Tabs.Tab variant="minimal" id="item1" key="Tab1">
                  <Text variant="bodyBold.fontSize14">Documentos</Text>
                </Tabs.Tab>
                <Tabs.Tab variant="minimal" id="item2" key="Tab2">
                  <Text variant="bodyBold.fontSize14">Enlaces</Text>
                </Tabs.Tab>
                <Tabs.Content id="item0" key="Content0">
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '24px',
                    }}
                  >
                    <Box>
                      <span className={styles.FilesInProfileText}>
                        Este mes
                      </span>
                      <Box
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gap: '10px',
                          width: '100%',
                          paddingTop: '10px',
                        }}
                      >
                        {imageUrls.map((url, index) => (
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            src={url}
                            alt={`Imagen ${index + 1}`}
                            style={{
                              width: '62px',
                              height: '62px',
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box>
                      <span className={styles.FilesInProfileText}>
                        Septiembre
                      </span>
                      <Box
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gap: '10px',
                          width: '100%',
                          paddingTop: '10px',
                        }}
                      >
                        {imageUrls.map((url, index) => (
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            src={url}
                            alt={`Imagen ${index + 1}`}
                            style={{
                              width: '62px',
                              height: '62px',
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Tabs.Content>
                <Tabs.Content id="item1" key="Content1">
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <Box className={styles.FilesInProfileBox}>
                      <Box className={styles.FilesInProfileIcon}>
                        <Icon
                          icon="icon-archivos"
                          fontSize="16px"
                          color="#4268EF"
                        />
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={styles.Body02FilesInProfile}>
                          Departamento - 2 ambientes.doc
                        </span>
                        <span className={styles.Body03FilesInProfile}>
                          22/04/2023
                        </span>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: '100%',
                        height: '1px',
                        background: '#DDE5E9',
                      }}
                    />
                    <Box className={styles.FilesInProfileBox}>
                      <Box className={styles.FilesInProfileIconImagenes}>
                        <Icon
                          icon="icon-imagenes"
                          fontSize="16px"
                          color="#FF800B"
                        />
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={styles.Body02FilesInProfile}>
                          PlanoExterior.jpg
                        </span>
                        <span className={styles.Body03FilesInProfile}>
                          22/04/2023
                        </span>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: '100%',
                        height: '1px',
                        background: '#DDE5E9',
                      }}
                    />
                    <Box className={styles.FilesInProfileBox}>
                      <Box className={styles.FilesInProfileIconArchivos}>
                        <Icon icon="icon-pdf" fontSize="16px" color="#DF1E02" />
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={styles.Body02FilesInProfile}>
                          Departamento - 2 ambientes.doc
                        </span>
                        <span className={styles.Body03FilesInProfile}>
                          22/04/2023
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Tabs.Content>
                <Tabs.Content id="item2" key="Content2">
                  <Box
                    style={{
                      gap: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span className={styles.FilesInProfileIconTag}>
                        Jose Regeiro {'>'} Tu
                      </span>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <span className={styles.FilesInProfileIconLabel}>
                          jueves
                        </span>
                        <Icon
                          style={{ transform: 'scaleX(-1)' }}
                          icon="icon-atras"
                          fontSize="12px"
                        />
                      </Box>
                    </Box>
                    <Box className={styles.FilesInProfileBoxLinks}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '8px',
                        }}
                      >
                        <Box className={styles.FilesInProfileIconLinks}>
                          <Icon
                            icon="icon-link"
                            fontSize="24px"
                            color="#25697E"
                          />
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflowWrap: 'break-word',
                            width: '192px',
                          }}
                        >
                          <span className={styles.Body03FilesInProfileLinks}>
                            https://www.tuinmobiliaria.com.ar/propiedad/blabla
                          </span>
                          <span className={styles.Body04FilesInProfileLinks}>
                            https://www.tuinmobiliaria.com.ar
                          </span>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          width: '248px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Box
                          style={{
                            width: '100%',
                            height: '1px',
                            background: '#DDE5E9',
                          }}
                        />
                        <span className={styles.InfoTextFilesInProfileLinks}>
                          https://www.tuinmobiliaria.com.ar/propiedad/blabla
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Tabs.Content>
              </Tabs>
            </Box>
          </Drawer.Body>
        </Box>
      </Drawer>
    </Box>
  );
}

export default FilesInProfile;
