/* eslint-disable react/no-array-index-key */
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useRef } from 'react';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';

export default function ListService({ array, title }) {
  const contRef = useRef(null);

  return (
    <Box className={style.bItem}>
      <Text className={style.BITitle} variant="bodyBold.fontSize14">
        {Trans(translations, title)}
      </Text>

      <Box ref={contRef} className={style.serviceCont}>
        {array.map((item, i) => (
          <Box key={i} className={style.itemService}>
            <Icon icon="icon-aceptar" fontSize="16px" />
            <Text variant={['body.fontSize14', 'ellipsis']}>{item}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
