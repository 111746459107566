/* eslint-disable import/prefer-default-export */

const actions = [
  'eventCreated',
  'closeModal',
  'eventEdited',
  'eventDeleted',
  'eventFinished',
];

export const tokkoModalActions = (action, host) => {
  if (
    !window.location.origin.includes(host) &&
    actions.includes(action) &&
    window.location.href.includes('outsidelayout')
  ) {
    window.parent.postMessage(action, host);
  }
};
