import { useState, useRef } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ToolbarButton from '../../ToolbarButton';
import FontFamilyPicker from '../../pickers/FontFamilyPicker';
import styles from './styles.module.scss';

const DEFAULT_FONT_FAMILY = 'Nunito Sans';

export default function FontFamilyButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  function handleChangeFontFamily(value) {
    editor.chain().focus().setFontFamily(value).run();
    setIsOpen(false);
  }

  const selectionFontFamily = editor.getAttributes('textStyle')?.fontFamily
    ? editor.getAttributes('textStyle')?.fontFamily
    : DEFAULT_FONT_FAMILY;

  return (
    <Box ref={ref} className={styles['font-family-button-wrapper']}>
      <ToolbarButton
        className={styles['font-family-button']}
        onClick={() => setIsOpen((prevState) => !prevState)}
        disabled={!editor.can().chain().focus().setFontFamily().run()}
        aria-label="editor-font-family"
      >
        <Box
          className={styles['button-text']}
          __css={{ fontFamily: selectionFontFamily }}
        >
          {selectionFontFamily}
        </Box>
        <Icon className={styles.chevron} icon="icon-dropdown" fontSize="12px" />
      </ToolbarButton>

      {isOpen && <FontFamilyPicker onClick={handleChangeFontFamily} />}
    </Box>
  );
}
