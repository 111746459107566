import { useState, useEffect, useRef } from 'react';
import { Box, Button, Icon } from '@oneloop/jopijs';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

function FloatingMenu({
  visibleFromOutside = true,
  editor,
  handleEditLink,
  handleUnlink,
}) {
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: null, y: null });
  const [pos, setPos] = useState({ x: null, y: null });
  const { href } = editor.getAttributes('link');
  const shouldHide = !isOpen || !visibleFromOutside || !href;

  useEffect(() => {
    if (!editor) return;

    const handleOutsideClick = (event) => {
      const { selection } = editor.state;
      const { from, to } = selection;

      const senderEditor = document.querySelector(
        '[aria-label="sender-editor"]'
      );

      if (menuRef.current && menuRef.current.contains(event.target)) {
        return;
      }

      if (senderEditor && !senderEditor.contains(event.target)) {
        setIsOpen(false);
        return;
      }

      if (from === to && editor.isActive('link')) {
        setIsOpen(true);
        setInitialPos({ x: event.clientX, y: event.clientY });
      } else if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mouseup', handleOutsideClick);
    // eslint-disable-next-line
    return () => document.removeEventListener('mouseup', handleOutsideClick);
  }, [editor, setIsOpen]);

  useEffect(() => {
    if (!isOpen || !menuRef.current) return;

    const senderEditor = document.querySelector('[aria-label="sender-editor"]');
    const editorRect = senderEditor.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();

    let { x, y } = initialPos;

    if (x + menuRect.width > editorRect.right) {
      x = editorRect.right - menuRect.width;
    }
    if (x < editorRect.left) {
      x = editorRect.left;
    }

    if (y + menuRect.height > editorRect.bottom) {
      y = editorRect.bottom - menuRect.height;
    }
    if (y < editorRect.top) {
      y = editorRect.top;
    }

    setPos({ x, y });
  }, [isOpen, initialPos]);

  if (shouldHide) return null;

  return ReactDOM.createPortal(
    <Box
      ref={menuRef}
      className={styles['floating-menu']}
      __css={{ left: pos.x, top: pos.y }}
    >
      <Box
        as="a"
        className={styles.link}
        data-bg="blue"
        href={href}
        target="_blank"
      >
        {href}
      </Box>
      <Box className={styles['buttons-wrapper']}>
        <Button
          variant="subtleText"
          as="button"
          className={styles.button}
          onClick={handleEditLink}
        >
          <Icon icon="icon-editar" fontSize="12px" color="#344149" />
        </Button>
        <Button
          variant="subtleText"
          className={styles.button}
          onClick={handleUnlink}
        >
          <Icon icon="icon-deslinkear" fontSize="12px" color="#344149" />
        </Button>
      </Box>
    </Box>,
    document.querySelector('[aria-label="sender-editor"]')
  );
}

export default FloatingMenu;
