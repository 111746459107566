import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@oneloop/jopijs';
import { setNotificationText } from '@/store/slices/NotificationSlice';
import styles from './styles.module.scss';

function Notification() {
  const dispatch = useDispatch(null);
  const { text } = useSelector((state) => state.NotificationSlice);
  const ref = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setNotificationText(null));
    }, 4000);

    return () => clearTimeout(timer);
  }, [text]);

  if (!text) return null;

  return (
    <Box
      key={text}
      ref={ref}
      className={`${styles['tokko-notification-tip-box']} ${styles.bounceIn}`}
    >
      <Box className={styles['tokko-notification-tip']}>{text}</Box>
    </Box>
  );
}

export default Notification;
