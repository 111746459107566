import { Box } from '@oneloop/jopijs';
import styles from './styles.module.scss';

const OPACITY_TWO_DIGITS = '4d';

function StatusChip({ statusBaseColor, statusText }) {
  const borderColor = `#${statusBaseColor}`;
  const backgroundColor = `#${statusBaseColor}${OPACITY_TWO_DIGITS}`;

  return (
    <Box
      as="span"
      className={styles['contact-status-chip']}
      __css={{ borderColor, backgroundColor }}
    >
      {statusText}
    </Box>
  );
}

export default StatusChip;
