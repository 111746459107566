import { Box, Checkbox, Icon, Radio, Text } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import List from './List';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';

export default function ExportToPdf() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showDD, setShowDD] = useState(false);
  const [options, setOptions] = useState({
    vertical: true,
    horizontal: false,
    grid: false,
  });
  const [map, setMap] = useState(true);
  const [agent, setAgent] = useState(true);
  const [lagunage, setLagunage] = useState({
    spanish: true,
    english: false,
  });
  const [value, setValue] = useState(5);
  const { id, versionFile, active } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const PositionsOptions = [
    {
      name: 'horizontal',
      icon: 'icon-pdfhorizontal',
      text: 'Horizontal',
    },
    {
      name: 'vertical',
      icon: 'icon-pdfvertical',
      text: 'Vertical',
    },
    {
      name: 'grid',
      icon: 'icon-pdfgrilla',
      text: Trans(translations, 'Grid'),
    },
  ];

  const handleValue = () => {
    if (options.horizontal && !map) {
      return setValue(1);
    }
    if (options.horizontal && map) {
      return setValue(2);
    }
    if (options.grid && !map) {
      return setValue(3);
    }
    if (options.vertical && !map) {
      return setValue(4);
    }
    if (options.vertical && map) {
      return setValue(5);
    }

    return setValue(0);
  };

  useEffect(() => {
    handleValue();
    if (options.grid) {
      setMap(false);
    }
  }, [options, map]);

  const toggleOption = (option) => {
    setOptions({
      vertical: false,
      horizontal: false,
      grid: false,
      [option]: true,
    });
  };

  const toggleLaguage = (option) => {
    setLagunage({
      spanish: false,
      english: false,
      [option]: true,
    });
  };

  const goToPdf = () => {
    let url = `${tokkoBrokerHost}/properties/pdf_list_details/?ids=${id}&val=${value}&include_agent=${agent}&o=${
      versionFile === 'edited'
    }`;

    if (lagunage.english) {
      url = url.concat('&in_english=true');
    }

    setShowDD(false);
    window.open(url, '_blank');
  };

  return (
    <Box
      className={`${!active ? style.propertyDeleted : ''}`}
      __css={{ position: 'relative' }}
    >
      <Box className={style.itemHeader} onClick={() => setShowDD(!showDD)}>
        <Icon icon="icon-exportar" fontSize="16px" />
        <Text variant={['bodyBold.fontSize12', 'ellipsis']}>
          {Trans(translations, 'ExportToPdf')}
        </Text>
      </Box>
      <List
        __css={{ borderRadius: '16px' }}
        open={showDD}
        seter={setShowDD}
        clickable
      >
        <Box className={style.contDDPdf}>
          {PositionsOptions.map((option) => (
            <Box
              key={option.name}
              className={`${style.pdfOption} ${
                options[option.name] && style.checked
              }`}
              onClick={() => toggleOption(option.name)}
            >
              <Radio
                name={option.name}
                checked={options[option.name]}
                readOnly
              />
              <Icon
                icon={option.icon}
                style={{ height: '18px' }}
                fontSize="16px"
              />
              <Text variant="body.fontSize14">{option.text}</Text>
            </Box>
          ))}

          <Box className={style.contLanguage}>
            <Text variant="bodyBold.fontSize12">
              {Trans(translations, 'Language')}
            </Text>
            <Box className={style.contItemsLanguage}>
              <Box
                className={style.itemLanguage}
                onClick={() => toggleLaguage('spanish')}
              >
                <Radio readOnly name="spanish" checked={lagunage.spanish} />
                <Text variant="body.fontSize12">
                  {Trans(translations, 'Spanish')}
                </Text>
              </Box>

              <Box
                className={style.itemLanguage}
                onClick={() => toggleLaguage('english')}
              >
                <Radio readOnly name="english" checked={lagunage.english} />
                <Text variant="body.fontSize12">
                  {Trans(translations, 'English')}
                </Text>
              </Box>
            </Box>
          </Box>

          <hr className={style.hr} />

          <Box
            className={`${style.contIncludeMap} ${
              options.grid && style.itemDisabled
            }`}
            onClick={() => setMap(!map)}
          >
            <Checkbox
              readOnly
              checked={map && !options.grid}
              style={{ width: '18px', height: '18px' }}
            />
            <Text variant="body.fontSize12">
              {Trans(translations, 'IncludeMap')}
            </Text>
          </Box>

          <Box
            className={style.contIncludeMap}
            onClick={() => setAgent(!agent)}
          >
            <Checkbox
              readOnly
              checked={agent}
              style={{ width: '18px', height: '18px' }}
            />
            <Text variant="body.fontSize12">
              {Trans(translations, 'IncludeCompanyLogoAndAgentInformation')}
            </Text>
          </Box>

          <Box
            __css={{ marginTop: '16px' }}
            className={style.exportButton}
            onClick={goToPdf}
          >
            <Text variant="bodyBold.fontSize14">
              {Trans(translations, 'ExportPdf')}
            </Text>
          </Box>
        </Box>
      </List>
    </Box>
  );
}
