import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import SearchResults from '../SearchResults';
import { getContactsCC } from '@/store/slices/components/sender/services';
import {
  setCCData,
  setSelectedCC,
  setWhatsappData,
} from '@/store/slices/components/sender/SenderSlice';
import createContact from '../../utils/createContact';
import getHowManyCollapsedToShow from '../../utils/getHowManyCollapsedToShow';
import InputForContact from '../InputForContact';
import SelectedItems from '../SelectedItems';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from '../styles.module.scss';

const MAX_SELECTED_CC = 10;
let debounceTimer;
let searchingSignal;

function CC() {
  const dispatch = useDispatch();
  const { addressees, cc } = useSelector((state) => state.SenderSlice);
  const selectedWrapperRef = useRef(null);
  const ccTooltipContainerId = 'cc-tooltip-container';
  const ccItemsContainerId = 'cc-items-container';
  const maxSelectedReached = cc.selected.length === MAX_SELECTED_CC;
  const allSelected = [...addressees.selected, ...cc.selected];
  const allSelectedAreValid = allSelected.every((element) => element.isValid);
  const inputAlreadyExists = allSelected.find(
    (el) => el.isRaw && el.id === cc.input
  );

  const cancelPendentOperations = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
      dispatch(setWhatsappData({ key: 'fetchedSuggestions', value: null }));
    }

    if (searchingSignal) {
      searchingSignal.abort();
    }
  };

  const addRawContact = () => {
    if (inputAlreadyExists) return;

    cancelPendentOperations();
    const rawContact = createContact.raw.email(cc.input);
    const newValue = [...cc.selected, rawContact];
    dispatch(setSelectedCC(newValue));
  };

  useEffect(() => {
    const collapseCC = (event) => {
      if (event.target.classList.contains('.sender-send-button')) {
        cancelPendentOperations();
        return;
      }

      if (
        selectedWrapperRef.current &&
        !selectedWrapperRef.current.contains(event.target)
      ) {
        if (cc.input) {
          addRawContact();
          return;
        }

        const value = getHowManyCollapsedToShow(`#${ccItemsContainerId}`);

        dispatch(setCCData({ key: 'isCollapsed', value: true }));
        dispatch(setCCData({ key: 'howManyShowCollapsed', value }));
      }
    };

    document.addEventListener('click', collapseCC);

    return () => {
      document.removeEventListener('click', collapseCC);
    };
  }, [cc.input]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    dispatch(setCCData({ key: 'input', value }));
    const clearQuery = value.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        searchingSignal = dispatch(getContactsCC(clearQuery));
      }, 1000);
    } else {
      dispatch(setCCData({ key: 'fetchedSuggestions', value: null }));
    }
  }, []);

  const handleSelect = (suggestion) => {
    const newSelected = createContact.suggestion.email(suggestion);
    const newValue = [...cc.selected, newSelected];
    dispatch(setSelectedCC(newValue));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addRawContact();
  };

  const handleClickItemsContainer = () => {
    dispatch(setCCData({ key: 'isCollapsed', value: false }));
  };

  const setSelected = (newValue) => {
    dispatch(setSelectedCC(newValue));
  };

  const handleAddNewContact = (newContact) => {
    const parsedContact = createContact.new(newContact);
    const updatedSelected = [...addressees.selected, parsedContact];
    dispatch(setSelectedCC(updatedSelected));
  };

  if (!cc.show) return null;

  return (
    <Box as="section" className={styles.container}>
      <Box
        className={styles.row}
        id={ccTooltipContainerId}
        data-testid="sender-cc"
      >
        <Text className={styles['row-label']}>CC</Text>

        <Box
          ref={selectedWrapperRef}
          id={ccItemsContainerId}
          className={styles['content-wrapper']}
          onClick={handleClickItemsContainer}
          __css={{ maxHeight: 100 }}
        >
          <SelectedItems
            selected={cc.selected}
            setSelected={setSelected}
            isCollapsed={cc.isCollapsed}
            howManyShowCollapsed={cc.howManyShowCollapsed}
            tooltipContainerId={ccTooltipContainerId}
          />

          {allSelectedAreValid && !maxSelectedReached && (
            <>
              <Box as="form" className={styles.form} onSubmit={handleSubmit}>
                <InputForContact
                  value={cc.input}
                  onChange={handleChange}
                  inputAlreadyExists={inputAlreadyExists}
                  isCollapsed={cc.isCollapsed}
                  testId="cc-input"
                />
              </Box>

              <SearchResults
                row="cc"
                isLoading={cc.isLoading}
                results={cc.fetchedSuggestions}
                onSelect={handleSelect}
                input={cc.input}
                handleAddNewContact={handleAddNewContact}
              />
            </>
          )}
        </Box>
      </Box>

      {maxSelectedReached && (
        <Text className={styles['warning-message']} data-testid="cc-error-text">
          {`${Trans(translations, 'YouHaveReached')} ${MAX_SELECTED_CC} ${Trans(
            translations,
            'ContactsLimit'
          )}`}
        </Text>
      )}
    </Box>
  );
}

export default CC;
