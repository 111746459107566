import React from 'react';
import { Box, Icon, ButtonIcon } from '@oneloop/jopijs';
import styles from './CustomSearch.module.scss';
import translations from './SearchBar.trans.json';
import Trans from '@/components/shared/Trans';

function SearchBar({ inputText, setInputText }) {
  return (
    <Box className={styles.searchContainer}>
      <Icon
        className={styles.searchIcon}
        icon="icon-buscar"
        fontSize="16px"
        color="#485C66"
      />
      <input
        className={styles.inputContainer}
        placeholder={Trans(translations, 'Search')}
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
        }}
      />
      {inputText && (
        <ButtonIcon
          className={styles.closeIcon}
          icon="icon-cerrar"
          variant={['subtleTrasnparentIconClearButton', 'iconLarge']}
          onClick={() => {
            setInputText('');
          }}
        />
      )}
    </Box>
  );
}

export default SearchBar;
