import { Box } from '@oneloop/jopijs';
import styles from './LeadStatusBadge.module.scss';

export default function LeadStatusBadge({ text, hexColor, big, ...props }) {
  function hexToRGBA(hex, alpha) {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }
    const alphaHex = Math.round((alpha / 100) * 255)
      .toString(16)
      .padStart(2, '0');

    return `#${hex}${alphaHex}`;
  }

  const isWhite = hexColor.toLowerCase() === '#ffffff';

  return (
    <Box
      className={big ? styles.badgeContainerBig : styles.badgeContainer}
      style={{
        background: isWhite ? null : hexToRGBA(hexColor, 30),
        borderColor: isWhite ? null : hexColor,
      }}
      as="span"
      {...props}
    >
      {text}
    </Box>
  );
}
