import React, { useEffect, useState } from 'react';
import { Box } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Layout.module.scss';
import Header from './Header/Header';
import Rightbar from './Rightbar/Rightbar';
import Sidebar from './Sidebar/Sidebar';
import { selectUserPreferencesState } from '@/store/slices/UserPreferencesSlice';
import UserService from '@/services/UserService';
import { setBadgesAsync } from '@/store/slices/LayoutSlice';

function Layout({ children }) {
  const [intervalId, setIntervalId] = useState(undefined);
  const dispatch = useDispatch();
  const [showTooltipCreateContact, setShowTooltipCreateContact] =
    useState(false);
  const { rightbarCollapse, sidebarCollapse } = useSelector(
    selectUserPreferencesState
  );
  const { show } = useSelector((state) => state.MessengerSlice);

  UserService.getBadgesCounters().then((resultBadges) => {
    dispatch(setBadgesAsync(resultBadges));
  });

  useEffect(() => {
    if (show) {
      clearInterval(intervalId);
      setTimeout(() => {
        UserService.getBadgesCounters().then((resultBadges) => {
          dispatch(setBadgesAsync(resultBadges));
        });
      }, [1000]);
    } else {
      const interval = setInterval(() => {
        UserService.getBadgesCounters().then((resultBadges) => {
          dispatch(setBadgesAsync(resultBadges));
        });
      }, [120000]);
      setIntervalId(interval);
    }
  }, [show]);

  return (
    <Box className={styles.container}>
      <Header
        showTooltipCreateContact={showTooltipCreateContact}
        setShowTooltipCreateContact={setShowTooltipCreateContact}
      />
      <Sidebar setShowTooltipCreateContact={setShowTooltipCreateContact} />
      <Box
        className={
          !sidebarCollapse ? styles.children : styles.childrenSidebarOpen
        }
      >
        <main className={styles.main}>{children}</main>
      </Box>
      <Box
        className={
          !rightbarCollapse ? styles.rightbarClose : styles.rightbarOpen
        }
      >
        <Rightbar />
      </Box>
    </Box>
  );
}

export default Layout;
