import { Box, Text, Loader, Icon } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../CustomChannelHeader.module.scss';
import {
  getDuplicateContacts,
  getAgentsChat,
  messengerManager,
} from '@/store/slices/components/chat/services';
import AgentList from '@/components/AgentsList/AgentsList';
import {
  resetDuplicated,
  setSelectedAgent,
  showRightbar,
} from '@/store/slices/components/chat/MessengerSlice';
import translations from './AsignInquiry.trans.json';
import Trans from '@/components/shared/Trans';
import DuplicateCard from './DuplicateCard';
import { showToast } from '@/store/slices/components/toast/ToastSlice';
import AssignButton from './AssignButton';
import { tokkoModalActions } from '@/components/TokkoActions/tokkoActions';

function AsignInquiry() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const dispatch = useDispatch();
  const [showAgentList, setShowAgentList] = useState(false);
  const [duplicateLoader, setDuplicateLoader] = useState(false);
  const { url } = useSelector((state) => state.LayoutSlice);
  const { id: userID } = useSelector((state) => state.UserSlice.data);
  const { agents, channelData, duplicateContacts } = useSelector(
    (state) => state.MessengerSlice
  );

  const { list, agentSelected, asingLoader, loading } = agents;

  useEffect(() => {
    if (channelData.data.web_contact?.id && !channelData.data.contact?.id) {
      dispatch(getDuplicateContacts(channelData.data.web_contact.id)).then(
        (res) => {
          if (res.payload.contacts.length === 0) {
            setShowAgentList(true);
          }
        }
      );
    } else {
      setShowAgentList(true);
    }

    return () => {
      dispatch(resetDuplicated());
    };
  }, []);

  const handleAssign = (agentData, contactId) => {
    const contactData = {
      old_channel_cid: channelData.cid,
      agent_id: agentSelected[0]?.id || agentData.id,
      contact_id: channelData.data.contact?.id || contactId,
      webcontact_id: channelData.data.web_contact?.id,
    };

    setDuplicateLoader(contactId);

    dispatch(messengerManager(contactData)).then(() => {
      dispatch(showRightbar(false));
      dispatch(
        showToast({
          text: Trans(
            translations,
            'InquiryAssignedTo',
            agentSelected[0]?.full_name || agentData.full_name
          ),
        })
      );

      if (
        channelData.data.web_contact?.id &&
        !channelData.data.contact?.id &&
        url.includes('/webcontact/')
      ) {
        tokkoModalActions(
          {
            msg: 'removeWebContactElement',
            data: channelData.data.web_contact?.id,
          },
          tokkoBrokerHost
        );
      }
    });
  };

  if (duplicateContacts.loading) {
    return (
      <Box className={styles.duplicatedSpinner}>
        <Loader
          variant="spinner"
          style={{
            backgroundImage: 'conic-gradient(#869498 ,#FFFFFF)',
            margin: '0px',
            width: '40px',
            height: '40px',
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      __css={{
        padding: '0px 10px',
        display: 'flex',
        flexDirection: 'column',
        height: '510px',
      }}
    >
      {duplicateContacts.contacts.length > 0 && !showAgentList && (
        <Box className={styles.duplicatedContainer}>
          <Box className={styles.duplicatedAlert}>
            <Icon icon="icon-alertas" fontSize="24px" />

            <Text variant="bodyBold.fontSize12">
              {Trans(translations, 'TheDataForThisRersonMatches')}{' '}
            </Text>
          </Box>

          <Text className={styles.duplicatedText} variant="bodyBold.fontSize12">
            {Trans(translations, 'SelectAnAgentToAssign')}
          </Text>

          <Box className={styles.duplicatedList}>
            {duplicateContacts.contacts.map((contact) => (
              <DuplicateCard
                click={handleAssign}
                loading={duplicateLoader}
                contact={contact}
              />
            ))}
          </Box>

          <AssignButton
            text={Trans(translations, 'AssignToAnotherAgent')}
            loading={asingLoader}
            onClick={() => setShowAgentList(true)}
          />
        </Box>
      )}

      {showAgentList && (
        <Box __css={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <AgentList
            getData={() => dispatch(getAgentsChat())}
            data={list}
            selectedAgent={agentSelected}
            itemClick={(item) => dispatch(setSelectedAgent(item))}
            loading={loading}
            showProfileUser={
              Number(channelData.data.web_contact?.assigned_agent) !== userID
            }
          />

          {agentSelected.length > 0 && (
            <AssignButton
              text={Trans(translations, 'AssignTo', agentSelected[0].full_name)}
              loading={asingLoader}
              onClick={handleAssign}
              __css={{ marginTop: '10px' }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default AsignInquiry;
