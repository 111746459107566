import { useState, useRef } from 'react';
import { Box } from '@oneloop/jopijs';
import useOnClickOutside from '@/components/shared/Hooks';
import ImagePicker from '../PickerWithInput/ImagePicker';
import ToolbarButton from '../ToolbarButton';
import { ImageIcon } from '../icons';

export default function ImageButton({ editor }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, isOpen, setIsOpen);

  return (
    <Box ref={ref}>
      <ToolbarButton
        onClick={() => setIsOpen((prevState) => !prevState)}
        aria-label="editor-image"
        data-active={isOpen}
      >
        <ImageIcon />
      </ToolbarButton>
      {isOpen && (
        <ImagePicker editor={editor} handleClose={() => setIsOpen(false)} />
      )}
    </Box>
  );
}
