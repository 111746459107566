/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { Box, Button, ButtonIcon, Icon, List, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@/components/shared/Link';
import CalendarService from '@/services/CalendarService';
import styles from './Calendar.module.scss';
import CalendarItem from './CalendarItem/CalendarItem';
import {
  toggleEditModal,
  toggleModal,
} from '@/store/slices/components/calendarEvents/CalendarEventsSlice';
import translations from './Calendar.trans.json';
import Trans from '@/components/shared/Trans';

function Calendar() {
  const [calendarData, setCalendarData] = useState(null);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { showModal } = useSelector((state) => state.CalendarEvents);
  const dispatch = useDispatch();

  const getCalendarsData = async () => {
    CalendarService.getEventsCalendar().then(setCalendarData);
  };

  useEffect(() => {
    if (!showModal) {
      getCalendarsData();
    }
  }, [showModal]);

  return (
    <Box className={styles.calendarContainer}>
      <Box className={styles.calendarHeader}>
        <Box className={styles.calendarTitle}>
          <Icon icon="icon-calendario" fontSize="18px" color="primary" />
          <Text variant="bodyBold.fontSize14" color="subtlePressed">
            {Trans(translations, 'Calendar')}
          </Text>
        </Box>
        <ButtonIcon
          icon="icon-agregar"
          variant={['primary', 'iconSmallSize18px']}
          onClick={() => {
            dispatch(toggleModal());
          }}
        />
      </Box>

      {calendarData && (
        <Box
          __css={{
            display:
              calendarData && calendarData.total_events === 0
                ? 'none'
                : 'block',
          }}
        >
          <List className={styles.containerList}>
            {calendarData.events.map((calendarItem) => (
              <div
                id={calendarItem.id}
                key={calendarItem.id}
                className={styles.divContainerItems}
                onClick={() =>
                  dispatch(
                    toggleEditModal({
                      id: calendarItem.id,
                      date:
                        calendarItem.is_recurrent && calendarItem.when_datetime,
                    })
                  )
                }
              >
                <CalendarItem calendarItem={calendarItem} />
              </div>
            ))}
          </List>
          {calendarData.total_events > 3 && (
            <Box className={styles.textShowMoreResults}>
              <Link href={`${tokkoBrokerHost}/schedule/full_calendar`}>
                <Button variant="link">
                  {Trans(translations, 'Seeupcomingevents')}
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Calendar;
