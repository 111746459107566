/* eslint-disable react/no-array-index-key */
import { Box } from '@oneloop/jopijs';
import Skeletons from '../Skeletons/Skeletons';

export default function AgentSkeleton() {
  return (
    <Box>
      {Array(3)
        .fill()
        .map((arr, i) => (
          <Box
            key={`${i}200`}
            __css={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <Skeletons height={46} width={46} borderRadius="50%" />

            <Box>
              <Skeletons height={18} width={200} borderRadius={12} />
              <Skeletons height={12} width={150} borderRadius={12} />
            </Box>
          </Box>
        ))}
    </Box>
  );
}
