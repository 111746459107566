import { useEffect, useRef, useState } from 'react';
import { Box, Icon, Text } from '@oneloop/jopijs';
import Checkbox from '@/components/checkbox/Checkbox';
import isAValidLink from '@/components/Sender/utils/isAValidLink';
import Trans from '@/components/shared/Trans';
import translations from '@/components/WysiwygEditor/WysiwygEditor.trans.json';
import styles from './styles.module.scss';

function LinkPicker({ editor, handleClose, shouldUseContext }) {
  const linkRef = useRef(null);
  const [text, setText] = useState(() => {
    if (shouldUseContext) {
      editor.chain().focus().extendMarkRange('link').run();
    }
    const { state } = editor;
    const { from, to } = state.selection;
    return state.doc.textBetween(from, to);
  });
  const [link, setLink] = useState(() => {
    if (shouldUseContext) {
      editor.chain().focus().extendMarkRange('link').run();
      return editor.getAttributes('link').href;
    }

    return '';
  });
  const [checked, setChecked] = useState(() => {
    if (shouldUseContext) {
      editor.chain().focus().extendMarkRange('link').run();
      return editor.getAttributes('link').target === '_blank';
    }

    return true;
  });
  const [inputIsDirty, setInputIsDirty] = useState(false);
  const [focusingLink, setFocusingLink] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const isValidLink = isAValidLink(link);
  const isError = !isValidLink;
  const showError =
    inputIsDirty &&
    ((wasSubmitted && !isValidLink) || (!focusingLink && !isValidLink));

  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setWasSubmitted(false);

    if (link) {
      setInputIsDirty(true);
    }
  }, [link]);

  const handlePressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      handleClose();
    }
  };

  const handleFocus = (event) => {
    event.target.select();
    setFocusingLink(true);
  };

  const handleBlur = () => {
    setFocusingLink(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWasSubmitted(true);
    setInputIsDirty(true);

    if (isError) return;

    const { state } = editor;
    const { from } = state.selection;
    const href = link;
    const target = checked ? '_blank' : '_self';
    const rangeSelected = !editor.view.state.selection.empty;

    if (rangeSelected) {
      editor.chain().focus().deleteSelection().run();
      editor.chain().focus().setTextSelection(from).run();
    }

    editor
      .chain()
      .focus()
      .setLink({ href, target })
      .command(({ tr }) => {
        tr.insertText(text || href);
        return true;
      })
      .run();

    handleClose();
  };

  return (
    <Box
      as="form"
      className={styles.container}
      onKeyDown={handlePressEscape}
      __css={{ maxWidth: 420 }}
      data-testid="link-picker"
    >
      <Box className={styles.header} __css={{ marginBottom: '12px!important' }}>
        <Text className={styles.title}>{Trans(translations, 'AddLink')}</Text>
        <Icon
          icon="icon-cerrar"
          fontSize="12px"
          onClick={handleClose}
          className={styles['close-icon']}
        />
      </Box>

      <Box className={styles['label-wrapper']}>
        <Text className={styles.label}>
          {Trans(translations, 'LinkTextOptional')}
        </Text>
        <Box className={styles['input-wrapper']} data-filled={Boolean(text)}>
          <Box
            as="input"
            className={styles.input}
            onChange={(event) => setText(event.target.value)}
            value={text}
          />
        </Box>
      </Box>

      <Box className={styles['label-wrapper']}>
        <Text className={styles.label}>{Trans(translations, 'Link')}</Text>
        <Box
          className={`${styles['input-wrapper']} ${
            showError ? styles['input-wrapper--error'] : ''
          }`}
          data-filled={Boolean(link)}
        >
          <Box
            as="input"
            ref={linkRef}
            data-testid="wysiwyg-link-input"
            className={styles.input}
            placeholder={Trans(translations, 'PasteLinkHere')}
            onBlur={handleBlur}
            onChange={(event) => setLink(event.target.value)}
            onFocus={handleFocus}
            value={link}
          />
        </Box>

        {showError && (
          <Box className={styles['error-message']}>
            {!link
              ? Trans(translations, 'AddALink')
              : Trans(translations, 'InvalidLink')}
          </Box>
        )}
      </Box>

      <Box
        as="label"
        className={styles['checkbox-wrapper']}
        onClick={() => setChecked((prevState) => !prevState)}
      >
        <Checkbox checked={checked} __css={{ height: 16, width: 16 }} />
        <Text className={styles['checkbox-text']}>
          {Trans(translations, 'OpenInANewTab')}
        </Text>
      </Box>

      <Box className={styles['buttons-wrapper']}>
        <Box
          as="button"
          type="button"
          className={styles['cancel-button']}
          onClick={handleClose}
        >
          {Trans(translations, 'Cancel')}
        </Box>
        <Box
          as="button"
          type="submit"
          data-testid="wysiwyg-link-submit-button"
          className={`${styles['accept-button']} ${
            isError ? styles['accept-button--disabled'] : ''
          }`}
          onClick={handleSubmit}
        >
          {Trans(translations, 'AddLink')}
        </Box>
      </Box>
    </Box>
  );
}

export default LinkPicker;
