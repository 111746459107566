import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Loader } from '@oneloop/jopijs';
import {
  setPermissionsAsync,
  selectPermissionsState,
  setCompanyConfigAsync,
} from '@/store/slices/AuthSlice';
import { updateUserDataAsync } from '@/store/slices/UserSlice';
import styles from './SettingsReactLoader.module.scss';
import {
  selectUserPreferencesState,
  setUserPreferencesAsync,
} from '@/store/slices/UserPreferencesSlice';

export default function SettingsReactLoader({ setEnabledToken }) {
  const [tokenState, setTokenState] = useState(null);
  const [, setTokkoBrokerHost] = useState(null);
  const permissions = useSelector(selectPermissionsState);
  const userConfig = useSelector(selectUserPreferencesState);
  const user = useSelector((state) => state.UserSlice.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window === 'object' && document !== undefined) {
      const routeHostReferrer = document.referrer;
      const routeHostReferrerFix =
        routeHostReferrer.charAt(routeHostReferrer.length - 1) === '/'
          ? routeHostReferrer.substring(0, routeHostReferrer.length - 1)
          : routeHostReferrer;
      const tokkoBrokerHostFinal = !routeHostReferrerFix.includes(
        window.location.origin
      )
        ? routeHostReferrerFix
        : localStorage.getItem('tokkoBrokerHost');
      if (tokkoBrokerHostFinal) {
        setTokkoBrokerHost(tokkoBrokerHostFinal);
        localStorage.setItem('tokkoBrokerHost', tokkoBrokerHostFinal);
      }

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const jwtBearer = url.searchParams.get('jwt');
      if (jwtBearer) {
        localStorage.setItem('jwt', jwtBearer);
        setTokenState(jwtBearer);
        params.delete('jwt');
        url.search = params.toString();
        window.history.pushState(null, null, url.toString());
      } else {
        setTokenState(localStorage.getItem('jwt'));
      }
    }

    if (permissions.user_perms && userConfig && user) {
      setEnabledToken(true);
    }
  }, [permissions, userConfig, user]);

  useEffect(() => {
    if (tokenState !== null) {
      dispatch(setUserPreferencesAsync());
      dispatch(setCompanyConfigAsync());
      dispatch(setPermissionsAsync());
      dispatch(updateUserDataAsync());
    }
  }, [tokenState]);

  return (
    <Box className={styles.bgLoader}>
      <Loader
        variant="spinner"
        style={{
          width: '50px',
          height: '50px',
          backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
        }}
      />
    </Box>
  );
}
