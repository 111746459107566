import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function NoResults() {
  return (
    <div
      className={styles['no-results-wrapper']}
      aria-label="suggestions-no-results"
    >
      <p className={styles['no-results-title']}>
        {Trans(translations, 'CouldNotFindContacts')}
      </p>
      <p className={styles['no-results-subtitle']}>
        {Trans(translations, 'TrySearching')}
      </p>
    </div>
  );
}

export default NoResults;
