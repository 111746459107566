import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@oneloop/jopijs';
import PropertyCard from '../PropertyCard';
import { setShowStarred } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import StarredProperties from '../StarredProperties';
import styles from './styles.module.scss';

function SuggestedProperties() {
  const dispatch = useDispatch();
  const { addressees, whatsapp, properties } = useSelector(
    (state) => state.SenderSlice
  );
  const suggestionsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        dispatch(setShowStarred(false));
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const shouldShowNoResults =
    !properties.showStarred && properties.fetchedSuggestions?.length === 0;
  const shouldShowResults =
    !properties.showStarred && properties.fetchedSuggestions?.length > 0;
  const showNothing =
    !isOpen ||
    (!properties.showStarred && !shouldShowResults && !shouldShowNoResults);

  useEffect(() => {
    if (properties.fetchedSuggestions || properties.showStarred) {
      setIsOpen(true);
    }
  }, [properties.fetchedSuggestions, properties.showStarred]);

  return (
    <Box
      ref={suggestionsRef}
      aria-label="suggested-properties-container"
      className={`${styles['absolute-container']} ${
        showNothing ||
        (properties.showStarred &&
          addressees.selected.length === 0 &&
          whatsapp.selected.length === 0)
          ? styles['absolute-container--hidden']
          : ''
      }`}
    >
      <Box className={styles['scroll-container']}>
        <StarredProperties />
      </Box>

      {shouldShowNoResults && (
        <Text className={styles['no-results-text']}>
          {Trans(translations, 'CouldNotFindProperties')}
        </Text>
      )}

      {shouldShowResults && (
        <Box className={styles['scroll-container']}>
          {properties.fetchedSuggestions?.map((suggestion) => (
            <PropertyCard
              key={suggestion.reference_code}
              property={suggestion}
              variant="suggestion"
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default SuggestedProperties;
