import { useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import StatusChip from '../../StatusChip';
import isAValidLink from '@/components/Sender/utils/isAValidLink';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function ContactSuggestion({ suggestion, handleSelectSuggestion }) {
  const { show, addressees, cc, whatsapp } = useSelector(
    (state) => state.SenderSlice
  );
  const { status, agent, name, contact_info, last_activity } = suggestion;

  const { email: mainEmail, work_email, other_email, cellphone } = contact_info;
  const emails = [mainEmail, work_email, other_email];
  const email = emails.find((el) => el);
  const showContactInfo =
    (show === 'email' && email) || (show === 'whatsapp' && cellphone);

  const allSelected =
    show === 'email'
      ? [...addressees.selected, ...cc.selected]
      : [...whatsapp.selected];

  const isAlreadySelected = Boolean(
    allSelected.find(
      (selected) => String(selected.id) === String(suggestion.id)
    )
  );

  const onHandleSelectSuggestion = () => {
    handleSelectSuggestion(suggestion);
  };

  return (
    <Box
      as="button"
      className={styles.suggestion}
      disabled={isAlreadySelected}
      onClick={onHandleSelectSuggestion}
    >
      {isAValidLink(suggestion.picture) ? (
        <Box
          as="img"
          src={suggestion.picture}
          className={`${styles.thumbnail} ${
            isAlreadySelected ? styles['disabled-opacity'] : ''
          }`}
        />
      ) : (
        <Icon
          icon="icon-perfil"
          fontSize="32px"
          color="#C7D1D7"
          alignSelf="center"
          className={isAlreadySelected ? styles['disabled-opacity'] : ''}
        />
      )}

      <Box
        className={`${styles['left-side']} ${
          isAlreadySelected ? styles['disabled-opacity'] : ''
        }`}
      >
        <Text className={styles['left-side__name']}>
          {name}
          <StatusChip statusBaseColor={status.color} statusText={status.name} />
        </Text>
        {showContactInfo && (
          <Box className={styles['left-side__contact-info']}>
            {show === 'email' && (
              <>
                <Icon icon="icon-email" fontSize="12px" color="#6B7C85" />
                {email}
              </>
            )}

            {show === 'whatsapp' && (
              <>
                <Icon icon="icon-whatsapp" fontSize="12px" color="#6B7C85" />
                {cellphone}
              </>
            )}
          </Box>
        )}
      </Box>
      {!isAlreadySelected && (
        <Box className={styles['right-side']}>
          <Text className={styles['right-side__agent']}>{agent?.name}</Text>
          <Text className={styles['right-side__last-activity']}>
            {Trans(translations, 'LastActivity')} {last_activity}
          </Text>
        </Box>
      )}

      {isAlreadySelected && (
        <Box className={styles.check}>
          <Icon icon="icon-check" fontSize="32px" color="#94A2AB" />
        </Box>
      )}
    </Box>
  );
}

export default ContactSuggestion;
