import { useEffect, useState } from 'react';
import { Box, Button, Card, Checkbox, Icon, Text } from '@oneloop/jopijs';
import Link from '@/components/shared/Link';
import styles from './Searchs.module.scss';
import emptySearch from '../../../../../public/images/layout/emptySearch.svg';
import ImageNext from '@/components/shared/ImageNext';
import translations from './Searchs.trans.json';
import Trans from '@/components/shared/Trans';
import SkeletonFavorite from '../SkeletonFavorite/SkeletonFavorite';

function Searchs({
  dataSearchs,
  listSearchs,
  setListSearchs,
  updateList,
  selectAll,
  isLoading,
}) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showEmptyState, setShowEmptyState] = useState(false);

  function openContact(id) {
    window.open(
      `${tokkoBrokerHost}/properties/?do_favourite_search=${id}`,
      '_blank'
    );
  }

  useEffect(() => {
    if (dataSearchs.length === 0) {
      setShowEmptyState(false);
      const timer = setTimeout(() => {
        setShowEmptyState(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    setShowEmptyState(true);
    return undefined;
  }, []);

  if (!showEmptyState || isLoading) return <SkeletonFavorite />;

  return showEmptyState && dataSearchs.length === 0 ? (
    <Box className={styles.containerEmpty}>
      <ImageNext src={emptySearch} unoptimized alt="emptySearch" />
      <Text
        variant="body.fontSize12"
        className={`${styles.emptyText} ${styles.colorNeutralGray3}`}
      >
        {Trans(translations, 'favoritesSearchs.Emptymessage')}
      </Text>
      <Link
        target="_blank"
        href="https://dudas.tokkobroker.com/es/articles/432333-como-guardar-una-busqueda-de-propiedades-en-favoritos"
      >
        <Button variant="linkTwoLines">
          {Trans(translations, 'favoritesSearchs.Howtosaveasfavorite')}
        </Button>
      </Link>
    </Box>
  ) : (
    <Box className={styles.container}>
      <Card
        variant="search"
        width="224px"
        active={listSearchs.length === dataSearchs.length}
      >
        <Checkbox
          key={listSearchs}
          onClick={() => {
            selectAll(dataSearchs, listSearchs, setListSearchs);
          }}
          isChecked={listSearchs.length === dataSearchs.length}
        />
        <Text variant="body.fontSize12" className={styles.textSearch}>
          {Trans(translations, 'Selectall')}
        </Text>
      </Card>
      {dataSearchs.map((search) => (
        <Card
          id={search.id}
          key={search.id}
          variant="search"
          width="224px"
          active={listSearchs.includes(search.id)}
          onClick={() => openContact(search.id)}
        >
          <Checkbox
            key={listSearchs}
            width="20px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateList(listSearchs, setListSearchs, search.id);
            }}
            isChecked={listSearchs.includes(search.id)}
          />
          <Text variant="body.fontSize12" className={styles.textSearch}>
            {search.name}
          </Text>
          <Icon icon="icon-ir" fontSize="12px" className={styles.icon} />
        </Card>
      ))}
    </Box>
  );
}

export default Searchs;
