import { useSelector } from 'react-redux';
import useSendEmail from './useSendEmail';
import useSendWhatsapp from './useSendWhatsapp';

function useSend() {
  const { show } = useSelector((state) => state.SenderSlice);
  const { handleEmail } = useSendEmail();
  const { handleWhatsapp } = useSendWhatsapp();

  const handleSend = () => {
    const send = show === 'email' ? handleEmail : handleWhatsapp;
    send();
  };

  return {
    handleSend,
  };
}

export default useSend;
