import React from 'react';
import { Box, Image, Text } from '@oneloop/jopijs';
import { useDispatch } from 'react-redux';
import styles from './SearchItemPropertie.module.scss';
import translations from './SearchItemPropertie.trans.json';
import Trans from '@/components/shared/Trans';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';

function SearchItemPropertie({ propertie }) {
  const dispatch = useDispatch();
  return (
    <Box
      className={styles.item}
      onClick={() => dispatch(showQuickDisplay({ id: propertie.id }))}
    >
      <Image variant="rows" src={propertie.image} />
      <Box className={`${styles.column} ${styles.columnDescription}`}>
        <Text
          variant="bodyBold.fontSize12"
          className={
            propertie.status === 2
              ? styles.colorSuccessAvailable
              : styles.colorRedDarkNegative
          }
        >
          {propertie.status === 2
            ? Trans(translations, 'Available')
            : Trans(translations, 'NotAvailable')}
        </Text>
        <Box className={styles.row}>
          <Text
            variant="bodyBold.fontSize14"
            className={styles.colorNeutralGray1}
          >
            {propertie.name}
          </Text>
        </Box>
        <Text variant="body.fontSize11" className={styles.colorNeutralGray3}>
          {propertie.description}
        </Text>
      </Box>
      <Box className={styles.containerEndRow}>
        {propertie.operations !== undefined &&
          propertie.operations.map((price) => (
            <Box className={styles.column} key={price.price}>
              <Text
                variant="bodyBold.fontSize14"
                className={styles.colorNeutralGray1}
              >
                {price.type}
              </Text>
              <Text
                variant="body.fontSize13"
                className={styles.colorNeutralGray3}
              >
                {price.price}
              </Text>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default SearchItemPropertie;
