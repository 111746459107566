/* eslint-disable no-unsafe-optional-chaining */
import { Box, Icon, Text } from '@oneloop/jopijs';
import { useRef, useState } from 'react';
import style from './TokkoTips.module.scss';
import translations from './TokkoTips.trans.json';
import Trans from '@/components/shared/Trans';

export default function TokkoTips() {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  return (
    <Box
      className={style.container}
      style={
        open ? { height: `${ref.current.scrollHeight}px` } : { height: '52px' }
      }
      ref={ref}
    >
      <Box className={style.subCont}>
        <Box className={style.tips}>
          <Icon icon="icon-tokkotip" fontSize="20px" />
        </Box>
        <Box className={style.textContainer}>
          <Text className={style.text} variant="bodyBold.fontSize14">
            Tokko Tips
          </Text>
          <Text className={style.subText} variant="body.fontSize12">
            {Trans(translations, 'tokkoTips.AvailableTags')}
          </Text>
        </Box>
        <Icon
          className={`${style.icon} ${open && style.rotate}`}
          onClick={() => setOpen(!open)}
          icon="icon-dropdown"
          fontSize="18px"
        />
      </Box>
      <Text className={style.text} variant="body.fontSize11">
        {`${Trans(translations, 'tokkoTips.UseTags')} [[${Trans(
          translations,
          'tokkoTips.report'
        )}]]*, [[${Trans(
          translations,
          'tokkoTips.propietary_names'
        )}]], [[${Trans(translations, 'tokkoTips.property_names')}]],
        [[${Trans(translations, 'tokkoTips.contact_names')}]], [[${Trans(
          translations,
          'tokkoTips.contact_first_name'
        )}]], [[${Trans(translations, 'tokkoTips.owner_first_name')}]]. ${Trans(
          translations,
          'tokkoTips.ReportField'
        )}`}
      </Text>
    </Box>
  );
}
