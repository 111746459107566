import { Box, Checkbox, Text } from '@oneloop/jopijs';
import styles from './styles.module.scss';

const CHEVRON_WIDTH = 25;

function EmailsDropdown({ parentRef, emails, handleToggle }) {
  const parentRects = parentRef.current.getBoundingClientRect();
  const axisXTranslation = -parentRects.width + CHEVRON_WIDTH;

  const handleClickContainer = (event) => {
    event.stopPropagation();
  };

  return (
    <Box
      className={styles.emails}
      onClick={handleClickContainer}
      __css={{ translate: `${axisXTranslation}px 2px` }}
    >
      {emails.map((email) => (
        <Box
          key={email.value}
          as="label"
          data-testid="contact-email-row"
          className={`${styles['email-row']} ${
            email.active ? styles['email-row--checked'] : ''
          }`}
          htmlFor={email.value}
        >
          <Checkbox
            id={email.value}
            defaultChecked={email.active}
            value={email.value}
            onChange={handleToggle}
          />
          <Text className={styles['email-text']}> {email.value} </Text>
        </Box>
      ))}
    </Box>
  );
}

export default EmailsDropdown;
