import { Box, Icon, Text } from '@oneloop/jopijs';
import { Avatar } from 'stream-chat-react';
import styles from './AgentsList.module.scss';
import translations from './AgentsList.trans.json';
import Trans from '@/components/shared/Trans';

export default function AgentCard({
  itemClick,
  agent,
  selectedAgent,
  me,
  ...props
}) {
  return (
    <Box
      {...props}
      onClick={() => itemClick(agent)}
      className={styles.agentCard}
      key={agent.id}
    >
      <Box className={styles.agentCardInfo}>
        <Box
          __css={{
            position: 'relative',
          }}
        >
          <Avatar
            className={styles.avatarStyles}
            image={
              agent.profile_image ||
              `https://ui-avatars.com/api/?name=${agent.full_name}`
            }
            shape="rounded"
            size={46}
          />

          {selectedAgent.find((selected) => selected.id === agent.id) && (
            <Box className={styles.selectedAgentCheck}>
              <Icon icon="icon-aceptar" fontSize="12px" color="white" />
            </Box>
          )}
        </Box>

        <Text variant={['bodyBold.fontSize14', 'ellipsis']}>
          {agent.full_name}
        </Text>

        {me && <Box as="span">{Trans(translations, 'Me')}</Box>}
      </Box>
    </Box>
  );
}
