/* eslint-disable react/no-array-index-key */
import { Box, Image, Text } from '@oneloop/jopijs';
import style from '../QuickDisplay.module.scss';
import Collapse from '@/components/collapse/Collapse';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import UderLineItem from './UnderLineItem';

export default function InternalInfoDevelopment({
  information,
  internal_info,
}) {
  return (
    <Collapse
      title={
        <Text className={style.internalText} variant="bodyBold.fontSize14">
          {Trans(translations, 'InternalInformation')}
        </Text>
      }
    >
      <Box className={style.contInternalInfo}>
        <Box className={style.internalInfo}>
          <Box className={style.cardBranch}>
            <Image
              className={style.devImg}
              src={information.logo}
              variant="rows"
            />
            <Box className={style.prodText}>
              <Box>
                <Text variant="bodyBold.fontSize14">
                  {Trans(translations, 'Branch')}
                </Text>
                <Text variant="body.fontSize14">{information.branch_name}</Text>
              </Box>
            </Box>
          </Box>

          {information.users_in_charge.length > 0 && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'UsersInCharge')}:{' '}
              {information.users_in_charge.map((item, i) => (
                <Box as="span" key={`userCharge${i}`}>
                  {i !== 0 && ', '}
                  <UderLineItem
                    text={item.name}
                    url={`/user/${item.id}/details`}
                  />
                </Box>
              ))}
            </Text>
          )}

          {information.commercial_contacts.length > 0 && (
            <Text variant="body.fontSize14">
              {Trans(translations, 'ComercialContact')}:{' '}
              {information.commercial_contacts.map((item, i) => (
                <Box as="span" key={`commercial${i}`}>
                  {i !== 0 && ', '}
                  <UderLineItem text={item.name} url={`/contact/${item.id}`} />
                </Box>
              ))}
            </Text>
          )}

          {information.developer && (
            <Box className={style.tagProp}>
              <Box style={{ marginRight: '4px' }} as="span">
                <Box style={{ fontWeight: '700' }} as="span">
                  {Trans(translations, 'Developer')}
                </Box>{' '}
                <Box as="span">
                  <UderLineItem
                    text={information.developer.name}
                    url={`/contact/${information.developer.id}`}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {internal_info.internal_comments && (
          <Box className={style.comment}>
            <Text className={style.internalText} variant="bodyBold.fontSize14">
              {Trans(translations, 'InternalComments')}
            </Text>
            <Box>
              {internal_info.internal_comments.split(/\n/).map((line, index) =>
                line ? (
                  <Text
                    key={`line${index}`}
                    className={style.neutral2}
                    variant="body.fontSize14"
                  >
                    {line}
                  </Text>
                ) : (
                  <br key={`line${index}`} />
                )
              )}
            </Box>
          </Box>
        )}

        {internal_info.financing_details && (
          <Box className={style.comment}>
            <Text className={style.internalText} variant="bodyBold.fontSize14">
              {Trans(translations, 'FinancingDetails')}
            </Text>
            <Text className={style.internalInfo} variant="body.fontSize14">
              {internal_info.financing_details}
            </Text>
          </Box>
        )}
      </Box>
    </Collapse>
  );
}
