import { EditorContent } from '@tiptap/react';
import QuickResponses from './QuickResponses';
import Toolbar from './Toolbar';
import { useWysiwygEditor } from './hooks/useWysiwygEditor';
import styles from './styles.module.scss';

export function ToolbarCompound() {
  const { editor } = useWysiwygEditor();
  return <Toolbar editor={editor} />;
}

export function EditorCompound() {
  const { editor } = useWysiwygEditor();
  return (
    <EditorContent
      editor={editor}
      className={styles['sender-editor']}
      aria-label="sender-editor"
    />
  );
}

export function QuickResponsesCompound({ onSelect }) {
  const { editor } = useWysiwygEditor();

  // Intermediate step
  const handleSelect = (props) => {
    const { html } = props;
    editor.commands.clearContent();
    editor.commands.setContent(html);

    onSelect(props);
  };

  return <QuickResponses onSelect={handleSelect} />;
}
