import { Box } from '@oneloop/jopijs';
import styles from './BadgeUser.module.scss';
import LeadStatusBadge from '@/components/LeadStatusBadge/LeadStatusBadge';
import Skeletons from '@/components/Skeletons/Skeletons';
import UseGetContact from '@/components/Messenger/Hooks/useGetContact';

export default function BadgeUser({ text, styleClass, lead, id }) {
  if (lead) {
    const currentContact = UseGetContact(id);

    return currentContact ? (
      <LeadStatusBadge
        text={currentContact.status.name}
        hexColor={`#${currentContact.status.color}`}
      />
    ) : (
      <Skeletons height={17} width={110} borderRadius={4} />
    );
  }

  return (
    <Box className={`${styles.badgeContainer} ${styles[styleClass]}`} as="span">
      {text}
    </Box>
  );
}
