import isAValidEmail from './isAValidEmail';

const filterAndMapEmails = (contactInfo) => {
  const { work_email, other_email, email: mainEmail } = contactInfo;
  const possibleEmails = [mainEmail, work_email, other_email];
  const filteredEmails = possibleEmails.filter((email) => email);

  return filteredEmails.map((email, index) => ({
    value: email,
    active: index === 0,
  }));
};

const createSuggestion = (
  id,
  name,
  emails,
  isValid,
  isRaw,
  additionalFields = {}
) => ({
  id: String(id),
  name,
  emails,
  isValid,
  isRaw,
  ...additionalFields,
});

const createContact = {
  suggestion: {
    email: (suggestion) => {
      const { id, name, contact_info: contactInfo } = suggestion;
      const emails = filterAndMapEmails(contactInfo);
      const isValid = emails.length > 0;
      const isRaw = false;

      return createSuggestion(id, name, emails, isValid, isRaw);
    },
    whatsapp: (suggestion) => {
      const { id, name, contact_info: contactInfo } = suggestion;
      const { cellphone = null } = contactInfo;
      const isValid = true;
      const isRaw = false;
      const isValidated = false;

      return createSuggestion(id, name, [], isValid, isRaw, {
        cellphone,
        isValidated,
      });
    },
  },
  raw: {
    email: (input) => {
      const trimmedInput = input.trim();
      const isValid = isAValidEmail(trimmedInput);
      const isRaw = true;

      return createSuggestion(
        trimmedInput,
        trimmedInput,
        [{ value: trimmedInput, active: true }],
        isValid,
        isRaw
      );
    },
    whatsapp: (number, fieldsToOverride = {}) => {
      const trimmedNumber = number.trim();
      const id = trimmedNumber;
      const name = trimmedNumber;
      const cellphone = trimmedNumber;
      const isValid = true;
      const isRaw = true;
      const isValidated = true;

      return createSuggestion(id, name, [], isValid, isRaw, {
        cellphone,
        isValidated,
        ...fieldsToOverride,
      });
    },
  },
  related: (relatedContact) => {
    const { contact_related, relation } = relatedContact;
    const { id, name: plainRelatedName, ...contactInfo } = contact_related;

    const emails = filterAndMapEmails(contactInfo);
    const isValid = emails.length > 0;
    const isRaw = false;
    const name = relation
      ? `${plainRelatedName} (${relation})`
      : plainRelatedName;

    return createSuggestion(id, name, emails, isValid, isRaw);
  },
  new: (newContact) => {
    const { cellphone, email, id, name } = newContact;

    const emails = email ? [{ value: email, active: true }] : [];

    const isValid = true;
    const isRaw = false;
    const isValidated = true;

    return createSuggestion(id, name, emails, isValid, isRaw, {
      cellphone,
      isValidated,
    });
  },
};

export default createContact;
