import { Box, Icon, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';

export default function DevelopmentFiles({ downloadFile }) {
  const { developmentFiles } = useSelector((state) => state.QuickDisplaySlice);

  if (developmentFiles.loading) {
    return null;
  }

  const { data } = developmentFiles;

  return (
    <Box className={style.kebabCont}>
      <Text className={style.gray1} variant="bodyBold.fontSize16">
        {Trans(translations, 'DevelopmentFiles')}
      </Text>
      {data ? (
        <Box className={style.sendigsCont}>
          {data.files.map((item) => (
            <Box className={style.filesItem} onClick={() => downloadFile(item)}>
              <Box
                className={`${style.filesIcon} ${
                  style[item.icon.substring(5)]
                }`}
              >
                <Icon icon={item.icon} fontSize="16px" />
              </Box>

              <Box __css={{ flex: '1' }} className={style.filesText}>
                <Text variant={['body.fontSize14', 'ellipsis']}>
                  {item.name}
                </Text>
              </Box>

              <Box className={style.downloadItem}>
                <Icon icon="icon-descargar" fontSize="16px" />
              </Box>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
