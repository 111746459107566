import { useEffect, useState } from 'react';
import { Box, ButtonIcon, Icon, Input } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import translations from './AgentsList.trans.json';
import Trans from '@/components/shared/Trans';
import styles from './AgentsList.module.scss';
import AgentSkeleton from './AgentSkeleton';
import AgentCard from './AgentCard';

export default function AgentsList({
  getData = () => {},
  data = [],
  selectedAgent = [],
  itemClick = () => {},
  loading = false,
  showProfileUser = false,
}) {
  const [text, setText] = useState('');
  const [agentData, setAgentData] = useState(data);
  const { id: userID } = useSelector((state) => state.UserSlice.data);

  const handleData = () => {
    if (!showProfileUser) {
      return data.filter((d) => d.id !== userID);
    }

    return data;
  };

  const handleChange = (e) => {
    setText(e.target.value);
    const query = e.target.value.trim().toLowerCase();

    const filterData = handleData().filter((agent) =>
      agent.full_name.toLowerCase().includes(query)
    );

    return setAgentData(filterData);
  };

  const handleClearText = () => {
    setText('');
    setAgentData(handleData());
  };

  useEffect(() => {
    if (!data.length) {
      getData();
    }

    if (handleData().length) {
      setAgentData(handleData());
    }

    return () => {
      itemClick();
    };
  }, [data]);

  return (
    <Box className={styles.AgentsComponentContainer}>
      <Input
        prefix={<Icon icon="icon-buscar" fontSize="14px" />}
        suffix={
          text !== '' ? (
            <ButtonIcon
              variant={['transparentIcon', 'iconExtraSmall22px']}
              icon="icon-cerrar"
              onClick={handleClearText}
            />
          ) : undefined
        }
        placeholder={Trans(translations, 'SearchAgent')}
        variant="inputSearch"
        width="100%"
        onChange={handleChange}
        value={text}
      />

      <Box className={styles.listContainer}>
        {/*         {agentData.length === 0 && !loading && (
          <Box className={styles.notFoundfilteredContactsBox}>
            <Text variant="body.fontSize14">
              {Trans(translations, 'NoResultsFoundFor', text)}
            </Text>
          </Box>
        )} */}

        {agentData.map((agent) => (
          <Box key={agent.id}>
            <AgentCard
              itemClick={itemClick}
              agent={agent}
              selectedAgent={selectedAgent}
              me={userID === agent.id}
            />

            {userID === agent.id && <hr />}
          </Box>
        ))}

        {loading && <AgentSkeleton />}
      </Box>
    </Box>
  );
}
