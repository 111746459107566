import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';
import { Extension } from '@tiptap/core';
import HardBreak from '@tiptap/extension-hard-break';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Underline from '@tiptap/extension-underline';

const notWriteOnImagePlugin = new Plugin({
  key: new PluginKey('notWriteOnImagePlugin'),
  props: {
    handleTextInput(view, _from, _to, text) {
      const { state, dispatch } = view;
      const { selection } = state;
      const { $from } = selection;

      if (selection?.node?.type?.name === 'image') {
        const { tr } = state;
        tr.insert($from.pos + 1, state.schema.text(text));
        tr.setSelection(
          TextSelection.create(tr.doc, $from.pos + 1 + text.length)
        );
        dispatch(tr);
        return true;
      }

      return false;
    },
  },
});

export const CustomHardBreak = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
    };
  },
});

export const CustomHighlight = Highlight.extend({
  parseHTML() {
    return [
      { tag: 'mark' },
      {
        tag: 'span',
        getAttrs: (element) =>
          Boolean(element.style?.backgroundColor) &&
          element.nodeName === 'SPAN',
      },
    ];
  },

  addAttributes() {
    if (!this.options.multicolor) {
      return {};
    }

    return {
      color: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-color') || element.style.backgroundColor,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          return {
            'data-color': attributes.color,
            style: `background-color: ${attributes.color}; display: inline-flex;`,
          };
        },
      },
      fontColor: {
        default: null,
        parseHTML: (element) => element.style.color,
        renderHTML: (attributes) => {
          if (!attributes.fontColor) {
            return {};
          }

          return {
            style: `color: ${attributes.fontColor ?? 'inherit'};`,
          };
        },
      },
    };
  },
});

export const CustomImageResize = Image.extend({
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      style: {
        default: 'cursor: pointer;',
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      preserve: {
        default: true,
      },
    };
  },

  addNodeView() {
    return ({ node, editor, getPos }) => {
      const { view } = editor;

      const dispatchNodeView = (paramWidth, paramHeight) => {
        if (typeof getPos === 'function') {
          const { width, height, ...rest } = node.attrs;
          view.dispatch(
            view.state.tr.setNodeMarkup(getPos(), undefined, {
              ...rest,
              // eslint-disable-next-line
              width: paramWidth ?? img.style.width,
              // eslint-disable-next-line
              height: paramHeight ?? img.style.height,
            })
          );
          editor.commands.focus();
        }
      };

      const createImageView = () => {
        const dom = document.createElement('div');
        dom.classList.add('node-view');
        dom.style.display = 'inline-flex';
        dom.style.position = 'relative';
        dom.style.width = 'fit-content';

        const borderContainer = document.createElement('div');
        borderContainer.style.position = 'absolute';
        borderContainer.style.top = '0';
        borderContainer.style.left = '0';
        borderContainer.style.right = '0';
        borderContainer.style.bottom = '0';
        borderContainer.style.border = '1px dashed transparent';
        borderContainer.style.zIndex = '31414';
        borderContainer.style.pointerEvents = 'none';

        const img = document.createElement('img');
        img.src = node.attrs.src;
        img.alt = node.attrs.alt ?? 'image';
        img.style.cssText = node.attrs.style;
        img.style.verticalAlign = 'bottom';
        img.style.cursor = 'pointer';

        if (node.attrs.width) {
          img.style.width = node.attrs.width;
        }

        if (node.attrs.height) {
          img.style.height = node.attrs.height;
        }

        img.onload = () => {
          const { height, width } = img.style;
          const { preserve } = node.attrs;
          const aspectRatio = img.naturalHeight / img.naturalWidth;
          let targetWidth;
          let targetHeight;

          if (height && !width) {
            targetWidth = `${height.split('px')[0] * aspectRatio}px`;
          }

          if (!height && width) {
            if (width.includes('%')) {
              const percentage = width.split('%')[0] / 100;
              const widthInPx =
                (dom.clientWidth + img.naturalWidth) * percentage;
              targetHeight = `${widthInPx * aspectRatio}px`;
              targetWidth = `${widthInPx}px`;
            } else {
              targetHeight = `${width.split('px')[0] * aspectRatio}px`;
            }
          }

          if (!height && !width && !preserve) {
            targetHeight = `${Math.min(170, img.naturalHeight)}px`;
            targetWidth = 'auto';
          }

          img.style.target = targetHeight;
          img.style.width = targetWidth;
          dispatchNodeView(targetWidth, targetHeight);
        };

        img.addEventListener('click', () => {
          if (!dom.classList.contains('edit-mode')) {
            dom.classList.add('edit-mode');
          }
        });

        dom.appendChild(borderContainer);
        dom.appendChild(img);

        return { dom, img, borderContainer };
      };

      const createResizeDots = (dom, img) => {
        const positions = [
          {
            top: '-3px',
            right: null,
            bottom: null,
            left: '-3px',
            cursor: 'nwse-resize',
          },
          {
            top: '-3px',
            right: '-3px',
            bottom: null,
            left: null,
            cursor: 'nesw-resize',
          },
          {
            top: null,
            right: '-3px',
            bottom: '-3px',
            left: null,
            cursor: 'nwse-resize',
          },
          {
            top: null,
            right: null,
            bottom: '-3px',
            left: '-3px',
            cursor: 'nesw-resize',
          },
        ];

        const borderStyles = [
          { borderBottom: 'none', borderRight: 'none' },
          { borderBottom: 'none', borderLeft: 'none' },
          { borderLeft: 'none', borderTop: 'none' },
          { borderRight: 'none', borderTop: 'none' },
        ];

        const dots = [];

        positions.forEach((pos, index) => {
          const dot = document.createElement('div');
          dot.style.border = '2px solid #1a4958aa';
          dot.style.backgroundColor = 'transparent';
          dot.style.backgroundClip = 'content-box';
          dot.style.position = 'absolute';
          dot.style.padding = '2px';
          dot.style.height = '10px';
          dot.style.width = '10px';
          dot.style.zIndex = '31415';
          dot.style.cursor = pos.cursor;
          dot.style.display = 'none';

          const currentBorderStyle = borderStyles[index];
          Object.assign(dot.style, currentBorderStyle);
          Object.assign(dot.style, pos);

          const onMouseDown = (e) => {
            e.preventDefault();
            if (index === 0 || index === 2) {
              document.body.classList.add('cursor-nwse');
            } else {
              document.body.classList.add('cursor-nesw');
            }

            const startX = e.clientX;
            const startY = e.clientY;
            const startWidth = parseFloat(getComputedStyle(img).width);
            const startHeight = parseFloat(getComputedStyle(img).height);
            const aspectRatio = startWidth / startHeight;

            const onMouseMove = (event) => {
              let newWidth;
              let newHeight;

              if (index === 0 || index === 1) {
                newWidth = startWidth - (event.clientX - startX);
                newHeight = startHeight - (event.clientY - startY);
              } else {
                newWidth = startWidth + (event.clientX - startX);
                newHeight = startHeight + (event.clientY - startY);
              }

              if (dot.style.cursor.includes('nwse-resize')) {
                newHeight = newWidth / aspectRatio;
              } else {
                newWidth = newHeight * aspectRatio;
              }

              img.style.width = `${newWidth}px`;
              img.style.height = `${newHeight}px`;
              dom.style.width = `${newWidth}px`;
              dom.style.height = `${newHeight}px`;
            };

            const onMouseUp = () => {
              document.removeEventListener('mousemove', onMouseMove);
              document.removeEventListener('mouseup', onMouseUp);
              document.body.classList.remove('cursor-nwse');
              document.body.classList.remove('cursor-nesw');

              dispatchNodeView();
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
          };

          dot.addEventListener('mousedown', onMouseDown);

          dom.append(dot);
          dots.push(dot);
        });

        return dots;
      };

      const { dom, img, borderContainer } = createImageView();
      const dots = createResizeDots(dom, img);

      const handleClickToggleEdition = (e) => {
        if (!dom.contains(e.target)) {
          dom.classList.remove('edit-mode');

          dots.forEach((dot) => {
            dot.style.display = 'none';
          });
          borderContainer.style.borderColor = 'transparent';
        } else {
          dom.classList.add('edit-mode');

          dots.forEach((dot) => {
            dot.style.display = 'block';
          });
          borderContainer.style.borderColor = '#1a4958';
        }
      };

      const handleBlur = (e) => {
        if (!dom.contains(e.target)) {
          dom.classList.remove('edit-mode');
          dots.forEach((dot) => {
            dot.style.display = 'none';
          });
          borderContainer.style.borderColor = 'transparent';
        } else {
          dom.classList.add('edit-mode');
          dots.forEach((dot) => {
            dot.style.display = 'block';
          });
          borderContainer.style.borderColor = '#1a4958';
        }
      };

      document.addEventListener('click', handleClickToggleEdition);
      document.addEventListener('keydown', handleBlur);

      const destroy = () => {
        document.removeEventListener('click', handleClickToggleEdition);
        document.removeEventListener('keydown', handleBlur);
      };

      return { dom, destroy };
    };
  },

  addProseMirrorPlugins() {
    return [notWriteOnImagePlugin];
  },
});

export const CustomLineHeight = Extension.create({
  name: 'lineHeight',

  addOptions() {
    return {
      types: [],
      defaultLineHeight: '1.5',
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: this.options.defaultLineHeight,
            parseHTML: (element) =>
              element.style.lineHeight || this.options.defaultLineHeight,
            renderHTML: (attributes) => {
              if (attributes.lineHeight === this.options.defaultLineHeight) {
                return {};
              }

              return { style: `line-height: ${attributes.lineHeight}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight:
        (alignment) =>
        ({ commands }) =>
          this.options.types.every((type) =>
            commands.updateAttributes(type, { lineHeight: alignment })
          ),
    };
  },
});

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      rel: {
        default: this.options.HTMLAttributes.rel,
      },
      class: {
        default: this.options.HTMLAttributes.class,
      },
      style: {
        renderHTML: (attributes) => ({
          style: attributes.style,
        }),
      },
    };
  },
});

export const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      style: {
        renderHTML: (attributes) => {
          const STYLES_DUPLICATED = [
            'margin',
            'text-align',
            'font-weight',
            'line-height',
          ];

          let updatedStyles = attributes.style || '';

          STYLES_DUPLICATED.forEach((property) => {
            const regex = new RegExp(`${property}:\\s*[^;]+;`, 'g');
            updatedStyles = updatedStyles.replaceAll(regex, '');
          });

          updatedStyles = updatedStyles.trim();
          updatedStyles = `${updatedStyles} margin: 0 0 10px;`;

          return { style: updatedStyles };
        },
      },
    };
  },
});

export const CustomUnderline = Underline.extend({
  parseHTML() {
    return [
      {
        tag: 'u',
      },
      {
        style: 'text-decoration',
        consuming: false,
        getAttrs: (style) => (style.includes('underline') ? {} : false),
      },
      {
        style: 'text-decoration-line',
        getAttrs: (style) => (style.includes('underline') ? {} : false),
      },
    ];
  },

  addAttributes() {
    return {
      style: {
        renderHTML: (attributes) => ({
          style: `${attributes.style} text-decoration: underline;`,
        }),
      },
    };
  },
});
