// blocker / higher priority first
const possibleEmailWarnings = [
  {
    id: 'no-contact',
    fields: ['contact'],
    isActive: (senderState) => {
      const { addressees } = senderState;
      return addressees.selected.length === 0;
    },
    isBlocker: true,
    messageKey: 'EmailWarningNoContact',
    firstButtonKey: 'GotIt',
    secondButtonKey: null,
  },
  {
    id: 'empty-message',
    fields: ['subject', 'message', 'properties', 'reports'],
    isActive: (senderState) => {
      const { addressees, subject, message, properties } = senderState;
      const noSubject = !subject;
      const noMessage = !message.text;
      const noProperties = properties.selected.length === 0;
      const noReports =
        addressees.selected.length === 1 &&
        (!addressees.selected[0].reports ||
          addressees.selected[0].reports.filter((report) => report.active)
            .length === 0);

      return noSubject && noMessage && noProperties && noReports;
    },
    isBlocker: true,
    messageKey: 'EmailWarningNoSubjectAndNorMessageNorPropertiesAndNoReports',
    firstButtonKey: 'GotIt',
    secondButtonKey: null,
  },
  {
    id: 'no-message-or-subject',
    fields: ['subject', 'message'],
    isActive: (senderState) => {
      const { subject, message } = senderState;
      const noSubject = !subject;
      const noMessage = !message.text;

      return noSubject && noMessage;
    },
    isBlocker: false,
    messageKey: 'EmailWarningNoSubjectAndNoMessage',
    firstButtonKey: 'KeepEditing',
    secondButtonKey: 'Send',
  },
  {
    id: 'no-subject',
    fields: ['subject'],
    isActive: (senderState) => {
      const { subject } = senderState;
      const noSubject = !subject;

      return noSubject;
    },
    isBlocker: false,
    messageKey: 'EmailWarningNoSubject',
    firstButtonKey: 'KeepEditing',
    secondButtonKey: 'Send',
  },
];

// blocker / higher priority first
const possibleWhatsappWarnings = [
  {
    id: 'no-contact',
    fields: ['contact'],
    isActive: (senderState) => {
      const { whatsapp } = senderState;
      const noContact = whatsapp.selected.length === 0;

      return noContact;
    },
    isBlocker: true,
    messageKey: 'WhatsappWarningNoContact',
    firstButtonKey: 'GotIt',
    secondButtonKey: null,
  },
  {
    id: 'no-message-or-properties',
    fields: ['message', 'properties'],
    isActive: (senderState) => {
      const { message, properties } = senderState;
      const noMessage = !message.text;
      const noProperties = properties.selected.length === 0;

      return noMessage && noProperties;
    },
    isBlocker: true,
    messageKey: 'WhatsappWarningNoMessageAndNoProperties',
    firstButtonKey: 'GotIt',
    secondButtonKey: null,
  },
  {
    id: 'no-message',
    fields: ['message'],
    isActive: (senderState) => {
      const { message } = senderState;
      const noMessage = !message.text;

      return noMessage;
    },
    isBlocker: false,
    messageKey: 'WhatsappWarningNoMessage',
    firstButtonKey: 'KeepEditing',
    secondButtonKey: 'Send',
  },
];

const getWarning = (senderState) => {
  const { show } = senderState;
  const possibleWarnings =
    show === 'email' ? possibleEmailWarnings : possibleWhatsappWarnings;

  const candidateWarnings = possibleWarnings
    .filter((warning) => warning.isActive(senderState))
    .map(({ isActive, ...restOfAttributes }) => restOfAttributes);

  const highestPriorityWarning = candidateWarnings[0] ?? null;
  return highestPriorityWarning;
};

export default getWarning;
