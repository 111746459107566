import { Box } from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import style from './descriptionBox.module.scss';
import translations from './descriptionBox.trans.json';
import Trans from '@/components/shared/Trans';

export default function DescriptionBox({ text, lines = 5, ...props }) {
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const boxRef = useRef(null);
  const [initialHeight, setInitialHeight] = useState(0);

  useEffect(() => {
    const boxElement = boxRef.current;
    boxRef.current.style.webkitLineClamp = lines;
    boxRef.current.style.maxHeight = `${boxRef.current.clientHeight}px`;
    setInitialHeight(boxRef.current.clientHeight);

    if (boxElement) {
      setIsOverflowing(boxElement.scrollHeight > boxElement.clientHeight);
    }
  }, [text]);

  useEffect(() => {
    if (!showMore && isOverflowing) {
      setTimeout(() => {
        boxRef.current.style.webkitLineClamp = lines;
      }, 300);
    } else {
      boxRef.current.style.webkitLineClamp = 'unset';
    }
    if (initialHeight) {
      boxRef.current.style.maxHeight = showMore
        ? `${boxRef.current.scrollHeight}px`
        : `${initialHeight}px`;
    }
  }, [showMore, isOverflowing]);

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(text),
  });

  const toggle = () => setShowMore((prev) => !prev);
  return (
    <Box className={style.descriptionCont} {...props}>
      <Box
        ref={boxRef}
        className={`${style.boxStyle} ${showMore ? style.show : ''}`}
        dangerouslySetInnerHTML={sanitizedData()}
      />
      {isOverflowing && (
        <Box as="span" className={style.actionBtn} onClick={toggle}>
          {showMore
            ? Trans(translations, 'ShowLess')
            : Trans(translations, 'ShowMore')}
        </Box>
      )}
    </Box>
  );
}
