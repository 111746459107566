import { useMemo } from 'react';
import { Box } from '@oneloop/jopijs';
import styles from '../styles.module.scss';

const lineHeights = ['1', '1.2', '1.4', '1.6', '1.8', '2'];

export default function LineHeightPicker({ onClick }) {
  const handleOnClick = (event) => {
    if (!onClick) return;

    const lineHeight = event.target.dataset.value;
    if (lineHeight) {
      onClick(lineHeight);
    }
  };

  const renderLineHeightButton = (lineHeight, onClickHandler) => (
    <Box
      key={lineHeight}
      as="button"
      className={styles['dropdown-item']}
      onClick={onClickHandler}
      data-event="backLineHeight"
      data-value={lineHeight}
    >
      {lineHeight}
    </Box>
  );

  const renderDropdownItems = useMemo(() => {
    const dropdown = [];
    for (let i = 0; i < lineHeights.length; i++) {
      const lineHeight = lineHeights[i];
      dropdown.push(renderLineHeightButton(lineHeight, handleOnClick));
    }

    return dropdown;
  }, [lineHeights]);

  return (
    <Box
      className={styles['dropdown-wrapper']}
      data-testid="line-height-picker"
    >
      <Box className={styles.dropdown}>{renderDropdownItems}</Box>
    </Box>
  );
}
