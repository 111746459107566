import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContact } from '@/store/slices/components/chat/services';

export default function UseGetContact(contactID) {
  const { contacts } = useSelector((state) => state.MessengerSlice);
  const dispatch = useDispatch();

  if (!contactID) return null;

  const findContact = useMemo(
    () => contacts.find((contact) => contact?.id === Number(contactID)),
    [contacts, contactID]
  );

  const [currentContact, setCurrentContact] = useState(findContact || null);

  useEffect(() => {
    if (!findContact) {
      dispatch(getContact(contactID)).then((res) => {
        try {
          const contact = res.payload.contacts[0];
          setCurrentContact(contact);
        } catch (error) {
          setCurrentContact(null);
        }
      });
    } else {
      setCurrentContact(findContact);
    }
  }, [findContact]);

  return currentContact;
}
