import { Box } from '@oneloop/jopijs';
import { useWysiwygEditor } from '../hooks/useWysiwygEditor';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  FontSizeButton,
  ColorButton,
  LineHeightButton,
  UnorderedListButton,
  OrderedListButton,
  AlignmentButton,
  LinkButton,
  ImageButton,
} from '../buttons';
import FontFamilyButton from '../buttons/FontFamilyButton';
import styles from './styles.module.scss';

const buttonComponents = {
  bold: BoldButton,
  italic: ItalicButton,
  underline: UnderlineButton,
  fontSize: FontSizeButton,
  fontFamily: FontFamilyButton,
  color: ColorButton,
  lineHeight: LineHeightButton,
  unorderedList: UnorderedListButton,
  orderedList: OrderedListButton,
  alignment: AlignmentButton,
  link: LinkButton,
  image: ImageButton,
};

function ButtonsGroup({ buttons = [] }) {
  const { editor, allowedButtons } = useWysiwygEditor();

  const buttonsToRender = buttons.filter((button) =>
    allowedButtons.includes(button)
  );

  if (buttonsToRender.length === 0) return null;
  if (allowedButtons.length === 0) return null;

  return (
    <Box className={styles['buttons-group']}>
      {buttonsToRender.map((possibleButton) => {
        const Button = buttonComponents[possibleButton];
        return <Button key={possibleButton} editor={editor} />;
      })}
    </Box>
  );
}

export default ButtonsGroup;
