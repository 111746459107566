/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import {
  MessageText,
  // ReactionSelector,
  useMessageContext,
  useChannelStateContext,
  useChatContext,
  useChannelActionContext,
} from 'stream-chat-react';
import styles from './CustomMessage.module.scss';
import './CustomMessage.scss';
import CustomMessageActions from '../CustomMessageActions/CustomMessageActions';
import { getColorForUser } from '@/components/shared/AvatarGenerator';
import CustomAttachemnts from '../CustomAttachments/CustomAttachments';

function CustomMessage({ setMessageReply }) {
  const [parentMessageState, setParentMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isHovered, setIsHovered] = useState(false);

  const { message, readBy } = useMessageContext();
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();
  const { jumpToMessage } = useChannelActionContext();
  const hasAttachments = message.attachments && message.attachments.length > 0;

  // Cambie a este nuevo formato ya que antes solo contemplaba la zona horaria de AR-Buenos Aires
  const horaFormateada = message?.created_at?.toLocaleTimeString([], {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  useEffect(() => {
    // Obtener el mensaje al que se responde si message.parent_id está presente
    if (message.quoted_message_id) {
      client
        .getMessage(message.quoted_message_id)
        .then((parentMessage) => {
          setParentMessage(parentMessage);
        })
        .catch(() => {
          // console.error('Error al obtener el mensaje padre:', error);
        });
    }
  }, [message.quoted_message_id]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const justReadByMe =
    readBy.length === 1 && readBy[0] && readBy[0].id === client.userID;
  const sending = message.status === 'sending';
  const delivered = message.status === 'received';
  const deliveredAndRead = !!(readBy?.length && !justReadByMe);

  const myMessage = message.user.id === client.userID;

  useEffect(() => {
    // Actualiza el mapa de colores cuando cambia el usuario del mensaje
    getColorForUser(message.user?.id);
  }, [message.user?.id]);

  const getUserName = (channelData, messageData) => {
    if (channelData?.data?.type?.startsWith('WhatsApp')) {
      return !messageData?.from_guest_user
        ? messageData.user.name
        : channelData.data.guest_user;
    }
    return messageData.user.name;
  };
  const getUserNameClient = (channelData, messageData, clientData) => {
    if (channelData?.data?.type?.startsWith('WhatsApp')) {
      if (!messageData?.from_guest_user) {
        if (messageData.user.id !== clientData.userID) {
          return messageData.user.name;
        }
        return null;
      }
      if (channelData?.data?.type === 'WhatsAppGuest') {
        return `~${channelData.data.channel_header?.description || ''} ${
          channelData.data.guest_user
        }`;
      }
      if (channelData?.data?.type === 'WhatsAppContact') {
        return `${channelData.data.channel_header?.description || ''}`;
      }
    }
    return messageData.user.name;
  };

  // funcion con validaciones feas que tenemos que volar
  const guestChannel = () => {
    if (channel.type === 'WhatsAppGuest') {
      return message?.from_guest_user ? 'initial' : 'row-reverse';
    }

    return !message?.from_guest_user &&
      message.user?.id !== client.userID &&
      message?.is_history
      ? 'row-reverse'
      : null;
  };

  return (
    message && (
      <Box>
        <Box
          className={
            message.user.id !== client.userID || message?.from_guest_user
              ? styles.messageContainerBox
              : styles.messageContainerBoxClient
          }
          style={{
            flexDirection: guestChannel(),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            className={
              message.user.id !== client.userID
                ? styles.messageWrapper
                : styles.messageWrapperClient
            }
          >
            <Box
              className={
                message.user.id !== client.userID
                  ? styles.messageWrapperContent
                  : styles.messageWrapperClientContent
              }
            >
              <Box
                className={
                  message.user.id !== client.userID || message?.from_guest_user
                    ? styles.messageContainerUser
                    : styles.messageContainerClient
                }
                style={{
                  backgroundColor: message?.is_history ? '#FFE3C1' : null,
                }}
              >
                {/* cuando es un mensaje de respuesta */}
                {parentMessageState && (
                  <Box
                    className={[
                      message.user.id !== client.userID
                        ? styles.responseMessage
                        : styles.responseMessageClient,
                      styles.cursorPointerStyle,
                    ]}
                    onClick={async () => {
                      jumpToMessage(parentMessageState?.message?.id); // Al hacer click en el mensaje respondido salta al mensaje original al que se respondió
                    }}
                  >
                    <p className={styles.responseUserName}>
                      {parentMessageState.message.user.name}
                    </p>
                    <p
                      className={
                        message.user.id !== client.userID
                          ? styles.responseText
                          : styles.responseTextClient
                      }
                    >
                      {parentMessageState?.message?.attachments[0]
                        ?.image_url && (
                        <img
                          style={{ width: '150px' }}
                          alt={parentMessageState?.message?.text}
                          src={
                            parentMessageState?.message?.attachments[0]
                              .image_url
                          }
                        />
                      )}
                      {parentMessageState?.message?.text}
                    </p>
                    {parentMessageState.message.attachments[0]?.title && (
                      <CustomAttachemnts message={parentMessageState.message} />
                    )}
                  </Box>
                )}
                {/* cuando el canal es un grupo */}
                {channel.data.member_count >= 3 &&
                message.user.id !== client.userID ? (
                  <p
                    className={styles.messageName}
                    style={{ color: getColorForUser(message.user.id) }}
                  >
                    {getUserName(channel, message)}
                  </p>
                ) : (
                  <p
                    className={styles.messageName}
                    style={{ color: getColorForUser(message.user.id) }}
                  >
                    {getUserNameClient(channel, message, client)}
                  </p>
                )}
                {channel?.data?.type === 'Colleague' && message?.is_history && (
                  <p
                    className={styles.messageName}
                    style={{ color: getColorForUser(message?.user.id) }}
                  >
                    {message?.user?.name}
                  </p>
                )}
                <Box className={styles.boxCenter}>
                  <CustomAttachemnts message={message} />
                </Box>

                <Box
                  className={
                    hasAttachments
                      ? styles.textTimeBoxImage
                      : styles.textTimeBox
                  }
                >
                  <Box
                    className={
                      channel.data.member_count >= 3 &&
                      message.user.id !== client.userID
                        ? styles.messageText
                        : styles.messageTextClient
                    }
                    style={{
                      padding:
                        channel.data.member_count >= 3
                          ? '0px 8px 1px 8px'
                          : '0px 8px 1px 8px',
                    }}
                  >
                    <Box
                      style={{
                        paddingTop:
                          channel.data.member_count >= 3 &&
                          message.user.id !== client.userID
                            ? '0px'
                            : '5px',
                      }}
                    >
                      <MessageText />
                    </Box>
                  </Box>
                  <Box className={styles.timeBoxMessage}>
                    <span className={styles.messageTime}>{horaFormateada}</span>
                    {deliveredAndRead && myMessage && (
                      <Icon
                        icon="icon-doblecheck"
                        fontSize="14px"
                        color="#4268EF"
                      />
                    )}
                    {sending && myMessage && (
                      <Icon
                        icon="icon-aceptar"
                        fontSize="14px"
                        color="#143742"
                      />
                    )}
                    {delivered && !deliveredAndRead && myMessage && (
                      <Icon
                        icon="icon-doblecheck"
                        fontSize="14px"
                        color="#143742"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={
              message.user.id !== client.userID
                ? styles.reactionContainerClient
                : styles.reactionContainer
            }
          >
            <Box
              className={
                message.user.id !== client.userID
                  ? styles.clientOptionsContainer
                  : styles.optionsContainer
              }
            >
              {!channel?.data?.frozen && isHovered && (
                <CustomMessageActions
                  client={client}
                  setMessageReply={setMessageReply}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default CustomMessage;
