function getHowManyCollapsedToShow(selector) {
  const ELEMENTS_TO_SKIP = 'form';
  const CORNER_CASE_VALUE = 0;
  const MIN_TO_SHOW = 1;
  const MAX_TO_SHOW = 2;

  const filterElement = (child) =>
    child.tagName.toLowerCase() !== ELEMENTS_TO_SKIP ||
    Boolean(child.dataset.element);

  const container = document.querySelector(selector);

  if (!container) return CORNER_CASE_VALUE;
  const children = [...container.children].filter(filterElement);

  if (children.length <= MIN_TO_SHOW) return MIN_TO_SHOW;

  const firstChild = children[0];
  const secondChild = children[1];

  // for the case when I remove some of them
  firstChild.style.display = 'flex';
  secondChild.style.display = 'flex';

  const containerWidth = container.clientWidth;
  const firstChildWidth = children[0].clientWidth;
  const secondChildWidth = children[1].clientWidth;
  const THRESHOLD = containerWidth * 0.5;

  // undo previous style
  firstChild.style.display = null;
  secondChild.style.display = null;

  const isChildrenWidthTooLarge =
    firstChildWidth + secondChildWidth > THRESHOLD;

  return isChildrenWidthTooLarge ? MIN_TO_SHOW : MAX_TO_SHOW;
}

export default getHowManyCollapsedToShow;
