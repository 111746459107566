import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Icon } from '@oneloop/jopijs';
import { setSelectedProperties } from '@/store/slices/components/sender/SenderSlice';
import getPropertyStatusInKebabCase from '@/components/Sender/utils/getPropertyStatusInKebabCase';
import {
  getDevelopmentFiles,
  getPropertyAndDevelopmentFiles,
  getPropertyFiles,
} from '@/store/slices/components/sender/services';
import FilesButton from '../../FilesButton';
import TogglePropertyButton from '../../TogglePropertyButton';
import isAValidLink from '@/components/Sender/utils/isAValidLink';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from '../styles.module.scss';

function SelectedPropertyCard({ property }) {
  const dispatch = useDispatch();
  const { show, properties } = useSelector((state) => state.SenderSlice);
  const imageRef = useRef(null);
  const {
    reference_code,
    picture_url,
    status,
    is_development,
    address,
    name,
    location,
    files,
    is_edited_data,
    is_from_network,
    isEditedActive,
    edited,
  } = property;
  const targetPictureUrl = isEditedActive
    ? edited.picture_url || picture_url
    : picture_url;
  const imageIsVertical =
    imageRef?.current?.naturalHeight > imageRef?.current?.naturalWidth;
  const targetAddress = isEditedActive ? edited.address || address : address;

  const populateFiles = () => {
    if (show === 'whatsapp') return null;
    if (property.files || property.isLoadingFiles) return null; // already fetched, maybe preselected

    if (property.is_development) {
      return dispatch(getDevelopmentFiles(property.id));
    }

    if (property.development) {
      return dispatch(
        getPropertyAndDevelopmentFiles({
          propertyId: property.id,
          developmentId: property.development.id,
        })
      );
    }

    return dispatch(getPropertyFiles(property.id));
  };

  useEffect(() => {
    populateFiles();
  }, []);

  const handleRemoveSelected = () => {
    const updatedSelected = properties.selected.filter(
      (el) => el.reference_code !== reference_code
    );
    dispatch(setSelectedProperties(updatedSelected));
  };

  return (
    <Box
      data-testid="sender-property-selected"
      className={`${styles.card} ${styles['card-selected']} ${
        files?.length > 0 ? 'card-selected-with-files' : ''
      }`}
    >
      <Box
        className={styles['thumbnail-wrapper']}
        __css={{ height: 52, width: 82 }}
      >
        {isAValidLink(targetPictureUrl) ? (
          <Box
            as="img"
            ref={imageRef}
            className={styles['thumbnail-image']}
            data-is-vertical={imageIsVertical}
            src={targetPictureUrl}
          />
        ) : (
          <Box className={styles['placeholder-wrapper']}>
            <Icon icon="icon-propiedades" fontSize="24px" />
          </Box>
        )}
      </Box>
      <Box
        className={`${styles['main-information']} ${styles['main-information-selected']}`}
      >
        <Box className={styles['main-information-selected__upper-section']}>
          <Text
            className={`${styles['text--status']} ${
              styles[`text--status--${getPropertyStatusInKebabCase(status.id)}`]
            }`}
          >
            {status.name}
          </Text>

          {is_edited_data && <TogglePropertyButton property={property} />}

          {is_from_network && (
            <Box className={styles['network-badge']}>
              {Trans(translations, 'Network')}
            </Box>
          )}

          <Icon
            icon="icon-cerrar"
            fontSize="12px"
            onClick={handleRemoveSelected}
            className={styles['close-icon']}
          />
        </Box>
        <Text
          className={`${styles['text--address']} ${styles['text--address--selected']}`}
        >
          {is_development ? name : targetAddress}
        </Text>
        <Box className={styles['location-wrapper']}>
          <Text className={styles['text--location']}>{location}</Text>
          {show === 'email' && <FilesButton property={property} />}
        </Box>
      </Box>
    </Box>
  );
}

export default SelectedPropertyCard;
