import ToolbarButton from '../ToolbarButton';
import { ItalicIcon } from '../icons';

export default function ItalicButton({ editor }) {
  return (
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleItalic().run()}
      disabled={!editor.can().chain().focus().toggleItalic().run()}
      data-active={editor.isActive('italic')}
      aria-label="editor-italic"
    >
      <ItalicIcon />
    </ToolbarButton>
  );
}
