import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '@oneloop/jopijs';
import { setSelectedProperties } from '@/store/slices/components/sender/SenderSlice';
import getPropertyStatusInKebabCase from '@/components/Sender/utils/getPropertyStatusInKebabCase';
import isAValidLink from '@/components/Sender/utils/isAValidLink';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from '../styles.module.scss';

function SuggestionPropertyCard({ property }) {
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.SenderSlice);
  const imageRef = useRef(null);
  const {
    reference_code,
    picture_url,
    status,
    is_development,
    address,
    name,
    operations,
    link,
  } = property;
  const imageIsVertical =
    imageRef?.current?.naturalHeight > imageRef?.current?.naturalWidth;
  const isAlreadySelected = Boolean(
    properties.selected.find(
      (selected) => String(selected.id) === String(property.id)
    )
  );

  const handleSelectProperty = (event) => {
    event.stopPropagation();
    if (isAlreadySelected) return;
    if (is_development && !link) return;

    const updatedSelected = [...properties.selected, property];
    dispatch(setSelectedProperties(updatedSelected));
  };

  return (
    <Box
      as="button"
      data-testid="sender-property-suggestion"
      className={`${styles.card} ${styles['card-suggestion']} ${
        isAlreadySelected
          ? styles['card-suggestion-disabled-already-selected']
          : ''
      } ${
        is_development && !link
          ? styles['card-suggestion-disabled-no-link']
          : ''
      }`}
      onClick={handleSelectProperty}
      disabled={isAlreadySelected || (is_development && !link)}
    >
      <Box
        className={styles['thumbnail-wrapper']}
        __css={{ height: 38, width: 60 }}
      >
        {isAValidLink(picture_url) ? (
          <Box
            as="img"
            ref={imageRef}
            className={styles['thumbnail-image']}
            alt="property-thumbnail"
            data-is-vertical={imageIsVertical}
            src={picture_url}
          />
        ) : (
          <Box className={styles['placeholder-wrapper']}>
            <Icon icon="icon-propiedades" fontSize="16px" />
          </Box>
        )}

        {is_development && (
          <Box className={styles['development-icon-wrapper']}>
            <Icon icon="icon-emprendimientos" fontSize="12px" color="#674DC5" />
          </Box>
        )}
      </Box>
      <Box
        className={`${styles['main-information']} ${styles['main-information-suggestion']}`}
      >
        <Box className={styles['main-information-suggestion__upper-section']}>
          <Text
            className={`${styles['text--reference-code']} ${
              is_development && !link
                ? styles['text--reference-code-invalid']
                : ''
            }`}
          >
            {reference_code}
          </Text>

          {is_development && !link ? (
            <Text className={styles['text--no-link']}>
              {Trans(translations, 'NoLinkToSend')}
            </Text>
          ) : (
            <Text
              className={`${styles['text--status']} ${
                styles[
                  `text--status--${getPropertyStatusInKebabCase(status.id)}`
                ]
              }`}
            >
              {status.name}
            </Text>
          )}
        </Box>

        <Text
          className={`${styles['text--address']} ${
            styles['text--address--suggestion']
          } ${
            is_development && !link
              ? styles['text--address--suggestion-disabled']
              : ''
          }`}
        >
          {is_development ? name : address}
        </Text>
      </Box>

      {!is_development && !link && (
        <>
          <Box className={styles['operations-wrapper']}>
            {operations?.map((operation) => (
              <Text key={operation.type} className={styles.operation}>
                {operation.type}
              </Text>
            ))}
          </Box>
          <Box className={styles['add-wrapper']}>
            <Text className={styles.add}>{Trans(translations, 'Add')}</Text>
          </Box>
        </>
      )}

      {isAlreadySelected && (
        <Icon
          icon="icon-check"
          className={styles.check}
          fontSize="32px"
          color="#94A2AB"
        />
      )}
    </Box>
  );
}

export default SuggestionPropertyCard;
