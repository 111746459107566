import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import iconWhatsapp from '@/public/images/chat/whatsappIcon.svg';
import iconTokko from '@/public/images/chat/tokkoicon.svg';
import Trans from '@/components/shared/Trans';
import translations from './translations.trans.json';
import useGetDataUser from './useGetDataUser';
import BadgeUser from '../ChatMessenger/Components/BadgeUser/BadgeUser';
import LeadStatusProfile from '../ChatMessenger/Components/LeadStatusProfile/LeadStatusProfile';

export default function UseChannelTypeActions(channel) {
  const [channelActions, setChannelActions] = useState();
  const { user_perms } = useSelector((state) => state.AuthSlice.permissions);
  const user = useGetDataUser(channel);

  const { type } = channel.data;

  const DICT = {
    WhatsAppGuest: {
      labelAssign: Trans(translations, 'Assign'),
      checkPermission: user_perms?.marketing?.includes(
        'can_manage_web_contacts'
      ),
      class: 'whatsapp',
      fileIcon: iconWhatsapp,
      badgeComponent: channel.data.frozen ? null : (
        <BadgeUser
          text={Trans(translations, 'NewInquiry')}
          styleClass="whatsapp"
        />
      ),
      displayName: (
        <>
          ~{channel.data.channel_header?.description}{' '}
          <small>{channel.data.channel_header?.phone_number}</small>
        </>
      ),
      showLeadStatusProfile: null,
    },

    WhatsAppContact: {
      labelAssign: Trans(translations, 'Reassign'),
      checkPermission: user_perms?.contacts?.includes(
        'can_change_contact_agent'
      ),
      class: 'whatsapp',
      fileIcon: iconWhatsapp,
      badgeComponent: channel.data.frozen ? null : (
        <BadgeUser id={channel.data.contact?.id} lead />
      ),
      displayName:
        channel.data.channel_header?.description ||
        channel.data.channel_header?.phone_number,
      showLeadStatusProfile: (
        <LeadStatusProfile contactID={channel.data.contact?.id} />
      ),
    },

    Colleague: {
      labelAssign: Trans(translations, 'Reassign'),
      checkPermission: user_perms?.contacts?.includes(
        'can_change_contact_agent'
      ),
      class: 'colleage',
      fileIcon: iconTokko,
      badgeComponent: <BadgeUser text={user?.company?.name} />,
      displayName: user?.name,
      showLeadStatusProfile: (
        <LeadStatusProfile contactID={channel.data.contact?.id} />
      ),
    },
  };

  useEffect(() => {
    setChannelActions(DICT[type]);
  }, [user, channel]);

  return channelActions;
}
