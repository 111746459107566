import ApiService from '@/services/ApiService';

class NotificationService {
  static async getUserNotifications() {
    const composedURL = `/api3/notifications`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getUserStarredNotification() {
    const composedURL = `/api3/notifications/starred`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async markAllNotificationsAsRead() {
    const composedURL = `/api3/notifications/read`;
    const response = ApiService.post(composedURL);
    return response;
  }

  static async starNotification(id) {
    const composedURL = `/api3/notifications/${id}/star`;
    const response = ApiService.post(composedURL);
    return response;
  }

  static async unstarNotification(id) {
    const composedURL = `/api3/notifications/${id}/unstar`;
    const response = ApiService.post(composedURL);
    return response;
  }
}

export default NotificationService;
