import { Box, Button, Text } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function Presentation({ handleSkipTour, handleContinue }) {
  return (
    <Box className={styles.container}>
      <Box className={styles.modal}>
        <Box className={styles['text-wrapper']}>
          <Text className={styles.emoji}>🎉</Text>
          <Text className={styles.text}>
            {Trans(translations, 'TourTitleModal')}
          </Text>
        </Box>

        <Box className={styles['buttons-wrapper']}>
          <Button
            className={`${styles.button} ${styles['button--secondary']}`}
            variant={['secondaryText', 'large']}
            onClick={handleSkipTour}
          >
            {Trans(translations, 'Skip')}
          </Button>
          <Button
            className={styles.button}
            variant={['primary', 'large']}
            onClick={handleContinue}
          >
            {Trans(translations, 'LearnMore')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Presentation;
