/* eslint-disable react/no-array-index-key */
import { Box, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';

export default function PriceProperty() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);

  const Operations = {
    Sale: Trans(translations, 'Sale'),
    Rent: Trans(translations, 'Rent'),
    Temporary: Trans(translations, 'Temporary'),
  };

  if (!data) {
    return (
      <Box
        __css={{
          display: 'flex',
          gap: '2px',
          marginTop: '24px',
          flexDirection: 'column',
        }}
      >
        <Skeletons width={49} borderRadius={50} height={25} />
        <Skeletons width={155} borderRadius={50} height={16} />
      </Box>
    );
  }

  const desiredOrder = ['Sale', 'Rent', 'Temporary'];

  const orderedKeys = desiredOrder.filter((key) =>
    Object.keys(data.operations).includes(key)
  );

  return (
    <Box className={style.pricePropertyContainer}>
      {orderedKeys.map((op, index) => (
        <Box key={op + index} className={style.pricePropertyItem}>
          <Box as="span" className={style.typeOperationTag}>
            {Operations[op]}
          </Box>
          {data.operations[op].map((price, i) => (
            <Text
              key={price + i}
              className={style.price}
              variant="bodyBold.fontSize20"
            >
              {price}
            </Text>
          ))}
        </Box>
      ))}
    </Box>
  );
}
