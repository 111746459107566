import { Box, Dropdown, Loader, Text } from '@oneloop/jopijs';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function AfterSending({
  label,
  isLoading,
  listOfLeadStatus,
  selectedLeadStatus,
  handleSelect,
  ...rest
}) {
  const defaultCheckedLead =
    listOfLeadStatus.find((el) => el.defaultChecked) || listOfLeadStatus[0];
  const selectedLead = selectedLeadStatus || defaultCheckedLead;

  if (isLoading)
    return (
      <Box className={styles.container}>
        <Text className={styles.text}>
          {Trans(translations, 'AfterSending')}
        </Text>
        <Box className={styles['loading-wrapper']}>
          <Loader
            variant="spinner"
            style={{
              margin: 0,
              width: '16px',
              height: '16px',
              backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
            }}
          />
        </Box>
      </Box>
    );

  if (listOfLeadStatus.length === 0) return null;

  return (
    <Box className={styles.container} {...rest}>
      {label && <Text className={styles.text}>{label}</Text>}
      <Dropdown
        height="32px"
        width="268px"
        className={styles['dropdown-wrapper']}
      >
        <Dropdown.Button
          type="button"
          variant="dropdownDefault"
          text={
            <Box className={styles['selected-option-wrapper']}>
              <Box
                as="span"
                className={styles['color-box']}
                __css={{ backgroundColor: `#${selectedLead.color}` }}
              />
              <Text className={styles['selected-text']}>
                {selectedLead.text}
              </Text>
            </Box>
          }
          filled={selectedLead.text}
        />
        <Dropdown.Items
          className={styles['dropdown-items']}
          width="100%"
          height={null}
          maxHeight="150px"
          clickClose
        >
          {listOfLeadStatus.map((item) => (
            <Dropdown.Default
              key={item.id}
              onClick={() => handleSelect(item)}
              ellipsis
            >
              <Box className={styles['selected-option-wrapper']}>
                <Box
                  as="span"
                  className={styles['color-box']}
                  __css={{ backgroundColor: `#${item.color}` }}
                />
                <Text className={styles['option-text']}>{item.text}</Text>
              </Box>
            </Dropdown.Default>
          ))}
        </Dropdown.Items>
      </Dropdown>
    </Box>
  );
}

export default AfterSending;
