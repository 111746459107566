import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, ButtonIcon, Text } from '@oneloop/jopijs';
import PropertyCard from '../PropertyCard';
import SendingOptions from '../SendingOptions';
import TooltipMessage from '../../TooltipMessage';
import Trans from '@/components/shared/Trans';
import senderTranslations from '@/components/Sender/Sender.trans.json';
import favouritesTranslations from '@/components/Layout/Rightbar/Favorites/Favorites.trans.json';
import styles from './styles.module.scss';

function SelectedProperties({ showAddMore, handleClickAddMore, withBorder }) {
  const { fromOuterNet, properties } = useSelector(
    (state) => state.SenderSlice
  );
  const { permissions } = useSelector((state) => state.AuthSlice);
  const [showTooltipMessage, setShowTooltipMessage] = useState(false);
  const disableButton =
    properties.selected.length >= 10 &&
    !permissions?.user_perms?.properties?.includes('can_export_more_than_10');

  return (
    <Box className={styles.container}>
      <Box
        __css={{ borderColor: withBorder ? '#dde5e9' : 'transparent' }}
        className={styles['bordered-wrapper']}
      >
        <Box className={styles['cards-wrapper']}>
          {properties.selected.map((property) => (
            <PropertyCard
              key={property.id}
              property={property}
              variant="selected"
            />
          ))}
        </Box>

        <SendingOptions />

        {showAddMore && !fromOuterNet && (
          <Box __css={{ position: 'relative' }}>
            <ButtonIcon
              onMouseEnter={() => setShowTooltipMessage(true)}
              onMouseLeave={() => setShowTooltipMessage(false)}
              id="add-more-properties"
              variant={['transparentIcon']}
              icon="icon-agregar"
              className={`${styles['add-more']} ${
                disableButton ? styles['add-more--disabled'] : ''
              }`}
              text={Trans(senderTranslations, 'AddMore')}
              onClick={!disableButton ? handleClickAddMore : undefined}
            />

            {disableButton && showTooltipMessage && (
              <TooltipMessage centered __css={{ top: '-10px' }}>
                <Text className={styles['tooltip-text']}>
                  {Trans(
                    favouritesTranslations,
                    'NoPermissionMoreThan10Properties'
                  )}
                </Text>
              </TooltipMessage>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SelectedProperties;
