import { Box, Tabs, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import DetailContent from './DetailsContent/DetailsContent';
import NotificationsContent from './NotificationsContent/NotificationsContent';
import DetailEventEdit from './DetailEventEdit/DetailEventEdit';
import NotificationsEdit from './NotificationsEdit/NotificationsEdit';
import ReportTabs from './ReportsTab/ReportsTab';
import translations from './TabsModal.trans.json';
import Trans from '@/components/shared/Trans';
import ReportsTabEdit from './ReportsTabEdit/ReportsTabEdit';
import style from './TabsModal.module.scss';

export default function TabsModal() {
  const { edit, errors, reports } = useSelector(
    (state) => state.CalendarEvents
  );
  const { bodyTemplate, text } = reports;
  const refTab = useRef(null);

  useEffect(() => {
    if (Object.keys(errors).includes('needReport')) {
      refTab.current.click();
    }
  }, [errors]);

  const tabReportsValidation = () => {
    if (!edit.editLoader) {
      if (edit.done || !edit.active) {
        if (bodyTemplate !== '[[reporte]]' || text) {
          return true;
        }
      }

      if (edit.editItem && !edit.done && edit.active) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box __css={{ width: '550px' }}>
      <Tabs variant="minimal" itemTabSelected="0">
        <Tabs.Tab variant="minimal" id="item0" key="Tab0">
          <Text variant="bodyBold.fontSize14">
            {Trans(translations, 'Details')}
          </Text>
        </Tabs.Tab>

        <Tabs.Tab
          className={style.noPrint}
          variant="minimal"
          id="item1"
          key="Tab1"
        >
          <Text variant="bodyBold.fontSize14">
            {Trans(translations, 'Notifications')}
          </Text>
        </Tabs.Tab>

        {tabReportsValidation() && (
          <Tabs.Tab
            className={style.noPrint}
            refTab={refTab}
            variant="minimal"
            id="item2"
            key="Tab2"
          >
            <Text variant="bodyBold.fontSize14">
              {Trans(translations, 'Reports')}
            </Text>
          </Tabs.Tab>
        )}

        <Tabs.Content id="item0" key="Content0">
          {edit.editPanel ? <DetailEventEdit /> : <DetailContent />}
        </Tabs.Content>

        <Tabs.Content id="item1" key="Content1">
          {edit.editPanel ? <NotificationsEdit /> : <NotificationsContent />}
        </Tabs.Content>

        <Tabs.Content id="item2" key="Content2">
          {!edit.done && edit.active ? <ReportTabs /> : <ReportsTabEdit />}
        </Tabs.Content>
      </Tabs>
    </Box>
  );
}
