/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import { Box } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import style from './ModalRepeatEvent.module.scss';

export default function DaysBtns({ myDays, setMyDays }) {
  const { event } = useSelector((state) => state.CalendarEvents);

  const handleDays = (index) => {
    const handleLastActive = () => myDays.filter((d) => d.active);

    if (handleLastActive().length > 1 || !myDays[index].active) {
      setMyDays((prev) => {
        const updatedDays = [...prev];
        updatedDays[index] = {
          ...updatedDays[index],
          active: !updatedDays[index].active,
        };
        return updatedDays;
      });
    }
  };

  useEffect(() => {
    if (!myDays.some((d) => d.active)) {
      const index = event[0].start.getDay();

      handleDays(index);
    }
  }, []);

  return (
    <Box className={style.contDaysBtns}>
      {myDays.map((item, index) => (
        <Box
          key={item.day + index}
          className={`${style.dayBtn} ${item.active && style.pressed}`}
          onClick={() => handleDays(index)}
        >
          {item.day}
        </Box>
      ))}
    </Box>
  );
}
