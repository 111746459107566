import { useMemo } from 'react';
import styles from './styles.module.scss';

const colors = [
  '#000000',
  '#424242',
  '#636363',
  '#9C9C94',
  '#CEC6CE',
  '#EFEFEF',
  '#F7F7F7',
  '#FFFFFF',
  '#FF0000',
  '#FF9C00',
  '#FFFF00',
  '#00FF00',
  '#00FFFF',
  '#0000FF',
  '#9C00FF',
  '#FF00FF',
  '#F7C6CE',
  '#FFE7CE',
  '#FFEFC6',
  '#D6EFD6',
  '#CEDEE7',
  '#CEE7F7',
  '#D6D6E7',
  '#E7D6DE',
  '#E79C9C',
  '#FFC69C',
  '#FFE79C',
  '#B5D6A5',
  '#A5C6CE',
  '#9CC6EF',
  '#B5A5D6',
  '#D6A5BD',
  '#E76363',
  '#F7AD6B',
  '#FFD663',
  '#94BD7B',
  '#73A5AD',
  '#6BADDE',
  '#8C7BC6',
  '#C67BA5',
  '#CE0000',
  '#E79439',
  '#EFC631',
  '#6BA54A',
  '#4A7B8C',
  '#3984C6',
  '#634AA5',
  '#A54A7B',
  '#9C0000',
  '#B56308',
  '#BD9400',
  '#397B21',
  '#104A5A',
  '#085294',
  '#311873',
  '#731842',
  '#630000',
  '#7B3900',
  '#846300',
  '#295218',
  '#083139',
  '#003163',
  '#21104A',
  '#4A1031',
];

const MAX_COLUMNS = 8;
const MAX_ROWS = 8;

export default function ColorPicker({
  title,
  resetColorText,
  resetColorValue = '#000000',
  onChange,
}) {
  const handleOnClick = (event) => {
    if (!onChange) return;

    const color = event.target.dataset.value;
    if (color) {
      onChange(color);
    }
  };

  const renderColorButton = (color, onClickHandler) => (
    <button
      key={color}
      type="button"
      className={styles['note-color-btn']}
      style={{ backgroundColor: color }}
      onClick={onClickHandler}
      data-event="backColor"
      data-value={color}
      aria-label="color"
    />
  );

  const renderGrid = useMemo(() => {
    const grid = [];
    for (let i = 0; i < MAX_COLUMNS; i++) {
      const row = [];
      for (let j = 0; j < MAX_ROWS; j++) {
        const index = i * MAX_COLUMNS + j;
        row.push(renderColorButton(colors[index], handleOnClick));
      }

      grid.push(
        <div key={i} className="grid-row">
          {row}
        </div>
      );
    }

    return grid;
  }, [colors]);

  const triggerDefaultAction = () => {
    onChange(resetColorValue);
  };

  return (
    <div className={styles['grid-outer-container']}>
      <p className={styles.title}>{title}</p>
      <span className={styles['separation-line']} />
      <button
        type="button"
        className={styles['reset-color-text']}
        onClick={triggerDefaultAction}
      >
        {resetColorText}
      </button>
      <div className={styles['grid-inner-container']}>{renderGrid}</div>
    </div>
  );
}
