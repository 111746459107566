import { useEffect } from 'react';
import { Box, Text } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@/components/radio/Radio';
import { setLanguage } from '@/store/slices/components/sender/SenderSlice';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';

function LanguageOptions() {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.SenderSlice);

  const options = [
    { id: 'es', label: Trans(translations, 'Spanish'), value: 'es' },
    { id: 'en', label: Trans(translations, 'English'), value: 'en' },
  ];

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      const DEFAULT_LANGUAGE = options[0].value;
      dispatch(setLanguage(DEFAULT_LANGUAGE));
    };
  }, []);

  const handleChangeRadio = (event) => {
    const { value } = event.target.dataset;
    dispatch(setLanguage(value));
  };

  return (
    <Box className={styles.container}>
      <Text className={styles.title}>{Trans(translations, 'Language')}</Text>
      <Box className={styles.wrapper}>
        {options.map((option) => (
          <Box
            key={option.id}
            as="label"
            data-testid={`sender-language-${option.value}`}
            className={styles['radio-wrapper']}
          >
            <Radio
              id={option.id}
              onChange={handleChangeRadio}
              checked={language === option.value}
              data-value={option.value}
            />
            <Text className={styles['radio-text']}>{option.label}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default LanguageOptions;
