import { Box } from '@oneloop/jopijs';
import styles from './styles.module.scss';

function PropertiesSkeleton() {
  return (
    <Box className={styles.container}>
      <Box className={styles['title-skeleton']} />
      {[1, 2].map((skeleton) => (
        <Box
          key={skeleton}
          data-testid="sender-property-skeleton"
          className={styles.skeleton}
        >
          <Box className={styles['skeleton__left-side']}>
            <Box className={styles.skeleton__thumbnail} />
          </Box>
          <Box className={styles['skeleton__right-side']}>
            <Box className={styles['skeleton__short-text']} />
            <Box className={styles['skeleton__long-text']} />
            <Box className={styles['skeleton__long-text']} />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default PropertiesSkeleton;
