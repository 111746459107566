import { useState, useEffect } from 'react';
import { Box, Button, Card, Checkbox, Image, Text } from '@oneloop/jopijs';
import styles from './Contacts.module.scss';
import emptyContact from '../../../../../public/images/layout/emptyContact.svg';
import ImageNext from '@/components/shared/ImageNext';
import translations from './Contacts.trans.json';
import Trans from '@/components/shared/Trans';
import SkeletonFavorite from '../SkeletonFavorite/SkeletonFavorite';

function Contacts({
  dataContacts,
  listContacts,
  setListContacts,
  updateList,
  selectAll,
  isLoading,
}) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    if (dataContacts.length === 0) {
      setShowEmptyState(false);
      const timer = setTimeout(() => {
        setShowEmptyState(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    setShowEmptyState(true);
    return undefined;
  }, []);

  function openContact(id) {
    window.open(`${tokkoBrokerHost}/contact/${id}`, '_blank');
  }

  if (!showEmptyState || isLoading) return <SkeletonFavorite />;

  return showEmptyState && dataContacts.length === 0 ? (
    <Box className={styles.containerEmpty}>
      <ImageNext src={emptyContact} unoptimized alt="emptyContact" />
      <Text
        variant="body.fontSize12"
        className={`${styles.emptyText} ${styles.colorNeutralGray3}`}
      >
        {Trans(translations, 'favoritesContacts.Emptymessage')}
      </Text>
      <a
        target="_blank"
        href="https://dudas.tokkobroker.com/es/articles/432329-como-marcar-un-contacto-como-favorito"
        rel="noreferrer"
      >
        <Button variant="linkTwoLines">
          {Trans(translations, 'favoritesContacts.Howtosaveasfavorite')}
        </Button>
      </a>
    </Box>
  ) : (
    <Box className={styles.container}>
      <Card
        variant="contacts"
        width="224px"
        active={listContacts.length === dataContacts.length}
      >
        <Checkbox
          key={listContacts}
          onClick={() => selectAll(dataContacts, listContacts, setListContacts)}
          isChecked={listContacts.length === dataContacts.length}
        />
        <Text variant="body.fontSize12" className={styles.text}>
          {Trans(translations, 'Selectall')}
        </Text>
      </Card>
      {dataContacts.map((contact) => (
        <Card
          id={contact.id}
          key={contact.id}
          variant="normal"
          width="224px"
          active={listContacts.includes(contact.id)}
          onClick={() => openContact(contact.id)}
        >
          <Checkbox
            key={listContacts}
            width="20px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateList(listContacts, setListContacts, contact.id);
            }}
            isChecked={listContacts.includes(contact.id)}
          />
          <Image
            variant="avatar"
            icon="icon-perfil"
            src={contact.picture}
            alt={contact.id}
          />
          <Box className={styles.boxContainerText}>
            <Text variant="body600.fontSize12">{contact.name}</Text>
            <Text
              variant={['body.fontSize11', 'ellipsis']}
              className={styles.colorText}
            >
              {contact.phone}
            </Text>
            <Text
              variant={['body.fontSize11', 'ellipsis']}
              className={styles.colorText}
            >
              {contact.email}
            </Text>
          </Box>
          <span className="icon-atras" id={styles.icon} />
        </Card>
      ))}
    </Box>
  );
}

export default Contacts;
