import { Box, Icon, Loader, Text } from '@oneloop/jopijs';
import { Avatar } from 'stream-chat-react';
import { useSelector } from 'react-redux';
import styles from '../CustomChannelHeader.module.scss';
import translations from './AsignInquiry.trans.json';
import Trans from '@/components/shared/Trans';
import LeadStatusBadge from '@/components/LeadStatusBadge/LeadStatusBadge';

export default function DuplicateCard({ contact, loading, click }) {
  const { id: userID } = useSelector((state) => state.UserSlice.data);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const { name, lead_status, phone, valid_cellphone, email, agent, id } =
    contact;

  const handleRedirect = (redirecId) =>
    window.open(`${tokkoBrokerHost}/contact/${redirecId}/`, '_blank');

  return (
    <Box
      className={`${styles.duplicateCard} ${
        loading && loading !== contact.id ? styles.cardDisabled : ''
      }`}
    >
      <Box className={styles.duplicatedContactBox}>
        <Box className={styles.nameBox}>
          <Icon icon="icon-contactos" fontSize="16px" />

          <Text
            variant={['body.fontSize14', 'ellipsis']}
            onClick={() => handleRedirect(id)}
          >
            {name}
          </Text>

          <LeadStatusBadge
            text={lead_status.name}
            hexColor={lead_status.background_color}
          />
        </Box>

        <Box className={styles.mailBox}>
          <Icon icon="icon-email" fontSize="16px" />

          <Text variant={['body.fontSize12', 'ellipsis']}>
            {email || '---'}
          </Text>
        </Box>

        <Box className={styles.phoneBox}>
          <Box __css={{ color: '#1473A9' }}>
            <Icon icon="icon-whatsapp" fontSize="16px" />

            <Text variant={['body.fontSize12', 'ellipsis']}>
              {(valid_cellphone !== 'not_valid' && valid_cellphone) || '---'}
            </Text>
          </Box>
          <Box>-</Box>
          <Box>
            <Icon icon="icon-telefono" fontSize="16px" />

            <Text variant={['body.fontSize12', 'ellipsis']}>
              {phone || '---'}
            </Text>
          </Box>
        </Box>
      </Box>

      <hr />

      <Box className={styles.duplicatedAgentBox}>
        <Box className={styles.agentBox}>
          <Avatar
            className={styles.avatarStyles}
            image={
              agent.picture ||
              `https://ui-avatars.com/api/?name=${agent.full_name}`
            }
            shape="rounded"
            size={36}
          />

          <Box className={styles.agentName}>
            <Text variant="bodyBold.fontSize12">{agent.full_name}</Text>
            {userID === agent.id && (
              <Box as="span">{Trans(translations, 'Me')}</Box>
            )}
          </Box>
        </Box>

        <Box
          className={`${styles.assignBox} ${
            loading === contact.id ? styles.loadingState : ''
          }`}
          onClick={() => click(agent, id)}
        >
          <Icon icon="icon-reasignar" fontSize="16px" />

          <Text variant="bodyBold.fontSize12">
            {Trans(translations, 'Assign')}
          </Text>

          {loading === contact.id && (
            <Box className={styles.spinnerCont}>
              <Loader
                variant="spinner"
                style={{
                  backgroundImage: 'conic-gradient(#869498 ,#FFFFFF)',
                  margin: '0px',
                  width: '20px',
                  height: '20px',
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
