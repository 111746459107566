import store from '@/store/store';
import ContactsService from '@/services/ContactsService';

export async function getSafetyContact(selectedContact) {
  const { show } = store.getState().SenderSlice;

  if (!selectedContact.isRaw) return selectedContact;

  try {
    const contactValue =
      show === 'email'
        ? selectedContact.emails[0].value
        : selectedContact.cellphone;
    const checkIfExists =
      show === 'email'
        ? ContactsService.checkIfExistsByEmail
        : ContactsService.checkIfExistsByCellphone;
    const response = await checkIfExists(contactValue);
    const contactId = response.length === 0 ? null : response[0];

    if (contactId !== null) {
      const modifiedContact = { ...selectedContact, id: contactId };
      return modifiedContact;
    }

    const payload =
      show === 'email' ? { email: contactValue } : { cellphone: contactValue };

    const creationResponse = await ContactsService.createContact(payload);

    const createdContact = { ...selectedContact, id: creationResponse.id };
    return createdContact;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAllSafetyContacts(selectedContacts) {
  const promises = [];

  for (let i = 0; i < selectedContacts.length; i++) {
    const contact = selectedContacts[i];
    promises.push(getSafetyContact(contact));
  }

  const result = await Promise.all(promises);

  return result;
}
