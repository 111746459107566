import React from 'react';
import { Box, Icon, Text } from '@oneloop/jopijs';
import styles from './CalendarItem.module.scss';

function CalendarItem({ calendarItem }) {
  const { contacts, properties, when, name } = calendarItem;

  return (
    <Box className={styles.container} as="li">
      <Text variant="bodyBold.fontSize14" className={styles.date}>
        {when}
      </Text>
      <Text variant="bodyBold.fontSize12" className={styles.title}>
        {name}
      </Text>
      {contacts.length > 0 && (
        <Box className={styles.boxDescription}>
          <Icon
            icon="icon-contactos"
            fontSize="10px"
            className={styles.description}
          />
          <Text variant="body.fontSize11" className={styles.description}>
            {`${contacts[0]} ${
              contacts.length > 1 ? `+${contacts.length - 1}` : ''
            }`}
          </Text>
        </Box>
      )}
      {properties.length > 0 && (
        <Box className={styles.boxDescription}>
          <Icon
            icon="icon-propiedades"
            fontSize="10px"
            className={styles.description}
          />
          <Text variant="body.fontSize11" className={styles.description}>
            {`${properties[0]} ${
              properties.length > 1 ? `+${properties.length - 1}` : ''
            }`}
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default CalendarItem;
