import { useState, useRef, useEffect } from 'react';
import { Box, Loader, Text } from '@oneloop/jopijs';
import ContactsService from '@/services/ContactsService';
import Trans from '@/components/shared/Trans';
import translations from '@/components/Sender/Sender.trans.json';
import styles from './styles.module.scss';
import SenderService from '@/services/SenderService';

function AddNewContact({ input, handleAddNewContact, type }) {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingNewContact, setIsAddingNewContact] = useState(false);
  const [newContactName, setNewContactName] = useState('');
  const [isWhatsappError, setIsWhatsappError] = useState(false);

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, [inputRef.current, isAddingNewContact]);

  const toggleAddingNewContact = () => {
    setIsAddingNewContact((prevState) => !prevState);
  };

  const handlePressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      setIsAddingNewContact(false);
    }
  };

  const handleChange = (event) => {
    setNewContactName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isWhatsappError) return;

    try {
      setIsLoading(true);

      const body = {
        name: newContactName,
        email: type === 'email' ? input : '',
        cellphone: type === 'whatsapp' ? input : '',
      };

      if (type === 'whatsapp') {
        await SenderService.validateWhatsapp(input);
      }
      const result = await ContactsService.createContact(body);
      handleAddNewContact(result);
    } catch (error) {
      console.log(error);
      // TODO: if error is from whatsapp, then:
      setIsWhatsappError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <Box className={styles['outer-container']} onKeyDown={handlePressEscape}>
        <Box className={styles['inner-container']}>
          <Box className={styles['left-side']}>
            <Text className={styles.title}>
              <Box as="span" className={styles['title--highlighted']}>
                {input}
              </Box>
              <Box as="span">{Trans(translations, 'isNotAContact')}</Box>
            </Text>

            <Text className={styles.subtitle}>
              {Trans(translations, 'WhenSending')}
            </Text>

            {isAddingNewContact && (
              <Box
                as="form"
                className={styles.form}
                onSubmit={handleSubmit}
                disabled={isLoading}
              >
                <Box className={styles['input-wrapper']}>
                  <Box
                    as="input"
                    ref={inputRef}
                    className={`${styles.input} ${
                      isWhatsappError ? styles['input--error'] : ''
                    }`}
                    disabled={isLoading}
                    placeholder={Trans(translations, 'ContactsName')}
                    value={newContactName}
                    onChange={handleChange}
                  />
                </Box>

                {isLoading && (
                  <Box __css={{ border: 'none', padding: '0 8px' }}>
                    <Loader
                      variant="spinner"
                      style={{
                        width: '24px',
                        height: '24px',
                        backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                      }}
                    />
                  </Box>
                )}

                {!isLoading && (
                  <Box
                    as="button"
                    type="submit"
                    className={`${styles.button} ${
                      isWhatsappError ? styles['button--disabled'] : ''
                    }`}
                  >
                    {Trans(translations, 'AddName')}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {!isAddingNewContact && (
            <Box className={styles['right-side']}>
              <Box
                as="button"
                type="button"
                className={styles.button}
                onClick={toggleAddingNewContact}
                disabled={isLoading}
              >
                {Trans(translations, 'AddName')}
              </Box>
            </Box>
          )}
        </Box>
        {isWhatsappError && (
          <Text className={styles['error-message']}>
            {Trans(translations, 'InvalidWhatsappNumber')}
          </Text>
        )}
      </Box>
    </>
  );
}

export default AddNewContact;
