import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, Text } from '@oneloop/jopijs';
import {
  setCCData,
  setSelectedCC,
} from '@/store/slices/components/sender/SenderSlice';
import styles from './styles.module.scss';

const MAX_SELECTED_CC = 10;

function CheckboxRowCC({ contact }) {
  const { cc, related } = useSelector((state) => state.SenderSlice);
  const dispatch = useDispatch();
  const isMaxReached = cc.selected.length === MAX_SELECTED_CC;
  const contactIsAlreadySelected = Boolean(
    cc.selected.find((elem) => String(elem.id) === String(contact.id))
  );

  const firstValidContactEmail =
    contact.emails.find((email) => email.value)?.value || null;

  const getSelectedWithoutThisContact = (id) => {
    const updatedCCSelected = cc.selected.filter(
      (relatedContact) => String(relatedContact.id) !== String(id)
    );

    return updatedCCSelected;
  };

  const getSelectedWithThisContact = (id) => {
    const newContact = related.data.find(
      (element) => String(element.id) === String(id)
    );

    const updatedCCSelected = [...cc.selected, newContact];

    return updatedCCSelected;
  };

  const showAndExpandCC = () => {
    dispatch(setCCData({ key: 'show', value: true }));

    dispatch(
      setCCData({
        key: 'isCollapsed',
        value: false,
      })
    );
  };

  const handleToggleCheck = (event) => {
    if (isMaxReached) return;

    const { checked } = event.target;
    const { id } = event.target.dataset;

    const foundContact = cc.selected.find(
      (element) => String(element.id) === String(id)
    );

    const updatedCCSelected = foundContact
      ? getSelectedWithoutThisContact(id)
      : getSelectedWithThisContact(id);

    dispatch(setSelectedCC(updatedCCSelected));

    if (checked) {
      showAndExpandCC();
    }
  };

  return (
    <Box
      key={contact.id}
      as="label"
      htmlFor={firstValidContactEmail}
      aria-disabled={isMaxReached}
      className={styles['dropdown-item']}
      data-testid="related-contact-row"
    >
      <Checkbox
        id={firstValidContactEmail}
        data-id={contact.id}
        value={firstValidContactEmail}
        disabled={isMaxReached}
        onChange={handleToggleCheck}
        checked={contactIsAlreadySelected}
        className={`${styles['dropdown-item-checkbox']} ${
          contactIsAlreadySelected
            ? styles['dropdown-item-checkbox--checked']
            : ''
        }`}
      />
      <Text className={styles['dropdown-item-text']}>{contact.name}</Text>
    </Box>
  );
}

export default CheckboxRowCC;
